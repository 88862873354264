import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';
declare var $: any;
import {UserService} from '../../_api/user.service'
import { ToastrService } from 'ngx-toastr'
import { Users } from '../../models/users';
import {LoaderService} from '../../_api/loader.service';
@Component({
  selector: 'app-pendingusers',
  templateUrl: './pendingusers.component.html',
  styleUrls: ['./pendingusers.component.css']
})
export class PendingusersComponent implements OnInit {
  sortBy = "email";
  sortOrder = "asc";
  filter:any;
  step1:boolean=true;
  step2:boolean=false;
  public pendingPayment: any;
  public checkedValue: any;
  public flag: any;
  public checkedValueArray: any = [];
  public cntChk: any;
  public temp: any;
  public ids: any = [];
  public totalvalue: any;
  public pendingUserList: any;
  public rejectedgroupdata: any;
  public rejectedgroupmsg: any;
  public pendingusers: any=[];
  public comment: any; 
  isViewuser:boolean;
  isUniuser:boolean;
  isBulkuser:boolean;
  isAppruser:boolean;
  isViewcard:boolean;
  isUnicard:boolean;
  isApprcard:boolean;
  isViewgroup:boolean;
  isUnigroup:boolean;
  isApprgroup:boolean;
  isRuleValidate:boolean;
  isUnirule:boolean;
  isRuleappr:boolean;
  isViewsup:boolean;
  isUnisup:boolean;
  isBulksup:boolean;
  isApprsup:boolean;
  isViewpay:boolean;
  isUnipay:boolean;
  isApprpay:boolean;
  isBulkpay:boolean; 
  selectall:boolean=false;
  constructor(private userservice: UserService,private loaderService: LoaderService,private toastr: ToastrService,private route:Router) { }

  ngOnInit() {
    this.temp = false;
    this.checkedValue = "";
    this.flag = 0;
    this.cntChk = 0;
    this.totalvalue = 0;
    this.pendingusers = [];
    this.loadpendingUsers();

    $(" input[name='mainmenu']").click(function () {
      var is_checked = $(this).is(":checked");
      $("input[name='submenu']").prop("checked", is_checked);
    });

    $(".nxt2").click(function () {
      $("#reportname").show();
      $("#payreport").hide();
      $("#lstpaymt").hide();
      // $("#myModal").show();
    });
  }

  sortByDesc(sortByValue: string) : void {
    this.sortBy = sortByValue;
    this.sortOrder = "desc";
  }

  sortByAsc(sortByValue: string) : void {
    this.sortBy = sortByValue;
    this.sortOrder = "asc";
  }

  checkClick(): void {
    if (this.temp == true) {
      $("#payreport").modal("show");
    } else {
      alert("First select at least one checkbox.");
    }

  }

  change(id): void {
    this.flag = 0;
  for (var i = 0; i < this.checkedValueArray.length; i++) {
    if (this.checkedValueArray[i] == id) {
      this.checkedValueArray.splice(i, 1);
      this.flag = 1;
    }
  }
  if (this.flag == 0) {
    this.checkedValueArray.push(id);
   
  }

  if (this.checkedValueArray.length > 0) {
    this.temp = true;
    if(this.checkedValueArray.length<this.pendingusers.length){
      this.selectall=false
    }else{
      this.selectall=true;
      this.cntChk = 1;
    }
    console.log(this.selectall)
   
  }
  else {
    this.temp = false;
    if(this.checkedValueArray.length<this.pendingusers.length){
      this.selectall=false
    }else{
      this.selectall=true;
    }
    console.log(this.selectall)
   
  }
  console.log(this.checkedValueArray)
  }

  changeAll(pendingPayment): void {
    if(this.checkedValueArray.length==this.pendingusers.length){
      this.cntChk=1
      }else{
      this.checkedValueArray = [];
      this.cntChk=0
      }
      console.log(this.selectall)
      if (this.cntChk == 0) {
        this.cntChk = 1;
        this.temp = true;
        this.selectall=true;
        for (var i = 0; i < pendingPayment.length; i++) {
          this.checkedValueArray[i] = pendingPayment[i].id;
        }
        this.cntChk = 0;
      }
     
      else {
        this.cntChk = 0;
        this.temp = false;
        this.checkedValueArray = [];
      }
      console.log(this.checkedValueArray)
  }

  gotoOTP(): void {
    if (this.temp == true) {
      this.route.navigate(['/main/otp-users', JSON.stringify(this.checkedValueArray)]);
    } else {
      alert("First select at least one checkbox.");
    }

  }

  rejectGroupById(): void {
    this.userservice.rejectedGroup(this.checkedValueArray, this.comment).then(resp => {
      this.rejectedgroupdata = resp;
      if (this.rejectedgroupdata.msg == "succes") {
        // this.router.navigate(['/asnpendingusers']);
        this.pendingusers = [];
        this.userservice.pendingUsersList().then(resp => {
          this.pendingUserList = resp.data;
          for(var i=0; i< this.pendingUserList.length;i++){
            if(this.pendingUserList[i].status =="Pending"){
              this.pendingusers.push(this.pendingUserList[i]);
            }
        }
        });
        this.route.navigate(['/main/rejectmsg'],{queryParams:{msg:'userreject'}});
      }
    });
  }

  private loadpendingUsers(){
    this.userservice.pendingUsersList().then(resp => {
      
      this.pendingUserList = resp.data;
      for(var i=0; i< this.pendingUserList.length;i++){
        
        if(this.pendingUserList[i].status =="Pending"){
          this.pendingusers.push(this.pendingUserList[i]);
        }
    }
    });
  }

  setPriv(str :string){
    console.log("user permission is "+str);
    str = str.replace(/\s/g, "");
    str = str.replace("pid=" ,"");
    str = str.replace(";cid=",",");
    str = str.replace(";","");
    str = str.replace(" ","");
    var tab_arr = str.split(",");
  
    this.isViewuser = this.checkValInArray("17",tab_arr)
    //console.log(tab_arr);
    this.isUniuser = this.checkValInArray("6",tab_arr)
    this.isBulkuser = this.checkValInArray("7",tab_arr)
    this.isAppruser = this.checkValInArray("76",tab_arr)
    this.isViewcard = this.checkValInArray("23",tab_arr)
    this.isUnicard = this.checkValInArray("24",tab_arr)
    //console.log(this.isUnicard);
    this.isApprcard = this.checkValInArray("77",tab_arr)
    this.isViewgroup = this.checkValInArray("54",tab_arr)
    this.isUnigroup = this.checkValInArray("53",tab_arr)
    this.isApprgroup = this.checkValInArray("80",tab_arr)
    this.isRuleValidate = this.checkValInArray("39",tab_arr)
    this.isUnirule = this.checkValInArray("40",tab_arr)
    this.isRuleappr = this.checkValInArray("79",tab_arr)
    this.isViewsup = this.checkValInArray("28",tab_arr)
    this.isUnisup = this.checkValInArray("27",tab_arr)
    this.isBulksup = this.checkValInArray("51",tab_arr)
    this.isApprsup = this.checkValInArray("74",tab_arr)
    this.isViewpay = this.checkValInArray("19",tab_arr)
    this.isUnipay = this.checkValInArray("20",tab_arr)
    this.isApprpay = this.checkValInArray("75",tab_arr)
    this.isBulkpay = this.checkValInArray("21",tab_arr)
  }
  
  checkValInArray(tabid:string , tab_array:string[]){
    return (tab_array.indexOf(tabid)==-1)?false:true;
  }

}
