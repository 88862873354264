import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
declare var loadAsPiCharts : any;
import { UserService } from '../../_api/user.service'
import { Router } from '@angular/router';
import { IMyDpOptions } from 'mydatepicker';
import { LoaderService } from '../../_api/loader.service';
import { Users } from '../../models/users';

import { ToastrService } from 'ngx-toastr'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-unitaryuser',
  templateUrl: './unitaryuser.component.html',
  styleUrls: ['./unitaryuser.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UnitaryuserComponent implements OnInit {
  model: any = {};
  date:Date = new Date("1999-12-31");
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }
  mindate:Date=new Date("1950-01-01")
maxdate:Date=new Date("2000-01-01")
  isComplexOrg:boolean=true;
  tabspermit:boolean=true
  supappr:boolean=false;
  supuni:boolean=false;
  supbulk:boolean=false;
  supview:boolean=false;
  payappr:boolean=false;
  payuni:boolean=false;
  paybulk:boolean=false;
  payview:boolean=false;
  usrappr:boolean=false;
  usruni:boolean=false;
  usrbulk:boolean=false;
  usrview:boolean=false;
  crdappr:boolean=false;
  crduni:boolean=false;
  crdview:boolean=false;
  grpappr:boolean=false;
  grpuni:boolean=false;
  grpview:boolean=false;
  rulappr:boolean=false;
  ruluni:boolean=false;
  rulview:boolean=false;
  currentUser: Users;
  next1:boolean=false;
  usergroups:any=[];
  //viewsup:boolean=false;
  viewsuptab:boolean=false;
  viewpaytab:boolean=false;
  viewusrtab:boolean=false;
  viewcrdtab:boolean=false;
  viewgrptab:boolean=false;
  viewruletab:boolean=false;
  unisuptab:boolean=false;
  unipaytab:boolean=false;
  uniusrtab:boolean=false;
  unicrdtab:boolean=false;
  unigrptab:boolean=false;
  uniruletab:boolean=false;
  bulksuptab:boolean=false;
  bulkpaytab:boolean=false;
  bulkusrtab:boolean=false;
  apprsuptab:boolean=false;
  apprpaytab:boolean=false;
  apprusrtab:boolean=false;
  apprcrdtab:boolean=false;
  apprgrptab:boolean=false;
  apprruletab:boolean=false;
 
 //formatDate(value:string|number|Date,format:string, locale:string,timezone?:string):string;
 dateModel:string
 groups:any=[];
  submitted = false;
  groupname:string;
  
  constructor(private router: Router,private userservice: UserService,private loaderService: LoaderService,private toastr: ToastrService,public datepipe: DatePipe) { }

  ngOnInit() {
   
    this.model['dob']=this.date;
    console.log("DATE of BIRTh")
    console.log(this.model['dob'])
    console.log("Tab "+this.tabspermit)

    this.model['isview']={};
    console.log(this.model['isview']);
    $(".gtou").on("click", function () {
      $(".paylink2").addClass("active");
      $(".paylink2").siblings(".active").removeClass("active");
    });

    this.userservice.getUserDetails().subscribe(users=>{
      this.currentUser = users['Data'];
      
      this.isComplexOrg=false;
     
      if(users['Data']['orgid']!=null){
      if(users['Data']['authmtrix']=="Complex"){
        this.isComplexOrg=true;
      }else{
        this.isComplexOrg=false;
      }

      console.log("Complex Org:"+this.isComplexOrg)
    }
    })

    this.model = this.userservice.getter();
console.log(this.userservice.getter())
    

    $(document).ready(function(){
      $(".backBtnpay_sup").hide();
      $(".sndback").hide();
     $(".nextpay").click(function(){
      $("#pagi").hide();
         $("#step-4").hide();
         $("#step-5").show();
         $("#step-6").hide();
       
     $(".backBtnpay_sup").hide();
     $("#fourth").addClass("bg-primary");
     $("#fourth").addClass("big-circle");
     $("#fifth").addClass("bg-primary");
     $(".tick2").css({"display": "block"});
     $(".tick3").css({"display": "none"});
     $("#p2").addClass("blu-color");
     });
     $(".backBtnpay_sup").click(function(){
         $("#step-5").hide();
         $("#step-6").hide();
       
         $("#step-4").show();
         $("#pagi").show();
     $(".backBtnpay_sup").hide();
     $("#first").addClass("bg-primary");
     $(".tick3").css({"display": "block"});
     $(".tick2").css({"display": "none"});
     $("#first").removeClass("bg-default");
     $("#fifth").addClass("bg-default");
     $("#fifth").removeClass("bg-primary");
     $("#fourth").removeClass("big-circle");
     $("#p1").addClass("blu-color");
     $("#p2").removeClass("blu-color");
     });
    $(".ntxpayment").click(function(){
         $("#step-4").hide();
         $("#step-5").hide();
         $("#step-6").show();
         $(".backBtnpay_sup").hide();
         $(".sndback").show();
     $("#fifth").addClass("bg-primary");
     $("#sixth").addClass("bg-primary");
     $("#p1").addClass("blu-color");
     $("#p2").addClass("blu-color");
     $("#fifth").addClass("big-circle");
     $(".tick3").css({"display": "block"});
     });

     $(".sndback").click(function(){
      $("#step-4").hide();
      $("#step-5").show();
      $("#step-6").hide();
     
      $(".sndback").hide();
      $(".backBtnpay_sup").show();
  $("#first").addClass("bg-primary");
  $(".tick3").css({"display": "none"});
  $("#first").removeClass("bg-default");
  $("#fifth").addClass("bg-default");
  $("#fifth").removeClass("big-circle");
  $("#sixth").removeClass("bg-primary");
  $("#p1").addClass("blu-color");
  $("#p2").removeClass("blu-color");
  });
  
     
    });


  
   //---------------------------------------Datepicker js start--------------------------------------------

 
//---------------------------------------Datepicker js END-------------------------------------------
//----------------------------drop down btn js----------------------------------------------------------
$(document).ready(function (e) {
  $('.firstgrp .dropdown-menu').find('a').click(function (e) {
      e.preventDefault();
      // var param = $(this).attr("href").replace("#", "");
      var concept = $(this).text();
      $('.firstgrp span#search_concept').text(concept);
      // $('.input-group #search_param').val(param);
  });
});


$(document).ready(function (e) {
  $('.secondgrp .dropdown-menu').find('a').click(function (e) {
      e.preventDefault();
      // var param = $(this).attr("href").replace("#", "");
      var concept = $(this).text();
      $('.secondgrp span#search_concept').text(concept);
      // $('.input-group #search_param').val(param);
  });
});
//---------------------------drop down btn js-End---------------------------------------------------------

  }

  validatedate(){
    var date=this.datepipe.transform(this.model['dob'], 'yyyy-MM-dd')
    if(date<this.datepipe.transform(this.mindate, 'yyyy-MM-dd')){
      this.toastr.warning("Please Enter date after 01st Jan 1950!",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
    }else if(date>this.datepipe.transform(this.maxdate, 'yyyy-MM-dd')){
      this.toastr.warning("Please Enter date before 31st Dec 2000!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
  }
  getSelectedTabs(){
    this.model['isview']={}
    if(this.viewsuptab){
      this.model['isview']['viewsup']=28
    }
    if(this.viewpaytab){
      this.model['isview']['viewpay']=19
    }
    if(this.viewusrtab){
      this.model['isview']['viewuser']=17
    }
    if(this.viewcrdtab){
      this.model['isview']['viewcard']=23
    }
    if(this.viewgrptab){
      this.model['isview']['viewgroup']=54
    }
    if(this.viewruletab){
      this.model['isview']['viewrule']=39
    }
    if(this.apprsuptab){
      this.model['isview']['approvesup']=74
    }
    if(this.apprpaytab){
      this.model['isview']['approvepay']=75
    }
    if(this.apprusrtab)
    this.model['isview']['approveuser']=76
    if(this.apprcrdtab)
    this.model['isview']['approvecard']=77
    if(this.apprgrptab)
    this.model['isview']['approvegroup']=80
    if(this.apprruletab)
    this.model['isview']['approverule']=79
    if(this.unisuptab)
    this.model['isview']['unisup']=27
    if(this.unipaytab)
    this.model['isview']['unipay']=20
    if(this.uniusrtab)
    this.model['isview']['uniuser']=6
    if(this.unicrdtab)
    this.model['isview']['unicard']=24
    if(this.unigrptab)
    this.model['isview']['unigroup']=53
    if(this.uniruletab)
    this.model['isview']['unirule']=40
    if(this.bulksuptab)
    this.model['isview']['bulksup']=51
    if(this.bulkpaytab)
    this.model['isview']['bulkpay']=21
    console.log(this.model['isview'])
    var tabspermitarr = []
    for(var key in this.model['isview'])
    tabspermitarr.push(parseInt(this.model['isview'][key]));
    console.log(tabspermitarr);
    this.supappr = this.contains.call(tabspermitarr,74)
    this.supuni=this.contains.call(tabspermitarr,27)
    this.supbulk=this.contains.call(tabspermitarr,51)
    this.supview=this.contains.call(tabspermitarr,28)
    this.payappr=this.contains.call(tabspermitarr,75)
    this.payuni=this.contains.call(tabspermitarr,20)
    this.paybulk=this.contains.call(tabspermitarr,21)
    this.payview=this.contains.call(tabspermitarr,19)
    this.usrappr=this.contains.call(tabspermitarr,76)
    this.usrbulk=this.contains.call(tabspermitarr,7)
    this.usruni=this.contains.call(tabspermitarr,6)
    this.usrview=this.contains.call(tabspermitarr,17)
    this.crdappr=this.contains.call(tabspermitarr,77)
    this.crduni=this.contains.call(tabspermitarr,24)
    this.crdview=this.contains.call(tabspermitarr,23)
    this.grpappr=this.contains.call(tabspermitarr,80)
    this.grpuni=this.contains.call(tabspermitarr,53)
    this.grpview=this.contains.call(tabspermitarr,54)
    this.rulappr=this.contains.call(tabspermitarr,79)
    this.ruluni=this.contains.call(tabspermitarr,40)
    this.rulview=this.contains.call(tabspermitarr,39)
    // console.log("crdappr:"+this.crdappr);
  }

  contains(needle) {
    // Per spec, the way to identify NaN is that it is not equal to itself
    var findNaN = needle !== needle;
    var indexOf;

    if(!findNaN && typeof Array.prototype.indexOf === 'function') {
        indexOf = Array.prototype.indexOf;
    } else {
        indexOf = function(needle) {
            var i = -1, index = -1;

            for(i = 0; i < this.length; i++) {
                var item = this[i];

                if((findNaN && item !== item) || item === needle) {
                    index = i;
                    break;
                }
            }

            return index;
        };
    }

    return indexOf.call(this, needle) > -1;
};

validateMobileNo(value){    
  var k = value.keyCode;
        return ((k >= 48 && k <= 57) || k == 8);
}

  onSubmit() {
    
    this.model['dob']=this.datepipe.transform(this.model['dob'], 'dd-MM-yyyy');
    this.loaderService.display(true);
    let obj: any = {};

    var isview_arr =[];
    console.log(this.model['isview'])
    for(var key in this.model['isview'])
      isview_arr.push(parseInt(this.model['isview'][key]));
      this.model['isview'] = isview_arr;
      console.log(this.model['dob'])
      console.log(this.model)
      this.userservice.create(this.model).subscribe(
      data=>{
        if(data==null){
          this.loaderService.display(false);
          this.toastr.warning("Failed to Register!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
          this.router.navigate(['/main/unitaryuser']);
        }else{
          this.loaderService.display(false);
          console.log(data)
  
          this.router.navigate(['/main/successmsg'],{queryParams:{msg:'usersuccess'}});
        }
      },error => {
        console.log("Failed to Register")
        console.log(error)
        this.loaderService.display(false);
    })
    //console.log('SUCCESS!! :-)\n\n' + JSON.stringify(this.model['isview']));
  }

  checkValInArray(tabid:string , tab_array:string[]){
    return (tab_array.indexOf(tabid)==-1)?false:true;
}

onChangerole(event){

  console.log(event);
  if(event == 2){
    // this.model['isview']['viewsup'] = 28;
    // this.model.isview.viewpay = 19;
    // this.model.isview.viewuser = 17;
    // this.model.isview.viewcard = 23;
    this.model.isview.approveuser = 76;
    // this.model.isview.approvecard = 77;
  }
}



getGroup(gname:any,id:number){
  console.log(gname)
  console.log(id)
  this.groupname="";
  for(let data of gname){
    if(data['id']==id){
      this.groupname=data['grpname']
    }
  }
  console.log(this.groupname)
}

resettabs(){
  this.model['isview']={}
  this.viewsuptab=false
  this.viewpaytab=false
  this.viewusrtab=false
  this.viewcrdtab=false
  this.viewgrptab=false
  this.viewruletab=false
  this.apprsuptab=false
  this.apprpaytab=false
  this.apprusrtab=false
  this.apprcrdtab=false
  this.apprgrptab=false
  this.apprruletab=false
  this.unisuptab=false
  this.unipaytab=false
  this.uniusrtab=false
  this.unicrdtab=false
  this.unigrptab=false
  this.uniruletab=false
  this.bulksuptab=false
  this.bulkpaytab=false
  this.bulkusrtab=false
 
}

setDefaultPriv(role:any){
  console.log(role)
  if(role==2){
    this.resettabs()
    this.viewsuptab=true;
    this.model['isview']['viewsup']=28
    this.viewpaytab=true;
    this.model['isview']['viewpay']=19
    this.viewusrtab=true;
    this.model['isview']['viewuser']=17
    this.viewcrdtab=true;
    this.model['isview']['viewcard']=23
    this.viewgrptab=true;
    this.model['isview']['viewgroup']=54
    this.viewruletab=true;
    this.model['isview']['viewrule']=39
    this.apprusrtab=true;
    this.model['isview']['approveuser']=76
    this.apprcrdtab=true;
    this.model['isview']['approvecard']=77
    this.apprgrptab=true;
    this.model['isview']['approvegroup']=80
    this.apprruletab=true;
    this.model['isview']['approverule']=79
    }else if(role==3){
      this.resettabs()
    this.viewusrtab=true;
    this.model['isview']['viewuser']=17
    this.viewcrdtab=true;
    this.model['isview']['viewcard']=23
    this.viewgrptab=true;
    this.model['isview']['viewgroup']=54
    this.viewruletab=true;
    this.model['isview']['viewrule']=39
    this.uniusrtab=true;
    this.model['isview']['uniuser']=6
    this.unicrdtab=true;
    this.model['isview']['unicard']=24
    this.unigrptab=true;
    this.model['isview']['unigroup']=53
    this.uniruletab=true;
    this.model['isview']['unirule']=40
    this.bulkusrtab=true;
    this.model['isview']['bulkuser']=7
  }else if(role==4){
    this.resettabs()
    this.viewsuptab=true;
    this.model['isview']['viewsup']=28
    this.viewpaytab=true;
    this.model['isview']['viewpay']=19
    this.viewcrdtab=true;
    this.model['isview']['viewcard']=23
    this.unisuptab=true;
    this.model['isview']['unisup']=27
    this.unipaytab=true;
    this.model['isview']['unipay']=20
    this.bulksuptab=true;
    this.model['isview']['bulksup']=51
    this.bulkpaytab=true;
    this.model['isview']['bulkpay']=21
  }else if(role==6){
    //this.model['isview']={}
    this.resettabs()
    this.viewsuptab=true;
    this.model['isview']['viewsup']=28
    this.viewpaytab=true;
    this.model['isview']['viewpay']=19
    this.viewcrdtab=true;
    this.model['isview']['viewcard']=23
    this.apprsuptab=true;
    this.model['isview']['approvesup']=74
    this.apprpaytab=true;
    this.model['isview']['approvepay']=75
  }
  console.log(this.model['isview'])
}
userselectedtab(){
  this.model['isview']={}
  if(this.viewsuptab){
    this.model['isview']['viewsup']=28
  }
  if(this.viewpaytab){
    this.model['isview']['viewpay']=19
  }
  if(this.viewusrtab){
    this.model['isview']['viewuser']=17
  }
  if(this.viewcrdtab){
    this.model['isview']['viewcard']=23
  }
  if(this.viewgrptab){
    this.model['isview']['viewgroup']=54
  }
  if(this.viewruletab){
    this.model['isview']['viewrule']=39
  }
  if(this.apprsuptab){
    this.model['isview']['approvesup']=74
  }
  if(this.apprpaytab){
    this.model['isview']['approvepay']=75
  }
  if(this.apprusrtab)
  this.model['isview']['approveuser']=76
  if(this.apprcrdtab)
  this.model['isview']['approvecard']=77
  if(this.apprgrptab)
  this.model['isview']['approvegroup']=80
  if(this.apprruletab)
  this.model['isview']['approverule']=79
  if(this.unisuptab)
  this.model['isview']['unisup']=27
  if(this.unipaytab)
  this.model['isview']['unipay']=20
  if(this.uniusrtab)
  this.model['isview']['uniuser']=6
  if(this.unicrdtab)
  this.model['isview']['unicard']=24
  if(this.unigrptab)
  this.model['isview']['unigroup']=53
  if(this.uniruletab)
  this.model['isview']['unirule']=40
  if(this.bulksuptab)
  this.model['isview']['bulksup']=51
  if(this.bulkpaytab)
  this.model['isview']['bulkpay']=21
}

}
