import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ResetPasswordService } from 'src/app/_api/reset-password.service';
import { VcnuserService } from 'src/app/_api/vcnuser.service';

@Component({
  selector: 'app-email-sign-in',
  templateUrl: './email-sign-in.component.html',
  styleUrls: ['./email-sign-in.component.css']
})
export class EmailSignInComponent implements OnInit {
  returnUrl: string;
  submitted = false;
  loginData:any = {};
  
  display='none';
  orgnames:any=[];
  forgotdetail : any ={};
id:number=0;
orgid:any;
orglist:any=[];
showorg:boolean=false;
email:string;
  constructor(private route: ActivatedRoute,private router: Router,private toastr: ToastrService,private resetService : ResetPasswordService, private vcnuser:VcnuserService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  loginPost(){
    this.spinner.show()
    if(this.email!=undefined || this.email!=null){
        var params={
          "email":this.email
        }
        this.vcnuser.loginWithMobile(params).then(resp=>{
          console.log(resp)
          if(resp['message']=='OTP sent successfully!'){
            this.spinner.hide()
            this.vcnuser.saveToken(resp['data']['secure_set'])
            localStorage.setItem('usermobile',this.email)
            this.router.navigate(['/email-otp']);
          }else if (resp['message']=='No user exist!'){
            this.spinner.hide()
            this.toastr.error("Card Details don't exist for given email",'Error',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else{
            this.spinner.hide()
            this.toastr.error("Something Went Wrong",'Error',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
        },error=>{
          this.spinner.hide()
          this.toastr.error("Failed to Login",'Error',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
          console.log(error)
        })
    }else{
      this.spinner.hide()
      this.toastr.error("Please Enter Mobile Number",'Error',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
    
  }

}
