import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { ExcelService } from '../../excelservice/excel.service'
import {ExcelGeneratorService} from  '../../_api/excel-generator.service'

@Component({
  selector: 'app-corp-current-report',
  templateUrl: './corp-current-report.component.html',
  styleUrls: ['./corp-current-report.component.css']
})
export class CorpCurrentReportComponent implements OnInit {
  transactiondata:any=[]
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  constructor(private _location: Location,private spinner: NgxSpinnerService,private excelservice : ExcelService,private excel:ExcelGeneratorService) { }

  ngOnInit() {
    this.loadTrxData()
  }
  backClicked() {
    localStorage.removeItem('authreport')
    this._location.back();
    
  }

  private loadTrxData(){
    this.spinner.show()
    this.transactiondata=[]
    var transaData=JSON.parse(localStorage.getItem("authreport") || "[]");
    if(Array.isArray(transaData)){
      for(var data of transaData){
        var obj={
          Amount:data['BillingAmount'],
          BillingCurrencyCode:data['BillingCurrencyCode'],
          TokenNumber:data['VirtualCardNumber'],
          MerchantName:data['MerchantName'],
          TransactionDateTime: "2020-" +data['TxnDateTimeWithTime'],
          IssuerResponse:data['IssuerResponse'],
          InControlResponse:data['InControlResponse'],
          Settled:data['Settled'],
          // TransactionDate:data['TxnDateTime'],
          PurchaseRequestID:data['PurchaseRequestId'],
          MerchantAmount:data['MerchantAmount'],
          MerchantCurrencyCode:data['MerchantCurrencyCode'],
          TransactionExchangeRate:data['TransactionExchangeRate'],
          NoofTransaction:data["no_of_transaction"],
          ValidFrom:data["valid_from"],
          ValidTo:data["valid_to"],
          MinimumTransactions:data["min_transaction"],
          MaximumTransactions:data["max_transaction"],
          Custom1:data["custom1"],
          Custom2:data["custom2"],
          Custom3:data["custom3"],
          Custom4:data["custom4"],
          Custom5:data["custom5"],
          Custom6:data["custom6"],
          Custom7:data["custom7"],
          Custom8:data["custom8"],
          Custom9:data["custom9"],
          Custom10:data["custom10"],
        }
        this.transactiondata.push(obj)
  
      }
      if(this.transactiondata.length>0){
        console.log(this.transactiondata)
        this.spinner.hide()
      }else{
        this.spinner.hide()
      }
    }else{
      this.spinner.hide()
      this.transactiondata=[{
        'Amount':transaData['BillingAmount'],
        'BillingCurrencyCode':transaData['BillingCurrencyCode'],
        'TokenNumber':transaData['VirtualCardNumber'],
        'MerchantName':transaData['MerchantName'],
        'TransactionDateTime':"2020-" +transaData['TxnDateTimeWithTime'],
        'IssuerResponse':transaData['IssuerResponse'],
        'InControlResponse':transaData['InControlResponse'],
        'Settled':transaData['Settled'],
        // 'TransactionDate':transaData['TxnDateTime'],
        'PurchaseRequestID':transaData['PurchaseRequestId'],
        'MerchantAmount':transaData['MerchantAmount'],
        'MerchantCurrencyCode':transaData['MerchantCurrencyCode'],
        'TransactionExchangeRate':transaData['TransactionExchangeRate'],
        'NoofTransaction':transaData["no_of_transaction"],
          'ValidFrom':transaData["valid_from"],
          'ValidTo':transaData["valid_to"],
          'MinimumTransactions':transaData["min_transaction"],
          'MaximumTransactions':transaData["max_transaction"],
          'Custom1':transaData["custom1"],
          'Custom2':transaData["custom2"],
          'Custom3':transaData["custom3"],
          'Custom4':transaData["custom4"],
          'Custom5':transaData["custom5"],
          'Custom6':transaData["custom6"],
          'Custom7':transaData["custom7"],
          'Custom8':transaData["custom8"],
          'Custom9':transaData["custom9"],
          'Custom10':transaData["custom10"],
      }]
    }
    
    

  }


  exportAsXLSX(){
    this.excel.exportAsExcelFile( this.transactiondata, 'Transaction_Report');
  }

}
