import { Injectable } from '@angular/core';
import { Config } from '../config';
import { HttpClient } from '@angular/common/http';

const path = new Config().getVcnUrl();
@Injectable({
  providedIn: 'root'
})
export class GdsServiceService {

  constructor(private http: HttpClient) { }

  
  getTMCData(): Promise<any>{
    let promise = new Promise((resolve,reject)=>{
      this.http.get(path+"onboarding/view").subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  getMappedTmcToBankCorporates(tmcid:any): Promise<any> {
    let params={'tmcId':tmcid, }
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}Aquapay/getMappedTmcToBankCorporates`,params)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }


  getMappedPccIdToBankCorp(tmcdata:any): Promise<any> {
    // let params={'tmcId':tmcid, }
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}Aquapay/getMappedPccIdToBankCorp`,tmcdata)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

}
