import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {AuthService} from '../../_api/auth.service'
import {FormControl, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr'
import {VcnuserService} from '../../_api/vcnuser.service'
import { NgxSpinnerService } from "ngx-spinner";
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-mobile-otp-open',
  templateUrl: './mobile-otp-open.component.html',
  styleUrls: ['./mobile-otp-open.component.css']
})
export class MobileOtpOpenComponent implements OnInit {
  returnUrl: string;
  submitted = false;
  otpData:any = {};
  otp:number;
  otpstring:string;
  otp1:string;
  otp2:string;
  otp3:string;
  otp4:string;
  otp5:string;
  constructor(private authService: AuthService,private route: ActivatedRoute,private router: Router,private toastr: ToastrService, private vcnuser:VcnuserService,private spinner: NgxSpinnerService,private titleService:Title) { 
    this.titleService.setTitle("Virtual Card OTP");
  }

  ngOnInit() {
  }

  otpPost(){
    this.spinner.show()
    localStorage.removeItem('data');
    this.submitted = true;
    this.otpstring=this.otp1+this.otp2+this.otp3+this.otp4+this.otp5
    // if(this.otpstring==undefined||this.otpstring==null||this.otpstring==""){
    //   this.toastr.warning("Please Enter OTP!","Alert",{
    //     timeOut:3000,
    //     positionClass:'toast-top-center'
    //     })
    // }else if(this.otpstring.length==5){
    //   this.toastr.warning("Please Enter 5 Digit OTP!","Alert",{
    //     timeOut:3000,
    //     positionClass:'toast-top-center'
    //     })
    // }else{
     
    //     this.router.navigate(['/cards-listing']);
      
    // }
    
    if(this.otpstring!=undefined||this.otpstring!=null||this.otpstring!=""){
      if(this.otpstring.length==5){
        var params={
          "otp":this.otpstring
        }
        this.vcnuser.postMobileOtp(params).then(resp=>{
          console.log(resp)
          if(resp['message']=='Otp Validated Successfully'){
            this.vcnuser.saveToken(resp['data']['token'])
            this.router.navigate(['/cards-listing-open']);
            this.spinner.hide()
          }else if (resp['message']=='Invalid Otp!'){
            this.toastr.error("Invalid OTP!","Alert",{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else if(resp['message']=='Otp Expired!'){
            this.spinner.hide()
            this.toastr.error("OTP Expired!!","Alert",{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else if(resp['message']=='Invalid user!'){
            this.spinner.hide()
            this.toastr.error("User not found!","Alert",{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
          
          else{
            this.spinner.hide()
            this.toastr.error("Something went wrong!!","Alert",{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
          
        },error=>{
          this.spinner.hide()
          this.toastr.error("Failed to validate OTP!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
          console.log(error)
        })
      }else{
        this.spinner.hide()
    this.toastr.warning("Please Enter 5 Digit OTP!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
      }
    }else{
      this.spinner.hide()
    this.toastr.warning("Please Enter OTP!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    //this.router.navigate([this.returnUrl]);
  }
  //this.router.navigate([this.returnUrl]);

}
