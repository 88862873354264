import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
import { Groups } from '../../models/groups';
import{LoaderService} from '../../_api/loader.service';
import { ExcelService } from '../../excelservice/excel.service'
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import { DatePipe } from '@angular/common'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
import {ExcelGeneratorService} from  '../../_api/excel-generator.service'

@Component({
  selector: 'app-makercardview',
  templateUrl: './makercardview.component.html',
  styleUrls: ['./makercardview.component.css']
})
export class MakercardviewComponent implements OnInit {
  public id: string;
  filedata:any=[]
  downloadArray:any=[];
  filesummary:any={}
  cardholder:any;
  cardno:any;
  cardcvv:any;
  cardexpiry:any;
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  additonal_amount:any=0
  current_amount:any=0
  add_curr_amt:any=0;
  total_amt_in_words:any="TWO THOUSAND RUPEES";
  selectedIndex = -1;
  cardDataPopup:any={};
  constructor(private activeroute: ActivatedRoute,private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private route:Router,private excelservice : ExcelService, private excel:ExcelGeneratorService) { }

  ngOnInit() {
    this.id = this.activeroute.snapshot.paramMap.get('id');
    if(this.id!=undefined || this.id!=null){
      this.loadFileData()
    }
    else{
      this.toastr.error("Something Went wrong!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }

  }

  viewcardDetails(crdno,name,expiry): void {
    this.cardno=`XXXX XXXX XXXX ${atob(crdno).substring(16,12)}`
    this.cardholder=name
   
    this.cardexpiry=expiry
    $("#payreport").modal("show");
 
}

  private loadFileData(){
    this.spinner.show()
    this.vcn.getFiledata(this.id).then(resp=>{
     // console.log(resp)
      this.filedata=resp['data']['file_data']
   
      this.filesummary={
        "corporate_name": resp['data']['corporate_name'],
        "corporate_nick_name" :resp['data']['corporate_nick_name'],
        "corporate_id": resp['data']['corporate_id'],
        "file_name": resp['data']['file_name'],
        "total_cards": resp['data']['total_cards'],
        "total_amount": resp['data']['total_amount'],
        "utr_no":resp['data']['utr_no'],
        "status":resp['data']['status'],
        "bank_name":resp['data']['bank_name'],
        "transaction_date": resp['data']['transaction_date'],
        "alias_name":resp['data']['alias_name'],
        "alias_id":resp['data']['alias_id'],
        "tempate_name":resp['data']['template_name'],
        "template_id":resp['data']['template_id'],
        "supplier_name":resp['data']['supplier_name'],
        "supplier_id":resp['data']['supplier_id'],
      }
      this.spinner.hide()

    },error=>{
      this.spinner.hide()
      console.log(error)
      this.toastr.error("Failed to load data!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    })
  }

  exportAsXLSX():void {
    this.downloadArray=[]
    
    if(this.filedata.length>=0){
      for(let data of this.filedata){
        var obj={
          Name:data['name'],
          Mobile_Number:data['mobile_no'],
          Email:data['email'],
          Amount:data['amount'],
          Token_Number:atob(data['card_no']),
          Expiry_date:data['expiry'],
          CVV:data['cvv'],
          Status:data['status'],
          Request_id:data['request_purchase_id'],
          // Alias_Name:this.filesummary['alias_name'],
          // Corporate_name:this.filesummary['corporate_name'],
          Token_Generation_Date:data['card_generated_date'],
          Token_Generation_Time:data['card_generated_time'],
          No_of_transaction:data["no_of_transaction"],
          Valid_from:data["valid_from"],
          Valid_to:data["valid_to"],
          minimum_transactions:data["min_transaction"],
          maximum_transactions:data["max_transaction"],
          Custom1:data["custom1"],
          Custom2:data["custom2"],
          Custom3:data["custom3"],
          Custom4:data["custom4"],
          Custom5:data["custom5"],
          Custom6:data["custom6"],
          Custom7:data["custom7"],
          Custom8:data["custom8"],
          Custom9:data["custom9"],
          Custom10:data["custom10"],
       
        }
        this.downloadArray.push(obj)
      }
      this.excel.exportAsExcelFile( this.downloadArray, this.filesummary['file_name'].split('.')[0]);
    }else{
      this.toastr.warning("No Data Available","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
   // this.excelservice.exportAsExcelFile( this.users, 'User');
  }

  gotobalanceview(){
    this.route.navigate(['/check-balances', this.id]);
  }

  checkBalPopUp(){
    $("#checkBalRep").modal("show");
  }

  checkClick(cardData): void {
    console.log(cardData)
    this.cardDataPopup=cardData
    $("#customFields").modal("show");
 

}
  
  addAdditionalAmt(amt){
    this.current_amount=amt
    this.add_curr_amt=parseFloat(amt)+parseFloat(this.additonal_amount)
    $("#AdditionAmtModal").modal("show");
  }

  submitaddbal(){
    this.toastr.success("New Limit has been updated successfully","Alert",{
      timeOut:30000000,
      positionClass:'toast-top-center'
      })
  }
  toggleExpandedView(index){
    this.selectedIndex = index;
   }
}
