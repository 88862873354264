import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/excelservice/excel.service';
import { ExcelGeneratorService } from 'src/app/_api/excel-generator.service';
import { VcnService } from 'src/app/_api/vcn.service';
import { VcncorpService } from 'src/app/_api/vcncorp.service';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-corp-cancelcard-vcn-view',
  templateUrl: './corp-cancelcard-vcn-view.component.html',
  styleUrls: ['./corp-cancelcard-vcn-view.component.css']
})
export class CorpCancelcardVcnViewComponent implements OnInit {
  public id: string;
  filedata:any=[]
  downloadArray:any=[];
  filesummary:any={}
  cardholder:any;
  cardno:any;
  cardcvv:any;
  cardexpiry:any;
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  additonal_amount:any=0
  current_amount:any=0
  add_curr_amt:any=0;
  total_amt_in_words:any="TWO THOUSAND RUPEES";
  selectedIndex = -1;
  showDownloadOption:boolean=false;
  constructor(private activeroute: ActivatedRoute,private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private route:Router,private excelservice : ExcelService, private excel:ExcelGeneratorService,private vcncorp:VcncorpService) { }

  ngOnInit() {
    this.id = this.activeroute.snapshot.paramMap.get('id');
    if(this.id!=undefined || this.id!=null){
      this.loadFileData()
    }
    else{
      this.toastr.error("Something Went wrong!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }

  }

  viewcardDetails(crdno,name,expiry): void {
    this.cardno=`XXXX XXXX XXXX ${atob(crdno.substring(16))}`
    this.cardholder=name
   
    this.cardexpiry=expiry
    $("#payreport").modal("show");
 
}

oldCardNumberDisplay(cardNo:any) {
  if(cardNo){
    var returnString = `XXXX XXXX XXXX ${atob(cardNo.substring(16))}`
    return returnString
  }
}

  private loadFileData(){
    this.spinner.show()
    this.vcncorp.getCancelCardFileData(this.id).then(resp=>{
     console.log(resp)
      this.filedata=resp['data']['file_data']
   
      this.filesummary={
        "corporate_name": resp['data']['corporate_name'],
        "corporate_id": resp['data']['corporate_id'],
        "file_name": resp['data']['file_name'],
        "total_cards": resp['data']['total_cards'],
        "total_amount": resp['data']['total_amount'],
        "utr_no":resp['data']['utr_no'],
        "status":resp['data']['status'],
        "bank_name":resp['data']['bank_name'],
        "transaction_date": resp['data']['transaction_date'],
        "sub_corp_name":resp['data']['sub_corp_name']
      }
      var fileList= this.filedata.filter(req=>(req['status']=='Inactive'))
      if(fileList.length==0){
        this.showDownloadOption=true
      }else{
        this.showDownloadOption=false
      }
      this.spinner.hide()

    },error=>{
      this.spinner.hide()
      console.log(error)
      this.toastr.error("Failed to load data!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
        console.log(error.error.message)
        if(error.error.message=='Token expired!'){
          this.route.navigate(['/']);
          localStorage.removeItem('token')
          localStorage.clear()
        }
    })
  }

  exportAsXLSX():void {
    this.downloadArray=[]
    
    if(this.filedata.length>=0){
      for(let data of this.filedata){
        var obj={
          Name:data['name'],
          Mobile_Number:data['mobile_no'],
          Email:data['email'],
          Amount:data['amount'],
          Token_Number:`XXXX XXXX XXXX ${atob(data['card_no'].substring(16))}`,
          Status:data['status'],
          Reference_No:data['request_purchase_id']
        }
        
        this.downloadArray.push(obj)
      }
      var fileNameArray = this.filesummary['file_name'].split('.');
      var finalFileName= ""
      for(let i = 0; i < fileNameArray.length - 1; i++){
        console.log(fileNameArray[i])
        finalFileName = finalFileName + "." + fileNameArray[i];
      }
      this.excel.exportAsExcelFile( this.downloadArray, finalFileName);
    }else{
      this.toastr.warning("No Data Available","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
   // this.excelservice.exportAsExcelFile( this.users, 'User');
  }

  gotobalanceview(){
    this.route.navigate(['/check-balances', this.id]);
  }

  checkBalPopUp(){
    $("#checkBalRep").modal("show");
  }
  
  addAdditionalAmt(amt){
    this.current_amount=amt
    this.add_curr_amt=parseFloat(amt)+parseFloat(this.additonal_amount)
    $("#AdditionAmtModal").modal("show");
  }

  submitaddbal(){
    this.toastr.success("New Limit has been updated successfully","Alert",{
      timeOut:30000000,
      positionClass:'toast-top-center'
      })
  }
  toggleExpandedView(index){
    this.selectedIndex = index;
   }


   getBalance(id){
    this.spinner.show()
    var params={
      "id":id
    }
    this.vcncorp.getCorpCardBalance(params).then(resp=>{
      if(resp['message']=='Purchase request id not available!'){
        this.spinner.hide()
        this.toastr.error("Token Details are not available","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='The Requested has been Canceled'){
        this.spinner.hide()
        this.toastr.error("This token has been blocked!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='Available Balance!'){
        for(var data of this.filedata){
            if(data['id']==resp['id']){
              this.spinner.hide()
              data['balance']=resp['data']
            }
        }
        console.log(resp)
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }
}
