import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
declare var jquery: any;
declare var $: any;
import{CardService} from '../../_api/card.service'
import{LoaderService} from '../../_api/loader.service';
@Component({
  selector: 'app-otpcard',
  templateUrl: './otpcard.component.html',
  styleUrls: ['./otpcard.component.css']
})
export class OtpcardComponent implements OnInit {
  validateOTP: any = [];
  public approvedPayment: any;
  //public id: string;
  public ids: any = [];
  public txt1: any;
  public txt2: any;
  public txt3: any;
  public txt4: any;
  public txt5: any;
  constructor(private route: ActivatedRoute, private router: Router,private loader:LoaderService,private cardservice:CardService) { }

  ngOnInit() {
    this.loader.display(true);
    this.ids = JSON.parse(this.route.snapshot.paramMap.get('ids'));
    this.cardservice.sendOtp(this.ids).then(resp => {
      this.validateOTP = resp.data;

      if(resp['cardotpvalue']==1){
        this.loader.display(false);
        this.router.navigate(['/main/successmsg'],{queryParams:{msg:'cardapprsuccess'}});
      }
      //console.log(this.validateOTP);
      this.loader.display(false);
    });

    $('.inputstyle').bind('keyup', function (event) {
      var val = $(this).val();
      if (val != '') {
        $(this).parent().next().find('input').focus();
      }
    });

  }


  gotoValidateOTP(): void {
    this.loader.display(true);
    if (!!this.txt1 && this.txt2 && this.txt3 && this.txt4 && this.txt5) {
      var checkOtp = this.txt1 + this.txt2 + this.txt3 + this.txt4 + this.txt5;
      console.log("OTP" + checkOtp);
    }
    else {
      this.loader.display(false);
      alert("Enter otp");
    }

    if (checkOtp.length == 5) {
      var checkOtp = this.txt1 + this.txt2 + this.txt3 + this.txt4 + this.txt5;
      this.cardservice.validateOTP(checkOtp).then(resp => {
        this.approvedPayment = resp.data;
        if (!!this.approvedPayment && this.approvedPayment == "Card Approve SucessFully") {
          this.router.navigate(['/main/successmsg'],{queryParams:{msg:'cardapprsuccess'}});
          this.loader.display(false);
          this.router.navigate(['/main/successmsg'],{queryParams:{msg:'cardapprsuccess'}});
        } else {
          this.loader.display(false);
          alert("Enter correct OTP Hello");

        }
      });

    }
    else {
      this.loader.display(false);
      alert("Enter proper OTP Hell");
    }
  }

}
