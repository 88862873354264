import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
declare var jquery: any;
declare var $: any;
import{CardService} from '../../_api/card.service'
import{LoaderService} from '../../_api/loader.service';
@Component({
  selector: 'app-pendingcards',
  templateUrl: './pendingcards.component.html',
  styleUrls: ['./pendingcards.component.css']
})
export class PendingcardsComponent implements OnInit {
  public temp: any;
  public rejectedgroupdata: any;
  public rejectedgroupmsg: any;
  public pendingCards: any;
  public pendingCardsid: any;
  public comment: any;
  public currentCard: any;
  public newpendingcards = [];
  constructor(private cardservice: CardService,private loaderService: LoaderService,private route:Router) { }

  ngOnInit() {
    this.currentCard = 0;
    this.loadpendingcards();
  }

  private loadpendingcards(){
    this.cardservice.getAllCards().then(resp => {
      this.pendingCards = resp.data;
      for (let i = 0; i < this.pendingCards.length; i++) {

        if (this.pendingCards[i].status == 2) {
          this.newpendingcards.push(this.pendingCards[i]);
        }
      }
    });
  }

  goToNextCard() {
    if (this.newpendingcards.length - 1 == this.currentCard) {
      this.currentCard = 0;
    }
    else {
      this.currentCard++;
    }

  }

  goToPrevCard() {
    if (this.currentCard == 0) {
      this.currentCard = this.newpendingcards.length - 1;
    }
    else {
      this.currentCard--;
    }
  }

  gotoOTP(id: any): void {
    // if (!!this.pendingCardsid) { 
    this.route.navigate(['/main/otp-cards', id]);
    // } else {
    //   alert("First select at least one checkbox.");
    // }
  }

  rejectGroupById(id: any): void {
    this.cardservice.rejectedCard(id, this.comment).then(resp => {
      this.rejectedgroupdata = resp.data;
      this.rejectedgroupmsg = resp.msg;
      if (this.rejectedgroupmsg == "succes") {
        this.route.navigate(['/main/rejectmsg'],{queryParams:{msg:'cardreject'}});
      }
    });
  }


}
