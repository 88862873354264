import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {AuthService} from '../../_api/auth.service'
import {FormControl, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr'
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-new-otp',
  templateUrl: './new-otp.component.html',
  styleUrls: ['./new-otp.component.css']
})
export class NewOtpComponent implements OnInit {
  returnUrl: string;
  submitted = false;
  otpData:any = {};
  otp:number;
  otpstring:string;
  otp1:string;
  otp2:string;
  otp3:string;
  otp4:string;
  otp5:string;
  constructor(private authService: AuthService,private route: ActivatedRoute,private router: Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/main';
  }

  otpPost(){
    localStorage.removeItem('data');
    this.submitted = true;
    this.otpstring=this.otp1+this.otp2+this.otp3+this.otp4+this.otp5
    if(this.otpstring==undefined||this.otpstring==null||this.otpstring==""){
      this.toastr.warning("Please Enter OTP!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }else if(this.otpstring.length>5){
      this.toastr.warning("Please Enter 5 Digit OTP!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }else{
     // this.otpstring=this.otpData['otp']
    
    //this.otpstring=String(this.otp)
   
    this.authService.postOtp(this.otpstring)
    }
    //this.router.navigate([this.returnUrl]);
  }

}
