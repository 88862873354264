import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { KarzaApiService } from 'src/app/_api/karza-api.service';
import { LoaderService } from 'src/app/_api/loader.service';
import { OrgserviceService } from 'src/app/_api/orgservice.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-yyyy',
  },
  display: {
    dateInput: 'DD-MM-yyyy',
    monthYearLabel: 'DD-MM-yyyy',
    dateA11yLabel: 'DD-MM-yyyy',
    monthYearA11yLabel: 'DD-MM-yyyy',
  },
};
@Component({
  selector: 'app-org-reg',
  templateUrl: './org-reg.component.html',
  styleUrls: ['./org-reg.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class OrgRegComponent implements OnInit {

  statelist : any[];
  citylist : any[];
  orgdata:any={};
  corpId:any;
  corpType:any;
  TOKEN_KEY:string = "token";
  panValidationRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
  isChecked:boolean = false;
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }
  // compnayPanValidationStatus:any;
  // companyGSTInValidationStatus:any;

    //TO REMOVE SUCCESS AFTER GETTING KARZA SERVICES
    compnayPanValidationStatus:any = 'Success';
    companyGSTInValidationStatus:any = 'Success';

  constructor(public datepipe: DatePipe,private spinner: NgxSpinnerService,private route: ActivatedRoute,private loaderService: LoaderService,private karzeService: KarzaApiService,private orgservice: OrgserviceService, private router: Router,private toastr: ToastrService) { }
  

  ngOnInit() {
    //UNCOMMENT AFTER GETTING KARZA SERVICE
    // this.compnayPanValidationStatus = "Pending";
    // this.companyGSTInValidationStatus = "Pending";
    this.spinner.show();
    this.orgservice.getOrganisationData().then(data=>{
      this.spinner.hide();
      this.corpId = parseInt(data["data"]["id"]);
      this.corpType = data["data"]["org_type"];
      this.orgdata.id = this.corpId
      this.orgdata.name = data["data"]["name"]

      //UNCOMMENT AFTER GETTING KARZA SERVICE
      // this.compnayPanValidationStatus = data["data"]["pan_status"] == "Success" ? "Success" : "Pending";
      // this.orgdata.pan_no = data["data"]["pan_status"] == "Success" ? data["data"]["pan_no"] : undefined
      // this.orgdata.pan_status=data["data"]["pan_status"] == "Success" ? data["data"]["pan_status"] : undefined
      // this.orgdata.pan_validation_id=data["data"]["pan_status"] == "Success" ? data["data"]["pan_validation_id"] : undefined
      console.log(this.corpId)
    },err=>{
      this.spinner.hide();
    })
  }

  validatePan(){
    if(this.orgdata.pan_no) {
      var data = {
        "corpid": this.corpId,
        "pan": this.orgdata.pan_no
      }
      this.spinner.show()
      this.karzeService.validatePanNO(data).then(resp=>{
        this.spinner.hide()
        console.log(resp)
        if(resp['status'] == 'success') {
          if(resp['message']['status'] && resp['message']['status']=="Active") {
            this.compnayPanValidationStatus = 'Success'
            this.orgdata["pan_status"]='Success'
            this.orgdata["pan_validation_id"]=resp["id"]
            this.toastr.success('PAN Validated', 'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
           });
          } else {
            this.toastr.error('PAN is Inactive', 'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
           });
          }
        } else {
          this.toastr.error('PAN Validation Failed', 'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
         });
        }
      },err=>{
        this.spinner.hide()
        this.toastr.error('PAN Validation Failed', 'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
       });
      })
    } else {
      this.toastr.warning('Please enter Company Pan and try again', 'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
     });
    }
  }

  validateGSTIN(){
    console.log(this.orgdata)
    if(!this.orgdata.corporate_gstin.includes(this.orgdata.pan_no)){
      this.toastr.warning('Please enter correct GSTIN number', 'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
     });
    }
    else if(this.orgdata.corporate_gstin) {
      var data = {
        "corpid": this.corpId,
        "gstin": this.orgdata.corporate_gstin
      }
      this.spinner.show()
      this.karzeService.validateGSTIN(data).then(resp=>{
        this.spinner.hide()
        if(resp['status'] == 'success') {
          if(resp['message']['sts'] && resp['message']['sts']=="Active") {
            this.companyGSTInValidationStatus = 'Success'
            this.orgdata["gstin_status"]='Success'
            this.orgdata["gstin_validation_id"]=resp["id"]
            this.toastr.success('GSTIN Validated', 'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
           });
          } else {
            this.toastr.error('Please enter correct GSTIN', 'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
           });
          }
        } else {
          this.toastr.error('GSTIN Validation Failed', 'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
         });
        }
      },err=>{
        this.spinner.hide()
        this.toastr.error('Something went wrong! PLease contact aquapay support', 'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
       });
      })
    } else {
      this.toastr.warning('Please enter Company GSTIN and try again', 'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
     });
    }
  }

  validateOFAC(name,dob){
    if(this.orgdata.corporate_gstin) {
      var data = {
        "corpid": this.corpId,
        "name": name,
        "dateOfBirth": dob
      }
      this.karzeService.validateGSTIN(data).then(resp=>{
        console.log(resp);
      })
    } else {
      this.toastr.warning('Please enter Company Pan and try again', 'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
     });
    }
  }

  dynamicSort(property) {
    var sortOrder = 1;
  
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
  
    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
  }

  getStateName(stateCode){
    console.log(stateCode)
    let stateObj = this.statelist.filter(state=>{
      return parseInt(state["id"]) == stateCode
    })
    console.log(stateObj)
    return stateObj['name'];
  }

  frac(f) {
    return f % 1;
}

validateData(){
  if (this.compnayPanValidationStatus == 'Pending') {
    this.toastr.warning("Please complete PAN Validation. Click on Validate button next to Company PAN",'Alert',{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
  }else if(this.corpType != "Proprietorship" && this.companyGSTInValidationStatus == 'Pending'){
    this.toastr.warning("Please complete GSTIN Validation. Click on Validate button next to Company GSTIN",'Alert',{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
  }else{
    this.formSubmit()
  }
}

omit_special_char(event)
{   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
}

checkuser() {
  this.spinner.show()
  this.orgservice.checkuser({"email":this.orgdata.asemail}).then(data=>{
    this.spinner.hide()
    if(data.message == "No user found!") {

    } else {
      this.orgdata.asemail = null
      this.toastr.error("Email Id already exist!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
  },err=>{
    this.spinner.hide()
  })
}
  
onCheckboxChange(event){
  console.log(this.orgdata.address)
  if((event.target.checked == true) && (this.orgdata.address == undefined || this.orgdata.address == null || this.orgdata.address.length == 0)){
    event.target.checked = false;
    this.toastr.warning('Please enter address first', 'Alert',{
      timeOut:3000,
      positionClass:'toast-top-center'
   });
  }else if((event.target.checked == true) && (this.orgdata.state == undefined || this.orgdata.state == null || this.orgdata.state.length == 0)){
    event.target.checked = false;
    this.toastr.warning('Please enter state first', 'Alert',{
      timeOut:3000,
      positionClass:'toast-top-center'
   })
  }else if((event.target.checked == true) && (this.orgdata.city == undefined || this.orgdata.city == null || this.orgdata.city.length == 0)){
    event.target.checked = false;
    this.toastr.warning('Please enter city first', 'Alert',{
      timeOut:3000,
      positionClass:'toast-top-center'
   })
  }else if((event.target.checked == true) && (this.orgdata.pin == undefined || this.orgdata.pin == null || this.orgdata.pin.length == 0)){
    event.target.checked = false;
    this.toastr.warning('Please enter pincode first', 'Alert',{
      timeOut:3000,
      positionClass:'toast-top-center'
   })
  } else if((event.target.checked == true) && (this.orgdata.address && this.orgdata.state_id && this.orgdata.city && this.orgdata.pin)){
    this.isChecked = true;
    this.orgdata.pan_address = this.orgdata.address;
    this.orgdata.pan_state = this.orgdata.state_id;
    this.orgdata.pan_city = this.orgdata.city ;
    this.orgdata.pan_pin = this.orgdata.pin;
  } 
  else if(event.target.checked == false){
      console.log('box is unchecked');
      this.isChecked = false;
      this.orgdata.pan_address = null;
      this.orgdata.pan_state = null;
      this.orgdata.pan_city = null;
      this.orgdata.pan_pin = null;
  }
}

  /* showGST= false; */
formSubmit(){

    // REMOVE AFTER KARZA APIs ARE LIVE AND MAKE CHANGES IN DATABASE
    this.orgdata["gstin_status"]='Success'
    this.orgdata["gstin_validation_id"]='1'
    this.orgdata["pan_validation_id"]='1'
    this.orgdata["pan_status"]='Success' 

    this.orgdata.dob = this.datepipe.transform(this.orgdata.dob, 'yyyy-MM-dd');    this.spinner.show()
    this.orgservice.organisationreister(this.orgdata).then(
      resp=>{
        this.spinner.hide()
        if(resp['status'] == 'Success'){
          localStorage.setItem('orgId', resp['data']['id']);
          this.toastr.success("Organisation Registered Successfully","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
          })
          if(resp['status'] == "Success" && this.orgdata['auth_matrix'] == "maker_checker"){
            this.router.navigate(['/add-users'])
          }
          else if(resp['status'] == "Success" && this.orgdata['auth_matrix'] == "self_approval"){
            this.openDeclarationPopup()
          }

        } else {
          console.log(resp['message']);
          this.toastr.error("Organisation Registration failed","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
          })
        }
        
      },error=>{
        this.spinner.hide()
        console.log(error)
        this.toastr.error("Organisation Registration failed","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
        })
      }
    )

}


openDeclarationPopup(){
    let element: HTMLElement = document.getElementById('din-match-button') as HTMLElement
    element.click();
}

changeCompanyId(){
  this.router.navigate(['/'])
}
}
