import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/config';
import { OrgserviceService } from 'src/app/_api/orgservice.service';
import { VcncorpService } from 'src/app/_api/vcncorp.service';
import { Users } from '../../models/users';
import { VcnService } from 'src/app/_api/vcn.service';
const onboardingURL = new Config().getOnboadingURL();
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-yyyy',
  },
  display: {
    dateInput: 'DD-MM-yyyy',
    monthYearLabel: 'DD-MM-yyyy',
    dateA11yLabel: 'DD-MM-yyyy',
    monthYearA11yLabel: 'DD-MM-yyyy',
  },
};

@Component({
  selector: 'app-token-upload',
  templateUrl: './token-upload.component.html',
  styleUrls: ['./token-upload.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class TokenUploadComponent implements OnInit {
  formdetailsrow: boolean=true;
  listbillerrow: boolean=false;
  billersaddedlist: any=[];
  selectedIndex: any;
  billdata:any={};
  corpId:any;
  authMatrix:any;
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }
  headChange:any;
  myDate:Date = new Date();

  public params:string;
   display='none'; //default Variable
   top='50px';
   public username:string;
   public rolename:string;
   currentUser: Users;
   isUnisup:boolean;
   isBulksup:boolean;
   isApprsup:boolean;
   isUnipay:boolean;
   isApprpay:boolean;
   isBulkpay:boolean;
   isPaysupstatus:boolean;
   isCardActivity:boolean;
   cards:any=[];
   pendingcards:any=[];
   approvedcards:any=[];
   rejectedcards:any[];
   fileUpload:File;
   filename:string="Click to Browse";
   cancelcardfilename:string="Click to Browse";
   selectedFiles: FileList;
   model: any = {};
   userData: any = {};
   _users:any[]=[];
   isSelected = false;
   step2:boolean=false;
   showAliaDD:boolean=false;
   showUploadFileOpt:boolean=false;
   corporateData:any=[]
   aliasdata:any=[]
   templatedata:any=[]
   supplierdata:any=[]
   corporate_id:any
   alias_id:any;
   template_id:any;
   supplier_id:any;
   aliasname:any;
   suppliername:any;
   templatename:any;
   showUploadOption:boolean=false;
   showCancellationModule: boolean = false;
   file_name_params:any;
   template_name_params:any;
   supplier_name_params:any;
   vgc_url:any;
   redirecttoken:string;
   orgId:any;
   nextStep= false;
   fromDate:any;
   toDate:any;
   finalData = [];
   utr_no:string="";
  sub_corp_name:string="";
  remarks:string="";
  bank_name:string="";
  trans_date:string=null;
  currenTdate:string;
  convertData:any={};
  isCorporateAdmin:boolean= false;
  constructor(private vcn:VcnService,private spinner: NgxSpinnerService,private vcncorp:VcncorpService,private router: Router,public datepipe: DatePipe,private orgservice: OrgserviceService,private http: HttpClient,private toastr: ToastrService) { }

  ngOnInit() {
    this.loadUserDetails()
    this.alias_id = sessionStorage.getItem("alias_id")
    this.template_id = sessionStorage.getItem("template_id");
    this.supplier_id = sessionStorage.getItem("supplier_id");
    this.aliasname = sessionStorage.getItem("alias_name");
    this.templatename = sessionStorage.getItem("template_name");
    this.suppliername = sessionStorage.getItem("supplier_name");
    this.currenTdate=this.datepipe.transform(this.myDate, 'dd-MM-yyyy')
  }
  private loadUserDetails(){
    this.vcn.getCurrentUser().then(users => {
      // console.log(users);
       this.currentUser = users['data'];
       this.orgId = this.currentUser.id
        if(this.currentUser['role_name']=='Corporate Admin'){
          this.isCorporateAdmin=true
        }else{
          this.isCorporateAdmin=false
        }
      });
  }

      dateChange(event){
   
 
        this.billdata.dob=event._d
        this.billdata.dob=this.datepipe.transform(this.billdata.dob, 'dd-MM-yyyy')
      }

      setFromDate(ar1,ar2){
        this.billdata.fromDate = ar2.value
      }
    
      setToDate(ar1,ar2){
        this.billdata.toDate = ar2.value
      }

      gotoNextStep(){
        var regex = new RegExp("^[a-zA-Z0-9 ]+$");
        if(this.billdata.fname != null && this.billdata.email != null && this.billdata.amount != null &&
          this.billdata.lname != null && this.billdata.mobile != null && this.billdata.fname != '' &&
           this.billdata.email != '' && this.billdata.amount != '' &&
          this.billdata.lname != '' && this.billdata.mobile != ''  ){
            if(parseInt(this.billdata.amount) <= 0){
              this.toastr.warning("Amount should be greater than 0!",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            }else if(parseInt(this.billdata.noOfTrans) <= 0){
              this.toastr.warning("No of transactions should be greater than 0!",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            }else if(parseInt(this.billdata.minAmount) <= 0){
              this.toastr.warning("Min Transaction Amount should be greater than 0!",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            } else if(parseInt(this.billdata.maxAmount) <= 0){
              this.toastr.warning("Max Transaction Amount should be greater than 0!",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            } else if(this.billdata.mobile.length < 10){
              this.toastr.warning("Please enter valid mobile no!",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            }  else if(parseInt(this.billdata.amount) < parseInt(this.billdata.maxAmount)){
              this.toastr.warning("Max transation amount cannot exceed token amount!",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
                
            }  else if(!regex.test(this.billdata.fname)){
              this.toastr.warning("First Name should not have special character",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            } else if(!regex.test(this.billdata.lname)){
              this.toastr.warning("Last Name should not have special character",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            }else {
            this.nextStep=true
            }
          }
        else{
          this.toastr.warning("Please fill all the required details!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
      }

      validateNumberInput(value){    
        var k = value.keyCode;
              return ((k >= 48 && k <= 57) || k == 8);
      }
    
      validateEmail(value){    
        var j = value.keyCode;
              return ((j >= 48 && j <= 57) ||(j >= 65 && j <= 90) || (j >= 97 && j <= 122) || j == 8 || j == 46 || j == 64 || j == 95 || j == 45);
      }

      submitData(){
        if(this.isCorporateAdmin || this.currentUser['role_name'] == 'AS'){
          this.submitUnitarySelfApproval()
        } else {
          this.submitUnitaryMaker()
        }
      }

      submitUnitaryMaker(){
        if(this.billdata['fromDate']=='undefined'||this.billdata['fromDate']==''||this.billdata['fromDate']==null){
          this.billdata['fromDate']=new Date()
        }
        if(this.billdata['toDate']=='undefined'||this.billdata['toDate']==''||this.billdata['toDate']==null){
          this.billdata['toDate']=new Date()
        }
        this.fromDate=this.datepipe.transform(this.billdata['fromDate'], 'dd-MM-yyyy');
        this.toDate=this.datepipe.transform(this.billdata['toDate'], 'dd-MM-yyyy');
        this.billdata['fromDate']=this.fromDate
        this.billdata['toDate']=this.toDate
        console.log(this.finalData);
      
        this.convertData['Mobile']=this.billdata['mobile']
        this.convertData['Email']=this.billdata['email']
        this.convertData['Amount']=this.billdata['amount'].toString()
        this.convertData['Validfrom']=this.billdata['fromDate']
        this.convertData['Validto']= this.billdata['toDate']
        this.convertData['MinTransactionAmount']= this.billdata['minAmount']
        this.convertData['MaxTransactionAmount']= this.billdata['maxAmount']
        this.convertData['NoOfTransaction']= this.billdata['noOfTrans']
        this.convertData['Name']=this.billdata['fname']+" "+this.billdata['lname']
        this.convertData["Custom1"] = this.billdata["custom1"]
        this.convertData["Custom2"] = this.billdata["custom2"]
        this.convertData["Custom3"] = this.billdata["custom3"]
        this.convertData["Custom4"] = this.billdata["custom4"]
        this.convertData["Custom5"] = this.billdata["custom5"]
        this.convertData["Custom6"] = this.billdata["custom6"]
        this.convertData["Custom7"] = this.billdata["custom7"]
        this.convertData["Custom8"] = this.billdata["custom8"]
        this.convertData["Custom9"] = this.billdata["custom9"]
        this.convertData["Custom10"] = this.billdata["custom10"]
        this.finalData[0]=this.convertData
        var params={
          "transaction_details":{
            //"file_name":this.filedata['data']['filename'],
            "total_amount":this.billdata['amount'],
            "total_cards":"1",
            "utr_no":this.utr_no,
            "transaction_date":!!this.trans_date?this.trans_date:this.currenTdate,
            "bank_name":this.bank_name,
            "alias_id":this.alias_id,
            "template_id":this.template_id,
            "supplier_id":this.supplier_id,
            "sub_corp_name":this.sub_corp_name,
            "remarks":this.remarks,
            "corporate_id":this.corporate_id
          }, 
          "file_data":this.finalData
        }
        this.spinner.show()
        this.vcncorp.submitCardRequestFile(params).subscribe(resp=>{
          if(resp['message']=='Card request submit for approval successfully'){
            this.spinner.hide()
            sessionStorage.removeItem("alias_id")
            sessionStorage.removeItem("template_id");
            sessionStorage.removeItem("supplier_id");
            sessionStorage.removeItem("alias_name");
            sessionStorage.removeItem("template_name");
            sessionStorage.removeItem("supplier_name");
            this.router.navigate(['/req-submit-success'],{queryParams:{msg:'cardrequestsuccess'}});
          }else if(resp['err']!=null){
            this.spinner.hide()
            this.toastr.error(resp['err'],'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else{
            this.spinner.hide()
            this.toastr.error("Something Went Wrong!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
         
        },error=>{
          this.spinner.hide()
          this.toastr.error("Failed to Submit Request!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
          console.log(error)
        })
      }

      submitUnitarySelfApproval(){
        if(this.billdata['fromDate']=='undefined'||this.billdata['fromDate']==''||this.billdata['fromDate']==null){
          this.billdata['fromDate']=new Date()
        }
        if(this.billdata['toDate']=='undefined'||this.billdata['toDate']==''||this.billdata['toDate']==null){
          this.billdata['toDate']=new Date()
        }
        this.fromDate=this.datepipe.transform(this.billdata['fromDate'], 'dd-MM-yyyy');
        this.toDate=this.datepipe.transform(this.billdata['toDate'], 'dd-MM-yyyy');
        this.billdata['fromDate']=this.fromDate
        this.billdata['toDate']=this.toDate
        console.log(this.finalData);
      
        this.convertData['Mobile']=this.billdata['mobile']
        this.convertData['Email']=this.billdata['email']
        this.convertData['Amount']=this.billdata['amount'].toString()
        this.convertData['Validfrom']=this.billdata['fromDate']
        this.convertData['Validto']= this.billdata['toDate']
        this.convertData['MinTransactionAmount']= this.billdata['minAmount']
        this.convertData['MaxTransactionAmount']= this.billdata['maxAmount']
        this.convertData['NoOfTransaction']= this.billdata['noOfTrans']
        this.convertData['Name']=this.billdata['fname']+" "+this.billdata['lname']
        this.convertData["Custom1"] = this.billdata["custom1"]
        this.convertData["Custom2"] = this.billdata["custom2"]
        this.convertData["Custom3"] = this.billdata["custom3"]
        this.convertData["Custom4"] = this.billdata["custom4"]
        this.convertData["Custom5"] = this.billdata["custom5"]
        this.convertData["Custom6"] = this.billdata["custom6"]
        this.convertData["Custom7"] = this.billdata["custom7"]
        this.convertData["Custom8"] = this.billdata["custom8"]
        this.convertData["Custom9"] = this.billdata["custom9"]
        this.convertData["Custom10"] = this.billdata["custom10"]
        this.finalData[0]=this.convertData
        var params={
          "transaction_details":{
            //"file_name":this.filedata['data']['filename'],
            "total_amount":this.billdata['amount'],
            "total_cards":"1",
            "utr_no":this.utr_no,
            "transaction_date":!!this.trans_date?this.trans_date:this.currenTdate,
            "bank_name":this.bank_name,
            "alias_id":this.alias_id,
            "template_id":this.template_id,
            "supplier_id":this.supplier_id,
            "sub_corp_name":this.sub_corp_name,
            "remarks":this.remarks,
            "corporate_id":this.corporate_id
          }, 
          "file_data":this.finalData
        }
        this.spinner.show()
        this.vcn.submitBulkReq(params).subscribe(resp=>{
          if(resp['message']=='Card request submit for approval successfully'){
            this.spinner.hide()
            sessionStorage.removeItem("alias_id")
            sessionStorage.removeItem("template_id");
            sessionStorage.removeItem("supplier_id");
            sessionStorage.removeItem("alias_name");
            sessionStorage.removeItem("template_name");
            sessionStorage.removeItem("supplier_name");
            this.router.navigate(['/req-submit-success'],{queryParams:{msg:'cardrequestsuccess'}});
          }else{
            this.spinner.hide()
            this.toastr.error("Something Went wrong!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
         
        },error=>{
          this.spinner.hide()
          this.toastr.error("Failed to Submit Request!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
          console.log(error)
        })
      }
      
      goBack(){
        this.nextStep=false
      }
}
