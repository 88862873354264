import { Component, OnInit } from '@angular/core';
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import { Router, ActivatedRoute,NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import {AuthService} from '../../_api/auth.service'
import { Location } from '@angular/common';
import {Urlconfig} from '../../urlconfig'
import { ToastrService } from 'ngx-toastr';
const vgc_path= new Urlconfig().getGiftCardURL()
const vcn_path= new Urlconfig().getVcnURL()
import * as $ from 'jquery';

@Component({
  selector: 'app-corporate-header',
  templateUrl: './corporate-header.component.html',
  styleUrls: ['./corporate-header.component.css']
})
export class CorporateHeaderComponent implements OnInit {
  userrole:string;
  pathroute: string;
  dashactive:boolean=true;
  actionactive:boolean=false;
  penFilesActive:boolean=false;
  apprFilesActive:boolean=false;
  reportFilesActive:boolean=false;
  rejFilesActive:boolean=false;
  updateContactActive:boolean=false;
  gdsActive:boolean=false;
  redirecttoken:string;
  localstoragetoken:string;
  vgc_url:string;
  email:string;
  is_sbm:boolean = false;
  is_vcn:boolean = false;
  is_cancel: boolean = false;
  toCheck: boolean=false;

  constructor(private userservice: UserService,private router: Router,private vcn:VcnService,private auth:AuthService,location: Location,private route: ActivatedRoute, private toastr: ToastrService) { 
    router.events.subscribe((val) => {
      this.pathroute=location.path();
      if(this.pathroute=='/corp-dashboard' || this.pathroute=='/req-submit-success'){
        this.clickDashEvent()
      }else if(this.pathroute=='/req-approve-success' || this.pathroute=='/corp-pending-files' || this.pathroute.includes('corp-pending-vcn-view')){
        this.clickPendingFilesEvent()
      }else if(this.pathroute=='/corp-approved-files' || this.pathroute.includes('corp-vcn-view')){
        this.clickApprovedFilesEvent();
      }else if(this.pathroute=='/corp-range-report' || this.pathroute=='/corp-current-report' || this.pathroute=='/corp-report-files' || this.pathroute.includes('corp-reports') ){
        this.clickReportsEvent();
      }else if(this.pathroute=='/req-reject-success' || this.pathroute=='/corp-rejected-files' ||  this.pathroute.includes('corp-vcn-view')){
        this.clickRejectedFilesEvent();
      }else if(this.pathroute=='/corp-update-contact-bulk' || this.pathroute=='/corp-update-contact-unitary' || this.pathroute=='/mobile-update-success-corp' || this.pathroute=='/corp-pending-contacts' || this.pathroute=='/mobile-approve-success'){
        this.clickUpdateContactEvent();
      }else if(this.pathroute=='/pending-pcc' || this.pathroute=='/view-pcc' || this.pathroute=='/pending-gds-users' || this.pathroute=='/view-gds-users'){
        this.clickGDSEvent();
      } 
  
  });
  }

  ngOnInit() {
    this.redirecttoken=this.route.snapshot.queryParams["token"];
    if(this.redirecttoken!=undefined){
      this.auth.saveToken(this.redirecttoken)
    }
    this.vcn.getCurrentUser().then(users => {
      this.userrole=users['data']['role_name'];
      this.is_sbm =  users['data']['is_sbm'];
      this.is_vcn = users['data']['is_vcn'];
      this.email = users['data']['email'];
      this.is_cancel = users['data']['is_cancel'];
     });
    // this.localstoragetoken=localStorage.getItem('token')
    // this.vgc_url=vgc_path+'corp-dashboard?token='+this.localstoragetoken;
  }

  logout(){
    localStorage.clear()
    sessionStorage.clear()
    this.auth.logout()
  
}

clickDashEvent(){
  this.dashactive=true;
  this.penFilesActive=false;
  this.apprFilesActive=false;
  this.reportFilesActive=false;
  this.rejFilesActive=false;
  this.updateContactActive=false;
  this.gdsActive =false;
}

clickPendingFilesEvent(){
  this.dashactive=false;
  this.penFilesActive=true;
  this.actionactive=true;
  this.apprFilesActive=false;
  this.reportFilesActive=false;
  this.rejFilesActive=false;
  this.updateContactActive=false;
  this.gdsActive =false;
}

clickApprovedFilesEvent(){
  this.dashactive=false;
  this.penFilesActive=false;
  this.apprFilesActive=true;
  this.actionactive=true;
  this.reportFilesActive=false;
  this.rejFilesActive=false;
  this.updateContactActive=false;
  this.gdsActive =false;
}

clickReportsEvent(){
  this.dashactive=false;
  this.penFilesActive=false;
  this.apprFilesActive=false;
  this.reportFilesActive=true;
  this.rejFilesActive=false;
  this.updateContactActive=false;
  this.gdsActive =false;
}

clickRejectedFilesEvent(){
  this.dashactive=false;
  this.penFilesActive=false;
  this.apprFilesActive=false;
  this.rejFilesActive=true;
  this.actionactive=true;
  this.reportFilesActive=false;
  this.updateContactActive=false;
  this.gdsActive =false;
}

clickUpdateContactEvent(){
  this.dashactive=false;
  this.penFilesActive=false;
  this.apprFilesActive=false;
  this.rejFilesActive=false;
  this.reportFilesActive=false;
  this.updateContactActive=true;
  this.gdsActive =false;
}

clickActionFilesEvent(){
  this.dashactive=false;
  this.penFilesActive=false;
  this.apprFilesActive=false;
  this.reportFilesActive=false;
  this.rejFilesActive=false;
  this.updateContactActive=false;
  this.actionactive = true;
  this.gdsActive =false;
}

clickGDSEvent(){
  this.dashactive=false;
  this.penFilesActive=false;
  this.apprFilesActive=false;
  this.reportFilesActive=false;
  this.rejFilesActive=false;
  this.updateContactActive=false;
  this.actionactive = false;
  this.gdsActive =true;
}

goToSBM(){
  this.auth.newSSOtoken(this.email).then(resp => {
    if(resp["message"]="Token generated!") {
      var sbmToken = resp["data"];
      this.vgc_url = vgc_path+'corp-dashboard?token='+ sbmToken;
      window.open(this.vgc_url,"_self")
    } else {
      this.toastr.error('Something went wrong!','Error',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
   });
}

// onTokenDropdownClick(){

//   document.getElementById("TokenDropdown").classList.toggle("show"); 
//   document.getElementById("Token").classList.toggle("show"); 
//   document.getElementById("Cancel").classList.toggle("show"); 
//   document.getElementById("CancelDropdown").classList.toggle("hide"); 
//   // $(document).ready(function(){
//   //   $('#Token').click(function() {
   
//   //        $('#TokenDropdown').dropdown("toggle");
//   //    });
//   //  });

//   //  $(document).ready(function() {
//   //   $('#Token').click(function() {
   
//   //        $('#TokenDropdown').slideToggle();
//   //    });
//   //   });
// }

// onCancelDropdownClick(){
//   document.getElementById("TokenDropdown").classList.toggle("hide"); 
//   document.getElementById("Token").classList.toggle("show"); 
//   document.getElementById("Cancel").classList.toggle("show"); 
//   document.getElementById("CancelDropdown").classList.toggle("show"); 
//   // $(document).ready(function(){
//   //   $('#Cancel').click(function() {
      
//   //        $('#CancelDropdown').dropdown("toggle");
//   //    });
//   //  });

//   //  $(document).ready(function() {
//   //   $('#Token').click(function() {
   
//   //        $('#TokenDropdown').slideToggle();
//   //    });
//   //   });
// }


}
