export class Urlconfig{
    vcnurl:string
    vgcurl:string


    constructor(){
        // this.vcnurl="http://localhost:4100/" //Local
        // this.vcnurl = "http://35.154.78.164/" //TEST & UAT
        // this.vcnurl="http://15.207.6.1/"  //UAT
        this.vcnurl="https://icici-vcn.aquapay.in/" //Production

        // this.vgcurl="http://localhost:4200/" //Local
        this.vgcurl="http://15.206.73.138/" //Test
        // this.vgcurl="https://vgc.aquapay.in/" //Production
    }

    getGiftCardURL(){
        return this.vgcurl
    }
  
    getVcnURL(){
        return this.vcnurl
    }



   
  

}