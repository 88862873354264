
import { Component, OnInit } from '@angular/core';
import { ResetPasswordService } from '../../_api/reset-password.service';
import { UserService } from '../../_api/user.service';
import { Router } from '../../../../node_modules/@angular/router';
import { ToastrService } from 'ngx-toastr'
import { VcnService } from 'src/app/_api/vcn.service';

@Component({
    selector : 'app-resetpassword',
    templateUrl : './resetpassword.component.html',
    styleUrls : ['./resetpassword.component.css']
})

export class ResetPasswordComponent implements OnInit{
    passwordData :any= {};
    uid : number;
    emailid:any
    errorMsg : "";
    enterpwd: any;
    reenterpwd: any;
    oldpwd:any;
    constructor(private vcn:VcnService,private resetpasswordService : ResetPasswordService,private userservice : UserService, private router: Router,private toastr: ToastrService){}
    ngOnInit(){
      this.vcn.getCurrentUser().then(users => {
        this.emailid = users['data']['email'];
       });
    }

    onSubmit(){
        console.log(this.passwordData);
          // this.resetpasswordService.resetPass(this.passwordData,this.uid).then(
          //   res =>   {
          //     if(res['msg']== "Password Update Successfully"){
          //       this.router.navigate(['/'])
          //       localStorage.removeItem('token');
          //       this.toastr.success("Your Password has been reset successfully, Please login again with new password.","Success",{
          //       timeOut:3000,
          //       positionClass:'toast-top-center'
          //       })
          //     }
          //     else if(res['msg']=="Password not Matched."){
          //       this.toastr.error("Passwords not matched","Alert",{
          //         timeOut:3000,
          //         positionClass:'toast-top-center'
          //         })
          //     }                
          // },
          //   error => this.errorMsg = error.statusText
            
          // )
          console.log(this.errorMsg);
    }

    setnewpwd(){
      console.log(this.enterpwd);
      console.log(this.reenterpwd);
      const regex=/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      console.log(regex.test(this.enterpwd));

      if(!!this.enterpwd && !!this.reenterpwd && this.enterpwd.length >= 8 && regex.test(this.enterpwd)){
        //
        if (this.enterpwd == this.reenterpwd) {
          this.resetpasswordService.setnewpassword(this.emailid,this.enterpwd,this.reenterpwd,this.oldpwd)
          console.log("Correct Password")
        }else{
          this.toastr.warning("Password and Confirm Password not matched!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
       
      }else{
        this.toastr.warning("Please enter atleast 8-alphanumeric characters and with at least one Uppercase or Special or Lowercase Character in password field!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
      
    }


}