import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cardreqsubsuccess',
  templateUrl: './cardreqsubsuccess.component.html',
  styleUrls: ['./cardreqsubsuccess.component.css']
})
export class CardreqsubsuccessComponent implements OnInit {
  params:any;
  cardRequestSuccess:boolean = false;
  updateBalanceSuccess: boolean = false;
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.params = this.activatedRoute.snapshot.queryParams["msg"];
    if(this.params=='cardrequestuccess'){
      this.cardRequestSuccess=true;
    }else if(this.params=='updatebalancesuccess') {
      this.updateBalanceSuccess=true;
    }
  }

}
