import { Component, OnInit } from '@angular/core';
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import { Router, ActivatedRoute,NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import {AuthService} from '../../_api/auth.service'
import { Location } from '@angular/common';
import {Urlconfig} from '../../urlconfig'
import { ToastrService } from 'ngx-toastr';
const vgc_path= new Urlconfig().getGiftCardURL()
const vcn_path= new Urlconfig().getVcnURL()
@Component({
  selector: 'app-cardreqheader',
  templateUrl: './cardreqheader.component.html',
  styleUrls: ['./cardreqheader.component.css']
})
export class CardreqheaderComponent implements OnInit {
  currentUser: Users;
  role: any;
  pathroute: string;
  dashactive:boolean=true;
  actionactive:boolean=false;
  penFilesActive:boolean=false;
  apprFilesActive:boolean=false;
  reportFilesActive:boolean=false;
  corporateOnboardingActive:boolean=false;
  UserDetailsActive:boolean=false;
  rejFilesActive:boolean=false;
  inprocessFilesActive:boolean=false;
  redirecttoken:string;
  localstoragetoken:string;
  vgc_url:string;
  email:string;
  is_sbm:boolean = false;
  is_vcn:boolean = false;
  userrole:string;
  constructor(private userservice: UserService,private router: Router,private vcn:VcnService,private auth:AuthService,location: Location,private route: ActivatedRoute, private toastr: ToastrService) { 
    router.events.subscribe((val) => {
      this.pathroute=location.path();
      if(this.pathroute=='/card-request-summary' || this.pathroute=='/card-upload-view' || this.pathroute=='/card-req-submit-success'){
        this.clickDashEvent()
      }else if(this.pathroute=='/pending-files' || this.pathroute=='/card-req-approve-success' || this.pathroute.includes('checker-card')){
        this.clickPendingFilesEvent()
      }else if(this.pathroute=='/approved-files' || this.pathroute=='/update-contact' || this.pathroute=='/mobile-update-success' || this.pathroute=='/block-card' || this.pathroute.includes('maker-card')){
        this.clickApprovedFilesEvent();
      }else if(this.pathroute=='/admin-report-files' || this.pathroute=='/admin-current-report' || this.pathroute=='/admin-range-report' || this.pathroute.includes('admin-reports') || this.pathroute=='/unitary-search'){
        this.clickReportsEvent();
      }else if(this.pathroute=='/inporocess-files' || this.pathroute.includes('inprocess-vcn-view')){
        this.clickInProcessFilesEvent();
      }else if(this.pathroute=='/org-listing' || this.pathroute.includes('add-ccadmin') || this.pathroute.includes('add-template') || this.pathroute.includes('add-alias')|| this.pathroute.includes('add-supplier')){
        this.clickInProcessFilesEvent();
      }else if(this.pathroute=='/update-user-details'){
        this.clickInProcessFilesEvent();
      }
  });
  }

  ngOnInit() {
    this.redirecttoken=this.route.snapshot.queryParams["token"];
    if(this.redirecttoken!=undefined){
      this.auth.saveToken(this.redirecttoken)
    }
    this.vcn.getCurrentUser().then(users => {
      this.userrole=users['data']['role_name'];
      this.is_sbm =  users['data']['is_sbm'];
      this.is_vcn = users['data']['is_vcn'];
      this.email = users['data']['email'];
     });
    // this.localstoragetoken=localStorage.getItem('token')
    // this.vgc_url=vgc_path+'card-request-summary?token='+this.localstoragetoken;
  }

  logout(){
      this.auth.logout()
    
  }

  clickDashEvent(){
    this.dashactive=true;
    this.penFilesActive=false;
    this.apprFilesActive=false;
    this.reportFilesActive=false;
    this.rejFilesActive=false;
    this.inprocessFilesActive=false;
    this.actionactive=false;
    this.corporateOnboardingActive = false;
    this.UserDetailsActive = false;
  }
  
  clickPendingFilesEvent(){
    this.dashactive=false;
    this.penFilesActive=true;
    this.actionactive=true;
    this.apprFilesActive=false;
    this.reportFilesActive=false;
    this.rejFilesActive=false;
    this.inprocessFilesActive=false;
    this.corporateOnboardingActive = false;
    this.UserDetailsActive = false;
  }
  
  clickApprovedFilesEvent(){
    this.dashactive=false;
    this.penFilesActive=false;
    this.apprFilesActive=true;
    this.actionactive=true;
    this.reportFilesActive=false;
    this.rejFilesActive=false;
    this.inprocessFilesActive=false;
    this.corporateOnboardingActive = false;
    this.UserDetailsActive = false;
  }
  
  clickInquireCardsEvent(){
    this.dashactive=false;
    this.penFilesActive=false;
    this.apprFilesActive=true;
    this.actionactive=true;
    this.reportFilesActive=false;
    this.rejFilesActive=false;
    this.inprocessFilesActive=false;
    this.corporateOnboardingActive = false;
    this.UserDetailsActive = false;
  }

  clickReportsEvent(){
    this.dashactive=false;
    this.penFilesActive=false;
    this.apprFilesActive=false;
    this.reportFilesActive=true;
    this.rejFilesActive=false;
    this.inprocessFilesActive=false;
    this.corporateOnboardingActive = false;
    this.UserDetailsActive = false;
  }

  clickCorpOnboardingEvent(){
    this.dashactive=false;
    this.penFilesActive=false;
    this.apprFilesActive=false;
    this.reportFilesActive=false;
    this.rejFilesActive=false;
    this.inprocessFilesActive=false;
    this.corporateOnboardingActive = true;
    this.UserDetailsActive = false;
  }

  clickUserDetailsEvent(){
    this.dashactive=false;
    this.penFilesActive=false;
    this.apprFilesActive=false;
    this.reportFilesActive=false;
    this.rejFilesActive=false;
    this.inprocessFilesActive=false;
    this.corporateOnboardingActive = false;
    this.UserDetailsActive = true;
  }
  
  clickRejectedFilesEvent(){
    this.dashactive=false;
    this.penFilesActive=false;
    this.apprFilesActive=false;
    this.rejFilesActive=true;
    this.reportFilesActive=false;
    this.inprocessFilesActive=false;
    this.corporateOnboardingActive = false;
    this.UserDetailsActive = false;
  }
  clickInProcessFilesEvent(){
    this.dashactive=false;
    this.penFilesActive=false;
    this.apprFilesActive=false;
    this.rejFilesActive=false;
    this.reportFilesActive=false;
    this.inprocessFilesActive=true;
    this.actionactive=true;
    this.corporateOnboardingActive = false;
    this.UserDetailsActive = false;
  }

  goToSBM(){
    this.auth.newSSOtoken(this.email).then(resp => {
      if(resp["message"]="Token generated!") {
        var sbmToken = resp["data"];
        this.vgc_url = vgc_path+'card-request-summary?token='+ sbmToken;
        window.open(this.vgc_url,"_self")
      } else {
        this.toastr.error('Something went wrong!','Error',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
     });
  }

}
