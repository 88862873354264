import { Component, OnInit } from '@angular/core';
import {VcnService} from '../../_api/vcn.service'
import { DatePipe } from '@angular/common'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router';
import { ExcelService } from '../../excelservice/excel.service'
import {ExcelGeneratorService} from  '../../_api/excel-generator.service'

@Component({
  selector: 'app-inprocess-files',
  templateUrl: './inprocess-files.component.html',
  styleUrls: ['./inprocess-files.component.css']
})
export class InprocessFilesComponent implements OnInit {
  filedata:any=[]
  rowsOnPage = 1000;
  sortBy = "created_date";
  sortOrder = "asc";
  selectedIndex = -1;
  downloadArray:any=[];
  constructor(private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private route:Router,private excelservice : ExcelService,private excel:ExcelGeneratorService) { }

  ngOnInit() {
    this.loadRequestList()
  }
  private loadRequestList(){
    this.spinner.show()
    this.vcn.getFileList().then(resp=>{
    
      var filedatalist=resp['data']
      this.filedata=filedatalist.filter(file=>(file['status']=='Inprocess' && file['checker_status']=='Approved' && file['maker_status']=='Uploaded' ))
    
      this.spinner.hide()
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }

  gotolistview(id){
    this.route.navigate(['/maker-card', id]);

  }

  toggleExpandedView(index){
    this.selectedIndex = index;
   }

   exportAsXLSX():void {
    this.downloadArray=[]
    
    if(this.filedata.length>=0){
      for(let data of this.filedata){
        var obj={
          FileName:data['file_name'],
          Total_Tokens:data['total_cards'],
          Total_Amount:data['total_amount'],
          Alias_Name:data['alias_name'],
          Corporate_Name:data['corporate_name'],
          Bank_Name:data['bank_name'],
          UTR:data['utr_no'],
          Transaction_Date:data['transaction_date'],
          Initiated_Time:data['created_time'],
          Initiated_Date:data['created_date'],
          // Alias_Name:this.filesummary['alias_name'],
          // Corporate_name:this.filesummary['corporate_name'],
          Approved_Date:data['updated_date'],
          Approved_Time:data['updated_time'],

       
        }
        this.downloadArray.push(obj)
      }
      this.excel.exportAsExcelFile( this.downloadArray, "Request_File_List");
    }else{
      this.toastr.warning("No Data Available","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
   // this.excelservice.exportAsExcelFile( this.users, 'User');
  }

}
