import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {AuthService} from '../../_api/auth.service'
import {FormControl, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  submitted = false;
  loginData:any = {};
  constructor(private authService: AuthService,private route: ActivatedRoute,private router: Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/otp';
  }

  loginPost() {
    localStorage.removeItem('token');
    this.submitted = true;
   
    if(this.loginData['username']==undefined || this.loginData['username']==""){
      this.toastr.warning("Please Enter User Name!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }else if(this.loginData['password']==undefined || this.loginData['password']==""){
      this.toastr.warning("Please Enter Password!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }else if((this.loginData['username']==undefined || this.loginData['username']=="") && (this.loginData['password']==undefined || this.loginData['password']=="")){
      this.toastr.warning("Please Enter User Name and Password!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }else{
    this.loginData['password']=btoa(this.loginData['password'])
    
    this.authService.loginUser(this.loginData)
    }
    //this.router.navigate([this.returnUrl]);
  }

}
