import { Component, OnInit } from '@angular/core';
import{OrganisationService} from '../../_api/organisation.service'
import{LoaderService} from '../../_api/loader.service';
import{UserService} from '../../_api/user.service'
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.css']
})
export class OrganisationComponent implements OnInit {
  orgdata:any={};
  address:string;
  city:string;
  state:string;
  constructor(private orgservice:OrganisationService,private loader:LoaderService,
    private userservice:UserService) { }

  ngOnInit() {
    this.getOrg();
    // $(document).ready(function(){
    //   $(".success").hide();
    
    //   $('.activeapp').click(function () {
    //     $(".success").show();
    //     $(".abc").hide();
    //   });

    // });
  }
  private getOrg(){
    this.orgservice.getOrganisation().subscribe(data=>{
      
      this.orgdata=data['data'];
    
    },error=>{
      console.log(error)
    })
}

}
