import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import {VcnService} from '../../_api/vcn.service'
declare var require:any;

@Component({
  selector: 'app-cardreqhome',
  templateUrl: './cardreqhome.component.html',
  styleUrls: ['./cardreqhome.component.css']
})
export class CardreqhomeComponent implements OnInit {
  currentUser: Users;
  fname: string;
  lname:string;
  cmpyname:string;
  dsgtn: string;
  lastsession:string;
  userrole:string;
  isUniuser:boolean;
  isBulkuser:boolean;
  isUnicard:boolean;
  isUnigroup:boolean;
  isUnirule:boolean;
  isAuthmatrix:boolean;
  isUnisup:boolean;
  isBulksup:boolean;
  constructor(private userservice: UserService,private vcn:VcnService) { }

  ngOnInit() {
    $(".addgps").on("click", function () {
      $(".paylink").addClass("active");
      $(".paylink").siblings(".active").removeClass("active");
    });


    $(".addruls").on("click", function () {
      $(".rulelink").addClass("active");
      $(".rulelink").siblings(".active").removeClass("active");
  });

  $(".addcrd").on("click", function () {
    $(".supplink").addClass("active");
    $(".supplink").siblings(".active").removeClass("active");
  });

  $(".adduusr").on("click", function () {
    $(".paylink2").addClass("active");
    $(".paylink2").siblings(".active").removeClass("active");
  });

  $(".addbusr").on("click", function () {
    $(".paylink2").addClass("active");
    $(".paylink2").siblings(".active").removeClass("active");
  });

  this.vcn.getCurrentUser().then(users => {
    
     this.currentUser = users['data']; 
    
     this.fname=users['data']['first_name'];
     this.lname=users['data']['last_name'];
     if(users['data']['last_login']!=null){
     this.lastsession=users['data']['last_login'];
     let dateFormat = require('dateformat');
     let now = this.lastsession
     this.lastsession=dateFormat(now, "dddd, mmmm dS, yyyy, hh:MM TT");
     }else{
     this.lastsession="mmm dd yyyy  h:mm"
     }
 
     this.dsgtn=users['data']['designation'];
     this.userrole=users['data']['role_name'];
   
    
     //console.log(this.isPermission);
    });

  }

  checkValInArray(tabid:string , tab_array:string[]){
    return (tab_array.indexOf(tabid)==-1)?false:true;
}

private loadUserDetails(){
  this.vcn.getCurrentUser().then(resp=>{
    console.log(resp)
  },error=>{
    console.log(error)
  })
}

}
