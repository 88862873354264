import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
import { Groups } from '../../models/groups';
import{LoaderService} from '../../_api/loader.service';
import { ExcelService } from '../../excelservice/excel.service'
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import { DatePipe } from '@angular/common'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
import {ExcelGeneratorService} from  '../../_api/excel-generator.service'
import {VcncorpService} from '../../_api/vcncorp.service'
import * as CryptoJS from 'crypto-js';
import { Config } from 'src/app/config';

const decryptKey = new Config().getVCNCipher()
const decryptIV = new Config().getVCNText()

@Component({
  selector: 'app-corporate-vcn-view',
  templateUrl: './corporate-vcn-view.component.html',
  styleUrls: ['./corporate-vcn-view.component.css']
})
export class CorporateVcnViewComponent implements OnInit {
  public id: string;
  iid: string;
  filedata:any=[];
  purchaseReqId:any=[];
  downloadArray:any=[];
  filesummary:any={}
  cardholder:any;
  cardno:any;
  cardcvv:any;
  cardexpiry:any;
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  additonal_amount:any=0
  current_amount:any=0
  add_curr_amt:any=0;
  total_amt_in_words:any="TWO THOUSAND RUPEES";
  selectedIndex = -1;
  showDownloadOption:boolean=false;
  cardDataPopup:any={};
  fname:any;
  dob:any;
  role:any;
  userId:any;
  base64:any;
  toEmail:any = "";
  toPassword:any = "";
  isDownloadFile:any;
  isSendFileOnEmail:any;
  corp_id:any;
  corp_name:any;
  fileStatus:any;
  isSendLoginLink:any = false;
  sendLoginLinkArray:any =[]
  sendCardDataArray:any =[]
  checkedValueArray: any=[];
  temp: boolean;
  cntChk: number;
  selectedAll: any;
  selectall:boolean=false;
  flag: number;
  mailType: any;
  allowedCorporates = [1,2]
  decrypted: any;
  cvv: any;
  cardView:any;
  transaction_ref_no: any;
  request_purchase_id: any;

  constructor(private activeroute: ActivatedRoute,private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private route:Router,private excelservice : ExcelService, private excel:ExcelGeneratorService,private vcncorp:VcncorpService) { }

  ngOnInit() {
    console.log("INSIDE INIT")
    this.isDownloadFile = sessionStorage.getItem("is_download_file")
    this.isSendFileOnEmail = sessionStorage.getItem("is_send_file_on_email")
    console.log("SET",typeof(this.isDownloadFile))
    console.log(this.toEmail.length)
    this.filedata=[]
    this.purchaseReqId=[]
    
    this.loadUserDetails()
    this.id = this.activeroute.snapshot.paramMap.get('id');
    if(this.id!=undefined || this.id!=null){
      this.loadFileData()
    }
    else{
      this.toastr.error("Something Went wrong!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }

  }

  checkCorporate(corp_id){
    return this.allowedCorporates.includes(parseInt(corp_id))
  }

  private loadUserDetails(){
    this.vcn.getCurrentUser().then(users => {
       this.fname = users['data'].first_name
       this.dob = users['data']['dob'].replace(/-/g, "");
       this.role = users['data'].role_name
       this.userId = users['data'].id
      //  this.isDownloadFile = users['data'].is_download_file
      //  this.isSendFileOnEmail = users['data'].is_send_file_on_email
       this.corp_id = users['data'].corporate_id
       this.corp_name = users['data']["dataValues"] ? users['data']["dataValues"].name : users['data']['corporate'].name
      });
  }

  checkClick(cardData): void {
    console.log(cardData)
    this.cardDataPopup=cardData
    $("#customFields").modal("show");
 

}

sendLoginLink(value){
  console.log(value)
  this.mailType = value;
  this.isSendLoginLink = !this.isSendLoginLink
  if(this.isSendLoginLink){
    this.toastr.warning("Please select tokens for sending the login link on email!",'Alert',{
      timeOut:2000,
      positionClass:'toast-top-center'
      })
  }
}

sendMail(){
  console.log(this.mailType)
  if(this.mailType == "card") {
    this.sendCardDetailsOnMail()
  } else if(this.mailType == "link"){
    this.sendLoginEmail()
  } else {
    this.toastr.warning("Something went wrong!",'Alert',{
      timeOut:2000,
      positionClass:'toast-top-center'
      })
  }
}

sendLoginEmail(){
  this.spinner.show()
  for (var i = 0; i < this.checkedValueArray.length; i++) {
    var emailObj = this.filedata.filter(item=>item.id == this.checkedValueArray[i])
    console.log("OBJ",emailObj)
    if(emailObj.length > 0)
    this.sendLoginLinkArray.includes(emailObj[0].email) ? "": this.sendLoginLinkArray.push(emailObj[0].email)
  }
  console.log(this.sendLoginLinkArray)
  this.excel.sendLoginLinkonMail(this.corp_name,this.sendLoginLinkArray).then(resp=>{
    this.sendLoginLinkArray = []
    this.checkedValueArray = []
    this.isSendLoginLink = false;
    this.spinner.hide()
    this.toastr.success("Email sent successfully!","Alert",{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
  },err=>{
    this.spinner.hide()
    this.toastr.warning("Something went wrong!","Alert",{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
  })
}

sendCardDetailsOnMail(){
  this.spinner.show()
  for (var i = 0; i < this.checkedValueArray.length; i++) {
    var emailObj = this.filedata.filter(item=>item.id == this.checkedValueArray[i])
    console.log("OBJ",emailObj)
    if(emailObj.length > 0){
      if(emailObj[0].status == "Active"){
        var temp = {
          "cardNo":emailObj[0].card_no,
          "expiry":emailObj[0].expiry,
          "cvv":emailObj[0].cvv,
          "toEmail":emailObj[0].email,
          "name":emailObj[0].name,
          "tokenId":emailObj[0].transaction_ref_no,
          "purchaseReqId":emailObj[0].request_purchase_id,
          "file_data_id":emailObj[0].id
        }
        this.sendCardDataArray.push(temp)
      }
    }
  }
  console.log(this.sendCardDataArray)
  this.excel.sendCardonMail(this.corp_name,this.sendCardDataArray,this.corp_id).then(resp=>{
    this.sendCardDataArray = []
    this.checkedValueArray = []
    this.isSendLoginLink = false;
    this.spinner.hide()
    this.toastr.success("Email sent successfully!","Alert",{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
  },err=>{
    this.spinner.hide()
    this.toastr.warning("Something went wrong!","Alert",{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
  })
}

change(id,selected): void {
  console.log("SELECTED",selected)
  this.flag = 0;
for (var i = 0; i < this.checkedValueArray.length; i++) {
  if (this.checkedValueArray[i] == id) {
    this.checkedValueArray.splice(i, 1);
    this.flag = 1;
  }
}
if (this.flag == 0) {
  this.checkedValueArray.push(id);
}

if (this.checkedValueArray.length > 0) {
  this.temp = true;
  if(this.checkedValueArray.length<this.filedata.length){
    this.selectall=false
  }else{
    this.selectall=true;
    this.cntChk = 1;
  }
}
else {
  this.temp = false;
  if(this.checkedValueArray.length<this.filedata.length){
    this.selectall=false
  }else{
    this.selectall=true;
  }
  console.log(this.selectall)
 
}
console.log(this.checkedValueArray)
}

changeAll(pendingContacts): void {
  if(this.checkedValueArray.length==this.filedata.length){
    this.cntChk=1
    }else{
    this.checkedValueArray = [];
    this.cntChk=0
    }
    console.log(this.selectall)
    if (this.cntChk == 0) {
      this.cntChk = 1;
      this.temp = true;
      this.selectall=true;
      for (var i = 0; i < pendingContacts.length; i++) {
        this.checkedValueArray[i] = pendingContacts[i].id;
      }
      for (var i = 0; i < this.filedata.length; i++) {
        this.filedata[i].selected = this.filedata;   // TO MARK ALL CHECKED when selectall is checked
      }
      this.cntChk = 0;
    }
   
    else {
      this.cntChk = 0;
      this.temp = false;
      this.checkedValueArray = [];
      for (var i = 0; i < this.filedata.length; i++) {
        this.filedata[i].selected =  undefined; // TO MARK ALL UNCHECKED when selectall is checked
      }
    }
    console.log(this.checkedValueArray)
}


checkIfAllSelected() {
  this.selectedAll = this.filedata.every(function(item:any) {
      return item.selected == true;
    })
}

  viewcardDetails(crdno,name,expiry,cvv,transno,reqid): void {
   
    this.cardholder=name

    // this.cardno=`XXXX XXXX XXXX ${atob(crdno).substring(16,12)}`
    // this.cardexpiry=expiry
   this.cardno=this.decryptUsingAES256(crdno).substring(16,12),
    this.cvv=this.decryptUsingAES256(cvv)
    let tempexp=this.decryptUsingAES256(expiry)
    this.cardexpiry=`${tempexp.slice(0,2)}${"/"}${tempexp.slice(2)}`;
    this.transaction_ref_no=transno
    this.request_purchase_id=reqid
    console.log(this.cardno+" "+this.cvv+" "+this.cardexpiry );
    

    $("#payreport").modal("show");
 
}

    decryptUsingAES256(value:any) {
      let _key = CryptoJS.enc.Utf8.parse(decryptKey);
      let _iv = CryptoJS.enc.Utf8.parse(decryptIV);
 

      this.decrypted = CryptoJS.AES.decrypt(value, _key, {
        keySize: 32,
        iv: _iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(CryptoJS.enc.Utf8);
      

      return this.decrypted
    }

    cardToggleView(){
      this.cardView=!this.cardView
  
  }


  private loadFileData(){
    this.spinner.show()
    this.vcncorp.getCorpFileData(this.id).then(resp=>{
     console.log(resp)
     this.fileStatus = resp["data"]['status']
      this.filedata=resp['data']['file_data']
      this.filedata.forEach(element=>{
        this.purchaseReqId.push(element.request_purchase_id)
      })
      this.filesummary={
        "corporate_name": resp['data']['corporate_name'],
        "corporate_id": resp['data']['corporate_id'],
        "file_name": resp['data']['file_name'],
        "total_cards": resp['data']['total_cards'],
        "total_amount": resp['data']['total_amount'],
        "utr_no":resp['data']['utr_no'],
        "status":resp['data']['status'],
        "bank_name":resp['data']['bank_name'],
        "transaction_date": resp['data']['transaction_date'],
        "alias_name":resp['data']['alias_name'],
        "alias_id":resp['data']['alias_id'],
        "tempate_name":resp['data']['template_name'],
        "template_id":resp['data']['template_id'],
        "supplier_name":resp['data']['supplier_name'],
        "supplier_id":resp['data']['supplier_id'],
        "sub_corp_name":resp['data']['sub_corp_name']
      }
      var fileList= this.filedata.filter(req=>(req['status']=='Active'))
      // if(fileList.length==parseInt(this.filesummary['total_cards'])){
        this.showDownloadOption= this.isDownloadFile
      // }else{
      //   this.showDownloadOption=false
      // }
      this.spinner.hide()

    },error=>{
      this.spinner.hide()
      console.log(error)
      this.toastr.error("Failed to load data!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
        console.log(error.error.message)
        if(error.error.message=='Token expired!'){
          this.route.navigate(['/']);
          localStorage.removeItem('token')
          localStorage.clear()
        }
    })
  }

  openDownloadFilePopup(){
    let element: HTMLElement = document.getElementById('download-file-button') as HTMLElement
        element.click();
  }

  exportAsXLSX():void {
    this.downloadArray=[]
    if(this.fileStatus == "Approved"){
      this.spinner.show()
      if(this.filedata.length>=0){
        for(let data of this.filedata){
          var obj={
            Name:data['name'],
            Mobile_Number:data['mobile_no'],
            Email:data['email'],
            Amount:data['amount'],
            Token_Number:data['card_no'],
            CVV:data["cvv"],
            Expiry_date_MMYY:data["expiry"],
            Status:data['status'],
            Purchase_Request_id:data['request_purchase_id'],
            Token_id:data['transaction_ref_no'],
            Token_Generation_Date:data['card_generated_date'],
            Token_Generation_Time:data['card_generated_time'],
            No_of_transaction:data["no_of_transaction"],
            Valid_from:data["valid_from"],
            Valid_to:data["valid_to"],
            minimum_transactions:data["min_transaction"],
            maximum_transactions:data["max_transaction"],
            Custom1:data["custom1"],
            Custom2:data["custom2"],
            Custom3:data["custom3"],
            Custom4:data["custom4"],
            Custom5:data["custom5"],
            Custom6:data["custom6"],
            Custom7:data["custom7"],
            Custom8:data["custom8"],
            Custom9:data["custom9"],
            Custom10:data["custom10"],
          }
          this.downloadArray.push(obj)
        }
        this.excel.exportAsExcelFileWithPassword( this.downloadArray, this.filesummary['file_name'].split('.')[0],this.fname.toUpperCase().replace(/\s/g, ""),this.dob)
        .then(res=>{
          this.spinner.hide()
          $("#excelpassword").modal("show");
        },error=>{
          this.spinner.hide()
          this.toastr.error("Something went wrong!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        });
      }else{
        this.spinner.hide()
        this.toastr.warning("No Data Available","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    } else {
      this.toastr.warning("Card generation is in process! Please try again after sometime","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }

    
   // this.excelservice.exportAsExcelFile( this.users, 'User');
  }

  openDeclarationPopup(){
    let element: HTMLElement = document.getElementById('din-match-button') as HTMLElement
        element.click();
  }

  sendEmail():void {
    if(this.fileStatus == "Approved"){
      if(this.toEmail.length > 0 ){
        if(this.toPassword.length > 0){
        this.downloadArray=[]
        this.spinner.show()
        if(this.filedata.length>=0){
          for(let data of this.filedata){
            var obj={
              Name:data['name'],
              Mobile_Number:data['mobile_no'],
              Email:data['email'],
              Amount:data['amount'],
              Token_Number:data['card_no'],
              CVV:data["cvv"],
              Expiry_date_MMYY:data["expiry"],
              Status:data['status'],
              Purchase_Request_id:data['request_purchase_id'],
              Token_id:data['transaction_ref_no'],
              Token_Generation_Date:data['card_generated_date'],
              Token_Generation_Time:data['card_generated_time'],
              No_of_transaction:data["no_of_transaction"],
              Valid_from:data["valid_from"],
              Valid_to:data["valid_to"],
              minimum_transactions:data["min_transaction"],
              maximum_transactions:data["max_transaction"],
              Custom1:data["custom1"],
              Custom2:data["custom2"],
              Custom3:data["custom3"],
              Custom4:data["custom4"],
              Custom5:data["custom5"],
              Custom6:data["custom6"],
              Custom7:data["custom7"],
              Custom8:data["custom8"],
              Custom9:data["custom9"],
              Custom10:data["custom10"],
            }
            this.downloadArray.push(obj)
          }
          this.excel.exportAsExcelFileWithPasswordonEmail( this.downloadArray,this.toPassword)
          .then(async res=>{
            this.spinner.hide()
            console.log("BLOB",res)
            var blob = res
    
            var reader = new FileReader();
            var base64data;
            var that = this;
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                base64data = reader.result;
                that.spinner.show()               
                that.excel.sendEmailwithXLSX(reader.result,that.filesummary['file_name'].split('.')[0],that.toEmail,that.fname,that.userId,that.corp_id,that.toPassword,that.corp_name).then(resp=>{
                  console.log("RESP",resp)
                  that.toEmail = ""
                  that.toPassword = ""
                  that.toastr.success("Email sent successfully!","Alert",{
                    timeOut:3000,
                    positionClass:'toast-top-center'
                    })
                  that.spinner.hide()
                },err=>{
                  that.spinner.hide()
                  that.toastr.warning("Something went wrong!","Alert",{
                    timeOut:3000,
                    positionClass:'toast-top-center'
                    })
                })
                return reader.result;
            }
            // $("#excelpassword").modal("show");
          },error=>{
            this.spinner.hide()
            this.toastr.error("Something went wrong!","Alert",{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          });
        }else{
          this.spinner.hide()
          this.toastr.warning("No Data Available","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
      } else {
        this.toastr.warning("Please enter the password!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
      } else {
        this.toastr.warning("Please enter an Email Id!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
  } else{
    this.toastr.warning("Card generation is in process! Please try again after sometime","Alert",{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
  }
  }

  gotobalanceview(){
    this.route.navigate(['/check-balances', this.id]);
  }

  checkBalPopUp(){
    $("#checkBalRep").modal("show");
  }
  
  addAdditionalAmt(amt){
    this.current_amount=amt
    this.add_curr_amt=parseFloat(amt)+parseFloat(this.additonal_amount)
    $("#AdditionAmtModal").modal("show");
  }

  submitaddbal(){
    this.toastr.success("New Limit has been updated successfully","Alert",{
      timeOut:30000000,
      positionClass:'toast-top-center'
      })
  }
  toggleExpandedView(index){
    this.selectedIndex = index;
   }


   getBalance(id){
    this.spinner.show()
    var params={
      "id":id
    }
    this.vcncorp.getCorpCardBalance(params).then(resp=>{
      if(resp['message']=='Purchase request id not available!'){
        this.spinner.hide()
        this.toastr.error("Card Details are not available","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='The Requested has been Canceled'){
        this.spinner.hide()
        this.toastr.error("This card has been blocked!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='Available Balance!'){
        for(var data of this.filedata){
            if(data['id']==resp['id']){
              this.spinner.hide()
              data['balance']=resp['data']
            }
        }
        console.log(resp)
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }

}
