import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Users } from '../../models/users';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GdsServiceService } from 'src/app/_api/gds-service.service';
import { VcncorpService } from 'src/app/_api/vcncorp.service';
import { Config } from 'src/app/config';
const path = new Config().getVcnUrl();

@Component({
  selector: 'app-pending-pcc',
  templateUrl: './pending-pcc.component.html',
  styleUrls: ['./pending-pcc.component.css']
})
export class PendingPccComponent implements OnInit {
  filtersupplier:boolean=false;
  corporateId:any;
  isData:any = false;
  corp_dropdown_data :any;
  pendingPCC :any;
  alias_dropdown_data :any;
  checkerAdded= false;
  asAdded= false;
  corpId:any;
  authMatrix:any;
  tmcname: any;
  params={}
  currentUser: Users;
  aliasdata:any=[]
  alaisNames:any;
 
constructor(private spinner: NgxSpinnerService,private router: Router,public datepipe: DatePipe,
  private gdsservice: GdsServiceService,private http: HttpClient,private toastr: ToastrService,private vcncorp:VcncorpService) {

    // this.getTMCData()
   }


   ngOnInit(){
    console.log("Inside Ng on init");
    
    // this.checkerAdded= false;
    // this.asAdded= false;
    // this.spinner.show()
    // this.getTMCData()
    // this.loadAlias()
    this.getTMCTOBANK()
   
  }

  loadAlias(){
    this.spinner.show()
    this.vcncorp.getAliasdata().then(resp=>{
     
      if(resp['message']=='Alias data' && resp['error']==null){
        this.spinner.hide()
        this.aliasdata=resp['data']
      }
      
     
    },error=>{
      this.spinner.hide()
      console.log(error)
      console.log('Failed to load Alias')
    })
  }

  getTMCData(){
    this.gdsservice.getTMCData().then(data=>{
      this.spinner.hide()
      console.log();
      console.log("Inside tmc on init");
      this.corpId = data["data"]["id"].toString();
      this.tmcname = data["data"]["name"]
      this.authMatrix = data["data"]["auth_matrix"]
      this.getTMCTOBANK()
      // this.loadPCC()
    },err=>{
      this.spinner.hide()
    })
  }

  gotoOTP(){}

  getTMCTOBANK(){
    this.corp_dropdown_data=[]
    this.spinner.show()
    this.gdsservice.getMappedTmcToBankCorporates("14").then(resp=>{
      this.spinner.hide()
      this.corp_dropdown_data=resp['data']
      console.log(this.corp_dropdown_data);    
    },error=>{
      this.spinner.hide()
      if(error['error']['error']=='No Mapping Found!'){
        this.toastr.error("No Mapping Found!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else{
        this.toastr.error("Something went wrong! Please try again!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    })
  }

  setCorp(){
    for(var i=0;i<this.corp_dropdown_data.length;i++){
      if(this.corporateId==this.corp_dropdown_data[i]['corporate_id']){
       this.params['corporateId']=this.corp_dropdown_data[i]['corporate_id']
       this.params['corporateName']=this.corp_dropdown_data[i]['corporate_name']
       this.params['tmcname']=this.tmcname
       this.params['tmcid']=this.corpId
      }
     }
     console.log(this.params);
     
     this.pendingPCC=[]
     this.isData = false
   this.spinner.show()
     console.log(this.corporateId);
       this.gdsservice.getMappedPccIdToBankCorp(this.params).then(resp=>{
         this.spinner.hide()
         this.pendingPCC=resp['data']
         console.log(this.pendingPCC);    
         this.isData = true
       },error=>{
         this.spinner.hide()
         if(error['error']['error']=='No Mapping Found!'){
           this.toastr.error("No Mapping Found!","Alert",{
             timeOut:3000,
             positionClass:'toast-top-center'
             })
         }else{
           this.toastr.error("Something went wrong! Please try again!","Alert",{
             timeOut:3000,
             positionClass:'toast-top-center'
             })
         }
       })
  }

  submitAliastoPCC(){
    this.submitAliastoPCCBack(this.pendingPCC).subscribe(data => {
      this.spinner.hide()
      // this.openDeclarationPopup()
      if(data['status'] == 'Success'){
        this.toastr.success("Alias to PCC Mapped Successfully ","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
        })
        this.router.navigate(['/successmsg'],{queryParams:{msg:'pccregsuccess'}})
      } else {
        console.log(data['message']);
        this.toastr.error("Alias to PCC Mapped Failed","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
        })
      }
      
   },error => {
     console.log(error)
     this.toastr.error("Something went wrong! Please try again!","Alert",{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
  });
  }

  submitAliastoPCCBack(users:any){
    console.log(users)
   
    return this.http.post(path+`Aquapay/mapAliasToPcc`, users);
}
}
