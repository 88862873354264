import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
// import { Observable, of, throwError } from 'rxjs';
// import { catchError, tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {Config} from '../config'
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
const path = new Config().getVcnUrl();
@Injectable({
  providedIn: 'root'
})
export class VcncorpService {

  constructor(private http: HttpClient) { }

  uploadCardReqFile(file:File){
    let data ={};
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    //console.log(file);
    return this.http.post(`${path}/user/file_upload`, formdata);
}

uploadCancelCardReqFile(file:File){
    let data ={};
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    //console.log(file);
    return this.http.post(`${path}/cancelCard/file_upload`, formdata);
}

submitCardRequestFile(redata:any){
  //console.log(users)
  return this.http.post(`${path}/user/card_request`, redata);
}

submitCancelCardRequestFile(redata:any){
    //console.log(users)
    return this.http.post(`${path}/cancelCard/card_request`, redata);
  }

getAliasdata(): Promise<any>{
  let promise = new Promise((resolve, reject) => {
      this.http.get(`${path}/user/alias_by_corp`)
          .subscribe(
              res => {
                
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );

  });

  return promise;
}

getTemplatedata(data:any): Promise<any>{
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/user/getTemplateForCorp`,data)
            .subscribe(
                res => {
                  
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

  getsupplierdata(data:any): Promise<any>{
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/user/getSuppliersForCorp`,data)
            .subscribe(
                res => {
                  
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

  refreshsupplierdata(data:any): Promise<any>{
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/user/addSuppliers`,data)
            .subscribe(
                res => {
                  
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

getpendingfilescount(id:any): Promise<any>{
  var params={
    "corporate_id":id
  }
  let promise = new Promise((resolve, reject) => {
      this.http.post(`${path}/user/pending_file_count`,params)
          .subscribe(
              res => {
                
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );

  });

  return promise;
}

getapprovedcardcount(id:any): Promise<any>{
  var params={
    "corporate_id":id
  }
  let promise = new Promise((resolve, reject) => {
      this.http.post(`${path}/user/approved_cards_count`,params)
          .subscribe(
              res => {
                
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );

  });

  return promise;
}

getCorpFileList(): Promise<any> {
  // let token = this.storage.getData("chlogin_data").token;
  // let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //     .set('authorization', 'Bearer ' + token);
  // let options = { headers: headers };      
  let promise = new Promise((resolve, reject) => {
      this.http.get(`${path}/user/request_list`)
          .subscribe(
              res => {
                  
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );

  });

  return promise;
}

getCancelCardFileList(): Promise<any> {
    // let token = this.storage.getData("chlogin_data").token;
    // let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //     .set('authorization', 'Bearer ' + token);
    // let options = { headers: headers };      
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/cancelCard/request_list`)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }


getCorpFileData(id:any): Promise<any> {
    
  let promise = new Promise((resolve, reject) => {
      this.http.get(`${path}/user/${id}/review_card_request`)
          .subscribe(
              res => {
                 
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );

  });

  return promise;
}

getCVVandExpiry(data:any): Promise<any> {
    
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/card/get_card_details`,data)
            .subscribe(
                res => {
                   
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

getCancelCardFileData(id:any): Promise<any> {
    
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/cancelCard/${id}/review_card_request`,"")
            .subscribe(
                res => {
                   
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

apprPendingFile(id:any): Promise<any> {
    var params={

    }
  let promise = new Promise((resolve, reject) => {
      this.http.post(`${path}/card/${id}/approve_request_checker`,params)
          .subscribe(
              res => {
                 
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );

  });

  return promise;
}

apprPendingCancelCardFileOther(id:any): Promise<any> {
    var params={

    }
  let promise = new Promise((resolve, reject) => {
      this.http.post(`${path}/cancelCard/${id}/approve_request`,params)
          .subscribe(
              res => {
                 
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );

  });

  return promise;
}

apprPendingFileOther(id:any): Promise<any> {
    var params={

    }
  let promise = new Promise((resolve, reject) => {
      this.http.post(`${path}/user/${id}/approve_request`,params)
          .subscribe(
              res => {
                 
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );

  });

  return promise;
}



rejPendingFile(id:any,cmt:any): Promise<any> {
  var params={
    "reject_comment":cmt
  }
let promise = new Promise((resolve, reject) => {
    this.http.post(`${path}/user/${id}/reject_request`,params)
        .subscribe(
            res => {
               
                resolve(res);
            },
            err => {
                console.log("Error occured : " + err);
                reject(err);
            }
        );

});

return promise;
}

rejCancelCardPendingFile(id:any,cmt:any): Promise<any> {
    var params={
      "reject_comment":cmt
    }
  let promise = new Promise((resolve, reject) => {
      this.http.post(`${path}/cancelCard/${id}/reject_request`,params)
          .subscribe(
              res => {
                 
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );
  
  });
  
  return promise;
  }

  getCardDetails(params): Promise<any>{
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/user/get_filedata_detail_corp`,params)
            .subscribe(
                res => {
                  
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
}

getCorpCardBalance(params): Promise<any> {
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/card/get_user_balance_check`,params)
            .subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    });
    return promise;
  }

  getCorpVcnAuthReport(params): Promise<any> {
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/card/get_user_vcn_auth_report`,params)
            .subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    });
    return promise;
  }

  updateContactBulkFile(file:File){
    let data ={};
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post(`${path}/user/initiate_corporate_mobile_updation`, formdata);
}

updateSingleContact(params:any): Promise<any>{
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/user/initiate_corporate_unitory_mobile_updation`,params)
            .subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    });
    return promise;
}

getUpdateContactList(): Promise<any> {
    
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/user/mobile_updation_list`)
            .subscribe(
                res => {
                   
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

  approveForContacts(params:any): Promise<any> {
    
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/user/approve_corporate_mobile_updation`,params)
            .subscribe(
                res => {
                   
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }



}





