import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { ExcelService } from '../../excelservice/excel.service'
import {ExcelGeneratorService} from  '../../_api/excel-generator.service'
@Component({
  selector: 'app-date-range-report',
  templateUrl: './date-range-report.component.html',
  styleUrls: ['./date-range-report.component.css']
})
export class DateRangeReportComponent implements OnInit {
  transactiondata:any=[{
    "TokenNumber":"7777 5555 8888 9999",
    "MerchantName":"To Check The Mechant Name Ellipssis work or Not",
    "TransactionDateTime":"22/01/2023",
    "Amount":"112200",
    "IssuerResponse":"To Check the response is fitting or not",
    "Settled":"Settled",
    "InControlResponse":"This is the main description in Dropdown"
    }]
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  selectedIndex = -1;
  constructor(private _location: Location,private spinner: NgxSpinnerService,private excelservice : ExcelService,private excel:ExcelGeneratorService) { }

  ngOnInit() {
    this.loadTrxData()
  }
  backClicked() {
    localStorage.removeItem('authreportdr')
    this._location.back();
  }

  toggleExpandedView(index){
    this.selectedIndex = index;
   }

  private loadTrxData(){
    var d1 = new Date (),
    d2 = new Date ( d1 );
d2.setMinutes ( d1.getMinutes() + 330 );
console.log(d2)
    this.spinner.show()
    this.transactiondata=[]
    var transaData=JSON.parse(localStorage.getItem("authreportdr") || "[]");
  
    if(Array.isArray(transaData)){
      for(var data of transaData){
        var obj={
          Amount:data['BillingAmount'],
          TokenNumber:data['VirtualCardNumber'],
          MerchantName:data['MerchantName'],
          TransactionDateTime:"2020-" +data['TxnDateTimeWithTime'],
          IssuerResponse:data['IssuerResponse'],
          InControlResponse:data['InControlResponse'],
          Settled:data['Settled'],
          TransactionDate:data['TxnDateTime']
        }
        this.transactiondata.push(obj)
  
      }
      if(this.transactiondata.length>0){
        console.log(this.transactiondata)
        this.spinner.hide()
      }else{
        this.spinner.hide()
      }
    }else{
      this.spinner.hide()
      this.transactiondata=[{
        'Amount':transaData['BillingAmount'],
        'TokenNumber':transaData['VirtualCardNumber'],
        'MerchantName':transaData['MerchantName'],
        'TransactionDateTime':"2020-" +data['TxnDateTimeWithTime'],
        'IssuerResponse':transaData['IssuerResponse'],
        'InControlResponse':transaData['InControlResponse'],
        'Settled':transaData['Settled'],
        'TransactionDate':transaData['TxnDateTime']
      }]

     
    }
    

    console.log(this.transactiondata)
    
    // this.transactiondata=[{
    // "TokenNumber":"7777 5555 8888 9999",
    // "MerchantName":"To Check The Mechant Name Ellipssis work or Not",
    // "TxnDateTimeWithTime":"2020-08-31T14:43:31.000Z",
    // "Amount":112200,
    // "IssuerResponse":"To Check the response is fitting or not",
    // "Settled":"Settled",
    // "InControlResponse":"This is the main description in Dropdown",
    // "PurchaseRequestStatus":"Approved"
    // }]
  }


  exportAsXLSX(){
    this.excel.exportAsExcelFile( this.transactiondata, 'Transaction_Report');
  }

}
