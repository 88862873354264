import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {FormControl, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr'
import { ResetPasswordService } from '../../_api/reset-password.service';
import {VcnuserService} from '../../_api/vcnuser.service'
import { NgxSpinnerService } from "ngx-spinner";
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  styleUrls: ['./mobile-login.component.css']
})
export class MobileLoginComponent implements OnInit {
  returnUrl: string;
  submitted = false;
  loginData:any = {};
  
  display='none';
  orgnames:any=[];
  forgotdetail : any ={};
id:number=0;
orgid:any;
email:any;
orglist:any=[];
showorg:boolean=false;
mobile_number:string;
  constructor(private route: ActivatedRoute,private router: Router,private toastr: ToastrService,private resetService : ResetPasswordService, private vcnuser:VcnuserService,private spinner: NgxSpinnerService,private titleService:Title) { 
    this.titleService.setTitle("Virtual Card Login");
  }

  ngOnInit() {
  }
  validateMobileNo(value){    
    var k = value.keyCode;
          return ((k >= 48 && k <= 57) || k == 8);
  }




  loginPost(){
    this.spinner.show()
    if(this.mobile_number!=undefined || this.mobile_number!=null){
      if(this.mobile_number.length==10){
        var params={
          "mobile_no":this.mobile_number
        }
        this.vcnuser.loginWithMobile(params).then(resp=>{
          console.log(resp)
          if(resp['message']=='OTP sent successfully!'){
            this.spinner.hide()
            this.vcnuser.saveToken(resp['data']['secure_set'])
            localStorage.setItem('usermobile',this.mobile_number)
            this.router.navigate(['/mobile-otp-open']);
          }else if (resp['message']=='No user exist!'){
            this.spinner.hide()
            this.toastr.error("Card Details don't exist for given mobile number",'Error',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else{
            this.spinner.hide()
            this.toastr.error("Something Went Wrong",'Error',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
        },error=>{
          this.spinner.hide()
          this.toastr.error("Failed to Login",'Error',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
          console.log(error)
        })
      }else{
        this.spinner.hide()
        this.toastr.error("Please Enter 10-digit Mobile Number",'Error',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
     
    }else{
      this.spinner.hide()
      this.toastr.error("Please Enter Mobile Number",'Error',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
    
  }

}
