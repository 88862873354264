import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute,NavigationEnd  } from '@angular/router';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
import { ToastrService } from 'ngx-toastr'
import {VcnuserService} from '../../_api/vcnuser.service'
import {VcncorpService} from '../../_api/vcncorp.service'
import {VcnService} from '../../_api/vcn.service'
import { NgxSpinnerService } from "ngx-spinner";
import {ExcelGeneratorService} from  '../../_api/excel-generator.service'
import { ExcelService } from '../../excelservice/excel.service'
import { DatePipe } from '@angular/common'
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-yyyy',
  },
  display: {
    dateInput: 'DD-MM-yyyy',
    monthYearLabel: 'DD-MM-yyyy',
    dateA11yLabel: 'DD-MM-yyyy',
    monthYearA11yLabel: 'DD-MM-yyyy',
  },
};

/** @title Datepicker with custom formats */

@Component({
  selector: 'app-unitary-search',
  templateUrl: './unitary-search.component.html',
  styleUrls: ['./unitary-search.component.css'] ,
  encapsulation: ViewEncapsulation.None,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class UnitarySearchComponent implements OnInit {
  fromdate:any;
  todate:any;
  step2:boolean=false;
  card_no:string=null
  pur_req_id:string=null
  cardData:any={

  }
  currentFDate:Date=new Date();
  currentTDate:Date=new Date();
  currFdate:string;
  currTdate:string;
  cardBalance:any=null;
  showBalanace:boolean=false;
  cardId:any;
  selectedOptp:any;
  showCardNo:boolean=false;
  showPurChasedReqId:boolean=false;
  constructor(private route:Router,private toastr: ToastrService, private vcnuser:VcnuserService,private spinner: NgxSpinnerService,private excelservice : ExcelService, private excel:ExcelGeneratorService,public datepipe: DatePipe,private vcn: VcnService,private vcncorp:VcncorpService) { }

  ngOnInit() {
    this.currentFDate.setDate(this.currentFDate.getDate() - 1);
    this.currFdate=this.datepipe.transform(this.currentFDate, 'yyyy-MM-dd');
    this.currTdate=this.datepipe.transform(this.currentTDate, 'yyyy-MM-dd');
  }

  viewcardDetails(id){
    this.cardId=id
    $("#payreport").modal("show");
  }

  getsearchparameter(param){
    if(param=='crd'){
      this.showCardNo=true;
      this.showPurChasedReqId=false;
      this.card_no = null;
      this.pur_req_id = null;
    }else if(param=='prid'){
      this.showCardNo=false;
      this.showPurChasedReqId=true;
      this.card_no = null;
      this.pur_req_id = null;
    }
  }

  finddetails(){
   this.spinner.show()
    if(this.card_no!=null && this.pur_req_id==null){
      var params={
        "card_no":this.card_no
      }
      this.vcn.getCardDetails(params).then(resp=>{
        
        if(resp['message']=='File Data!'){
          this.cardData=resp['data']
          this.step2=true;
          this.spinner.hide()
          console.log(this.cardData)
        }else{
          this.spinner.hide()
          this.toastr.warning("Something Went Wrong!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
      },error=>{
        this.spinner.hide()
        console.log(error)
        this.toastr.warning("Failed to get details!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      })
    }else if(this.card_no==null && this.pur_req_id!=null){
      var vparams={
        "request_purchase_id":this.pur_req_id
      }
      this.vcn.getCardDetails(vparams).then(resp=>{
        if(resp['message']=='File Data!'){
          this.cardData=resp['data']
          this.step2=true;
          this.spinner.hide()
          console.log(this.cardData)
        }else{
          this.spinner.hide()
          this.toastr.warning("Something Went Wrong!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
      },error=>{
        this.spinner.hide()
        console.log(error)
        this.toastr.warning("Failed to get details!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      })
    }else{
      this.spinner.hide()
      this.toastr.warning("Please enter token no or purchased requestid","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
   
  }
  getBalance(id){
    this.spinner.show()
    var params={
      "id":id
    }
    this.vcncorp.getCorpCardBalance(params).then(resp=>{
      if(resp['message']=='Purchase request id not available!'){
        this.spinner.hide()
        this.toastr.error("Token Details are not available","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='The Requested has been Canceled'){
        this.spinner.hide()
        this.toastr.error("This token has been blocked!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='Available Balance!'){
        this.spinner.hide()
        this.cardBalance=resp['data']
        this.showBalanace=true
        console.log(resp)
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }

  gotoTransactions(id:any){
    this.spinner.show()
    var param={
      "id":id,
      "from_date":this.currFdate,
      "to_date":this.currTdate
    }
    this.vcncorp.getCorpVcnAuthReport(param).then(resp=>{
      console.log(resp)
      if(resp['message']=='There is no transaction between the given dates'){
        this.spinner.hide()
        this.toastr.error("No Transactions found!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='VCN authorization report!'){
        this.spinner.hide()
        localStorage.setItem('authreport',JSON.stringify(resp['data']))
        this.route.navigate(['/admin-current-report']);
      }else if(resp['message']=='Code: ERROR_CREATE_DELETED_VCN_AUTHS_REPORT Description: We are unable to create an authorization report for a cancelled VCN. To view these transactions please run the authorization report for the RCN.'){
        this.spinner.hide()
        this.toastr.error("Token has been cancelled!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else{
        this.spinner.hide()
        this.toastr.error("Semething Went Wrong!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
      this.toastr.error("Failed to Get Auth Report!!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
     
    })
  }

  gotodaterangereport(){
    // console.log(this.cardId)
    // console.log(this.fromdate)
    // console.log(this.todate)
    this.spinner.show()
    if(this.fromdate!=undefined || this.fromdate!=null){
      this.fromdate=this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    }
    else{
      this.fromdate=this.currFdate
    }
    
    if(this.todate!=undefined || this.todate!=null){
      this.todate=this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    }
   else{
    this.todate=this.currTdate
    }


    var param={
      "id":this.cardId,
      "from_date":this.fromdate,
      "to_date":this.todate
    }
    this.vcncorp.getCorpVcnAuthReport(param).then(resp=>{
      console.log(resp)
      if(resp['message']=='There is no transaction between the given dates'){
        this.spinner.hide()
        this.toastr.error("No Transactions found!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='VCN authorization report!'){
        this.spinner.hide()
        localStorage.setItem('authreportdr',JSON.stringify(resp['data']))
       this.route.navigate(['/admin-range-report']);
       
      }else if(resp['message']=='Code: ERROR_CREATE_DELETED_VCN_AUTHS_REPORT Description: We are unable to create an authorization report for a cancelled VCN. To view these transactions please run the authorization report for the RCN.'){
        this.spinner.hide()
        this.toastr.error("Token has been cancelled!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='The report date range cannot exceed 31 day(s).'){
        this.spinner.hide()
        this.toastr.error("The report date range cannot exceed 31 day(s).","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='From date should be before to date.'){
        this.spinner.hide()
        this.toastr.error("From date should be before to date.","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else{
        this.spinner.hide()
        this.toastr.error("Semething Went Wrong!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
      this.toastr.error("Failed to Get Auth Report!!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
     
    })
   // this.route.navigate(['/date-range-report']);

  }

  backClicked(){
    this.step2=false
  }


}
