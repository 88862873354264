import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
import { Groups } from '../../models/groups';
import{LoaderService} from '../../_api/loader.service';
import { ExcelService } from '../../excelservice/excel.service'
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import { DatePipe } from '@angular/common'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-checkercard',
  templateUrl: './checkercard.component.html',
  styleUrls: ['./checkercard.component.css']
})
export class CheckercardComponent implements OnInit {
  filterQuery = "";
  sortBy = "amount";
  sortOrder = "asc";
  public asGroupsPendingDetails: any=[];
  public temp: any;
  public checkedValueArray: any = [];
  public showHide: any;
  public test: any;
  public flag: any;
  public comment: any;
  public rejectedgroupdata: any;
  public rejectedgroupmsg: any;
  public cntChk:any;
  public pendingdetails:any=[];
  selectall:boolean=false;
  isSelected:boolean=false
  requestSelected:number=0;
  filedata:any=[]
  filesummary:any={}
  rowsOnPage = 1000;
  // sortBy = "amount";
  // sortOrder = "asc";
  public id: string;
  constructor(private loader:LoaderService,private route: Router, private excel:ExcelService,private activeroute: ActivatedRoute,private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService) { }

  ngOnInit() {
    this.id = this.activeroute.snapshot.paramMap.get('id');
    if(this.id!=undefined || this.id!=null){
      this.loadFileData()
    }
    else{
      this.toastr.error("Something Went wrong!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
  }
  checkAll(){
    this.isSelected=!this.isSelected
    if(this.isSelected==true){
      this.requestSelected=3;
    }else{
      this.requestSelected=0
    }

  }

  gotoOTP(){
    this.spinner.show()
    this.vcn.approveFiletest(this.id).then(resp=>{
 
      if(resp['message']=='Request approved successfully!'){
        this.spinner.hide()
        this.route.navigate(['/card-req-approve-success']);
      }else{
        this.spinner.hide()
        this.toastr.error("Something went wrong!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
      
    },(error: HttpErrorResponse)=>{
     
      console.log(error.status)
      console.log(error.statusText)
      if(error.statusText=='Unknown Error'){
        this.spinner.hide()
        this.route.navigate(['/card-req-approve-success']);
      }else{
        this.spinner.hide()
        this.toastr.error("Failed to Approve file!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
   
    })
    
  }

  checkClick(): void {
   
      $("#payreport").modal("show");
   
  }

  private loadFileData(){
    this.spinner.show()
    this.vcn.getFiledata(this.id).then(resp=>{
    
      var filedatalist=resp['data']['file_data']
      this.filedata=filedatalist.filter(file=>(file['status']=='Inactive'))

      this.filesummary={
        "corporate_name": resp['data']['corporate_name'],
        "corporate_nick_name" :resp['data']['corporate_nick_name'],
        "corporate_id": resp['data']['corporate_id'],
        "file_name": resp['data']['file_name'],
        "total_cards": resp['data']['total_cards'],
        "total_amount": resp['data']['total_amount'],
        "utr_no":resp['data']['utr_no'],
        "status":resp['data']['status'],
        "bank_name":resp['data']['bank_name'],
        "transaction_date": resp['data']['transaction_date'],
        "alias_name":resp['data']['alias_name'],
        "alias_id":resp['data']['alias_id'],
        "tempate_name":resp['data']['template_name'],
        "template_id":resp['data']['template_id'],
        "supplier_name":resp['data']['supplier_name'],
        "supplier_id":resp['data']['supplier_id'],
        "rcn_id":resp['data']['rcn_id'],
        "sub_corp_name":resp['data']['sub_corp_name']
        
      }
      this.spinner.hide()

    },error=>{
      this.spinner.hide()
      console.log(error)
      this.toastr.error("Failed to load data!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    })
  }


  approvesingle(){
    for(var data of this.filedata){
      var params={
        
          "amount":data['amount'],
          "mobile":data['mobile_no'],
          "trnrefno":data[''],
          "id":data['id']
        
          }
        this.vcn.approveSingleCard(params,this.filesummary['rcn_id'],this.filesummary['alias_name'],this.filesummary['file_name'],this.filesummary['total_cards'],this.id)

    }
  }

  sendBack(){
    $("#payreport").modal("show");
  }
  rejectFile(){
    this.spinner.show()
    this.vcn.sendFileBack(this.id,this.comment).then(resp=>{
      if(resp['message']=='Request send back successfully!'){
        this.route.navigate(['/send-back-success']);
      }else{
        this.toastr.error("Something went wrong!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
      this.spinner.hide()
    },error=>{
      this.spinner.hide()
      this.toastr.error("Failed to sending back the file!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
      console.log(error)
    })
  }
}
