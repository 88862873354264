import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_api/auth.service';
import { ResetPasswordService } from '../../_api/reset-password.service';
import { ToastrService } from 'ngx-toastr'
import { Router, ActivatedRoute,NavigationEnd,RoutesRecognized   } from '@angular/router';
@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
orgnames:any=[];
  forgotdetail : any ={};
id:number=0;
orgid:any;
email:any;
orglist:any=[];
  constructor(private resetService : ResetPasswordService,private auth:AuthService,private toastr: ToastrService,private router: Router) { }

  ngOnInit() {
    // this.getAllOrganisations();
  }
  
//   forgot(){
//     this.forgotdetail={
//       "email":this.email,
//       "orgid":this.orgid
//     }

//     console.log(this.forgotdetail)
//    this.auth.getrestpasswordlink(this.forgotdetail).subscribe(res=>{
//      console.log(res)
//      this.router.navigate(['/']);
//      this.toastr.success("Password reset link has been sent on your mail!",'Alert',{
//       timeOut:3000,
//       positionClass:'toast-top-center'
//       })
//    },error=>{
//      console.log(error)
//      this.router.navigate(['/']);
//      this.toastr.error("Failed to send password reset link!",'Alert',{
//       timeOut:3000,
//       positionClass:'toast-top-center'
//       })
//    })
//    // console.log(this.forgotdetail)
//    // console.log("Dataa")
//   }

// private getAllOrganisations(){
//   this.auth.getAllOrgNames().then(res=>{
//     this.orgnames=res['data']  
//     console.log(this.orgnames)  
//     const sorted = this.orgnames.sort((a, b) => a.orgname > b.orgname ? 1 : -1);  
 
//  // console.log(this.orglist)
//   },error=>{
//     console.log(error)
//   })
// }

selectOption(id: number){
  this.orgid=id;
  console.log(this.orgid)
    }
}
