import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse,HttpHeaders} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError} from 'rxjs';
import {Config} from '../config'
import { ToastrService } from 'ngx-toastr'
import { Routes, RouterModule,Router } from '@angular/router';
const vcnurl = new Config().getVcnUrl();

@Injectable()
export class ResetPasswordService {
    loginpath = new Config().getVcnUrl();
    constructor(private http : HttpClient,private toastr: ToastrService,private router: Router){}

    // resetPassword(passwordData:any,id:number){
    //   return this.http.post(path+'/backend/resetpwd',passwordData)
    //   .pipe(catchError(this.errorHandler));  
    // }

    errorHandler(error : HttpErrorResponse){
        return throwError(error);
    }

    // getAllOrgNames(): Promise<any> {
        
    //     // let token = this.storage.getData("rmlogin_data").token;
    //     // let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //     //     .set('authorization', 'Bearer ' + token);
    //     // let options = { headers: headers };
        
    //    // this.cardsUrl = path+"/rm/rmcard?id="+id;
    //     let promise = new Promise((resolve, reject) => {
    
    //         this.http.get(path+"/ccadmin/organizations-all")
    //             .subscribe(
    //                 res => {
                        
    //                     resolve(res);
    //                 },
    //                 err => {
    //                     console.log("Error occured : " + err);
    //                     reject(err);
    //                 }
    //             );
    
    //     });
    
    //     return promise;
    // }

    // resetPass(forgotDetails : any,id:number): Promise<any>{
    //     let headers = new HttpHeaders().set('Content-Type', 'application/json');
    //     let options = { headers: headers };
    //     let paramsValue = {
    //         "forgotDetails": forgotDetails
    //     }
    //     let promise = new Promise((resolve, reject) => {
    //         this.http.post(path+`/backend/resetnewpwd?uid=${id}`, forgotDetails, options)
    //             .subscribe(
    //                 res => {
                        
    //                     resolve(res);
    //                 },
    //                 err => {
    //                     console.log("Error occured : " + err);
    //                     reject(err);
    //                 }
    //             );
    
    //     });
    
    //     return promise;
    // }

    setnewpassword(email:any,password:any,cnf_pwd:any,old_pwd:any){
       
        let body = new URLSearchParams();
        body.set('email', email);
        body.set('password', btoa(password));
        body.set('confirm_password', btoa(cnf_pwd));
        body.set('oldpassword',btoa(old_pwd))
        let options = {
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
      
        this.http.post(`${vcnurl}/auth/resetPassword`,body.toString(), options).subscribe(data=>{
          if(data['message']=="Invalid Old password!"){
            this.toastr.error("Invalid Old password!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else if(data['message']=="Old password matches new one!"){
            this.toastr.error("Old password matches new one!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else{
          this.toastr.success("Your password has been reset successfully, Please login to continue!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
           this.router.navigate(['/']);
        }
         // this.saveToken(data['data']['secure_set'])
         
        },error=>{
          console.log(error)
          if(error['message']=='No user exist!'){
            this.toastr.error("User not available!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else if(error['message']=='token expired'){
            this.toastr.error("Password reset link has been expired!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else if(error['message']=='malformed token'){
            this.toastr.error("Something went wrong!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else if(error['message']=='Something went wrong!'){
            this.toastr.error("Something went wrong!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
        });
        localStorage.removeItem('token')
        localStorage.removeItem('modules')
      }

      setnewpasswordold(password:any,cnf_pwd:any){
       
        let body = new URLSearchParams();
        body.set('password', password);
        body.set('confirm_password', cnf_pwd);
        let options = {
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
      
        this.http.post(`${this.loginpath}api/auth/reset_password_allDB`,body.toString(), options).subscribe(data=>{
        
         // this.saveToken(data['data']['secure_set'])
         this.toastr.success("Your password has been reset successfully, Please login to continue!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
          this.router.navigate(['/']);
        },error=>{
          console.log(error)
          if(error['msg']=='User not found'){
            this.toastr.error("User not available!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else if(error['msg']=='token expired'){
            this.toastr.error("Password reset link has been expired!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else if(error['msg']=='malformed token'){
            this.toastr.error("Something went wrong!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
        });
        localStorage.removeItem('token')
        localStorage.removeItem('modules')
      }
        
   

//    validateauthcode(authcode: any): Promise<any> {
//     let headers = new HttpHeaders().set('Content-Type', 'application/json');
//     let options = { headers: headers };
//     let paramsValue = {
//         "authcode": authcode
//     }
//     console.log("param id" + authcode);
//     let promise = new Promise((resolve, reject) => {
//         this.http.post(this.validateauthcodeUrl, paramsValue, options)
//             .subscribe(
//                 res => {
//                     
//                     resolve(res);
//                 },
//                 err => {
//                     console.log("Error occured : " + err);
//                     reject(err);
//                 }
//             );

//     });

//     return promise;
// }
}