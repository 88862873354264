import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
declare var require:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [UserService]
})
export class HomeComponent implements OnInit {

  constructor(private userservice: UserService) { }
  currentUser: Users;
  fname: string;
  lname:string;
  cmpyname:string;
  dsgtn: string;
  lastsession:string;
  userrole:string;
  isUniuser:boolean;
  isBulkuser:boolean;
  isUnicard:boolean;
  isUnigroup:boolean;
  isUnirule:boolean;
  isAuthmatrix:boolean;
  isUnisup:boolean;
  isBulksup:boolean;
  ngOnInit() {

    
    $(".addgps").on("click", function () {
      $(".paylink").addClass("active");
      $(".paylink").siblings(".active").removeClass("active");
    });


    $(".addruls").on("click", function () {
      $(".rulelink").addClass("active");
      $(".rulelink").siblings(".active").removeClass("active");
  });

  $(".addcrd").on("click", function () {
    $(".supplink").addClass("active");
    $(".supplink").siblings(".active").removeClass("active");
  });

  $(".adduusr").on("click", function () {
    $(".paylink2").addClass("active");
    $(".paylink2").siblings(".active").removeClass("active");
  });

  $(".addbusr").on("click", function () {
    $(".paylink2").addClass("active");
    $(".paylink2").siblings(".active").removeClass("active");
  });

  this.userservice.getUserDetails().subscribe(users => {
    //console.log(users);
    
     this.currentUser = users['Data']; 
    
     this.fname=users['Data']['firstname'];
     this.lname=users['Data']['lastname'];
     if(users['Data']['lastsession']!="NULL" || users['Data']['lastsession']!=null){
     this.lastsession=users['Data']['lastsession'];
     let dateFormat = require('dateformat');
     let now = this.lastsession
     this.lastsession=dateFormat(now, "dddd, mmmm dS, yyyy, hh:MM TT");
     }else{
     this.lastsession="mmm dd yyyy  h:mm"
     }
     if(users['Data']['orgid']!=null){
     this.cmpyname=this.currentUser['companyname'];
     }else{
      this.cmpyname="Axis Bank";
     }
     this.dsgtn=users['Data']['designation'];
     this.userrole=users['Data']['rolename'];
     if(this.currentUser.tabpermissionids !=null){
     var str = this.currentUser.tabpermissionids;
     str = str.replace("pid=" ,"");
     str = str.replace(";cid=",",");
     str = str.replace(";","");
     str = str.replace(" ","");
     var tab_arr = str.split(",");

      this.isUniuser = this.checkValInArray("6",tab_arr)
      this.isBulkuser = this.checkValInArray("7",tab_arr)
      this.isUnicard = this.checkValInArray("24",tab_arr)
      this.isUnigroup = this.checkValInArray("53",tab_arr)
      this.isUnirule = this.checkValInArray("40",tab_arr)
      this.isUnisup = this.checkValInArray("27",tab_arr)
      this.isBulksup = this.checkValInArray("51",tab_arr)
    }
      this.isAuthmatrix=false;
      if(users['Data']['authmtrix']=="Complex"){
        this.isAuthmatrix=true;
      }else{
        this.isAuthmatrix=false;
      }
     //console.log(this.isPermission);
    });
  }

  checkValInArray(tabid:string , tab_array:string[]){
    return (tab_array.indexOf(tabid)==-1)?false:true;
}

}
