import {HttpClient,HttpHeaders  } from '@angular/common/http'
import { Injectable,EventEmitter,Output } from '@angular/core';
import { Routes, RouterModule,Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { ToastrService } from 'ngx-toastr'
import {Config} from '../config'
import { NgxSpinnerService } from 'ngx-spinner';
import * as CryptoJS from 'crypto-js';


const vcnpath = new Config().getVcnUrl();
const vgcpath = new Config().getVgcUrl();

const vcnCipher = new Config().getVCNCipher();
const vcnText = new Config().getVCNText();

@Injectable()
export class AuthService{
  
    @Output() isLoggedIn: EventEmitter<string> = new EventEmitter();
    TOKEN_KEY = 'token'
    loginpath = new Config().getVcnUrl();
    id:any;
    count:any =0
    constructor(private spinner: NgxSpinnerService,private userIdle: UserIdleService,private http: HttpClient,private router: Router,private toastr: ToastrService){}

    get token(){
        return localStorage.getItem(this.TOKEN_KEY)
    }

    get isAuthenticated(){
        return !!localStorage.getItem(this.TOKEN_KEY)
    }

    getIsLoggedIn(){
        if(localStorage.getItem('data')){
            return true;
        }else{
            return false;
        }
    }

    logout(){
        this.updatelastsession().subscribe(resp=>{
          this.router.navigate(['/']);
        },error=>{
          console.log(error)
          this.router.navigate(['/']);
        })
        this.router.navigate(['/']);
        localStorage.removeItem(this.TOKEN_KEY)
        localStorage.clear()
      
    }

    updatelastsession(){
        return this.http.get<any>(vcnpath+'/auth/logout');
    }

    loginUser(loginData){
        let body = new URLSearchParams();
        body.set('email', loginData['username']);
        var encryptedPassword= this.encryptUsingAES256(loginData['password'])
        body.set('password', encryptedPassword);      

      let options = {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      };



     this.spinner.show();
      this.http.post(`${vcnpath}/auth/signIn`,body.toString(), options).subscribe(data=>{
        this.spinner.hide();
        if(data['message']=='Login success!' && data['data']['otp_enable']==false){
          this.saveToken(data['data']['token'])

          //Start watching for user inactivity.
          this.userIdle.startWatching();
          // Start watching when user idle is starting.
          this.userIdle.onTimerStart().subscribe(count=>{
            this.count = 0
          });
          // Start watch when time is up.
          this.userIdle.onTimeout().subscribe(() => {
            this.userIdle.stopWatching();
            this.userIdle.stopTimer();
            this.count++;
            if(this.count==1) {
            this.toastr.error('Session Timeout! Please login again','Error',{
              timeOut:9000,
              positionClass:'toast-top-center'
              })
            }
            this.router.navigate(['/']);
          });

          if(data['data']['role_name']=='Admin'){
            this.router.navigate(['/card-request-summary']);
          }else if(data['data']['role_name']=='Maker' || data['data']['role_name']=='Checker' || data['data']['role_name']=='Corporate Admin' || data['data']['role_name']=='AS'){
            sessionStorage.setItem('showAttentionBox','false')
            this.router.navigate(['/corp-dashboard']);
          }
       
          
        }else if(data['message']=='Invalid password!'){
          this.toastr.error('Invalid Email ID or Password!','Error',{
            timeOut:3000,
            positionClass:'toast-top-center'
          })
        }else if(data['message']=='OTP sent successfully!' && data['data']['otp_enable']==true){
          this.saveToken(data['data']['token'])
          this.router.navigate(['/new-otp']);
        }else if(data['message']=='No user exist!'){
          this.toastr.error('Invalid Email ID or Password!','Error',{
            timeOut:3000,
            positionClass:'toast-top-center'
          })
        }else if(data['message']=='Please try again after 30 mins'){
          this.toastr.error('User Locked! Please try again after 30 mins','Warning',{
            timeOut:3000,
            positionClass:'toast-top-center'
          })
        }else if(data['message']=='Too many login attempts.Please try again after 30 mins'){
          this.toastr.error('Too many login attempts.Please try again after 30 mins','Error',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
        else{
          this.toastr.error('Something Went Wrong!','Error',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
      },error=>{
      console.log(error)
      this.spinner.hide();
      if(error['message']=='Error while creating OTP!'){
        this.toastr.error('Error while creating OTP!','Error',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(error['message']=='Something Went Wrong!'){
        this.toastr.error('Something Went Wrong!','Error',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else{
        this.toastr.error('Invalid Email ID or Password!','Error',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      
      }
       
      });
      
      }
      
      postOtp(otpData){
      let body = new URLSearchParams();
      body.set('otp', otpData);
      
      let options = {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      };
      this.spinner.show();
      this.http.post(`${vcnpath}/auth/signInOtp`,body.toString(),options).subscribe(data=>{
        this.spinner.hide();
        if(data['message']=='Otp Validated Successfully'){
          this.saveToken(data['data']['token'])

          //Start watching for user inactivity.
          this.userIdle.startWatching();
          // Start watching when user idle is starting.
          this.userIdle.onTimerStart().subscribe(count=>{
            this.count = 0
          });
          // Start watch when time is up.
          this.userIdle.onTimeout().subscribe(() => {
            this.userIdle.stopWatching();
            this.userIdle.stopTimer();
            this.count++;
            if(this.count==1) {
            this.toastr.error('Session Timeout! Please login again','Error',{
              timeOut:9000,
              positionClass:'toast-top-center'
              })
            }
            this.router.navigate(['/']);
          });

          if(data['data']['role_name']=='Admin'){
            this.router.navigate(['/card-request-summary']);
          }else if(data['data']['role_name']=='Maker' || data['data']['role_name']=='Checker' || data['data']['role_name']=='Corporate Admin' || data['data']['role_name']=='AS'){
            this.router.navigate(['/corp-dashboard']);
          }
        }else if(data['message']=='User not found'){
          this.toastr.error("Invalid OTP!",'Error',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }else if(data['message']=='token expired'){
          this.toastr.error("OTP has been expired!",'Error',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }else if(data['message']=='malformed token'){
          this.toastr.error("Something went wrong!",'Error',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
        
        else{
          this.toastr.error("Something went wrong!",'Error',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
        
      },error=>{
        console.log(error)
        this.spinner.hide();
        if(error["error"]['message']=='Invalid Otp!'){
          this.toastr.error("Invalid OTP!",'Error',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        } else {
        this.toastr.error("Something went wrong!",'Error',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
        }
      })
      
      }
      
      getorganisationbyemail(email:any): Promise<any> {    
        let promise = new Promise((resolve, reject) => {
            this.http.get(`${this.loginpath}api/auth/organizations_by_email?email=${email}&no=${2}`)
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        console.log(err);
                        reject(err);
                    }
                );
        });
        return promise;
      }

    requestPassword(email:any,org:any){
        let body = new URLSearchParams();
        body.set('email', email);
        body.set('corp_id', org);
        body.set('no','2')
        let options = {
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
      
        this.http.post(`${this.loginpath}api/auth/master_send_forget_password`,body.toString(), options).subscribe(data=>{
        
         // this.saveToken(data['data']['secure_set'])
         this.toastr.success("Password reset link has been sent on your mail!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
         
        },error=>{
          console.log(error)
        });
      }

    

    saveToken(token){
        localStorage.setItem(this.TOKEN_KEY,token)
    }


    // setnewpassword(password:any,token:any,id:any){
    //     this.saveToken(token);
    // var pwddetails={
    //     "password":password,
    //     "id":id
    // }
    //  //  console.log(password)
    //   // this.router.navigate(['/']);
    //     this.http.post(orgpath+'/setnewpassword',pwddetails).subscribe(data=>{
        
    //       console.log(data)
   
    //      this.toastr.success("Your password has been reset successfully, Please login to continue!",'Alert',{
    //       timeOut:3000,
    //       positionClass:'toast-top-center'
    //       })
    //       this.router.navigate(['/']);
    //     },error=>{
    //       console.log(error)
    //       this.router.navigate(['/']);
    //       this.toastr.success("Failed to reset the password!",'Alert',{
    //         timeOut:3000,
    //         positionClass:'toast-top-center'
    //         })
    //     });
    //     localStorage.removeItem(this.TOKEN_KEY)
    //   }

      setnewpassword(password:any,cnf_pwd:any,token:any){
        this.saveToken(token);
        let body = new URLSearchParams();
        body.set('password', password);
        body.set('confirm_password', cnf_pwd);
        let options = {
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
      
        this.http.post(`${this.loginpath}api/auth/master_forget_password`,body.toString(), options).subscribe(data=>{
        
          console.log(data)
         // this.saveToken(data['data']['secure_set'])
         this.toastr.success("Your password has been reset successfully, Please login to continue!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
          this.router.navigate(['/']);
        },error=>{
          console.log(error)
          if(error['msg']=='User not found'){
            this.toastr.error("User not available!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else if(error['msg']=='token expired'){
            this.toastr.error("Password reset link has been expired!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }else if(error['msg']=='malformed token'){
            this.toastr.error("Something went wrong!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
        });
        localStorage.removeItem(this.TOKEN_KEY)
      }

      newSSOtoken(email: any): Promise<any> {
        let paramsValue = {
            "email": email
        };
        let promise = new Promise((resolve, reject) => {
            this.http.post(vgcpath+"/authSBM/sso-token", paramsValue)
                .subscribe(
                    res => {
                      //  
                        resolve(res);
                    },
                    err => {
                        console.log("Error occured : " + err);
                        reject(err);
                    }
                );
      
        });
      
        return promise;
      }
     
      encryptUsingAES256(password:any) {
        let _key = CryptoJS.enc.Utf8.parse(vcnCipher);
        let _iv = CryptoJS.enc.Utf8.parse(vcnText);
        let encrypted = CryptoJS.AES.encrypt(password, _key, {
          keySize: 32,
          iv: _iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
      }

}