import { Component, OnInit } from '@angular/core';
import {VcnService} from '../../_api/vcn.service'
import { DatePipe } from '@angular/common'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router';
import {VcncorpService} from '../../_api/vcncorp.service'
@Component({
  selector: 'app-pendingfileswidget',
  templateUrl: './pendingfileswidget.component.html',
  styleUrls: ['./pendingfileswidget.component.css']
})
export class PendingfileswidgetComponent implements OnInit {
  filedata:any=[]
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  totalamount:number=0;
  totalcount:number=0
  constructor(private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private route:Router,private vcncorp:VcncorpService) { }

  ngOnInit() {
    this.loadUserDetails()
    
  }

  private loadpendingfilescount(id){
    this.totalamount=0
    this.spinner.show()
  
    this.vcncorp.getpendingfilescount(id).then(resp=>{
    
     if(resp['message']=='Pending File Count!'){
      this.totalcount=resp['data']['file_count']
      if(resp['data']['total_amount']!=null){
        this.totalamount=resp['data']['total_amount']
      }else{
        this.totalamount=0
      }
      
     
       this.spinner.hide()
     }else{
      this.spinner.hide()
      console.log('Not able to fetch count')
     }
     
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }

  private loadUserDetails(){
    this.vcn.getCurrentUser().then(users => {
     // console.log(users);
      if(users['message']=='User info!'){
        this.loadpendingfilescount(users['data']['corporate_id'])
      }
      },error=>{
        console.log(error)
      });
  }

}
