import { Component, OnInit } from '@angular/core';
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import { Router, ActivatedRoute,NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import {AuthService} from '../../_api/auth.service'

@Component({
  selector: 'app-userheader',
  templateUrl: './userheader.component.html',
  styleUrls: ['./userheader.component.css']
})
export class UserheaderComponent implements OnInit {
mobile_number:any;
  constructor(private userservice: UserService,private router: Router,private vcn:VcnService,private auth:AuthService) { }

  ngOnInit() {
    this.mobile_number=localStorage.getItem('usermobile')
  }

  logout(){
   this.router.navigate(['/mobile-sign-in'])
   localStorage.clear()
  
}
}
