import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
// import { Observable, of, throwError } from 'rxjs';
// import { catchError, tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {Config} from '../config'
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

const path = new Config().getVcnUrl();
@Injectable({
  providedIn: 'root'
})
export class VcnService {

  constructor(private http: HttpClient) { }

  uploadreqfile(file:File){
    let data ={};
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post(`${path}/card/file_upload`, formdata);
}

uploadUpdateBalanceFile(file:File){
    let data ={};
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post(`${path}/card/add_upload`, formdata);
}

uploadcontactreqfile(file:File){
    let data ={};
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post(`${path}/card/generate_card`, formdata);
}

uploadinquiredcardsfile(file:File){
    let data = {};
    const formdata: FormData = new FormData();
    formdata.append('file',file);
    return this.http.post(`${path}/card/wibmo_inquire`, formdata);
}

submitBulkReq(redata:any){
  //console.log(users)
  return this.http.post(`${path}/card/card_request`, redata);
}

getFileList(): Promise<any> {
  // let token = this.storage.getData("chlogin_data").token;
  // let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //     .set('authorization', 'Bearer ' + token);
  // let options = { headers: headers };      
  let promise = new Promise((resolve, reject) => {
      this.http.get(`${path}/card/request_list`)
          .subscribe(
              res => {
                  
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );

  });

  return promise;
}

getCancelCardFileList(): Promise<any> {
    // let token = this.storage.getData("chlogin_data").token;
    // let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //     .set('authorization', 'Bearer ' + token);
    // let options = { headers: headers };      
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/cancelCard/request_list`)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

  getCancelCardFileListAdmin(): Promise<any> {
    // let token = this.storage.getData("chlogin_data").token;
    // let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //     .set('authorization', 'Bearer ' + token);
    // let options = { headers: headers };      
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/cancelCard/request_list_admin`)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

getFiledata(id:any): Promise<any> {
  // let token = this.storage.getData("chlogin_data").token;
  // let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //     .set('authorization', 'Bearer ' + token);
  // let options = { headers: headers };      
  let promise = new Promise((resolve, reject) => {
      this.http.get(`${path}/card/${id}/review_card_request`)
          .subscribe(
              res => {
                 
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );

  });

  return promise;
}

getCancelCardFiledata(id:any): Promise<any> {
    // let token = this.storage.getData("chlogin_data").token;
    // let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //     .set('authorization', 'Bearer ' + token);
    // let options = { headers: headers };      
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/cancelCard/${id}/review_card_request`,{"id":id})
            .subscribe(
                res => {
                   
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

approveFile(id:any): Promise<any> {
  // let token = this.storage.getData("chlogin_data").token;
  // let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //     .set('authorization', 'Bearer ' + token);
  // let options = { headers: headers }; 
  var params={}     
  let promise = new Promise((resolve, reject) => {
      this.http.post(`${path}/card/${id}/approve_request`,params)
          .subscribe(
              res => {
                  
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );

  });

  return promise;
}


approveFiletest(id:any): Promise<any> {
    // let token = this.storage.getData("chlogin_data").token;
    // let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //     .set('authorization', 'Bearer ' + token);
    // let options = { headers: headers }; 
    var params={}     
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/card/${id}/approve_request_checker`,params)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

  approveSingleCard(params:any,rcnid,rcnalias,filename,totalcards,id): Promise<any> {
    // let token = this.storage.getData("chlogin_data").token;
    // let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //     .set('authorization', 'Bearer ' + token);
    // let options = { headers: headers }; 
    
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/card/${id}/approve_single_request?rcn_id=${rcnid}&alias_name=${rcnalias}&file_name=${filename}&total_cards=${totalcards}`,params)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

rejectFile(id:any): Promise<any> {
  // let token = this.storage.getData("chlogin_data").token;
  // let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //     .set('authorization', 'Bearer ' + token);
  // let options = { headers: headers }; 
  var params={}     
  let promise = new Promise((resolve, reject) => {
      this.http.post(`${path}/card/${id}/reject_request`,params)
          .subscribe(
              res => {
                  
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );

  });

  return promise;
}

getCorporate(): Promise<any>{
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/card/corporate_list`)
            .subscribe(
                res => {
                  
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
}

getCorporatebyId(id:any): Promise<any>{
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/card/${id}/corporate_details`)
            .subscribe(
                res => {
                  
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
}

getAliasByCorpId(id:any): Promise<any>{
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/card/${id}/alias_by_corp`)
            .subscribe(
                res => {
                  
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
}

getTemplateByCorpId(id:any): Promise<any>{
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/card/${id}/template_by_corp`)
            .subscribe(
                res => {
                  
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
}

getsupplierByCorpId(id:any): Promise<any>{
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/card/${id}/supplier_by_corp`)
            .subscribe(
                res => {
                  
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
}

getCurrentUser(): Promise<any>{
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/auth/info`)
            .subscribe(
                res => {
                  
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
}

uploadCardCancelFile(file:File){
    let data ={};
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post(`${path}/card/cancelVCN`, formdata);
}

getCardDetails(params): Promise<any>{
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/user/get_filedata_detail`,params)
            .subscribe(
                res => {
                  
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
}

sendFileBack(id:any,rejcmt:any): Promise<any> {
    // let token = this.storage.getData("chlogin_data").token;
    // let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //     .set('authorization', 'Bearer ' + token);
    // let options = { headers: headers }; 
    var params={
        "id":id,
        "reject_comment":rejcmt
    }     
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/card/send_back`,params)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

  deleteFilebyId(id:any): Promise<any> {
    var params={}
      
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/user/${id}/delete_file`,params)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }

  getAllCorps(): Promise<any>{
    var params={}
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/dashboard/Overall_Corp`)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
    return promise;
  }

  getDailyMISALiasWise(): Promise<any>{
    var params={}
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/dashboard/Overall_Alias`)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
    return promise;
  }


  getDailyMIS(): Promise<any>{
    var params={}
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/dashboard/todayCorp`)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
    return promise;
  }

  getYearlyMIS(): Promise<any>{
    var params={}
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/dashboard/yearlyCorp`)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
    return promise;
  }

  getMonthlyMIS(): Promise<any>{
    var params={}
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}/dashboard/monthlyCorp`)
            .subscribe(
                res => {
                    
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
    return promise;
  }
}
