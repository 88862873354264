import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import {VcnService} from '../../_api/vcn.service'
declare var require:any;
import { Routes, RouterModule,Router } from '@angular/router';

@Component({
  selector: 'app-corp-home',
  templateUrl: './corp-home.component.html',
  styleUrls: ['./corp-home.component.css']
})
export class CorpHomeComponent implements OnInit {
  currentUser: Users;
  fname: string;
  lname:string;
  cmpyname:string;
  dsgtn: string;
  lastsession:string;
  userrole:string;
  isUniuser:boolean;
  isBulkuser:boolean;
  isUnicard:boolean;
  isUnigroup:boolean;
  isUnirule:boolean;
  isAuthmatrix:boolean;
  isUnisup:boolean;
  isBulksup:boolean;
  corporate_id:any=null;
  corporate_process:any=null;
  constructor(private userservice: UserService,private vcn:VcnService,private router: Router) { }

  ngOnInit() {

    this.loadUserDetails()
  }
  checkValInArray(tabid:string , tab_array:string[]){
    return (tab_array.indexOf(tabid)==-1)?false:true;
}

  loadUserDetails(){
    this.vcn.getCurrentUser().then(users => {
     // console.log(users);
      
       this.currentUser = users['data']; 
      
       this.fname=users['data']['first_name'];
       this.lname=users['data']['last_name'];
       if(users['data']['last_login']!=null){
       this.lastsession=users['data']['last_login'];
       let dateFormat = require('dateformat');
       let now = this.lastsession
       this.lastsession=dateFormat(now, "dddd, mmmm dS, yyyy, hh:MM TT");
       }else{
       this.lastsession="mmm dd yyyy  h:mm"
       }
   
       this.dsgtn=users['data']['designation'];
       this.userrole=users['data']['role_name'];
       this.corporate_id=users['data']['corporate_id']
       this.corporate_process=users['data']['corporate_process']
       localStorage.setItem('corporate_id',this.corporate_id)
       localStorage.setItem('corporate_process',this.corporate_process)
       localStorage.setItem('rolename',this.userrole)
       localStorage.setItem('self_approval',users['data']['self_approval'])
      
       //console.log(this.isPermission);
      },error=>{
        console.log(error.error.message)
        if(error.error.message=='Token expired!'){
          this.router.navigate(['/']);
          localStorage.removeItem('token')
          localStorage.clear()
        }
      });
  }
}
