import { Component, OnInit } from '@angular/core';
import{LoaderService} from '../_api/loader.service'
import {AuthService} from '../_api/auth.service'
@Component({
  selector: 'app-userhome',
  templateUrl: './userhome.component.html',
  styleUrls: ['./userhome.component.css']
})
export class UserhomeComponent implements OnInit {

  showLoader: boolean;
  title = 'Aquapay';
  isLoggedIn:boolean=false;
  constructor(
    private loaderService: LoaderService,private authservice:AuthService ) {
      this.authservice.isLoggedIn.subscribe(res =>this.loggedInEmitterCatch(res));
      this.isLoggedIn=this.authservice.getIsLoggedIn();
      //console.log(this.isLoggedIn + "user is logged in");
}
loggedInEmitterCatch(res:string){
  if(res ==="true"){
      this.isLoggedIn = true;
  }else{
      this.isLoggedIn=false;
  }
}
ngOnInit() {
  this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
      //console.log(this.showLoader)
  });
  
  //console.log("Token:"+localStorage.getItem('token'));
}

}
