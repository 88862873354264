import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/excelservice/excel.service';
import { ExcelGeneratorService } from 'src/app/_api/excel-generator.service';
import { VcnService } from 'src/app/_api/vcn.service';

@Component({
  selector: 'app-rejected-cancel-card-files',
  templateUrl: './rejected-cancel-card-files.component.html',
  styleUrls: ['./rejected-cancel-card-files.component.css']
})
export class RejectedCancelCardFilesComponent implements OnInit {

  filedata:any=[]
  rowsOnPage = 1000;
  sortBy = "created_date";
  sortOrder = "asc";
  selectedIndex = -1;
  downloadArray:any=[];
  params:any = "true"
  constructor(private activatedRoute: ActivatedRoute,private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private route:Router,private excelservice : ExcelService,private excel:ExcelGeneratorService) { }

  ngOnInit() {
        this.loadRequestList()
  }
  private loadRequestList(){
    this.spinner.show()
    this.vcn.getCancelCardFileList().then(resp=>{
    
      var filedatalist=resp['data']
      this.filedata=filedatalist.filter(file=>(file['status']=='Rejected' && file['checker_status']=='Rejected' && file['maker_status']=='Uploaded'))
    console.log(this.filedata)
      this.spinner.hide()
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }

  gotolistview(id){
    this.route.navigate(['/maker-cancel-card', id]);
}

  toggleExpandedView(index){
    this.selectedIndex = index;
   }

   exportAsXLSX():void {
    this.downloadArray=[]
    
    if(this.filedata.length>=0){
      for(let data of this.filedata){
        var obj={
          FileName:data['file_name'],
          Total_Tokens:data['total_cards'],
          Total_Amount:data['total_amount'],
          Alias_Name:data['alias_name'],
          Corporate_Name:data['corporate_name'],
          Bank_Name:data['bank_name'],
          UTR:data['utr_no'],
          Transaction_Date:data['transaction_date'],
          Initiated_by:data['initiator_name'],
          Initiated_Time:data['created_time'],
          Initiated_Date:data['created_date'],
          // Alias_Name:this.filesummary['alias_name'],
          // Corporate_name:this.filesummary['corporate_name'],
          Approved_by:data['approver_name'],
          Approved_Date:data['approved_date'],
          Approved_Time:data['approved_time'],
          Admin_Approved_by:data['admin_approved_date'],
          Admin_Approved_Date:data['admin_approver_name'],
          Admin_Approved_Time:data['admin_approved_time'],

       
        }
        this.downloadArray.push(obj)
      }
      this.excel.exportAsExcelFile( this.downloadArray, "Request_File_List");
    }else{
      this.toastr.warning("No Data Available","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
   // this.excelservice.exportAsExcelFile( this.users, 'User');
  }
}
