export class Config{
    baseUrl:string
    orgpath:string
    loginurl:string;
    raneurl:string
    vcnurl:string;
    vgcurl:string;
    onboardingURL:string;
    karzaAPIsURL:string;
    VCNKEY:string;
    VCNSALT:string;
    sessionK = "E184235117E88C948EA3CB3A90C312FF"
    sessionS = "1E2BEA3BAAC203DD"

    constructor(){
    this.vcnurl = "http://134.209.157.35:3000" // VCN UAT
    // this.onboardingURL = "http://localhost:5000/"
    // this.karzaAPIsURL = "http://localhost:5000/"
    this.onboardingURL = "http://134.209.157.35:5000/"
    this.karzaAPIsURL = "http://134.209.157.35:5000/"
    this.VCNKEY = "338317A711A0DD6308791404BF010917" //UAT
    this.VCNSALT = "1E2BEA3BAAC203DD" //UAT

    // For SSO TOKEN Generation
    // this.vgcurl = "http://15.206.73.138:5000"  // VGC UAT
    // this.vgcurl = "https://vgc.aquapay.in:5000" //Production URL

    // this.vcnurl = "https://icici-vcn-backend.aquapay.in:3000" //VCN Production URL
    // this.onboardingURL = "https://icici-vcn-corponboarding-backend.aquapay.in:5000/"
    // this.karzaAPIsURL = "https://icici-vcn-corponboarding-backend.aquapay.in:5000/"
    // this.VCNKEY = "B14CA5898A4E4133BBCE2EA2315A1916"
    // this.VCNSALT = "5183666c72eec9e4"
    }

    getVcnUrl(){
        return this.vcnurl
    }

    getVgcUrl(){
        return this.vgcurl
    }

    getOnboadingURL(){
        return this.onboardingURL
    }
    getKarzaURL(){
        return this.karzaAPIsURL
    }
    getVCNCipher(){
        return this.VCNKEY
    }

    getVCNText(){
        return this.VCNSALT
    }
    getSessionK(){
        return this.sessionK
    }
    getSessionS(){
      return this.sessionS
  }
}