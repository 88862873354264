import { Component, OnInit } from '@angular/core';
import {VcnService} from '../../_api/vcn.service'
import { DatePipe } from '@angular/common'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router';
import {VcncorpService} from '../../_api/vcncorp.service'
import { Users } from '../../models/users';

@Component({
  selector: 'app-corporate-pending-files',
  templateUrl: './corporate-pending-files.component.html',
  styleUrls: ['./corporate-pending-files.component.css']
})
export class CorporatePendingFilesComponent implements OnInit {
  filedata:any=[]
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  selectedIndex = -1;
  corporate_process:any;
   currentUser: any;
   corporate_id:any=null;
   fileId:any;
   rolename:any;
   userId:any;
  constructor(private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private route:Router,private vcncorp:VcncorpService) { }

  ngOnInit() {
    this.loadUserDetails()
  }

  private loadUserDetails(){
    this.vcn.getCurrentUser().then(users => {
       this.currentUser = users['data']; 
       this.corporate_id=this.currentUser['corporate_id'];
       this.rolename=this.currentUser['role_name'];
       this.userId = this.currentUser['id'];
       this.loadRequestList()
      });
  }
  private loadRequestList(){
    this.spinner.show()
    this.corporate_process=localStorage.getItem('corporate_process')
    this.vcncorp.getCorpFileList().then(resp=>{
      console.log(resp)
       var filedatalist=resp['data']
       if(this.rolename == 'Corporate Admin' || this.rolename == 'AS'){
        this.filedata=filedatalist.filter(file=>((file['status']=='Pending' || file['status']=='Uploading') && (file['maker_status']=='Uploaded'|| file['maker_status']=='Uploading') && file['checker_status']=='Approved'))
       } else {
        if(this.rolename == 'Maker'){
          if(this.corporate_process=='maker_checker'){
            this.filedata=filedatalist.filter(file=>(file['checker_status']=='Pending' && file['initiator_id']==this.userId && (file['maker_status']=='Uploaded' || file['maker_status']=='Uploading') && (file['status']=='Pending' || file['status']=='Uploading')))
          }else if(this.corporate_process=='maker'){
            this.filedata=filedatalist.filter(file=>(file['checker_status']=='Approved' && file['initiator_id']==this.userId && file['maker_status']=='Uploaded' && file['status']=='Pending'))
          }
        } else {
          if(this.corporate_process=='maker_checker'){
            this.filedata=filedatalist.filter(file=>(file['checker_status']=='Pending' && (file['maker_status']=='Uploaded' || file['maker_status']=='Uploading') && (file['status']=='Pending' || file['status']=='Uploading')))
          }else if(this.corporate_process=='maker'){
            this.filedata=filedatalist.filter(file=>(file['checker_status']=='Approved' && file['maker_status']=='Uploaded' && file['status']=='Pending'))
          }
        }
       }
      this.spinner.hide()
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }

  gotolistview(id){
    this.route.navigate(['/corp-pending-vcn-view', id]);

  }
 toggleExpandedView(index){
  this.selectedIndex = index;
 }

 getFileId(id:any){
  this.fileId=id
 }

 deleteFile(){
   this.spinner.show()
    this.vcn.deleteFilebyId(this.fileId).then(resp=>{
      console.log(resp)
      if(resp['message']=='File Deleted successfully!'){
        this.spinner.hide()
        this.toastr.success("File has been deleted successfully!","Success",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
          this.loadRequestList()
      }else{
        this.spinner.hide()
        this.toastr.error("Something went wrong!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      console.log(error)
      this.spinner.hide()
        this.toastr.error("Failed to delete!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
    })
 }
}
