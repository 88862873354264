import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/excelservice/excel.service';
import { ExcelGeneratorService } from 'src/app/_api/excel-generator.service';
import { VcnService } from 'src/app/_api/vcn.service';
import { VcncorpService } from 'src/app/_api/vcncorp.service';

@Component({
  selector: 'app-corp-cancelcard-approved-files',
  templateUrl: './corp-cancelcard-approved-files.component.html',
  styleUrls: ['./corp-cancelcard-approved-files.component.css']
})
export class CorpCancelcardApprovedFilesComponent implements OnInit {

  filedata:any=[]
  rowsOnPage = 1000;
  sortBy = "created_date";
  sortOrder = "asc";
  selectedIndex = -1;
  downloadArray:any=[];
  corporate_process:any=null
  corporate_id:any=null;
  corpIds:any=[]
  showAdminActivity:boolean=false;
  rolename:any;
  currentUser: any;
  userId:any;
  constructor(private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private route:Router,private excelservice : ExcelService,private excel:ExcelGeneratorService,private vcncorp:VcncorpService,private http:HttpClient) { }

  ngOnInit() {
    this.http.get("assets/json/makerCheckerAdminCorpId.json").subscribe(data =>{
      console.log(data);
      this.corpIds = data['corpoId'];
      if(this.corpIds.includes(parseInt(this.corporate_id))){
        this.showAdminActivity=true
        console.log('Includes')
      }else{
        this.showAdminActivity=false;
        console.log('Not Includes')
      }
    })
   
    this.loadUserDetails()
   
  }

  private loadUserDetails(){
    this.vcn.getCurrentUser().then(users => {
       this.currentUser = users['data']; 
       this.corporate_id=this.currentUser['corporate_id'];
       this.rolename=this.currentUser['role_name'];
       this.userId = this.currentUser['id'];
       this.loadRequestList()
      });
  }

  private loadRequestList(){
    this.spinner.show()
    this.corporate_process=localStorage.getItem('corporate_process')
    this.vcncorp.getCancelCardFileList().then(resp=>{
    console.log(resp)
      var filedatalist=resp['data']
      if(this.rolename == 'Corporate Admin' || this.rolename == 'AS'){
        this.filedata=filedatalist.filter(file=>(file['status']=='Blocked' && file['maker_status']=='Uploaded' && file['checker_status']=='Approved'))
       } else {
        if(this.rolename == 'Maker') {
          if(this.corporate_process=='maker_checker'){
            this.filedata=filedatalist.filter(file=>(file['checker_status']=='Approved' && file['maker_status']=='Uploaded' && file['initiator_id']==this.userId))
          }else if (this.corporate_process=='maker'){
            this.filedata=filedatalist.filter(file=>(file['checker_status']=='Approved' && file['maker_status']=='Uploaded' && file['status']=='Approved' && file['initiator_id']==this.userId))
          }
        } else {
          if(this.corporate_process=='maker_checker'){
            this.filedata=filedatalist.filter(file=>(file['checker_status']=='Approved' && file['maker_status']=='Uploaded'))
          }else if (this.corporate_process=='maker'){
            this.filedata=filedatalist.filter(file=>(file['checker_status']=='Approved' && file['maker_status']=='Uploaded' && file['status']=='Approved'))
          }
        }
    }

      console.log(this.corporate_process)
      this.spinner.hide()
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }

  gotolistview(id){
    this.route.navigate(['/corp-cancel-card-vcn-view', id]);

  }

  toggleExpandedView(index){
    this.selectedIndex = index;
   }

   exportAsXLSX():void {
    this.downloadArray=[]
    
    if(this.filedata.length>=0){
      for(let data of this.filedata){
        var obj={
          FileName:data['file_name'],
          Total_Tokens:data['total_cards'],
          Total_Amount:data['total_amount'],
          // Alias_Name:data['alias_name'],
          Corporate_Name:data['corporate_name'],
          // Bank_Name:data['bank_name'],
          // UTR:data['utr_no'],
          Transaction_Date:data['transaction_date'],
          // Sub_Corporta_Name_Code:data['sub_corp_name'],
          // Remarks:data['remarks'],
          Initiated_Time:data['created_time'],
          Initiated_Date:data['created_date'],
          Initiated_By:data['initiator_name'],
          Approved_By:data['approver_name'],
          // Alias_Name:this.filesummary['alias_name'],
          // Corporate_name:this.filesummary['corporate_name'],
          Approved_Date:data['approved_date'],
          Approved_Time:data['approved_time'],
          Bank_Approval:data['status'],

       
        }
        this.downloadArray.push(obj)
      }
      this.excel.exportAsExcelFile( this.downloadArray, "Request_File_List");
    }else{
      this.toastr.warning("No Data Available","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
   // this.excelservice.exportAsExcelFile( this.users, 'User');
  }
}
