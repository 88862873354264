import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute,NavigationEnd,RoutesRecognized   } from '@angular/router';
import { filter,pairwise  } from 'rxjs/operators';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
declare var loadAsPiCharts : any;

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  previousUrl: string;
  currentUrl: string;
  params:string;
  groupsuccess:boolean=false;
  cardsuccess:boolean=false;
  usersucess:boolean=false;
  rulesuccess:boolean=false;
  paymentrefundsuccess:boolean=false;
  suppliersuccess:boolean=false;
  paymentsuccess:boolean=false;
  usrapprsuccess:boolean=false;
  grpapprsuccess:boolean=false;
  ruleapprsuccess:boolean=false;
  crdapprsuccess:boolean=false;
  supplierapprsuccess:boolean=false;
  paymentapprsuccess:boolean=false;
  constructor(private router: Router,private activatedRoute: ActivatedRoute) { 
    
  }

 

  ngOnInit() {

    this.params = this.activatedRoute.snapshot.queryParams["msg"];
    console.log(this.params);
    if(this.params=='groupsuccess'){
      this.groupsuccess=true;
    }else if(this.params=='cardsuccess'){
      this.cardsuccess=true;
    }else if(this.params=='rulesuccess'){
      this.rulesuccess=true;
    }else if(this.params=='usersuccess'){
      this.usersucess=true;
    }else if(this.params=='suppliersuccess'){
      this.suppliersuccess=true;
    }else if(this.params=='paymentsuccess'){
      this.paymentsuccess=true;
    }else if(this.params=='usrapprsuccess'){
      this.usrapprsuccess=true;
    }else if(this.params=='cardapprsuccess'){
      this.crdapprsuccess=true;
    }else if(this.params=='ruleapprsuccess'){
      this.ruleapprsuccess=true;
    }else if(this.params=='grpapprsuccess'){
      this.grpapprsuccess=true;
    }else if(this.params=='supplierapprsuccess'){
      this.supplierapprsuccess=true;
    }else if(this.params=='paymentapprsuccess'){
      this.paymentapprsuccess=true;
    }else if(this.params=='paymentrefundsuccess'){
      this.paymentrefundsuccess=true;
    }
    else{
      this.groupsuccess=false;
      this.cardsuccess=false;
      this.rulesuccess=false;
      this.usersucess=false;
      this.suppliersuccess=false;
      this.paymentapprsuccess=false;
      this.supplierapprsuccess=false;
      this.paymentsuccess=false;
      this.paymentrefundsuccess=false;
    }
    $(document).ready(function(){
      $(".gtog").on("click", function () {
        $(".supplink").addClass("active");
        $(".supplink").siblings(".active").removeClass("active");
      });

    });
    
    
  }


}
