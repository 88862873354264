import { Component, OnInit } from '@angular/core';
import {VcnService} from '../../_api/vcn.service'
import { DatePipe } from '@angular/common'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router';
@Component({
  selector: 'app-pending-files',
  templateUrl: './pending-files.component.html',
  styleUrls: ['./pending-files.component.css']
})
export class PendingFilesComponent implements OnInit {
  filedata:any=[]
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  selectedIndex = -1;
  fileId:any;
  constructor(private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private route:Router) { }

  ngOnInit() {
    this.loadRequestList()
  }


  private loadRequestList(){
    this.spinner.show()
    this.vcn.getFileList().then(resp=>{
     console.log(resp)
      var filedatalist=resp['data']
      this.filedata=filedatalist.filter(file=>(file['status']=='Pending' && file['maker_status']=='Uploaded' && file['checker_status']=='Approved'))
    
      this.spinner.hide()
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }

  gotolistview(id){
    this.route.navigate(['/checker-card', id]);

  }
 toggleExpandedView(index){
  this.selectedIndex = index;
 }

 getFileId(id:any){
  this.fileId=id
 }

 deleteFile(){
  this.spinner.show()
   this.vcn.deleteFilebyId(this.fileId).then(resp=>{
     console.log(resp)
     if(resp['message']=='File Deleted successfully!'){
       this.spinner.hide()
       this.toastr.success("File has been deleted successfully!","Success",{
         timeOut:3000,
         positionClass:'toast-top-center'
         })
         this.loadRequestList()
     }else{
       this.spinner.hide()
       this.toastr.error("Something went wrong!","Alert",{
         timeOut:3000,
         positionClass:'toast-top-center'
         })
     }
   },error=>{
     console.log(error)
     this.spinner.hide()
       this.toastr.error("Failed to delete!","Alert",{
         timeOut:3000,
         positionClass:'toast-top-center'
         })
   })
}
}
