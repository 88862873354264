import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
declare var jquery:any;
declare var $ :any;
// declare var loadCharts: any;
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import{CardService} from '../../_api/card.service'
import { Router,ActivatedRoute } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import {Urlconfig} from '../../urlconfig';
import {AuthService} from '../../_api/auth.service';
const vgc_path= new Urlconfig().getGiftCardURL()
const vcn_path= new Urlconfig().getVcnURL()
@Component({
  selector: 'app-cardupload',
  templateUrl: './cardupload.component.html',
  styleUrls: ['./cardupload.component.css']
})
export class CarduploadComponent implements OnInit {
  @ViewChild('myDiv') myDiv: ElementRef;
 // public dashboardData: any;
  public params:string;
  display='none'; //default Variable
  top='50px';
  public username:string;
  public rolename:string;
  currentUser: Users;
  isUnisup:boolean;
  isBulksup:boolean;
  isApprsup:boolean;
  isUnipay:boolean;
  isApprpay:boolean;
  isBulkpay:boolean;
  isPaysupstatus:boolean;
  isCardActivity:boolean;
  cards:any=[];
  pendingcards:any=[];
  approvedcards:any=[];
  rejectedcards:any[];
  fileUpload:File;
  filename:string="Click to Browse"
  selectedFiles: FileList;
  model: any = {};
  userData: any = {};
  _users:any[]=[];
  isSelected = false;
  step2:boolean=false;
  showAliaDD:boolean=false;
  showUploadFileOpt:boolean=false;
  corporateData:any=[]
  aliasdata:any=[]
  templatedata:any=[]
   supplierdata:any=[]
  corporate_id:any
  alias_id:any;
  template_id:any;
  supplier_id:any;
  file_name_params:any;
  template_name_params:any;
  supplier_name_params:any;
  corpMis:any=[]
  aliasMIS:any=[]
  monthlyMIS:any=[]
  yearlyMIS:any=[]
  dailyMIS:any=[]
  rowsOnPage = 1000;
  sortBy = "Name";
  sortOrder = "asc";
  monthlyMISSummary:any={}
  yearlyMISSummary:any={}
  dailyMISSummary:any={}
  aliasWiseSummary:any={}
  corpWiseSummary:any={}
  vgc_url:any;
  redirecttoken:string;
  constructor(private userservice: UserService,private cardservice: CardService,private router: Router,private activatedRoute: ActivatedRoute,private vcn:VcnService,private spinner: NgxSpinnerService,private toastr: ToastrService,private auth:AuthService,private route: ActivatedRoute) { }

  ngOnInit() {
    this.template_id = null
    this.supplier_id = null
    this.redirecttoken=this.route.snapshot.queryParams["token"];
    if(this.redirecttoken!=undefined){
      this.auth.saveToken(this.redirecttoken)
    }
    this.loadUserDetails()
    this.loadCorporates()
    this.loadAllCorpsData()
    this.loadMISAliasWise()
    this.loadMonthlyMIS()
    this.loadYearlyMIS()
    this.loadDailyMIS()
    //this.params = this.activatedRoute.snapshot.queryParams["msg"];
    // this.userservice.getUserDetails().subscribe(users => {
    //   this.currentUser = users['Data'];
    //   this.username=this.currentUser['firstname']+' '+this.currentUser['lastname'];
    //   this.rolename=this.currentUser['rolename']
    //   if(this.currentUser.tabpermissionids !=null){
    //   var str = this.currentUser.tabpermissionids;
    //   str = str.replace("pid=" ,"");
    //   str = str.replace(";cid=",",");
    //   str = str.replace(";","");
    //   var tab_arr = str.split(",");
    //   //this.isUnicard = this.checkValInArray("24",tab_arr)
      
    //   this.isUnisup = this.checkValInArray("27",tab_arr)
    //   this.isBulksup = this.checkValInArray("51",tab_arr)
    //   this.isApprsup = this.checkValInArray("74",tab_arr)
    //   this.isUnipay = this.checkValInArray("20",tab_arr)
    //   this.isApprpay = this.checkValInArray("75",tab_arr)
    //   this.isBulkpay = this.checkValInArray("21",tab_arr)

    //   if(this.isUnisup==true || this.isBulksup==true || this.isApprsup==true || this.isUnipay==true || this.isApprpay == true || this.isBulkpay == true){
    //     this.isPaysupstatus=true;
    //     this.isCardActivity=false;
    //   }else{
    //     this.isPaysupstatus=false;
    //     this.isCardActivity=true;
    //   }
    // }else{
    // this.isCardActivity=true;}


    //   });
  }

  checkValInArray(tabid:string , tab_array:string[]){
    return (tab_array.indexOf(tabid)==-1)?false:true;
}

private loadUserDetails(){
  this.vcn.getCurrentUser().then(users => {
    // console.log("USER INFO",users);
    this.vgc_url=vgc_path+'card-request-summary?token='+localStorage.getItem('token');

    console.log(this.vgc_url)
      if(users['data']['is_vcn'] == false && users['data']['is_sbm'] == true){
        window.open(this.vgc_url, '_self');
      }
    });
}

UploadFile(event){
  this.selectedFiles = event.target.files;
  this.filename = this.selectedFiles[0].name;
 
 
  this.vcn.uploadreqfile(this.selectedFiles[0]).subscribe(
    
    data=>{
    
     // this.router.navigate(['/card-upload-view']);
      // this.step2=true;
      // this.userData=data["Data"];
      // console.log(this.userData);
    },error=>{
      this.step2=false;
      console.log("Failed to Upload")
    }
  )

}
UploadDirectFile(event){
  this.spinner.show()
  this.selectedFiles = event.target.files;
  this.filename = this.selectedFiles[0].name;
  console.log(this.filename)
  console.log(this.filename.length)
 // console.log('selectedFiles: ' + event.target.files[0] );
 if(this.filename.length<70){
  // if(this.filename.includes('_')){
  //   if(this.filename.split('_')[0]==this.file_name_params){
  this.vcn.uploadreqfile(this.selectedFiles[0]).subscribe(
    
    data=>{
      this.step2=true;
      // this.userData=data["Data"];
      // console.log(data);
       localStorage.setItem('crdData',JSON.stringify(data))
       localStorage.setItem('corporate_id',this.corporate_id)
       localStorage.setItem('alias_id',this.alias_id)
       localStorage.setItem('template_id',this.template_id)
      localStorage.setItem('supplier_id',this.supplier_id)
       this.spinner.hide()
       this.router.navigate(['/card-upload-view']);
    },error=>{
      console.log(error.error.message)
      if(error.error.message=='File already exist!'){
        this.step2=false;
        this.spinner.hide()
        this.toastr.warning("Please change the filename, file with this filename already used before!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else{
        this.step2=false;
        this.spinner.hide()
        this.toastr.warning("Failed to upload!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
     
    }
  )
//   }else{
//     this.step2=false;
//     this.spinner.hide()
//     this.toastr.warning("Please change the file nameing convention as example shown!","Alert",{
//       timeOut:3000,
//       positionClass:'toast-top-center'
//       })
//   }
//  }else{
//   this.step2=false;
//   this.spinner.hide()
//   this.toastr.warning("Please change the file nameing convention as example shown!","Alert",{
//     timeOut:3000,
//     positionClass:'toast-top-center'
//     })
//  }
 }else{
  this.step2=false;
  this.spinner.hide()
  this.toastr.warning("Filename length should be of 70 characters only including extension!",'Alert',{
    timeOut:3000,
    positionClass:'toast-top-center'
    })
 }
 

}


loadAliasdd(){
  this.showAliaDD=true
}

// loadUploadFileOpt(aliasid){
//   for(var data of this.aliasdata){
//     if(data['id']==aliasid){
//       this.file_name_params=data['alias_name']
//       console.log(this.file_name_params)
//       this.showUploadFileOpt=true;
//     }
//   }
// }

loadUploadFileOpt(){
  this.file_name_params = null
    for(var data of this.aliasdata){
      if(data['id']==this.alias_id){
        this.file_name_params=data['alias_name']
        console.log(this.file_name_params)
      }
    }
  if(this.file_name_params != null && this.template_name_params != null && this.supplier_name_params != null) {
    this.showUploadFileOpt=true;
  } else {
    this.showUploadFileOpt=false;
  }
}
loadUploadFileOptTemplate(){
    this.template_name_params = null
    for(var data of this.templatedata){
      if(data['id']==this.template_id){
        this.template_name_params=data['name']
        console.log(this.template_name_params)
      }
    }
    if(this.file_name_params != null && this.template_name_params != null && this.supplier_name_params != null) {
      this.showUploadFileOpt=true;
    } else {
      this.showUploadFileOpt=false;
    }
}
loadUploadFileOptSupplier(){
    this.supplier_name_params = null
    for(var data of this.supplierdata){
      if(data['id']==this.supplier_id){
        this.supplier_name_params=data['name']
        console.log(this.supplier_name_params)
      }
    }
    if(this.file_name_params != null && this.template_name_params != null && this.supplier_name_params != null) {
      this.showUploadFileOpt=true;
    } else {
      this.showUploadFileOpt=false;
    }
}


private loadCorporates(){
  this.vcn.getCorporate().then(resp=>{
   
    if(resp['message']=='Corporates info' && resp['error']==null){
      this.corporateData=resp['data']
    }else{
      console.log("Something Went Wrong")
    }
  },error=>{
    console.log(error)
    console.log("Failed to Load Corporate Data")
  })
}
loadData(id){
  this.loadAliasbyCorpId(id)
  this.loadTemplatebyCorpId(id)
  this.loadSupplierbyCorpId(id)
}

loadAliasbyCorpId(id){
  this.spinner.show()
  this.vcn.getAliasByCorpId(id).then(resp=>{
   
    if(resp['message']=='Alias data' && resp['error']==null){
      this.aliasdata=resp['data']
      this.spinner.hide()
      this.loadAliasdd()
    }else{
      console.log('Something Went Wrong')
      this.spinner.hide()
    }
   
  },error=>{
    this.spinner.hide()
    console.log(error)
    console.log('Failed to load Alias')
  })
}

private loadTemplatebyCorpId(id){
  this.spinner.show()
  this.vcn.getTemplateByCorpId(id).then(resp=>{
   
    if(resp['msg']=='Tempaltes Found Successfully' && resp['statusCode']==200){
      this.templatedata=resp['log']
      console.log('data',this.templatedata)
      this.spinner.hide()
    }else{
      console.log('Something Went Wrong')
      this.spinner.hide()
    }
   
  },error=>{
    this.spinner.hide()
    console.log(error)
    console.log('Failed to load templates')
  })
}
private loadSupplierbyCorpId(id){
  this.spinner.show()
  this.vcn.getsupplierByCorpId(id).then(resp=>{
   
    if(resp['msg']=='Suppliers Found Successfully' && resp['statusCode']==200){
      this.supplierdata=resp['log']
      this.spinner.hide()
    }else{
      console.log('Something Went Wrong')
      this.spinner.hide()
    }
   
  },error=>{
    this.spinner.hide()
    console.log(error)
    console.log('Failed to load suppliers')
  })
}

private loadAllCorpsData(){
 
  this.vcn.getAllCorps().then(resp=>{
    console.log("Overall MIS")
    console.log(resp)
    if(resp['msg']=='Data received Overall Corporate'){
      this.corpMis=resp['data']
      this.corpWiseSummary={
        sumofAmount:resp['sumofAmount'],
        sumofCards:resp['sumofCards']
      }
    }else{
      this.corpMis=[]
    }
  },error=>{
    console.log(error)
  })
}

private loadMISAliasWise(){
  
  this.vcn.getDailyMISALiasWise().then(resp=>{
    console.log("Alias Wise MIS")
    console.log(resp)
    if(resp['msg']=='Data received Overall Alias'){
      this.aliasMIS=resp['data']
      this.aliasWiseSummary={
        sumofAmount:resp['sumofAmount'],
        sumofCards:resp['sumofCards']
      }
    }else{
      this.aliasMIS=[]
    }

  },error=>{
    console.log(error)
  })
}

private loadDailyMIS(){
 
  this.vcn.getDailyMIS().then(resp=>{
    console.log("DAily MIS")
    console.log(resp)
    if(resp['msg']=='Data received for Todays Corporate Transaction'){
      this.dailyMIS=resp['data']
      this.dailyMISSummary={
        sumofAmount:resp['sumofAmount'],
        sumofCards:resp['sumofCards']
      }
    }else{
      this.dailyMIS=[]
    }
  },error=>{
    console.log(error)
  })
}

private loadMonthlyMIS(){

  this.vcn.getMonthlyMIS().then(resp=>{
    console.log("Monthly MIS")
    console.log(resp)
    if(resp['msg']=='Data received for Monthly Corporate Transaction'){
      this.monthlyMIS=resp['data']
      this.monthlyMISSummary={
        "curr_sumofCards":resp['curr_sumofCards'],
        "curr_sumofAmount":resp['curr_sumofAmount'],
        "prev_sumofCards":resp['prev_sumofCards'],
        "prev_sumofAmount":resp['prev_sumofAmount']
      }
      console.log(this.monthlyMIS)
    }else{
      this.monthlyMIS=[]
    }
  },error=>{
    console.log(error)
  })
}


private loadYearlyMIS(){
 
  this.vcn.getYearlyMIS().then(resp=>{
    console.log("Yearly MIS")
    console.log(resp)
    if(resp['msg']=='Data received for Yearly Corporate Transaction'){
      this.yearlyMIS=resp['data']
      this.yearlyMISSummary={
        "curr_sumofCards":resp['curr_sumofCards'],
        "curr_sumofAmount":resp['curr_sumofAmount'],
        "prev_sumofCards":resp['prev_sumofCards'],
        "prev_sumofAmount":resp['prev_sumofAmount']
      }
    }else{
      this.yearlyMIS=[]
    }
  },error=>{
    console.log(error)
  })
}

}
