import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/_api/auth.service';
import { FirstLoginService } from 'src/app/_api/first-login.service';
import { LoaderService } from 'src/app/_api/loader.service';

@Component({
  selector: 'app-first-time-otp',
  templateUrl: './first-time-otp.component.html',
  styleUrls: ['./first-time-otp.component.css']
})
export class FirstTimeOTPComponent implements OnInit {
  submitted = false;
  otpData:any = {};
  otp:number;
  otpstring:string;
  otp1:string;
  otp2:string;
  otp3:string;
  otp4:string;
  otp5:string;
  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private firstLogin:FirstLoginService,
    private loaderService: LoaderService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  otpPost(){
    this.spinner.show();
    this.otpstring=this.otp1+this.otp2+this.otp3+this.otp4+this.otp5
    if(this.otpstring==undefined||this.otpstring==null||this.otpstring==""){
      this.spinner.hide();
      this.toastr.warning("Please Enter OTP!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }else if(this.otpstring.length<5){
      this.spinner.hide();
      this.toastr.warning("Please Enter 5 Digit OTP!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }else{
      this.firstLogin.otpValidation(this.otpstring).then(resp=>{
        this.spinner.hide();
        if(resp['message']=='Otp Validated Successfully'){
          this.firstLogin.saveToken(resp['data']['token'])
          this.router.navigate(['/first-time-set-pwd']);
          this.spinner.hide();
        }else{
          this.spinner.hide();
          this.toastr.warning("Something went wrong, please contact Aquapay Support!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
      },error=>{
        this.spinner.hide();
          this.toastr.warning("Failed to validate OTP, please contact Aquapay Support!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        console.log(error)
      })
   
   
    }
  }
}
