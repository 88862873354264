import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
const headers =  new HttpHeaders()
  .set('content-type', 'application/json')
const path = new Config().getKarzaURL();
const onboardingURL = new Config().getOnboadingURL();
@Injectable({
  providedIn: 'root'
})
export class KarzaApiService {

  constructor(private http: HttpClient) { }

  validatePanNO(data : any): Promise<any>{
    console.log(data);
    let promise = new Promise((resolve,reject)=>{
      this.http.post(path+"karza/pan-validate", data, { 'headers': headers }).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  validateGSTIN(data : any): Promise<any>{
    console.log(data);
    let promise = new Promise((resolve,reject)=>{
      this.http.post(path+"karza/gstin-validate", data, { 'headers': headers }).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  uploadContractForEsign(data : any): Promise<any>{
    console.log(data);
    let promise = new Promise((resolve,reject)=>{
      this.http.post(onboardingURL+"karza/upload-contract", data, { 'headers': headers }).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }
}
