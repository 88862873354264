import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {AuthService} from '../../_api/auth.service'
import {FormControl, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr'
import { ResetPasswordService } from '../../_api/reset-password.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.css']
})
export class NewLoginComponent implements OnInit {
  returnUrl: string;
  submitted = false;
  loginData:any = {};
  
  display='none';
  orgnames:any=[];
  forgotdetail : any ={};
  id:number=0;
  orgid:any;
  email:any;
  orglist:any=[];
  showorg:boolean=false;
  constructor(private authService: AuthService,private route: ActivatedRoute,private router: Router,private toastr: ToastrService,private resetService : ResetPasswordService) { }
  

  ngOnInit() {
  }
  openModalDialog(){
    this.display='block'; //Set block css
  }
  closeModalDialog(){
    this.display='none'; //set none css after close dialog
  }
   loginPost() {
    localStorage.removeItem('token');
    this.submitted = true;
   
    if(this.loginData['username']==undefined || this.loginData['username']==""){
      this.toastr.warning("Please Enter User Name!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }else if(this.loginData['password']==undefined || this.loginData['password']==""){
      this.toastr.warning("Please Enter Password!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }else if((this.loginData['username']==undefined || this.loginData['username']=="") && (this.loginData['password']==undefined || this.loginData['password']=="")){
      this.toastr.warning("Please Enter User Name and Password!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }else{
      //this.loginData['password']=btoa(this.loginData['password'])
     // this.loginData['password']=btoa(this.loginData['password']);
     // console.log(this.loginData['password']);
      
    this.authService.loginUser(this.loginData)
    }
  }
  // private getAllOrganisations(){
  //   this.authService.getAllOrgNames().then(res=>{
  //     this.orgnames=res['data']  
  //     console.log(this.orgnames)  
  //     const sorted = this.orgnames.sort((a, b) => a.orgname > b.orgname ? 1 : -1);  
   
  //  // console.log(this.orglist)
  //   },error=>{
  //     console.log(error)
  //   })
  // }

  // forgot(){
  //   this.forgotdetail={
  //     "email":this.email
  //   }

  //   console.log(this.forgotdetail)
  //  this.authService.getrestpasswordlink(this.forgotdetail).subscribe(res=>{
  //    console.log(res)
  //    if(res['msg']=='Reset link sent to email'){
  //    this.router.navigate(['/']);
  //    this.toastr.success("Password reset link has been sent on your mail!",'Alert',{
  //     timeOut:3000,
  //     positionClass:'toast-top-center'
  //     })
  //   }else if(res['msg']=='Email Not exist'){
  //     this.toastr.error("Email does not exist!",'Alert',{
  //       timeOut:3000,
  //       positionClass:'toast-top-center'
  //       })
  //   }else if(res['msg']=='Multiple email present'){
  //     this.toastr.error("Email exists multiple times, please contact aquapay support team for further support!",'Alert',{
  //       timeOut:3000,
  //       positionClass:'toast-top-center'
  //       })
  //   }
  //  },error=>{
  //    console.log(error)
  //    this.router.navigate(['/']);
  //    this.toastr.error("Failed to send password reset link!",'Alert',{
  //     timeOut:3000,
  //     positionClass:'toast-top-center'
  //     })
  //  })
  //  // console.log(this.forgotdetail)
  //  // console.log("Dataa")
  // }

  getorglist(email){
    this.authService.getorganisationbyemail(email).then(data=>{
      if(data['data'].length>0 && data['data']!=undefined){
        this.showorg=true
        this.orglist=data['data']
        console.log(this.orglist)
      }
      
    })
     }

     reqpwd(){
      this.authService.requestPassword(this.email,this.orgid);
    }
  
    selectOption(id: number){
  this.orgid=id;
    }
}
