import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AwsuploadService } from 'src/app/_api/awsupload.service';
import { LoaderService } from 'src/app/_api/loader.service';
import { OrgserviceService } from 'src/app/_api/orgservice.service';
import { UploadDocsService } from 'src/app/_api/upload-docs.service';
declare var jquery:any;
declare var $: any;
@Component({
  selector: 'app-upload-docs',
  templateUrl: './upload-docs.component.html',
  styleUrls: ['./upload-docs.component.css']
})
export class UploadDocsComponent implements OnInit {
  selectedFiles: FileList;
  GSTfilename:string="Click to Browse";
  LOPfilename:string="Click to Browse";
  COAfilename:string="Click to Browse";
  PANfilename:string="Click to Browse";
  Aadharfilename:string="Click to Browse";
  GSTFile: any;
  LOPFile: any;
  COAFile: any;
  PANFile: any;
  AadharFile: any;
  GSTKey: any;
  LOPKey: any;
  COAKey: any;
  PANKey: any;
  AadharKey: any;
  uploadedGSTStatus:any = 'Pending';
  uploadedPANStatus:any = 'Pending';
  uploadedLOPStatus:any = 'Pending';
  uploadedCOAStatus:any = 'Pending';
  uploadedAadharStatus:any = 'Pending';
  corporateType:any;
  uploadedData:{} = {};
  partnershipDocObject = {
    "companyGstCertificate":{
      "type": "companyGstCertificate",
      "status":"Pending"
    },
    "companyPan":{
      "type": "companyPan",
      "status":"Pending"
    },
    "listOfPartners":{
      "type": "listOfPartners",
      "status":"Pending"
    },
    "registrationCertificate":{
      "type": "registrationCertificate",
      "status":"Pending"
    },
    "asDetails":{
      "type": "asDetails",
      "status":"Pending"
    },
    "partnershipDeed":{
      "type": "partnershipDeed",
      "status":"Pending"
    },
    "companyAuthorizationLetter":{
      "type": "companyAuthorizationLetter",
      "status":"Pending"
    }
  };

  privateltdDocObject = {
    "companyGstCertificate":{
      "type": "companyGstCertificate",
      "status":"Pending"
    },
    "companyPan":{
      "type": "companyPan",
      "status":"Pending"
    },
    "moa":{
      "type": "moa",
      "status":"Pending"
    },
    "aoa":{
      "type": "aoa",
      "status":"Pending"
    },
    "certificateOfIncorporation":{
      "type": "certificateOfIncorporation",
      "status":"Pending"
    },
    "allDirectorsPanAndAadhar":{
      "type": "allDirectorsPanAndAadhar",
      "status":"Pending"
    },
    "listOfDirectors":{
      "type": "listOfDirectors",
      "status":"Pending"
    },
    "asDetails":{
      "type": "asDetails",
      "status":"Pending"
    },
    "companyAuthorizationLetter":{
      "type": "companyAuthorizationLetter",
      "status":"Pending"
    }
  };

  proprietorshipDocObject = {
    "companyGstCertificate":{
      "type": "companyGstCertificate",
      "status":"Pending"
    },
    "companyPan":{
      "type": "companyPan",
      "status":"Pending"
    },
    "gumastaOrMsme":{
      "type": "gumastaOrMsme",
      "status":"Pending"
    },
    "proprietorDetails":{
      "type": "proprietorDetails",
      "status":"Pending"
    },
    "asDetails":{
      "type": "asDetails",
      "status":"Pending"
    },
    "companyAuthorizationLetter":{
      "type": "companyAuthorizationLetter",
      "status":"Pending"
    }
  };

  publicDocObject = {
    "companyGstCertificate":{
      "type": "companyGstCertificate",
      "status":"Pending"
    },
    "companyPan":{
      "type": "companyPan",
      "status":"Pending"
    },
    "moa":{
      "type": "moa",
      "status":"Pending"
    },
    "aoa":{
      "type": "aoa",
      "status":"Pending"
    },
    "certificateOfIncorporation":{
      "type": "certificateOfIncorporation",
      "status":"Pending"
    },
    "allDirectorsPanAndAadhar":{
      "type": "allDirectorsPanAndAadhar",
      "status":"Pending"
    },
    "listOfDirectors":{
      "type": "listOfDirectors",
      "status":"Pending"
    },
    "asDetails":{
      "type": "asDetails",
      "status":"Pending"
    },
    "companyAuthorizationLetter":{
      "type": "companyAuthorizationLetter",
      "status":"Pending"
    }
  };

  LLPDocObject = {
    "companyGstCertificate":{
      "type": "companyGstCertificate",
      "status":"Pending"
    },
    "companyPan":{
      "type": "companyPan",
      "status":"Pending"
    },
    "listOfPartners":{
      "type": "listOfPartners",
      "status":"Pending"
    },
    "registrationCertificate":{
      "type": "registrationCertificate",
      "status":"Pending"
    },
    "asDetails":{
      "type": "asDetails",
      "status":"Pending"
    },
    "partnershipDeed":{
      "type": "partnershipDeed",
      "status":"Pending"
    },
    "companyAuthorizationLetter":{
      "type": "companyAuthorizationLetter",
      "status":"Pending"
    }
  };
  mimes = {
    'jpeg': 'data:image/jpeg;base64,'
};
result:any;
corpId:any;
orgData:any;
type1 = false;
type2 = false;
type3 = false;
type4 = false;
type5 = false;
  constructor(private spinner: NgxSpinnerService,private orgService: OrgserviceService,private awsService:AwsuploadService ,private http: HttpClient, private loaderService: LoaderService, private uploadDocService: UploadDocsService, private toastr: ToastrService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.spinner.show()
    this.orgService.getOrganisationData().then(data=>{
      this.spinner.hide()
      this.orgData = data["data"];
      this.corporateType = this.orgData.org_type;
      if(this.orgData.org_type == "Partnership"){
        this.type1 = true;
        this.type2 = false;
        this.type3 = false;
        this.type4 = false;
        this.type5 = false;
      } else if(this.orgData.org_type == "Private Ltd"){
        this.type1 = false;
        this.type2 = true;
        this.type3 = false;
        this.type4 = false;
        this.type5 = false;
      } else if(this.orgData.org_type == "Proprietorship") {
        this.type1 = false;
        this.type2 = false;
        this.type3 = true;
        this.type4 = false;
        this.type5 = false;
      } else if(this.orgData.org_type == "Public"){
        this.type1 = false;
        this.type2 = false;
        this.type3 = false;
        this.type4 = true;
        this.type5 = false;
      } else if(this.orgData.org_type == "LLP") {
        this.type1 = false;
        this.type2 = false;
        this.type3 = false;
        this.type4 = false;
        this.type5=true;
      }
      this.awsService.list().then(resp=>{
        if(resp.status == "Success") {
          console.log(resp);
          var objects = resp.data;
          objects.map(element => {
            if(element.status == "Success") {
              if(this.type1){
                this.partnershipDocObject[element.type]["name"] = element.name;
                this.partnershipDocObject[element.type]["status"] = element.status;
                this.partnershipDocObject[element.type]["key"] = element.key;
              } else if(this.type2){
                this.privateltdDocObject[element.type]["name"] = element.name;
                this.privateltdDocObject[element.type]["status"] = element.status;
                this.privateltdDocObject[element.type]["key"] = element.key;
              } else if(this.type3){
                this.proprietorshipDocObject[element.type]["name"] = element.name;
                this.proprietorshipDocObject[element.type]["status"] = element.status;
                this.proprietorshipDocObject[element.type]["key"] = element.key;
              } else if(this.type4){
                this.publicDocObject[element.type]["name"] = element.name;
                this.publicDocObject[element.type]["status"] = element.status;
                this.publicDocObject[element.type]["key"] = element.key;
              } else if(this.type5){
                this.LLPDocObject[element.type]["name"] = element.name;
                this.LLPDocObject[element.type]["status"] = element.status;
                this.LLPDocObject[element.type]["key"] = element.key;
              }
              
            }
          });
        } else {
          this.toastr.warning("Something went wrong. Please try again", 'Alert', {
                  positionClass:'toast-top-center',
                  timeOut:3000
                })
        }
      })
    })
    
  }
  // checkForm(){
  //   var disableObj = false;
  //   if(this.partnershipDocObject.companyGstCertificate.status == "Pending" || 
  //   this.partnershipDocObject.companyPan.status == "Pending" ||
  //   this.partnershipDocObject.listOfPartners.status == "Pending" ||
  //   this.partnershipDocObject.shareholdingPattern.status == "Pending" ||
  //   this.partnershipDocObject.partnershipDeed.status == "Pending" ||
  //   this.partnershipDocObject.registrationCertificate.status == "Pending" ) {
  //     disableObj = true
  //   }
  //   console.log(disableObj)
  //   return disableObj;
  // }

  // checkFormPrivateLtd(){
  //   var disableObj = false;
  //   if(this.privateltdDocObject.companyGstCertificate.status == "Pending" || 
  //   this.privateltdDocObject.companyPan.status == "Pending" ||
  //   this.privateltdDocObject.moa.status == "Pending" ||
  //   this.privateltdDocObject.aoa.status == "Pending" ||
  //   this.privateltdDocObject.certificateOfIncorporation.status == "Pending" ||
  //   this.privateltdDocObject.allDirectorsPanAndAadhar.status == "Pending" ||
  //   this.privateltdDocObject.beneficiaryOwnersDeclation.status == "Pending"||
  //   this.privateltdDocObject.asDetails.status == "Pending") {
  //     disableObj = true
  //   }
  //   console.log(disableObj)
  //   return disableObj;
  // }

   encode(data)
      {
          var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
          return btoa(str).replace(/.{76}(?=.)/g,'$&\n');
      }

    getUrlByFileName(file,mimeType) {
        this.result =  mimeType + this.encode(file.Body);
        // document.querySelector('img').src = this.result;
        // document.getElementById("PAN").src = this.result;
        $("#PAN").attr('src', this.result);
      }

      showDocuList($event){
        console.log("SELECTED",$event.target.value)
        this.corporateType = $event.target.value
        if($event.target.value == "Partnership"){
          this.type1 = true;
          this.type2 = false;
          this.type3 = false;
          this.type4 = false;
          this.type5 = false;
        } else if($event.target.value == "Private Ltd"){
          this.type1 = false;
          this.type2 = true;
          this.type3 = false;
          this.type4 = false;
          this.type5 = false;
        } else if($event.target.value == "Proprietorship") {
          this.type1 = false;
          this.type2 = false;
          this.type3 = true;
          this.type4 = false;
          this.type5 = false;
        } else if($event.target.value == "Public"){
          this.type1 = false;
          this.type2 = false;
          this.type3 = false;
          this.type4 = true;
          this.type5 = false;
        } else if($event.target.value == "LLP"){
          this.type1 = false;
          this.type2 = false;
          this.type3 = false;
          this.type4 = false;
          this.type5 = true;
        }
        this.spinner.show()
        this.orgService.setOrganizationType({"org_type": $event.target.value}).then(resp=>{
          this.spinner.hide()
          if(resp.status != "Success") {
            this.toastr.error(resp.message, 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          }
        },error=>{
          this.toastr.error(error.message, 'Alert', {
            positionClass:'toast-top-center',
            timeOut:3000
          })
        })
      }

  uploadStep1(event,type){
    if(event.target.files){
      console.log(event.target.files[0].type)
      if(event.target.files[0].size / 1000000 < 5){
      if(event.target.files[0].type == "application/pdf" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png"){
      this.GSTFile = event.target.files;
      console.log("TYPE",type)
      this.spinner.show()
      this.awsService.uploadFile(this.GSTFile.item(0),this.orgData.name).then(async resp=>{
        this.spinner.hide()
        if(resp.status == "Success") {
          console.log(resp);
          if(this.partnershipDocObject[type]["status"] == "Success") {
            this.awsService.deleteDocument(this.partnershipDocObject[type]["key"]).catch(err=>console.log(err));
          }
          this.partnershipDocObject[type]["status"] = "Success";
          this.partnershipDocObject[type]["key"] = resp["data"]["key"];
          this.partnershipDocObject[type]["name"] = this.GSTFile.item(0).name;
          this.awsService.addDocumentRecord(this.partnershipDocObject[type]).then(respObject=>{
            if(respObject.status == "Success")
            {
              this.toastr.success("Document Uploaded Successfully", 'Alert', {
                positionClass:'toast-top-center',
                timeOut:3000
              })
            } else {
              this.toastr.error("Something went wrong. Please try again", 'Alert', {
                positionClass:'toast-top-center',
                timeOut:3000
              })
            }
          })
        } else {
          this.toastr.warning("Something went wrong. Please try again", 'Alert', {
                  positionClass:'toast-top-center',
                  timeOut:3000
                })
        }
      },err=>{
        this.spinner.hide()
        this.toastr.warning("Something went wrong. Please try again", 'Alert', {
          positionClass:'toast-top-center',
          timeOut:3000
        })
      })
    } else {
      this.toastr.error("Please upload a jpeg, png or pdf file!", 'Alert', {
        positionClass:'toast-top-center',
        timeOut:3000
      })
    }
  } else {
    this.toastr.error("File size cannot exceed 5MB!", 'Alert', {
      positionClass:'toast-top-center',
      timeOut:3000
    })
  }
  } else {
    this.toastr.warning("Please select a file!", 'Alert', {
      positionClass:'toast-top-center',
      timeOut:3000
    })
  }
  }

  uploadStep2(event,type){
    if(event.target.files){
      console.log(event.target.files[0].type)
      if(event.target.files[0].size / 1000000 < 5){
      if(event.target.files[0].type == "application/pdf" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png"){
    this.GSTFile = event.target.files;
    // this.GSTfilename = this.GSTFile.name;
    console.log("TYPE",type)
    this.spinner.show()
    this.awsService.uploadFile(this.GSTFile.item(0),this.orgData.name).then(async resp=>{
      this.spinner.hide()
      if(resp.status == "Success") {
        console.log(resp);
        if(this.privateltdDocObject[type]["status"] == "Success") {
          this.awsService.deleteDocument(this.privateltdDocObject[type]["key"]).catch(err=>console.log(err));
        }
        this.privateltdDocObject[type]["status"] = "Success";
        this.privateltdDocObject[type]["key"] = resp["data"]["key"];
        this.privateltdDocObject[type]["name"] = this.GSTFile.item(0).name;
        this.awsService.addDocumentRecord(this.privateltdDocObject[type]).then(respObject=>{
          if(respObject.status == "Success")
          {
            this.toastr.success("Document Uploaded Successfully", 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          } else {
            this.toastr.error("Something went wrong. Please try again", 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          }
        })
      } else {
        this.toastr.warning("Something went wrong. Please try again", 'Alert', {
                positionClass:'toast-top-center',
                timeOut:3000
              })
      }
    },err=>{
      this.spinner.hide()
      this.toastr.warning("Something went wrong. Please try again", 'Alert', {
        positionClass:'toast-top-center',
        timeOut:3000
      })
    })
  } else {
    this.toastr.error("Please upload a jpeg, png or pdf file!", 'Alert', {
      positionClass:'toast-top-center',
      timeOut:3000
    })
  }
} else {
  this.toastr.error("File size cannot exceed 5MB!", 'Alert', {
    positionClass:'toast-top-center',
    timeOut:3000
  })
}
} else {
  this.toastr.warning("Please select a file!", 'Alert', {
    positionClass:'toast-top-center',
    timeOut:3000
  })
}
  }

  uploadStep3(event,type){
    if(event.target.files){
      console.log(event.target.files[0].type)
      if(event.target.files[0].size / 1000000 < 5){
      if(event.target.files[0].type == "application/pdf" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png"){
    this.GSTFile = event.target.files;
    // this.GSTfilename = this.GSTFile.name;
    console.log("TYPE",type)
    this.spinner.show()
    this.awsService.uploadFile(this.GSTFile.item(0),this.orgData.name).then(async resp=>{
      this.spinner.hide()
      if(resp.status == "Success") {
        console.log(resp);
        if(this.proprietorshipDocObject[type]["status"] == "Success") {
          this.awsService.deleteDocument(this.proprietorshipDocObject[type]["key"]).catch(err=>console.log(err));
        }
        this.proprietorshipDocObject[type]["status"] = "Success";
        this.proprietorshipDocObject[type]["key"] = resp["data"]["key"];
        this.proprietorshipDocObject[type]["name"] = this.GSTFile.item(0).name;
        this.awsService.addDocumentRecord(this.proprietorshipDocObject[type]).then(respObject=>{
          if(respObject.status == "Success")
          {
            this.toastr.success("Document Uploaded Successfully", 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          } else {
            this.toastr.error("Something went wrong. Please try again", 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          }
        })
      } else {
        this.toastr.warning("Something went wrong. Please try again", 'Alert', {
                positionClass:'toast-top-center',
                timeOut:3000
              })
      }
    },err=>{
      this.spinner.hide()
      this.toastr.warning("Something went wrong. Please try again", 'Alert', {
        positionClass:'toast-top-center',
        timeOut:3000
      })
    })
  } else {
    this.toastr.error("Please upload a jpeg, png or pdf file!", 'Alert', {
      positionClass:'toast-top-center',
      timeOut:3000
    })
  }
} else {
  this.toastr.error("File size cannot exceed 5MB!", 'Alert', {
    positionClass:'toast-top-center',
    timeOut:3000
  })
}
} else {
  this.toastr.warning("Please select a file!", 'Alert', {
    positionClass:'toast-top-center',
    timeOut:3000
  })
}
  }

  uploadStep4(event,type){
    if(event.target.files){
      console.log(event.target.files[0].type)
      if(event.target.files[0].size / 1000000 < 5){
      if(event.target.files[0].type == "application/pdf" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png"){
    this.GSTFile = event.target.files;
    // this.GSTfilename = this.GSTFile.name;
    console.log("TYPE",type)
    this.spinner.show()
    this.awsService.uploadFile(this.GSTFile.item(0),this.orgData.name).then(async resp=>{
      this.spinner.hide()
      if(resp.status == "Success") {
        console.log(resp);
        if(this.publicDocObject[type]["status"] == "Success") {
          this.awsService.deleteDocument(this.publicDocObject[type]["key"]).catch(err=>console.log(err));
        }
        this.publicDocObject[type]["status"] = "Success";
        this.publicDocObject[type]["key"] = resp["data"]["key"];
        this.publicDocObject[type]["name"] = this.GSTFile.item(0).name;
        this.awsService.addDocumentRecord(this.publicDocObject[type]).then(respObject=>{
          if(respObject.status == "Success")
          {
            this.toastr.success("Document Uploaded Successfully", 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          } else {
            this.toastr.error("Something went wrong. Please try again", 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          }
        })
      } else {
        this.toastr.warning("Something went wrong. Please try again", 'Alert', {
                positionClass:'toast-top-center',
                timeOut:3000
              })
      }
    },err=>{
      this.spinner.hide()
      this.toastr.warning("Something went wrong. Please try again", 'Alert', {
        positionClass:'toast-top-center',
        timeOut:3000
      })
    })
  } else {
    this.toastr.error("Please upload a jpeg, png or pdf file!", 'Alert', {
      positionClass:'toast-top-center',
      timeOut:3000
    })
  }
} else {
  this.toastr.error("File size cannot exceed 5MB!", 'Alert', {
    positionClass:'toast-top-center',
    timeOut:3000
  })
}
} else {
  this.toastr.warning("Please select a file!", 'Alert', {
    positionClass:'toast-top-center',
    timeOut:3000
  })
}
  }

  uploadStep5(event,type){
    if(event.target.files){
      console.log(event.target.files[0].type)
      if(event.target.files[0].size / 1000000 < 5){
      if(event.target.files[0].type == "application/pdf" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png"){
    this.GSTFile = event.target.files;
    // this.GSTfilename = this.GSTFile.name;
    console.log("TYPE",type)
    this.spinner.show()
    this.awsService.uploadFile(this.GSTFile.item(0),this.orgData.name).then(async resp=>{
      this.spinner.hide()
      if(resp.status == "Success") {
        console.log(resp);
        if(this.LLPDocObject[type]["status"] == "Success") {
          this.awsService.deleteDocument(this.LLPDocObject[type]["key"]).catch(err=>console.log(err));
        }
        this.LLPDocObject[type]["status"] = "Success";
        this.LLPDocObject[type]["key"] = resp["data"]["key"];
        this.LLPDocObject[type]["name"] = this.GSTFile.item(0).name;
        this.awsService.addDocumentRecord(this.LLPDocObject[type]).then(respObject=>{
          if(respObject.status == "Success")
          {
            this.toastr.success("Document Uploaded Successfully", 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          } else {
            this.toastr.error("Something went wrong. Please try again", 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          }
        })
      } else {
        this.toastr.warning("Something went wrong. Please try again", 'Alert', {
                positionClass:'toast-top-center',
                timeOut:3000
              })
      }
    },err=>{
      this.spinner.hide()
      this.toastr.warning("Something went wrong. Please try again", 'Alert', {
        positionClass:'toast-top-center',
        timeOut:3000
      })
    })
  } else {
    this.toastr.error("Please upload a jpeg, png or pdf file!", 'Alert', {
      positionClass:'toast-top-center',
      timeOut:3000
    })
  }
} else {
  this.toastr.error("File size cannot exceed 5MB!", 'Alert', {
    positionClass:'toast-top-center',
    timeOut:3000
  })
}
} else {
  this.toastr.warning("Please select a file!", 'Alert', {
    positionClass:'toast-top-center',
    timeOut:3000
  })
}
  }
  // uploadPAN(event){
  //   this.PANFile = event.target.files;
  //   this.PANfilename = this.PANFile.name;
  //   console.log(this.PANFile)
  //   this.awsService.uploadFile(this.PANFile.item(0)).then(resp=>{
  //     if(resp.status == "Success") {
  //       console.log(resp);
  //       this.uploadedPANStatus = "Success";
  //       this.PANKey = resp["data"]["key"];
  //     } else {
  //       this.toastr.warning("Something went wrong. Please try again", 'Alert', {
  //               positionClass:'toast-top-center',
  //               timeOut:3000
  //             })
  //     }
  //   })
  //   // this.uploadDocService.uploadPAN(this.selectedFiles[0]).then(
  //   //   resp => {
  //   //     this.uploadedPANStatus = resp['msg'];
  //   //   }
  //   // ).catch(err =>{
  //   //     this.uploadedPANStatus = err['error']['err']['err'];
  //   //     this.toastr.warning(err['error']['err']['err'], 'Alert', {
  //   //       positionClass:'toast-top-center',
  //   //       timeOut:3000
  //   //     })
  //   // })
  // }

  uploadMOA(event){
    this.LOPFile = event.target.files;
    this.LOPfilename = this.selectedFiles[0].name;
    // this.uploadDocService.uploadMOA(this.selectedFiles[0]).then(
    //   resp => {
    //     this.uploadedMOAStatus = resp['msg']
    //   }
    // ).catch(err =>{
    //     this.uploadedMOAStatus = err['error']['err']['err'];
    //     this.toastr.warning(err['error']['err']['err'], 'Alert', {
    //       positionClass:'toast-top-center',
    //       timeOut:3000
    //     })
    // })
  }
  
  uploadCOA(event){
    this.COAFile = event.target.files;
    this.COAfilename = this.selectedFiles[0].name;
    // this.uploadDocService.uploadCOA(this.selectedFiles[0]).then(
    //   resp => {
    //     console.log(resp)
    //     this.uploadedCOAStatus = resp['msg'];
    //   }
    // ).catch(err =>{
    //     this.uploadedCOAStatus = err['error']['err']['err'];
    //     this.toastr.warning(err['error']['err']['err'], 'Alert', {
    //       positionClass:'toast-top-center',
    //       timeOut:3000
    //     })
    // })
  }

  uploadAadhar(event){
    this.AadharFile = event.target.files;
    this.Aadharfilename = this.selectedFiles[0].name;
    // this.uploadDocService.uploadAadhar(this.selectedFiles[0]).then(
    //   resp => {
    //     this.uploadedAadharStatus = resp['msg'];
    //   }
    // ).catch(err =>{
    //     this.uploadedAadharStatus = err['error']['err']['err'];
    //     this.toastr.warning(err['error']['err']['err'], 'Alert', {
    //       positionClass:'toast-top-center',
    //       timeOut:3000
    //     })
    // })
  }

  validateData(){
    var validateObject = false;
    var message = "Please upload the pending documents";
    validateObject = this.validateByType()
    if(validateObject){
      this.router.navigate(['/org-reg'],{queryParams:{orgId:this.orgData.id}})
    } else {
      this.toastr.warning(message, 'Alert', {
        positionClass:'toast-top-center',
        timeOut:3000
      })
    }
  }

  validateByType(){
    var object = true;
    if(this.type1){
      if(this.partnershipDocObject.companyGstCertificate.status == "Pending" || 
      this.partnershipDocObject.companyPan.status == "Pending" ||
      this.partnershipDocObject.listOfPartners.status == "Pending" ||
      this.partnershipDocObject.partnershipDeed.status == "Pending" ||
      this.partnershipDocObject.registrationCertificate.status == "Pending"||
      this.partnershipDocObject.asDetails.status == "Pending" ||
      this.partnershipDocObject.companyAuthorizationLetter.status == "Pending"){
        object=false;
      }
      return object;
    } else if(this.type2){
      if(this.privateltdDocObject.companyGstCertificate.status == "Pending" || 
      this.privateltdDocObject.companyPan.status == "Pending" ||
      this.privateltdDocObject.moa.status == "Pending" ||
      this.privateltdDocObject.aoa.status == "Pending" ||
      this.privateltdDocObject.certificateOfIncorporation.status == "Pending" ||
      this.privateltdDocObject.allDirectorsPanAndAadhar.status == "Pending"||
      this.privateltdDocObject.listOfDirectors.status == "Pending"||
      this.privateltdDocObject.asDetails.status == "Pending"||
      this.privateltdDocObject.companyAuthorizationLetter.status == "Pending"){
        object=false;
      }
      return object;
    } else if(this.type3){
      if(this.proprietorshipDocObject.companyPan.status == "Pending" ||
      this.proprietorshipDocObject.gumastaOrMsme.status == "Pending" ||
      this.proprietorshipDocObject.proprietorDetails.status == "Pending" ||
      this.proprietorshipDocObject.asDetails.status == "Pending"||
      this.proprietorshipDocObject.companyAuthorizationLetter.status == "Pending"){
        object=false;
      }
      return object;
    } else if(this.type4){
      if(this.publicDocObject.companyGstCertificate.status == "Pending" || 
      this.publicDocObject.companyPan.status == "Pending" ||
      this.publicDocObject.moa.status == "Pending" ||
      this.publicDocObject.aoa.status == "Pending" ||
      this.publicDocObject.certificateOfIncorporation.status == "Pending" ||
      this.publicDocObject.allDirectorsPanAndAadhar.status == "Pending"||
      this.publicDocObject.listOfDirectors.status == "Pending"||
      this.publicDocObject.asDetails.status == "Pending"||
      this.publicDocObject.companyAuthorizationLetter.status == "Pending"){
        object=false;
      }
      return object;
    } else if(this.type5){
      if(this.LLPDocObject.companyGstCertificate.status == "Pending" || 
      this.LLPDocObject.companyPan.status == "Pending" ||
      this.LLPDocObject.listOfPartners.status == "Pending" ||
      this.LLPDocObject.partnershipDeed.status == "Pending" ||
      this.LLPDocObject.registrationCertificate.status == "Pending"||
      this.LLPDocObject.asDetails.status == "Pending"||
      this.LLPDocObject.companyAuthorizationLetter.status == "Pending"){
        object=false;
      }
      return object;
    }
  }

}
