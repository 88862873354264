import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
declare var loadAsPiCharts : any;
import{CardService} from '../../_api/card.service'
import { Router } from '@angular/router';
import{LoaderService} from '../../_api/loader.service';
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-unitarycard',
  templateUrl: './unitarycard.component.html',
  styleUrls: ['./unitarycard.component.css']
})
export class UnitarycardComponent implements OnInit {
  cardData: any = {};
  constructor(private router: Router,private cardservice: CardService,private loaderService: LoaderService,private toastr: ToastrService) { }

  ngOnInit() {

    this.cardData['digits']={};
    $(".submit").on("click", function () {
      $(".supplink").addClass("active");
      $(".supplink").siblings(".active").removeClass("active");
    });

   
    
    $('.inputstyledot').bind('keyup', function(event) {
      var val=$(this).val();
      if(val != ''){
        var parId=$(this).parent().attr('id');
        if(parId == 'par1') {
          $(this).parent().next().find('input').focus();
        }else{
          $(this).parent().parent().next().find('input:first').focus();
        }
       }
    });


   //---------------------------------------Datepicker js start--------------------------------------------

   loadAsPiCharts();
    $('.example2').datepicker({
      autoclose: true,
      format: "dd M yy"
  });
  }
  onSubmit() {
    this.loaderService.display(true);
    var cardno_arr =[];
    for(var key in this.cardData['digits'])
    cardno_arr.push(this.cardData['digits'][key]);
    this.cardData['digits'] = cardno_arr.join("");
    this.cardservice.registercard(this.cardData).subscribe(
      data=>{
        console.log(data)
        if(data['code']=='CONFLICT'){
          this.loaderService.display(false);
          this.toastr.warning("Card is already Exist!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }else{
        this.loaderService.display(false);
       this.router.navigate(['/main/successmsg'],{queryParams:{msg:'cardsuccess'}});
        }
      },error => {
        this.loaderService.display(false);
        console.log("Failed to Register")
    })
    //console.log('SUCCESS!! :-)\n\n' + JSON.stringify(this.cardData));
  }
}
