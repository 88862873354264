import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FirstLoginService } from 'src/app/_api/first-login.service';
import { LoaderService } from 'src/app/_api/loader.service';

@Component({
  selector: 'app-auth-code-setup',
  templateUrl: './auth-code-setup.component.html',
  styleUrls: ['./auth-code-setup.component.css']
})
export class AuthCodeSetupComponent implements OnInit {

  authcode:string;
  email:string;
  constructor(private router: Router,
    private toastr: ToastrService,
    private firstLogin:FirstLoginService,
    private loaderService: LoaderService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }
  submit_authcode(){
    this.spinner.show();
    if (this.authcode!=undefined || this.authcode !=null ){
      if(this.email!=undefined || this.email !=null){
        localStorage.setItem("email",this.email)
        var object ={
          "email":this.email,
          "authCode":this.authcode
        }
      this.firstLogin.setAuthCode(object).then(resp=>{
        console.log(resp)
        if (resp['msg']=='OTP sent to reset the password'){
          this.spinner.hide();
          this.firstLogin.saveToken(resp['data']['token'])
          this.router.navigate(['/first-time-otp']);
        }else{
          this.spinner.hide();
          this.toastr.error("Something went wrong, please contact Aquapay Support!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
       
      },error=>{
        this.spinner.hide();
        console.log(error)
        this.toastr.warning("Failed to send OTP, please contact Aquapay Support!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      })
      } else {
        this.spinner.hide();
        this.toastr.warning("Please enter email id!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    }else{
      this.spinner.hide();
      this.toastr.warning("Please enter authentication code!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
  }
}
