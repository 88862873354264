import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
declare var jquery:any;
declare var $ :any;
// declare var loadCharts: any;
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import{CardService} from '../../_api/card.service'
import { Router,ActivatedRoute } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
import {ExcelGeneratorService} from  '../../_api/excel-generator.service'

@Component({
  selector: 'app-inquire-cards',
  templateUrl: './inquire-cards.component.html',
  styleUrls: ['./inquire-cards.component.css']
})
export class InquireCardsComponent implements OnInit {
  @ViewChild('myDiv') myDiv: ElementRef;
  // public dashboardData: any;
   public params:string;
   display='none'; //default Variable
   top='50px';
   public username:string;
   public rolename:string;
   currentUser: Users;
   isUnisup:boolean;
   isBulksup:boolean;
   isApprsup:boolean;
   isUnipay:boolean;
   isApprpay:boolean;
   isBulkpay:boolean;
   isPaysupstatus:boolean;
   isCardActivity:boolean;
   cards:any=[];
   pendingcards:any=[];
   approvedcards:any=[];
   rejectedcards:any[];
   downloadArray:any=[];
   fileUpload:File;
   filename:string="Click to Browse"
   selectedFiles: FileList;
   model: any = {};
   userData: any = {};
   _users:any[]=[];
   isSelected = false;
   //step2:boolean=false;
   showAliaDD:boolean=false;
   showUploadFileOpt:boolean=false;
   corporateData:any=[]
   aliasdata:any=[]
   corporate_id:any
   alias_id:any;
   inquiredData: any=[];
   uploadData:boolean=false;
   constructor(private userservice: UserService,private cardservice: CardService,private router: Router,private activatedRoute: ActivatedRoute,private vcn:VcnService,private spinner: NgxSpinnerService,private toastr: ToastrService, private excel:ExcelGeneratorService) { }
 
   ngOnInit() {
    // this.loadCorporates()
   }
 
  //  private loadCorporates(){
  //    this.vcn.getCorporate().then(resp=>{
      
  //      if(resp['message']=='Corporates info' && resp['error']==null){
  //        this.corporateData=resp['data']
  //      }else{
  //        console.log("Something Went Wrong")
  //      }
  //    },error=>{
  //      console.log(error)
  //      console.log("Failed to Load Corporate Data")
  //    })
  //  }
 
   UploadDirectFile(event){
     this.spinner.show()
     this.selectedFiles = event.target.files;
     this.filename = this.selectedFiles[0].name;
     
    // console.log('selectedFiles: ' + event.target.files[0] );
     this.vcn.uploadinquiredcardsfile(this.selectedFiles[0]).subscribe(
       
       data=>{
         //this.step2=true;
         if(data['message']=='Wibmo Inquiry API response!'){
           this.spinner.hide();
           this.inquiredData = data['data'];
           this.inquiredData.map(data=>{
             var cardNo = data.CardNumber
             data.CardNumber = `${cardNo.toString().substring(4,0)} XXXX XXXX ${cardNo.toString().substring(16,12)}`
           })
           this.uploadData=true;
           //this.router.navigate(['/inquire-card-success']);
         }else{
           this.spinner.hide()
           this.toastr.error("Something Went wrong!",'Alert',{
             timeOut:3000,
             positionClass:'toast-top-center'
             })
         }
         // this.userData=data["Data"];
         // console.log(data);
         //  localStorage.setItem('crdData',JSON.stringify(data))
         //  localStorage.setItem('corporate_id',this.corporate_id)
         //  localStorage.setItem('alias_id',this.alias_id)
          this.spinner.hide()
          //this.router.navigate(['/card-upload-view']);
       },error=>{
         //this.step2=false;
         this.spinner.hide()
         console.log("Failed to Upload")
         this.toastr.error("failed to Update inquired card!",'Alert',{
           timeOut:3000,
           positionClass:'toast-top-center'
           })
       }
     )
   
   }

   exportAsXLSX():void {
    this.downloadArray=[]
    
    if(this.inquiredData.length>=0){
      for(let data of this.inquiredData){
        var obj={
          Name:data['Name'],
          Mobile_Number:data['Mobile_Number'],
          Token_Number:data['CardNumber'],
          Response_Identifier:data['api_responseIdentifier'],
          Response_Code:data['api_resCode'],
          Response_Description:data['api_resDesc'],
        }
        this.downloadArray.push(obj)
      }
      this.excel.exportAsExcelFile( this.downloadArray, this.filename.split('.')[0]);
    }else{
      this.toastr.warning("No Data Available","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }  
  }
  }