import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './authentication/login/login.component';
import { ForgotComponent } from './authentication/forgot/forgot.component';
import { DasboardComponent } from './routingcomponents/dasboard/dasboard.component';
import { OrganisationComponent } from './routingcomponents/organisation/organisation.component';
import { AddbulkusersComponent } from './routingcomponents/addbulkusers/addbulkusers.component';
import { AuthGuard } from './gaurd/auth.gaurd';
import { UserviewComponent } from './routingcomponents/userview/userview.component';
import { UnitaryuserComponent } from './routingcomponents/unitaryuser/unitaryuser.component';
import { CardviewComponent } from './routingcomponents/cardview/cardview.component';
import { UnitarycardComponent } from './routingcomponents/unitarycard/unitarycard.component';
import { SuccessComponent } from './supportingcomponents/success/success.component';
import { MyprofileComponent } from './supportingcomponents/myprofile/myprofile.component';
import { LandingComponent } from './landing/landing.component';
import { EncodeurlComponent } from './encodeurl/encodeurl.component';
import { OtpComponent } from './authentication/otp/otp.component';
import { ResetPasswordComponent } from './routingcomponents/resetpassword/resetpassword.component';
import { NotificationmatrixComponent } from './routingcomponents/notificationmatrix/notificationmatrix.component';
import { UserhomeComponent } from './userhome/userhome.component';
import { PendingusersComponent } from './routingcomponents/pendingusers/pendingusers.component';
import { PendingcardsComponent } from './routingcomponents/pendingcards/pendingcards.component';
import { OtpuserComponent } from './routingcomponents/otpuser/otpuser.component';
import { OtpcardComponent } from './routingcomponents/otpcard/otpcard.component';
import { AccountsetupComponent } from './routingcomponents/accountsetup/accountsetup.component';
import { RejectmsgComponent } from './supportingcomponents/rejectmsg/rejectmsg.component';
import { SetnewpasswordComponent } from './authentication/setnewpassword/setnewpassword.component';
import { NewLoginComponent } from './authentication/new-login/new-login.component';
import { NewOtpComponent } from './authentication/new-otp/new-otp.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NewForgotpwdComponent } from './authentication/new-forgotpwd/new-forgotpwd.component';
import { CarduploadComponent } from './admin_components/cardupload/cardupload.component';
import { CheckercardComponent } from './admin_components/checkercard/checkercard.component';
import { CarduploadViewComponent } from './admin_components/cardupload-view/cardupload-view.component';
import { CardreqsubsuccessComponent } from './admin_components/cardreqsubsuccess/cardreqsubsuccess.component';
import { CardreqapprsuccessComponent } from './admin_components/cardreqapprsuccess/cardreqapprsuccess.component';
import { MakercardviewComponent } from './admin_components/makercardview/makercardview.component';
import { CardaliasComponent } from './admin_components/cardalias/cardalias.component';
import { PendingFilesComponent } from './admin_components/pending-files/pending-files.component';
import { ApprovedFilesComponent } from './admin_components/approved-files/approved-files.component';


import { ClearanceReportComponent } from './admin_components/clearance-report/clearance-report.component'
import { CheckBalanceComponent } from './admin_components/check-balance/check-balance.component';
import { CorporateOnboardingComponent } from './admin_components/corporate-onboarding/corporate-onboarding.component'
import { CorporateviewComponent } from './admin_components/corporateview/corporateview.component';
import { CorporateUsersComponent } from './admin_components/corporate-users/corporate-users.component'
import { UpdateContactComponent } from './admin_components/update-contact/update-contact.component'
import { MobilesuccessupdateComponent } from './admin_components/mobilesuccessupdate/mobilesuccessupdate.component'
import { MobileSignInComponent } from './user_components/mobile-sign-in/mobile-sign-in.component';
import { MobileOtpComponent } from './user_components/mobile-otp/mobile-otp.component';
import { CardsListingComponent } from './user_components/cards-listing/cards-listing.component';
import { AuthrizationReportComponent } from './user_components/authrization-report/authrization-report.component'
import { DateRangeReportComponent } from './user_components/date-range-report/date-range-report.component'
import { CardBlockComponent } from './admin_components/card-block/card-block.component'
import { CardcancelsuccessComponent } from './admin_components/cardcancelsuccess/cardcancelsuccess.component'
import { ReqReviewPageComponent } from './corporatecomponents/req-review-page/req-review-page.component'
import { CorporatePendingFilesComponent } from './corporatecomponents/corporate-pending-files/corporate-pending-files.component';
import { CorporateApprovedFilesComponent } from './corporatecomponents/corporate-approved-files/corporate-approved-files.component';
import { CorporateDashboardComponent } from './corporatecomponents/corporate-dashboard/corporate-dashboard.component';
import { PendingVcnViewComponent } from './corporatecomponents/pending-vcn-view/pending-vcn-view.component';
import { CorporateVcnViewComponent } from './corporatecomponents/corporate-vcn-view/corporate-vcn-view.component'
import { ReqSubmitSuccessComponent } from './corporatecomponents/req-submit-success/req-submit-success.component';
import { ReqApproveSuccessComponent } from './corporatecomponents/req-approve-success/req-approve-success.component';
import { ReqRejectSuccessComponent } from './corporatecomponents/req-reject-success/req-reject-success.component'
import { CorporateRejectedFilesComponent } from './corporatecomponents/corporate-rejected-files/corporate-rejected-files.component'
import { CorpdrangereportComponent } from './corporatecomponents/corpdrangereport/corpdrangereport.component';
import { CorpCurrentReportComponent } from './corporatecomponents/corp-current-report/corp-current-report.component'
import { CorpReportsComponent } from './corporatecomponents/corp-reports/corp-reports.component';
import { CorpReportsFilesComponent } from './corporatecomponents/corp-reports-files/corp-reports-files.component'
import { AdminReportsComponent } from './admin_components/admin-reports/admin-reports.component';
import { AdminReportsFilesComponent } from './admin_components/admin-reports-files/admin-reports-files.component'
import { AdminCurrentReportComponent } from './admin_components/admin-current-report/admin-current-report.component';
import { AdminRangeReportComponent } from './admin_components/admin-range-report/admin-range-report.component'
import { UnitarySearchComponent } from './admin_components/unitary-search/unitary-search.component'
import { UpdateCorpContactBulkComponent } from './corporatecomponents/update-corp-contact-bulk/update-corp-contact-bulk.component';
import { UpdateCorpContactUnitaryComponent } from './corporatecomponents/update-corp-contact-unitary/update-corp-contact-unitary.component'
import { UpdateCorpContactSuccessComponent } from './corporatecomponents/update-corp-contact-success/update-corp-contact-success.component'
import { InprocessFilesComponent } from './admin_components/inprocess-files/inprocess-files.component';
import { InprocessVcnViewComponent } from './admin_components/inprocess-vcn-view/inprocess-vcn-view.component'
import { SendBackSuccessComponent } from './admin_components/send-back-success/send-back-success.component'
import { PendingContactComponent } from './corporatecomponents/pending-contact/pending-contact.component'
import { ApproveContactSuccessComponent } from './corporatecomponents/approve-contact-success/approve-contact-success.component'
import { MobileLoginComponent } from './user_components_open/mobile-login/mobile-login.component';
import { MobileOtpOpenComponent } from './user_components_open/mobile-otp-open/mobile-otp-open.component';
import { AuthReportOpenComponent } from './user_components_open/auth-report-open/auth-report-open.component';
import { CardListingOpenComponent } from './user_components_open/card-listing-open/card-listing-open.component';
import { DateRangeReportOpenComponent } from './user_components_open/date-range-report-open/date-range-report-open.component';
import { UserHeaderOpenComponent } from './user_components_open/user-header-open/user-header-open.component'
import { InquireCardsComponent } from './admin_components/inquire-cards/inquire-cards.component';
import { CorpCancelcardReviewReqComponent } from './corporatecomponents/corp-cancelcard-review-req/corp-cancelcard-review-req.component';
import { CorpCancelcardApprovedFilesComponent } from './corporatecomponents/corp-cancelcard-approved-files/corp-cancelcard-approved-files.component';
import { CorpCancelcardRejectedFilesComponent } from './corporatecomponents/corp-cancelcard-rejected-files/corp-cancelcard-rejected-files.component';
import { CorpCancelcardPendingFilesComponent } from './corporatecomponents/corp-cancelcard-pending-files/corp-cancelcard-pending-files.component';
import { CorpCancelcardVcnViewComponent } from './corporatecomponents/corp-cancelcard-vcn-view/corp-cancelcard-vcn-view.component';
import { PendingCancelcardVcnViewComponent } from './corporatecomponents/pending-cancelcard-vcn-view/pending-cancelcard-vcn-view.component';
import { ApprovedCancelCardFilesComponent } from './admin_components/approved-cancel-card-files/approved-cancel-card-files.component';
import { RejectedCancelCardFilesComponent } from './admin_components/rejected-cancel-card-files/rejected-cancel-card-files.component';
import { MakerCancelCardViewComponent } from './admin_components/maker-cancel-card-view/maker-cancel-card-view.component';
import { UpdateBalanceComponent } from './admin_components/update-balance/update-balance.component';
import { AuthCodeSetupComponent } from './authentication/auth-code-setup/auth-code-setup.component';
import { FirstTimeOTPComponent } from './authentication/first-time-otp/first-time-otp.component';
import { FirstTimeSetPasswordComponent } from './authentication/first-time-set-password/first-time-set-password.component';
import { CorpUnitarySearchComponent } from './corporatecomponents/corp-unitary-search/corp-unitary-search.component';
import { AddUsersComponent } from './corporateonboarding/add-users/add-users.component';
import { UploadDocsComponent } from './supportingcomponents/upload-docs/upload-docs.component';
import { OrgRegComponent } from './supportingcomponents/org-reg/org-reg.component';
import { OrgListComponent } from './supportingcomponents/org-list/org-list.component';
import { OrgDetailsComponent } from './supportingcomponents/org-details/org-details.component';
import { AddCcadminComponent } from './corporateonboarding/add-ccadmin/add-ccadmin.component';
import { AddAliasComponent } from './supportingcomponents/add-alias/add-alias.component';
import { AddTemplateComponent } from './supportingcomponents/add-template/add-template.component';
import { AddSupplierComponent } from './supportingcomponents/add-supplier/add-supplier.component';
import { UpdateUserDetailsComponent } from './supportingcomponents/update-user-details/update-user-details.component';
import { ResetAuthcodeComponent } from './supportingcomponents/reset-authcode/reset-authcode.component';
import { AddUsersAdminComponent } from './supportingcomponents/add-users-admin/add-users-admin.component';
import { TokenUploadComponent } from './corporatecomponents/token-upload/token-upload.component';
import { AddAuthorizeUserComponent } from './corporatecomponents/add-authorize-user/add-authorize-user.component';
import { EmailOtpComponent } from './user_components/email-otp/email-otp.component';
import { EmailSignInComponent } from './user_components/email-sign-in/email-sign-in.component';
import { MapUserAliasComponent } from './supportingcomponents/map-user-alias/map-user-alias.component';
import { VoloDashboardAdminComponent } from './admin_components/volo-dashboard-admin/volo-dashboard-admin.component';
import { VoloSeachClaimnoAdminComponent } from './admin_components/volo-seach-claimno-admin/volo-seach-claimno-admin.component';
import { MaintenancePageComponent } from './supportingcomponents/maintenance-page/maintenance-page.component';
import { PendingPccComponent } from './gdscomponents/pending-pcc/pending-pcc.component';
import { ViewPccComponent } from './gdscomponents/view-pcc/view-pcc.component';
import { PendingGdsUsersComponent } from './gdscomponents/pending-gds-users/pending-gds-users.component';
import { ViewGdsUsersComponent } from './gdscomponents/view-gds-users/view-gds-users.component';
const appRoutes: Routes = [
   // { path : '', component: LandingComponent},
    
    
    { path:'main',component:UserhomeComponent,
        children:[
        { path : '', component: DasboardComponent},
        { path : 'organisation', component: OrganisationComponent,canActivate: [AuthGuard]},
        { path : 'addbulkusers', component: AddbulkusersComponent,canActivate: [AuthGuard]},
        { path : 'userview', component: UserviewComponent,canActivate: [AuthGuard]},
        { path : 'unitaryuser', component: UnitaryuserComponent,canActivate: [AuthGuard]},
        { path : 'cardsview', component: CardviewComponent,canActivate: [AuthGuard]},
        { path : 'unitarycard', component: UnitarycardComponent,canActivate: [AuthGuard]},
        { path : 'successmsg', component: SuccessComponent,canActivate: [AuthGuard]},
        { path : 'myprofile', component: MyprofileComponent,canActivate: [AuthGuard]},
        { path : 'encurl', component: EncodeurlComponent},
        { path : 'resetpassword', component : ResetPasswordComponent,canActivate:[AuthGuard]},
        { path : 'notification' , component : NotificationmatrixComponent,canActivate:[AuthGuard]},
        { path : 'pending-users' , component : PendingusersComponent,canActivate:[AuthGuard]},
        { path : 'pending-cards' , component : PendingcardsComponent,canActivate:[AuthGuard]},
        { path : 'otp-users/:ids' , component : OtpuserComponent,canActivate:[AuthGuard]},
        { path : 'otp-cards/:ids' , component : OtpcardComponent,canActivate:[AuthGuard]},
        { path:'rejectmsg',component:RejectmsgComponent,canActivate: [AuthGuard]},
        ]
    },
    
    // otherwise redirect to home
    { path : 'new-login', component: LoginComponent},
    { path : 'forgot', component: ForgotComponent},
    { path : 'setnewpwrd', component: NewForgotpwdComponent},
    { path : 'firstlogin', component: AccountsetupComponent},
    { path :'', component: NewLoginComponent},
    { path :'new-otp', component: NewOtpComponent},
    { path : 'otp', component: OtpComponent},
    { path : 'privacy-policy', component:  PrivacyPolicyComponent},
    { path: 'card-request-summary',component:CarduploadComponent},
    //USER FIRST LOGIN
    { path : 'authcode-setup', component: AuthCodeSetupComponent},
    { path : 'first-time-otp', component: FirstTimeOTPComponent},
    { path : 'first-time-set-pwd', component: FirstTimeSetPasswordComponent},
        { path: 'card-upload-view',component:CarduploadViewComponent,canActivate:[AuthGuard]},
        { path: 'checker-card/:id',component:CheckercardComponent,canActivate:[AuthGuard]},
        { path: 'card-req-submit-success',component:CardreqsubsuccessComponent,canActivate:[AuthGuard]},
        { path: 'card-req-approve-success',component:CardreqapprsuccessComponent,canActivate:[AuthGuard]},
        { path: 'maker-card/:id',component:MakercardviewComponent,canActivate:[AuthGuard]},
        { path: 'inprocess-vcn-view/:id',component:InprocessVcnViewComponent,canActivate:[AuthGuard]},
        { path: 'card-alias',component:CardaliasComponent,canActivate:[AuthGuard]},
        { path: 'pending-files',component:PendingFilesComponent,canActivate:[AuthGuard]},
        { path: 'send-back-success',component:SendBackSuccessComponent,canActivate:[AuthGuard]},
        { path: 'inporocess-files',component:InprocessFilesComponent,canActivate:[AuthGuard]},
        { path: 'approved-files',component:ApprovedFilesComponent,canActivate:[AuthGuard]},
        { path: 'update-contact',component:UpdateContactComponent,canActivate:[AuthGuard]},
        { path: 'inquire-cards',component:InquireCardsComponent,canActivate:[AuthGuard]},
        { path: 'mobile-update-success',component:MobilesuccessupdateComponent,canActivate:[AuthGuard]},
        { path: 'mobile-approve-success',component:ApproveContactSuccessComponent,canActivate:[AuthGuard]},
        { path: 'unitary-search',component:UnitarySearchComponent,canActivate:[AuthGuard]},
        { path: 'corp-unitary-search',component:CorpUnitarySearchComponent,canActivate:[AuthGuard]},
        { path: 'update-balance',component:UpdateBalanceComponent,canActivate:[AuthGuard]},
        { path: 'mobile-sign-in',component:MobileSignInComponent},
        { path: 'mobile-otp',component:MobileOtpComponent},
        { path: 'cards-listing',component:CardsListingComponent,canActivate:[AuthGuard]},
        // { path: 'email-sign-in',component:EmailSignInComponent},
        // { path: 'email-otp',component:EmailOtpComponent},
        { path: 'card-auth-report',component:AuthrizationReportComponent,canActivate:[AuthGuard]},
        { path: 'date-range-report',component:DateRangeReportComponent,canActivate:[AuthGuard]},
        { path: 'block-card',component:CardBlockComponent,canActivate:[AuthGuard]},
        { path: 'card-cancel-success',component:CardcancelsuccessComponent,canActivate:[AuthGuard]},
        { path: 'card-cancel-success',component:CardcancelsuccessComponent,canActivate:[AuthGuard]},
        { path: 'corp-rev-card-req',component:ReqReviewPageComponent},
        { path: 'corp-pending-files',component:CorporatePendingFilesComponent,canActivate:[AuthGuard]},
        { path: 'corp-approved-files',component:CorporateApprovedFilesComponent,canActivate:[AuthGuard]},
        { path: 'corp-rejected-files',component:CorporateRejectedFilesComponent,canActivate:[AuthGuard]},
        { path: 'corp-vcn-view/:id',component:CorporateVcnViewComponent,canActivate:[AuthGuard]},
        { path: 'corp-pending-vcn-view/:id',component:PendingVcnViewComponent,canActivate:[AuthGuard]},
        { path: 'corp-dashboard',component:CorporateDashboardComponent},
        { path: 'req-submit-success',component:ReqSubmitSuccessComponent,canActivate:[AuthGuard]},
        { path: 'req-approve-success',component:ReqApproveSuccessComponent,canActivate:[AuthGuard]},
        { path: 'req-reject-success',component:ReqRejectSuccessComponent,canActivate:[AuthGuard]},
        { path: 'corp-range-report',component:CorpdrangereportComponent,canActivate:[AuthGuard]},
        { path: 'corp-current-report',component:CorpCurrentReportComponent,canActivate:[AuthGuard]},
        { path: 'admin-range-report',component:AdminRangeReportComponent,canActivate:[AuthGuard]},
        { path: 'admin-current-report',component:AdminCurrentReportComponent,canActivate:[AuthGuard]},
        { path: 'corp-report-files',component:CorpReportsFilesComponent,canActivate:[AuthGuard]},
        { path: 'corp-reports/:id',component:CorpReportsComponent,canActivate:[AuthGuard]},
        { path: 'admin-report-files',component:AdminReportsFilesComponent,canActivate:[AuthGuard]},
        { path: 'corp-update-contact-bulk',component:UpdateCorpContactBulkComponent,canActivate:[AuthGuard]},
        { path: 'corp-update-contact-unitary',component:UpdateCorpContactUnitaryComponent,canActivate:[AuthGuard]},
        { path: 'corp-pending-contacts',component:PendingContactComponent,canActivate:[AuthGuard]},
        { path: 'mobile-update-success-corp',component:UpdateCorpContactSuccessComponent,canActivate:[AuthGuard]},
        { path: 'admin-reports/:id',component:AdminReportsComponent,canActivate:[AuthGuard]},
        { path: 'clearance-reports',component:ClearanceReportComponent},
        { path: 'check-balance',component:CheckBalanceComponent},
        { path: 'corporate-onboarding',component:CorporateOnboardingComponent},
        { path: 'corporate-view',component:CorporateviewComponent},
        { path: 'corporate-user-view',component:CorporateUsersComponent},
        { path: 'mobile-login-open',component:MobileLoginComponent},
        { path: 'mobile-otp-open',component:MobileOtpOpenComponent},
        { path: 'cards-listing-open',component:CardListingOpenComponent,canActivate:[AuthGuard]},
        { path: 'card-auth-report-open',component:AuthReportOpenComponent,canActivate:[AuthGuard]},
        { path: 'date-range-report-open',component:DateRangeReportOpenComponent,canActivate:[AuthGuard]},
        { path: 'corp-cancel-card-rev-req',component:CorpCancelcardReviewReqComponent,canActivate:[AuthGuard]},
        { path: 'corp-cancel-card-approved-files',component:CorpCancelcardApprovedFilesComponent,canActivate:[AuthGuard]},
        { path: 'corp-cancel-card-rejected-files',component:CorpCancelcardRejectedFilesComponent,canActivate:[AuthGuard]},
        { path: 'corp-cancel-card-pending-files',component:CorpCancelcardPendingFilesComponent,canActivate:[AuthGuard]},
        { path: 'corp-cancel-card-vcn-view/:id',component:CorpCancelcardVcnViewComponent,canActivate:[AuthGuard]},
        { path: 'corp-pending-cancel-card-vcn-view/:id',component:PendingCancelcardVcnViewComponent,canActivate:[AuthGuard]},
        { path: 'approved-cancel-card-files',component:ApprovedCancelCardFilesComponent,canActivate:[AuthGuard]},
        { path: 'rejected-cancel-card-files',component:RejectedCancelCardFilesComponent,canActivate:[AuthGuard]},
        { path: 'maker-cancel-card/:id',component:MakerCancelCardViewComponent,canActivate:[AuthGuard]},
        { path: 'upload-docs', component: UploadDocsComponent,canActivate:[AuthGuard]},
        { path: 'org-reg', component: OrgRegComponent,canActivate:[AuthGuard]},
        { path: 'add-users', component: AddUsersComponent,canActivate:[AuthGuard]},
        { path: 'map-user-alias', component: MapUserAliasComponent,canActivate:[AuthGuard]},
        { path: 'volo-dashboard', component: VoloDashboardAdminComponent,canActivate:[AuthGuard]},
        { path: 'volo-search-admin', component: VoloSeachClaimnoAdminComponent,canActivate:[AuthGuard]},

        //Aquapay Admin FLow
        { path : 'org-listing', component: OrgListComponent,canActivate:[AuthGuard]},
        { path : 'org-details', component: OrgDetailsComponent,canActivate:[AuthGuard]},
        { path : 'add-ccadmin', component: AddCcadminComponent ,canActivate:[AuthGuard]},
        { path : 'add-alias', component: AddAliasComponent ,canActivate:[AuthGuard]},
        { path : 'add-template', component: AddTemplateComponent ,canActivate:[AuthGuard]},
        { path : 'add-supplier', component: AddSupplierComponent ,canActivate:[AuthGuard]},
        { path : 'update-user-details', component: UpdateUserDetailsComponent ,canActivate:[AuthGuard]},
        { path : 'update-user-authcode', component: ResetAuthcodeComponent ,canActivate:[AuthGuard]},
        { path : 'add-user-admin', component: AddUsersAdminComponent ,canActivate:[AuthGuard]},
        { path : 'token-upload', component: TokenUploadComponent ,canActivate:[AuthGuard]},
        { path: 'add-authorize-users', component: AddAuthorizeUserComponent},

        //GDS Components
        { path : 'pending-pcc', component: PendingPccComponent ,canActivate:[AuthGuard]},
        { path : 'view-pcc', component: ViewPccComponent ,canActivate:[AuthGuard]},
        { path : 'pending-gds-users', component: PendingGdsUsersComponent ,canActivate:[AuthGuard]},
        { path : 'view-gds-users', component: ViewGdsUsersComponent ,canActivate:[AuthGuard]},
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);