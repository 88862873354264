import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/excelservice/excel.service';
import { ExcelGeneratorService } from 'src/app/_api/excel-generator.service';
import { VcnService } from 'src/app/_api/vcn.service';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'app-maker-cancel-card-view',
  templateUrl: './maker-cancel-card-view.component.html',
  styleUrls: ['./maker-cancel-card-view.component.css']
})
export class MakerCancelCardViewComponent implements OnInit {
  public id: string;
  filedata:any=[]
  downloadArray:any=[];
  filesummary:any={}
  cardholder:any;
  cardno:any;
  cardcvv:any;
  cardexpiry:any;
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  additonal_amount:any=0
  current_amount:any=0
  add_curr_amt:any=0;
  total_amt_in_words:any="TWO THOUSAND RUPEES";
  selectedIndex = -1;
  constructor(private activeroute: ActivatedRoute,private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private route:Router,private excelservice : ExcelService, private excel:ExcelGeneratorService) { }

  ngOnInit() {
    this.id = this.activeroute.snapshot.paramMap.get('id');
    if(this.id!=undefined || this.id!=null){
      this.loadFileData()
    }
    else{
      this.toastr.error("Something Went wrong!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }

  }

  viewcardDetails(crdno,name,expiry): void {
    this.cardno=`${crdno.substring(0,4)} XXXX XXXX ${crdno.substring(16,12)}`
    this.cardholder=name
   
    this.cardexpiry=expiry
    $("#payreport").modal("show");
 
}

  private loadFileData(){
    this.spinner.show()
    this.vcn.getCancelCardFiledata(this.id).then(resp=>{
    //  console.log(resp)
      this.filedata=resp['data']['file_data']
   
      this.filesummary={
        "corporate_name": resp['data']['corporate_name'],
        "corporate_nick_name" :resp['data']['corporate_nick_name'],
        "corporate_id": resp['data']['corporate_id'],
        "file_name": resp['data']['file_name'],
        "total_cards": resp['data']['total_cards'],
        "total_amount": resp['data']['total_amount'],
        "utr_no":resp['data']['utr_no'],
        "status":resp['data']['status'],
        "bank_name":resp['data']['bank_name'],
        "transaction_date": resp['data']['transaction_date'],
        "sub_corp_name":resp['data']['sub_corp_name']
        // "alias_name":resp['data']['alias_name'],
        // "alias_id":resp['data']['alias_id'],
      }
      this.spinner.hide()

    },error=>{
      this.spinner.hide()
      console.log(error)
      this.toastr.error("Failed to load data!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    })
  }

  exportAsXLSX():void {
    this.downloadArray=[]
    
    if(this.filedata.length>=0){
      for(let data of this.filedata){
        var obj={
          Name:data['name'],
          Mobile_Number:data['mobile_no'],
          Email:data['email'],
          Amount:data['amount'],
          Reference_Number:data['reference_no'],
        }
        this.downloadArray.push(obj)
      }
      var fileNameArray = this.filesummary['file_name'].split('.');
      var finalFileName= ""
      for(let i = 0; i < fileNameArray.length - 1; i++){
        console.log(fileNameArray[i])
        finalFileName = finalFileName + "." + fileNameArray[i];
      }
      this.excel.exportAsExcelFile( this.downloadArray, finalFileName);
    }else{
      this.toastr.warning("No Data Available","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
   // this.excelservice.exportAsExcelFile( this.users, 'User');
  }

  gotobalanceview(){
    this.route.navigate(['/check-balances', this.id]);
  }

  checkBalPopUp(){
    $("#checkBalRep").modal("show");
  }
  
  addAdditionalAmt(amt){
    this.current_amount=amt
    this.add_curr_amt=parseFloat(amt)+parseFloat(this.additonal_amount)
    $("#AdditionAmtModal").modal("show");
  }

  submitaddbal(){
    this.toastr.success("New Limit has been updated successfully","Alert",{
      timeOut:30000000,
      positionClass:'toast-top-center'
      })
  }
  toggleExpandedView(index){
    this.selectedIndex = index;
   }
   oldCardNumberDisplay(cardNo:any) {
    if(cardNo){
      var returnString = `${cardNo.substring(4,0)} XXXX XXXX ${cardNo.substring(16,12)}`
      return returnString
    }
  }
}
