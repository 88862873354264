import { Injectable ,EventEmitter,Output} from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
// import { Observable, of, throwError } from 'rxjs';
// import { catchError, tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {Config} from '../config'
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

const path = new Config().getVcnUrl();

@Injectable({
  providedIn: 'root'
})
export class VcnuserService {
  @Output() isLoggedIn: EventEmitter<string> = new EventEmitter();
  TOKEN_KEY = 'token'
  id:any;

  constructor(private http: HttpClient) { }

  loginWithMobile(params:any): Promise<any> {  
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/auth/mobile-login`,params)
            .subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    });
    return promise;
  }


  postMobileOtp(params:any): Promise<any> {
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/auth/mobile-otp`,params)
            .subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    });
    return promise;
  }

  getUserCards(params:any): Promise<any> {
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/card/get_user_cards`,params)
            .subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    });
    return promise;
  }

  getCardBalance(params): Promise<any> {
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/card/get_balance_check`,params)
            .subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    });
    return promise;
  }


  getVcnAuthReport(params): Promise<any> {
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/card/get_vcn_auth_report`,params)
            .subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    });
    return promise;
  }

  saveToken(token){
    //console.log('Set Token')
    localStorage.setItem(this.TOKEN_KEY,token)
}
get token(){
  //console.log("Get Token Called");
  return localStorage.getItem(this.TOKEN_KEY)
}

get isAuthenticated(){
  return !!localStorage.getItem(this.TOKEN_KEY)
}
getIsLoggedIn(){
  if(localStorage.getItem('data')){
      console.log("User is logged in")
      return true;
  }else{
      return false;
  }
}

logout(){
  localStorage.removeItem(this.TOKEN_KEY)
}


}
