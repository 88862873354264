import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { ExcelService } from '../../excelservice/excel.service'
import {ExcelGeneratorService} from  '../../_api/excel-generator.service'

@Component({
  selector: 'app-admin-range-report',
  templateUrl: './admin-range-report.component.html',
  styleUrls: ['./admin-range-report.component.css']
})
export class AdminRangeReportComponent implements OnInit {
  transactiondata:any=[]
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  constructor(private _location: Location,private spinner: NgxSpinnerService,private excelservice : ExcelService,private excel:ExcelGeneratorService) { }

  ngOnInit() {
    this.loadTrxData()
  }
  backClicked() {
    localStorage.removeItem('authreportdr')
    this._location.back();
  }

  private loadTrxData(){
    var d1 = new Date (),
    d2 = new Date ( d1 );
d2.setMinutes ( d1.getMinutes() + 330 );
    this.spinner.show()
    this.transactiondata=[]
    var transaData=JSON.parse(localStorage.getItem("authreportdr") || "[]");
  
    if(Array.isArray(transaData)){
      for(var data of transaData){
        var obj={
          Amount:data['BillingAmount'],
          TokenNumber:data['VirtualCardNumber'],
          MerchantName:data['MerchantName'],
          TransactionDateTime:"2020-" +data['TxnDateTimeWithTime'],
          IssuerResponse:data['IssuerResponse'],
          InControlResponse:data['InControlResponse'],
          Settled:data['Settled'],
          TransactionDate:data['TxnDateTime']
        }
        this.transactiondata.push(obj)
  
      }
      if(this.transactiondata.length>0){
        this.spinner.hide()
      }else{
        this.spinner.hide()
      }
    }else{
      this.spinner.hide()
      this.transactiondata=[{
        'Amount':transaData['BillingAmount'],
        'TokenNumber':transaData['VirtualCardNumber'],
        'MerchantName':transaData['MerchantName'],
        'TransactionDateTime':"2020-" +transaData['TxnDateTimeWithTime'],
        'IssuerResponse':transaData['IssuerResponse'],
        'InControlResponse':transaData['InControlResponse'],
        'Settled':transaData['Settled'],
        'TransactionDate':transaData['TxnDateTime']
      }]
     
    }
    

    

  }


  exportAsXLSX(){
    this.excel.exportAsExcelFile( this.transactiondata, 'Transaction_Report');
  }

}
