import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-req-reject-success',
  templateUrl: './req-reject-success.component.html',
  styleUrls: ['./req-reject-success.component.css']
})
export class ReqRejectSuccessComponent implements OnInit {
  cardRequestSuccess:boolean = false;
  cancelCardSuccess: boolean = false;
  params:any;
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.params = this.activatedRoute.snapshot.queryParams["msg"];
    console.log(this.params);
    if(this.params=='cardrequestsuccess'){
      this.cardRequestSuccess=true;
    }else if(this.params=='cancelcardsuccess'){
      this.cancelCardSuccess=true;
    }
  }

}
