import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import{CardService} from '../../_api/card.service'
import { Router,ActivatedRoute } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-corporate-onboarding',
  templateUrl: './corporate-onboarding.component.html',
  styleUrls: ['./corporate-onboarding.component.css']
})
export class CorporateOnboardingComponent implements OnInit {
  corporatedata:any={}
  emailForm = this.fb.group({
    email: this.fb.array([
      this.fb.control('')
    ])
  })
  constructor(private fb: FormBuilder,private userservice: UserService,private cardservice: CardService,private router: Router,private activatedRoute: ActivatedRoute,private vcn:VcnService,private spinner: NgxSpinnerService ) { }
  productForm: FormGroup;
  step1:boolean=true;
  step2:boolean=false;
  step3:boolean=false;
  step4:boolean=false;
  step5:boolean=false;
  filename:string="Click to Browse"
  fileUpload:File;
  selectedFiles: FileList;
  ngOnInit() {
    this.productForm = this.fb.group({
      title: [],
      selling_points: this.fb.array([this.fb.group({point:''})])
    })
  }

  addEmail(){
    this.email.push(this.fb.control(''));
  }
  get email(){
    return this.emailForm.get('email') as FormArray
  }

  gotostep2(){
    this.step1=false;
    this.step2=true;
    this.step3=false;
    this.step4=false;
    this.step5=false;

  }
  gotostep3(){
    this.step1=false;
    this.step2=false;;
    this.step3=true;
    this.step4=false;
    this.step5=false;
  }

  gotostep1(){
    this.step1=true;
    this.step2=false;
    this.step3=false;
    this.step4=false;
    this.step5=false;
  }
  gotostep4(){
    this.step1=false;
    this.step2=false;
    this.step3=false;
    this.step4=true;
    this.step5=false;
  }
  gotostep5(){
    this.step1=false;
    this.step2=false;
    this.step3=false;
    this.step4=false;
    this.step5=true;
  }

  UploadDirectFile(event){
    this.spinner.show()
    this.selectedFiles = event.target.files;
    this.filename = this.selectedFiles[0].name;
    this.gotostep4()
   // console.log('selectedFiles: ' + event.target.files[0] );
    // this.vcn.uploadreqfile(this.selectedFiles[0]).subscribe(
      
    //   data=>{
    //     this.step2=true;
    //     // this.userData=data["Data"];
    //     // console.log(data);
    //      localStorage.setItem('crdData',JSON.stringify(data))
    //      this.spinner.hide()
    //      this.router.navigate(['/card-upload-view']);
    //   },error=>{
    //     this.step2=false;
    //     this.spinner.hide()
    //     console.log("Failed to Upload")
    //   }
    // )
  
  }

}
