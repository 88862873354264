import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
declare var jquery: any;
declare var $: any;
import {VcnService} from '../../_api/vcn.service'
import { DatePipe } from '@angular/common'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
import {VcncorpService} from '../../_api/vcncorp.service'
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-yyyy',
  },
  display: {
    dateInput: 'DD-MM-yyyy',
    monthYearLabel: 'DD-MM-yyyy',
    dateA11yLabel: 'DD-MM-yyyy',
    monthYearA11yLabel: 'DD-MM-yyyy',
  },
};

/** @title Datepicker with custom formats */
@Component({
  selector: 'app-req-review-page',
  templateUrl: './req-review-page.component.html',
  styleUrls: ['./req-review-page.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ReqReviewPageComponent implements OnInit {
  isSelected:boolean=false
  filedata:any={}
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }
  transdate:any;
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  filesummary={
  }
  uns_filesummary={
  }
  carddata:any=[]
  uns_carddata:any=[]
  utr_no:string="";
  sub_corp_name:string="";
  remarks:string="";
  bank_name:string="";
  transaction_date:any=null;
  trans_date:string=null;
  total_amount:any=null;
  file_data:any[]
  
  myDate:Date = new Date();
  currenTdate:string
  amtinwords:any;
  corporate_id:any;
  alias_id:any;
  template_id:any;
  supplier_id:any;
  corporate_name:any;
  alias_name:any;
  alias_data:any=[]
  successfulTab:boolean=true;
  unsuccessFultab:boolean=false;
  successEntries:number=0;
  unsuccessEntries:number=0;
  unsuccesAmount:number=0;
  successAmount:number=0;
  activeSuccess:string='active'
  activeUnSuccess:string='none'
  totalCardData:number=0;
  selectedIndex = -1;
  cardDataPopup:any={};
  currentUser:any;
  isCorporateAdmin:boolean= false;
  orgId:any;
  constructor(private router: Router, private route: ActivatedRoute,private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private vcncorp:VcncorpService) { }


  ngOnInit() {
   
    this.loadcardData()
    this.loadUserDetails()
    this.currenTdate=this.datepipe.transform(this.myDate, 'dd-MM-yyyy')
    console.log(this.currenTdate)
  }

  private loadUserDetails(){
    this.vcn.getCurrentUser().then(users => {
      // console.log(users);
       this.currentUser = users['data'];
       this.orgId = this.currentUser.id
        if(this.currentUser['role_name']=='Corporate Admin'){
          this.isCorporateAdmin=true
        }else{
          this.isCorporateAdmin=false
        }
      });
  }

  checkAll(){
    this.isSelected=!this.isSelected

  }
  checkClick(cardData): void {
      console.log(cardData)
      this.cardDataPopup=cardData
      $("#payreport").modal("show");
   

  }
  submitreq(): void {
  
        this.router.navigate(['/card-req-submit-success'],{queryParams:{msg:'cardrequestuccess'}});
  }

  toggleExpandedView(index){
    this.selectedIndex = index;
   }

  private async loadcardData(){
    this.filedata=JSON.parse(localStorage.getItem("crdData") || "[]");
    this.successAmount=0;
    this.unsuccesAmount=0;
   // this.corporate_id=localStorage.getItem('corporate_id')
    this.alias_id=localStorage.getItem('alias_id')
    this.template_id=localStorage.getItem('template_id')
    this.supplier_id=localStorage.getItem('supplier_id')
    // if(this.corporate_id!=undefined){
    //   this.getcorporatename(this.corporate_id)
    // }else{

    // }
 if(this.alias_id!=undefined){
  this.getaliasname()
    }else{
      
    }
  
    //console.log(this.filedata)
    var cardEntries=this.filedata['data']['records']
    this.totalCardData=cardEntries.length
    this.carddata=cardEntries.filter(crd=>(crd['status']=='successful' || crd['reason'] == 'Amount must be greater than zero')) //temp fix for allowing card with 0 amount
    this.uns_carddata=cardEntries.filter(crd=>(crd['status']=='unsuccessful'))

    for (var data of this.carddata){
      this.successAmount+=parseFloat(data['Amount']) 
    }

    for (var data of this.uns_carddata){
      this.unsuccesAmount+=parseFloat(data['Amount']) 
    }

    this.filesummary={
      "corporate_name":this.filedata['data']['corporate_name'],
      "filename":this.filedata['data']['filename'],
      "total_amount":this.successAmount,
      "total_request":this.carddata.length,
    }
    this.uns_filesummary={
      "corporate_name":this.filedata['data']['corporate_name'],
      "filename":this.filedata['data']['filename'],
   
      "total_request":this.uns_carddata.length,
    }
    
    console.log(this.total_amount)
  }

  dateChange(event){
   
 
    this.trans_date=event._d
    this.trans_date=this.datepipe.transform(this.trans_date, 'dd-MM-yyyy')
    console.log(!!this.trans_date)
  }

  onSubmit(){
    console.log("IS CP?",this.isCorporateAdmin)
    if(this.isCorporateAdmin || this.currentUser['role_name'] == 'AS'){
      this.submitReqCorporateAdmin()
    } else {
      this.submitReq()
    }
  }

  submitReq(){
    this.spinner.show()
    console.log(typeof(this.successAmount))
    this.showcommasepared(this.successAmount.toString())
    this.total_amount=this.successAmount.toString()
    //this.removeCommas(this.total_amount)
    console.log(this.total_amount,this.total_amount.length)
    if(this.total_amount!=null){
    if(this.totalCardData==this.carddata.length){
    var params={
      "transaction_details":{
        "file_name":this.filedata['data']['filename'],
        "total_amount":this.total_amount.replace(/,/g, ''),
        "total_cards":this.filedata['data']['total_request'],
        "utr_no":this.utr_no,
        "transaction_date":!!this.trans_date?this.trans_date:this.currenTdate,
        "bank_name":this.bank_name,
        "alias_id":this.alias_id,
        "template_id":this.template_id,
        "supplier_id":this.supplier_id,
        "sub_corp_name":this.sub_corp_name,
        "remarks":this.remarks
      }, 
      "file_data":this.carddata
    }

    console.log(JSON.stringify(params))

  
    if(parseFloat(params['transaction_details']['total_amount'])===parseFloat(this.filesummary['total_amount'])){
    
      
        this.vcncorp.submitCardRequestFile(params).subscribe(resp=>{
        if(resp['message']=='Card request submit for approval successfully'){
          localStorage.removeItem('crdData')
          localStorage.removeItem('alias_id')
          this.spinner.hide()
          this.router.navigate(['/req-submit-success'],{queryParams:{msg:'cardrequestsuccess'}});
        }else{
          this.spinner.hide()
          this.toastr.error("Something Went wrong!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
       
      },error=>{
        this.spinner.hide()
        this.toastr.error("Failed to Submit Request!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
        console.log(error)
      })
    }else if(parseFloat(params['transaction_details']['total_amount'])>parseFloat(this.filesummary['total_amount'])){
           this.spinner.hide()
        this.toastr.warning("Entered Amount is greater than total amount of file!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
    }else if(parseFloat(params['transaction_details']['total_amount'])<parseFloat(this.filesummary['total_amount'])){
           this.spinner.hide()
        this.toastr.warning("Entered Amount is less than total amount of file!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
    }else{
      console.log('Amount Mismathced')
           this.spinner.hide()
        this.toastr.warning("Amount Mismathced!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
    }
  }else{
    console.log('Please Correct the file')
    this.spinner.hide()
 this.toastr.warning("Please Correct the file first!",'Alert',{
   timeOut:3000,
   positionClass:'toast-top-center'
   })
  }
}else{
  this.spinner.hide()
  this.toastr.warning("Please enter all the transaction details!",'Alert',{
    timeOut:3000,
    positionClass:'toast-top-center'
    })
}
    //console.log(JSON.stringify(params))
    
  }

  submitReqCorporateAdmin(){
    this.spinner.show()
    console.log("In CP ADMIN FLOW",typeof(this.successAmount))
    this.showcommasepared(this.successAmount.toString())
    this.total_amount=this.successAmount.toString()
    //this.removeCommas(this.total_amount)
    console.log(this.total_amount,this.total_amount.length)
    if(this.total_amount!=null){
    if(this.totalCardData==this.carddata.length){
    var params={
      "transaction_details":{
        "file_name":this.filedata['data']['filename'],
        "total_amount":this.total_amount.replace(/,/g, ''),
        "total_cards":this.filedata['data']['total_request'],
        "utr_no":this.utr_no,
        "transaction_date":!!this.trans_date?this.trans_date:this.currenTdate,
        "bank_name":this.bank_name,
        "alias_id":this.alias_id,
        "template_id":this.template_id,
        "supplier_id":this.supplier_id,
        "sub_corp_name":this.sub_corp_name,
        "remarks":this.remarks,
        "corporate_id":this.corporate_id
      }, 
      "file_data":this.carddata
    }

    console.log(JSON.stringify(params))

  
    if(parseFloat(params['transaction_details']['total_amount'])===parseFloat(this.filesummary['total_amount'])){
    
      
        this.vcn.submitBulkReq(params).subscribe(resp=>{
        if(resp['message']=='Card request submit for approval successfully'){
          localStorage.removeItem('crdData')
          localStorage.removeItem('alias_id')
          this.spinner.hide()
          this.router.navigate(['/req-submit-success'],{queryParams:{msg:'cardrequestsuccess'}});
        }else{
          this.spinner.hide()
          this.toastr.error("Something Went wrong!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
       
      },error=>{
        this.spinner.hide()
        this.toastr.error("Failed to Submit Request!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
        console.log(error)
      })
    }else if(parseFloat(params['transaction_details']['total_amount'])>parseFloat(this.filesummary['total_amount'])){
           this.spinner.hide()
        this.toastr.warning("Entered Amount is greater than total amount of file!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
    }else if(parseFloat(params['transaction_details']['total_amount'])<parseFloat(this.filesummary['total_amount'])){
           this.spinner.hide()
        this.toastr.warning("Entered Amount is less than total amount of file!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
    }else{
      console.log('Amount Mismathced')
           this.spinner.hide()
        this.toastr.warning("Amount Mismathced!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
    }
  }else{
    console.log('Please Correct the file')
    this.spinner.hide()
 this.toastr.warning("Please Correct the file first!",'Alert',{
   timeOut:3000,
   positionClass:'toast-top-center'
   })
  }
}else{
  this.spinner.hide()
  this.toastr.warning("Please enter all the transaction details!",'Alert',{
    timeOut:3000,
    positionClass:'toast-top-center'
    })
}
    //console.log(JSON.stringify(params))
    
  }

  CommaFormatted(event) {
    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40) return;
   
    // format number
    if (this.total_amount) {
     this.total_amount = this.total_amount.replace(/\D/g, "")
       .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }}
   
   numberCheck (args) {
   if (args.key === 'e' || args.key === '+' || args.key === '-') {
     return false;
   } else {
     return true;
   }}

   showcommasepared(amt){
    

    if(amt.search(",") >= 0)
    this.amtinwords=this.number2text(amt.replace(/,/g, ''))
    else{
      this.amtinwords=this.number2text(amt)
    }
   }

   number2text(value) {
    var fraction = Math.round(this.frac(value)*100);
    var f_text  = "";

    if(fraction > 0) {
        f_text = "AND "+this.convert_number(fraction)+" PAISE";
    }

    return this.convert_number(value)+" RUPEES "+f_text+" ONLY";
}

 frac(f) {
    return f % 1;
}

 convert_number(number)
{
    if ((number < 0) || (number > 999999999)) 
    { 
        return "NUMBER OUT OF RANGE!";
    }
    var Gn = Math.floor(number / 10000000);  /* Crore */ 
    number -= Gn * 10000000; 
    var kn = Math.floor(number / 100000);     /* lakhs */ 
    number -= kn * 100000; 
    var Hn = Math.floor(number / 1000);      /* thousand */ 
    number -= Hn * 1000; 
    var Dn = Math.floor(number / 100);       /* Tens (deca) */ 
    number = number % 100;               /* Ones */ 
    var tn= Math.floor(number / 10); 
    var one=Math.floor(number % 10); 
    var res = ""; 

    if (Gn>0) 
    { 
        res += (this.convert_number(Gn) + " CRORE"); 
    } 
    if (kn>0) 
    { 
            res += (((res=="") ? "" : " ") + 
            this.convert_number(kn) + " LAKH"); 
    } 
    if (Hn>0) 
    { 
        res += (((res=="") ? "" : " ") +
            this.convert_number(Hn) + " THOUSAND"); 
    } 

    if (Dn) 
    { 
        res += (((res=="") ? "" : " ") + 
            this.convert_number(Dn) + " HUNDRED"); 
    } 


    var ones = Array("", "ONE", "TWO", "THREE", "FOUR", "FIVE", "SIX","SEVEN", "EIGHT", "NINE", "TEN", "ELEVEN", "TWELVE", "THIRTEEN","FOURTEEN", "FIFTEEN", "SIXTEEN", "SEVENTEEN", "EIGHTEEN","NINETEEN"); 
var tens = Array("", "", "TWENTY", "THIRTY", "FOURTY", "FIFTY", "SIXTY","SEVENTY", "EIGHTY", "NINETY"); 

    if (tn>0 || one>0) 
    { 
        if (!(res=="")) 
        { 
            res += " AND "; 
        } 
        if (tn < 2) 
        { 
            res += ones[tn * 10 + one]; 
        } 
        else 
        { 

            res += tens[tn];
            if (one>0) 
            { 
                res += ("-" + ones[one]); 
            } 
        } 
    }

    if (res=="")
    { 
        res = "zero"; 
    } 
    return res;
}

getcorporatename(id){
  this.vcn.getCorporatebyId(id).then(resp=>{

   this.corporate_name=resp['data']['name']
  },error=>{
    console.log(error)
  })
}

getaliasname(){
  this.vcncorp.getAliasdata().then(resp=>{
  //  console.log(resp)
  if(resp['message']=='Alias data' && resp['error']==null){
    var alias_detail =resp['data']
    
    this.alias_data=alias_detail.filter(alias=>(alias['id']==this.alias_id))
    
   this.alias_name= this.alias_data[0]['alias_name']
   
    // for(var data of resp['data']){
    //   if(this.alias_id==resp['data']['id']){
    //     this.alias_name=data['alias_name']
    //   }
    // }
  }
   
  },error=>{
    console.log(error)
  })
}

showSuccessful(){
  this.successfulTab=true;
  this.unsuccessFultab=false;
  this.activeSuccess='active'
  this.activeUnSuccess='none'

}

showUnSuccessful(){
  this.successfulTab=false;
  this.unsuccessFultab=true;
  this.activeSuccess='none'
  this.activeUnSuccess='active'
  
}

}
