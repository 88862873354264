import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-gds-users',
  templateUrl: './view-gds-users.component.html',
  styleUrls: ['./view-gds-users.component.css']
})
export class ViewGdsUsersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
