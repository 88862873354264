import { Component, OnInit } from '@angular/core';
import{OrganisationService} from '../../_api/organisation.service'
import{LoaderService} from '../../_api/loader.service';
import{UserService} from '../../_api/user.service'

@Component({
  selector: 'app-editorg',
  templateUrl: './editorg.component.html',
  styleUrls: ['./editorg.component.css']
})
export class EditorgComponent implements OnInit {
  orgdata:any={};
  address:string;
  city:string;
  state:string;
  constructor(private orgservice:OrganisationService,private loader:LoaderService,
    private userservice:UserService) { }

  ngOnInit() {
    this.getOrg();
  }

  private getOrg(){
      this.orgservice.getOrganisation().subscribe(data=>{
        
        this.orgdata=data['data'];
      
      },error=>{
        console.log(error)
      })
  }

}
