import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-encodeurl',
  templateUrl: './encodeurl.component.html',
  styleUrls: ['./encodeurl.component.css']
})
export class EncodeurlComponent implements OnInit {
  url:string;
  encoderesult:string;
  constructor() { }

  ngOnInit() {
  }

  encodeuri(){
    this.url="<!DOCTYPE html><html><head><title></title></head><body><div style='padding: 10px;border: 3px solid #d3d3d3;border-radius: 10px;font-family: Cambria, Georgia, serif;font-size: 18px;'><div style='text-align:center><img src='https://aquapay.in/assets/img/Aquapay_Logo_Final.png' alt='logowillcomehere' style='width:25%;'></div><p>Dear Mr.Rajeev Girdhari Lal Kent Sikand(Authorised Signatory),</p><p>Welcome to Digital Payment Solution powered by Aquapay</p><p>To Complete Your Company Registration, Kindly <a href='https://www.aquapay.in/firstloginas'>Click here</a></p><p>Your Authentication Code: <b>860947836</b></p><p>This is an auto generated notification. Please do not reply.</p><p>Disclaimer</p><p style='font-size:12px;'>This email and any attachments to it may be confidential and are intended solely for the use of the individual to whom it is addressed. Any views or opinions expressed are solely those of the author and do not necessarily represent those of Aquapay Payment Solution.<br>If you are not the intended recipient of this email, you must neither take any action based upon its contents, nor copy or show it to anyone.</p></div></body></html>"
    this.encoderesult = encodeURI(this.url);
    console.log(this.encoderesult);

  }

}
