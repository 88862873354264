import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrgserviceService } from 'src/app/_api/orgservice.service';
import { VcnService } from 'src/app/_api/vcn.service';
import { Config } from 'src/app/config';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
const path = new Config().getVcnUrl();

@Component({
  selector: 'app-map-user-alias',
  templateUrl: './map-user-alias.component.html',
  styleUrls: ['./map-user-alias.component.css'],
})
export class MapUserAliasComponent implements OnInit {
  formdetailsrow: boolean=true;
  listbillerrow: boolean=false;
  billersaddedlist: any=[];
  selectedIndex: any;
  billdata:any={};
  corpId:any;
  authMatrix:any;
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }
  headChange:any;
  dropdownData:any;
  userDropdownData:any;
  aliasDropdownData:any;
  showSubmitButton = false;
  index:any;
  showNext = false;
  constructor(private vcn:VcnService,private spinner: NgxSpinnerService,private router: Router,public datepipe: DatePipe,private orgservice: OrgserviceService,private http: HttpClient,private toastr: ToastrService) { }

  ngOnInit() {
    this.showSubmitButton = false;
    this.loadOrganizations()
  }

  loadOrganizations(){
    this.spinner.show()
    this.orgservice.getOrganisationList().then(data=>{
     this.dropdownData = data['data'];
     this.spinner.hide();
    },error=>{
      console.log(error.message);
      this.spinner.hide();
    }).catch(err=>{
      console.log(err.message);
      this.spinner.hide();
    })
  }

  getDetails(id){
    this.index = this.dropdownData.findIndex((obj) => obj.id == id)
    this.authMatrix = this.dropdownData[this.index].auth_matrix
    if(this.dropdownData[this.index].status != 'Approved'){
      this.toastr.warning("Corporate is not yet approved!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    } else if(this.dropdownData[this.index].maker_restriction == 'restricted') {
      this.showNext = true
      this.getCorpUsers(id)
      this.getCorpAlias(id)
    } else {
      this.showNext = false;
      this.toastr.error("Corporate is set to maker unrestricted!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
  }

  getCorpUsers(id){
    console.log("ID",id)
    this.spinner.show()
     
    this.orgservice.getOrganisationUserList(id).then(data=>{
      this.spinner.hide();
      if(data['success'] == true){
        this.userDropdownData = data['log'].filter(obj =>{
          return this.authMatrix == 'maker_checker' ?  obj.role.name == 'Maker' : obj.self_approval == true
        });
      } else {
        this.toastr.error("Something went wrong!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
     },error=>{
       console.log(error.message);
       this.spinner.hide();
     }).catch(err=>{
       console.log(err.message);
       this.spinner.hide();
     })
  }

  getCorpAlias(id){
    this.spinner.show()
  this.vcn.getAliasByCorpId(id).then(resp=>{
   
    if(resp['message']=='Alias data' && resp['error']==null){
      this.aliasDropdownData=resp['data']
      this.spinner.hide()
    }else{
      console.log('Something Went Wrong')
      this.spinner.hide()
    }
  },error=>{
    this.spinner.hide()
    this.aliasDropdownData = []
    this.toastr.error("NO Alias Found!","Alert",{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
  })
  }

  async setUser(id){
    const user = await this.userDropdownData.filter(usr => {return id == usr.id})
    console.log(user[0].alias_id.split(","))
    this.billdata.alias_id =user[0].alias_id == null ? '': user[0].alias_id.split(",")
  }

  onSubmit(){
    console.log({
      "corporate_id":this.billdata.corporate_id,
      "user_id":this.billdata.user_id,
      "alias_ids":this.billdata.alias_id.toString()
    })
    this.spinner.show()
    this.orgservice.setUserAliasMapping({
      "corporate_id":this.billdata.corporate_id,
      "user_id":this.billdata.user_id,
      "alias_ids":this.billdata.alias_id.toString()
    }).then(data=>{
      this.spinner.hide();
      if(data['success'] == true){
        this.toastr.success("User Mapped Successfully!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      } else {
        this.toastr.error("Something went wrong!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
      this.getCorpUsers(this.billdata.corporate_id)
     },error=>{
       console.log(error.message);
       this.toastr.error("Something went wrong!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
       this.spinner.hide();
     }).catch(err=>{
       console.log(err.message);
       this.spinner.hide();
     })
  }
  getAlaisName(id){
    const searchIndex = this.aliasDropdownData.findIndex((car) => car.id==id);
    console.log("ID",id,searchIndex)
    return this.aliasDropdownData[searchIndex].alias_name
  }

  loadSupLogs(id:number,index){
    this.selectedIndex = index;
  }

}
