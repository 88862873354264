import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
declare var jquery:any;
declare var $ :any;
// declare var loadCharts: any;
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import{CardService} from '../../_api/card.service'
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dasboard',
  templateUrl: './dasboard.component.html',
  styleUrls: ['./dasboard.component.css']
})
export class DasboardComponent implements OnInit {
  @ViewChild('myDiv') myDiv: ElementRef;
 // public dashboardData: any;
  public params:string;
  display='none'; //default Variable
  top='50px';
  public username:string;
  public rolename:string;
  currentUser: Users;
  isUnisup:boolean;
  isBulksup:boolean;
  isApprsup:boolean;
  isUnipay:boolean;
  isApprpay:boolean;
  isBulkpay:boolean;
  isPaysupstatus:boolean;
  isCardActivity:boolean;
  cards:any=[];
  pendingcards:any=[];
  approvedcards:any=[];
  rejectedcards:any[];
  constructor(private userservice: UserService,private cardservice: CardService,private router: Router,private activatedRoute: ActivatedRoute) { }
  
  ngOnInit() {
    
    this.params = this.activatedRoute.snapshot.queryParams["msg"];
    this.userservice.getUserDetails().subscribe(users => {
      this.currentUser = users['Data'];
      this.username=this.currentUser['firstname']+' '+this.currentUser['lastname'];
      this.rolename=this.currentUser['rolename']
      if(this.currentUser.tabpermissionids !=null){
      var str = this.currentUser.tabpermissionids;
      str = str.replace("pid=" ,"");
      str = str.replace(";cid=",",");
      str = str.replace(";","");
      var tab_arr = str.split(",");
      //this.isUnicard = this.checkValInArray("24",tab_arr)
      
      this.isUnisup = this.checkValInArray("27",tab_arr)
      this.isBulksup = this.checkValInArray("51",tab_arr)
      this.isApprsup = this.checkValInArray("74",tab_arr)
      this.isUnipay = this.checkValInArray("20",tab_arr)
      this.isApprpay = this.checkValInArray("75",tab_arr)
      this.isBulkpay = this.checkValInArray("21",tab_arr)

      if(this.isUnisup==true || this.isBulksup==true || this.isApprsup==true || this.isUnipay==true || this.isApprpay == true || this.isBulkpay == true){
        this.isPaysupstatus=true;
        this.isCardActivity=false;
      }else{
        this.isPaysupstatus=false;
        this.isCardActivity=true;
      }
    }else{
    this.isCardActivity=true;}


      });

      if(this.params=='firstloginas'){
   
        this.myDiv.nativeElement.click();
      
      }
    
  }
  checkValInArray(tabid:string , tab_array:string[]){
    return (tab_array.indexOf(tabid)==-1)?false:true;
}

private loadallcards(){

  this.cardservice.getAll().subscribe(data=>{

    this.cards=data["data"];
    this.pendingcards=data["data"]["pendingcards"];
    this.rejectedcards=data["data"]["rejectedcards"]
    this.approvedcards=data["data"]["approvedcards"]
  },error=>{
    
      console.log("Failed to Fetch")
  }

  )

}


}
