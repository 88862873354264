import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute,NavigationEnd  } from '@angular/router';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
import { ToastrService } from 'ngx-toastr'
import {VcnuserService} from '../../_api/vcnuser.service'
import {VcnService} from '../../_api/vcn.service'
import { NgxSpinnerService } from "ngx-spinner";
import {ExcelGeneratorService} from  '../../_api/excel-generator.service'
import { ExcelService } from '../../excelservice/excel.service'
import {VcncorpService} from '../../_api/vcncorp.service'
@Component({
  selector: 'app-update-corp-contact-unitary',
  templateUrl: './update-corp-contact-unitary.component.html',
  styleUrls: ['./update-corp-contact-unitary.component.css']
})
export class UpdateCorpContactUnitaryComponent implements OnInit {
mobile_num:string=null
purchase_id:string=null
  constructor(private route:Router,private toastr: ToastrService, private vcnuser:VcnuserService,private spinner: NgxSpinnerService,private excelservice : ExcelService, private excel:ExcelGeneratorService,private vcn: VcnService,private vcncorp:VcncorpService) { }

  ngOnInit() {
  }

  updateContact(){
    this.spinner.show()
    if(this.mobile_num!=null && this.purchase_id!=null){
      var params={
        "mobile_no":this.mobile_num,
        "request_purchase_id":this.purchase_id
      }
      this.vcncorp.updateSingleContact(params).then(resp=>{
        console.log(resp)
        if(resp['message']=='Record not found!'){
          this.spinner.hide()
          this.toastr.warning("Purchased Request ID not found in our system!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }else if(resp['message']=='Mobile updation request initiated successfully!'){
          this.spinner.hide()
                 this.route.navigate(['/mobile-update-success-corp']);
        }else{
          this.spinner.hide()
          this.toastr.warning("Something went wrong!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
       
      },error=>{
        this.spinner.hide()
        console.log(error)
        this.toastr.warning("Failed to update number!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      })
    }else{
      this.spinner.hide()
      this.toastr.warning("Please fill all the details first!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
  
  }


validateMobileNo(value){    
  var k = value.keyCode;
        return ((k >= 48 && k <= 57) || k == 8);
}

}
