import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {AuthService} from '../../_api/auth.service'
import {FormControl, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OtpComponent implements OnInit {
  returnUrl: string;
  submitted = false;
  otpData:any = {};
  otp:number;
  otpstring:string;

  constructor(private authService: AuthService,private route: ActivatedRoute,private router: Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/main';
  }

  otpPost(){
    localStorage.removeItem('data');
    this.submitted = true;
    
    if(this.otpData['otp']==undefined || this.otpData['otp']==""){
      this.toastr.warning("Please Enter OTP!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }else{
      this.otpstring=this.otpData['otp']
    
    //this.otpstring=String(this.otp)
   
    this.authService.postOtp(this.otpstring)
    }
    //this.router.navigate([this.returnUrl]);
  }

}
