import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
import { Groups } from '../../models/groups';
import{LoaderService} from '../../_api/loader.service';
import { ExcelService } from '../../excelservice/excel.service'
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import { DatePipe } from '@angular/common'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
import {ExcelGeneratorService} from  '../../_api/excel-generator.service'
import {VcncorpService} from '../../_api/vcncorp.service'

import * as CryptoJS from 'crypto-js';
import { Config } from 'src/app/config';

const decryptKey = new Config().getVCNCipher()
const decryptIV = new Config().getVCNText()

@Component({
  selector: 'app-corp-reports',
  templateUrl: './corp-reports.component.html',
  styleUrls: ['./corp-reports.component.css']
})


export class CorpReportsComponent implements OnInit {
  public id: string;
  filedata:any=[]
  downloadArray:any=[];
  filesummary:any={}
  cardholder:any;
  cardno:any;
  cardcvv:any;
  cardexpiry:any;
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  additonal_amount:any=0
  current_amount:any=0
  add_curr_amt:any=0;
  total_amt_in_words:any="TWO THOUSAND RUPEES";
  selectedIndex = -1;
  currentFDate:Date=new Date();
  currentTDate:Date=new Date();
  currFdate:string;
  currTdate:string;
  fromdate:any;
  todate:any;
  cardId:any
  decrypted: any;
  balanceSeen: boolean=false;
  constructor(private activeroute: ActivatedRoute,private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private route:Router,private excelservice : ExcelService, private excel:ExcelGeneratorService,private vcncorp:VcncorpService) { }

  ngOnInit() {
    this.id = this.activeroute.snapshot.paramMap.get('id');
    this.currentFDate.setDate(this.currentFDate.getDate() - 1);
    this.currFdate=this.datepipe.transform(this.currentFDate, 'yyyy-MM-dd');
    this.currTdate=this.datepipe.transform(this.currentTDate, 'yyyy-MM-dd');
    if(this.id!=undefined || this.id!=null){
      this.loadFileData()
    }
    else{
      this.toastr.error("Something Went wrong!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }

  }

  viewcardDetails(id:any): void {
    this.cardId=id
    $("#payreport").modal("show");
 
}

  private loadFileData(){
    this.spinner.show()
    this.vcncorp.getCorpFileData(this.id).then(resp=>{
      var cardList=resp['data']['file_data']
      for(var data of cardList){
        var obj={
          id:data['id'],
          name:data['name'],
          card_no:data['card_no'],
          card_no_show:`${data["status"] == "Inactive" ? "" : data['card_no'] != null ? 'XXXX XXXX XXXX '+this.decryptUsingAES256(data['card_no']).substring(16,12) : 'null'}`,
          request_purchase_id:data['request_purchase_id'],
          transaction_ref_no:data['transaction_ref_no'],
          amount:data['amount'],
          card_generated_date:data['card_generated_date'],
          card_generated_time:data['card_generated_time'],
          no_of_transaction:data["no_of_transaction"],
          valid_from:data["valid_from"],
          valid_to:data["valid_to"],
          min_transaction:data["min_transaction"],
          max_transaction:data["max_transaction"],
          new_card_number:data['card_no'] != null ? `XXXX XXXX XXXX 
                ${this.decryptUsingAES256(data['card_no']).substring(16,12)}`:"",
          new_expiry:data['expiry'] != null ? `${this.decryptUsingAES256(data['expiry']).substring(2,0)} / ${this.decryptUsingAES256(data['expiry']).substring(6,2)}`:"" ,
          cardView:false,
          cvv_new:data['cvv'] != null ? this.decryptUsingAES256( data['cvv']) : "",
          balanceSeen:false
        }
        this.filedata.push(obj)
     
      }
      for(var i=0;i<resp['data'].length;i++){
       
      }
      console.log(this.filedata);
      
      
      //this.filedata=resp['data']['file_data']
   
      this.filesummary={
        "corporate_name": resp['data']['corporate_name'],
        "corporate_id": resp['data']['corporate_id'],
        "file_name": resp['data']['file_name'],
        "total_cards": resp['data']['total_cards'],
        "total_amount": resp['data']['total_amount'],
        "utr_no":resp['data']['utr_no'],
        "status":resp['data']['status'],
        "bank_name":resp['data']['bank_name'],
        "transaction_date": resp['data']['transaction_date'],
        "alias_name":resp['data']['alias_name'],
        "alias_id":resp['data']['alias_id'],
        "tempate_name":resp['data']['template_name'],
      "template_id":resp['data']['template_id'],
      "supplier_name":resp['data']['supplier_name'],
      "supplier_id":resp['data']['supplier_id'],
      }
      this.spinner.hide()

    },error=>{
      this.spinner.hide()
      console.log(error)
      this.toastr.error("Failed to load data!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    })
  }

  exportAsXLSX():void {
    this.downloadArray=[]
    
    if(this.filedata.length>=0){
      for(let data of this.filedata){
        var obj={
          Name:data['name'],
          Token_Number:'XXXX XXXX XXXX '+ data['card_no'] != null ? this.decryptUsingAES256(data['card_no']).substring(16,12) : "",
          Amount:data['amount'],
          Balance: data['balance'],
          Transaction_Ref_No:data['request_purchase_id'],
          Token_Generation_Date:data['card_generated_date'],
          Token_Generation_Time:data['card_generated_time'],
          No_of_transaction:data["no_of_transaction"],
          Valid_from:data["valid_from"],
          Valid_to:data["valid_to"],
          minimum_transactions:data["min_transaction"],
          maximum_transactions:data["max_transaction"],
        }
        this.downloadArray.push(obj)
      }
      this.excel.exportAsExcelFile( this.downloadArray, this.filesummary['file_name'].split('.')[0]);
    }else{
      this.toastr.warning("No Data Available","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
   // this.excelservice.exportAsExcelFile( this.users, 'User');
  }

  gotobalanceview(){
    this.route.navigate(['/check-balances', this.id]);
  }

  checkBalPopUp(){
    $("#checkBalRep").modal("show");
  }
  
  addAdditionalAmt(amt){
    this.current_amount=amt
    this.add_curr_amt=parseFloat(amt)+parseFloat(this.additonal_amount)
    $("#AdditionAmtModal").modal("show");
  }

  submitaddbal(){
    this.toastr.success("New Limit has been updated successfully","Alert",{
      timeOut:30000000,
      positionClass:'toast-top-center'
      })
  }
  toggleExpandedView(index){
    this.selectedIndex = index;
   }


   getBalance(id,crd,i){
    this.spinner.show()
    var params={
      "id":id
    }
    this.vcncorp.getCorpCardBalance(params).then(resp=>{
      if(resp['message']=='Purchase request id not available!'){
        this.spinner.hide()
        this.toastr.error("Token Details are not available","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='The Requested has been Canceled'){
        this.spinner.hide()
        this.toastr.error("This token has been blocked!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='Available Balance!'){
        for(var data of this.filedata){
            if(data['id']==resp['id']){
              this.spinner.hide()
              data['balance']=resp['data']
              
            }
        }
        console.log(i);
        
        console.log(this.filedata[i]);
        this.filedata[i]['balanceSeen']=true
        console.log(this.filedata);
        
        console.log(resp)
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }

  gotoTransactions(id:any){
    this.spinner.show()
    var param={
      "id":id,
      "from_date":this.currFdate,
      "to_date":this.currTdate
    }
    this.vcncorp.getCorpVcnAuthReport(param).then(resp=>{
      console.log(resp)
      if(resp['message']=='There is no transaction between the given dates'){
        this.spinner.hide()
        this.toastr.error("No Transactions found!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='VCN authorization report!'){
        this.spinner.hide()
        localStorage.setItem('authreport',JSON.stringify(resp['data']))
        this.route.navigate(['/corp-current-report']);
      }else if(resp['message']=='Code: ERROR_CREATE_DELETED_VCN_AUTHS_REPORT Description: We are unable to create an authorization report for a cancelled VCN. To view these transactions please run the authorization report for the RCN.'){
        this.spinner.hide()
        this.toastr.error("Token has been cancelled!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else{
        this.spinner.hide()
        this.toastr.error("Semething Went Wrong!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
      if(error.error.message=='File data not found!') {
        this.toastr.error("Failed to get transaction details as token has been blocked!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      } else {
        this.toastr.error("Failed to Get Auth Report!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    })
  }


  decryptUsingAES256(value:any) {
    if(value == null){
      return null
    }

    let _key = CryptoJS.enc.Utf8.parse(decryptKey);
    let _iv = CryptoJS.enc.Utf8.parse(decryptIV);


    this.decrypted = CryptoJS.AES.decrypt(value, _key, {
      keySize: 32,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);

    return this.decrypted
  }

  gotodaterangereport(){
    // console.log(this.cardId)
    // console.log(this.fromdate)
    // console.log(this.todate)
    this.spinner.show()
    if(this.fromdate!=undefined || this.fromdate!=null){
      this.fromdate=this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    }
    else{
      this.fromdate=this.currFdate
    }
    
    if(this.todate!=undefined || this.todate!=null){
      this.todate=this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    }
   else{
    this.todate=this.currTdate
    }


    var param={
      "id":this.cardId,
      "from_date":this.fromdate,
      "to_date":this.todate
    }
    this.vcncorp.getCorpVcnAuthReport(param).then(resp=>{
      console.log(resp)
      if(resp['message']=='There is no transaction between the given dates'){
        this.spinner.hide()
        this.toastr.error("No Transactions found!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='VCN authorization report!'){
        this.spinner.hide()
        localStorage.setItem('authreportdr',JSON.stringify(resp['data']))
       this.route.navigate(['/corp-range-report']);
       
      }else if(resp['message']=='Code: ERROR_CREATE_DELETED_VCN_AUTHS_REPORT Description: We are unable to create an authorization report for a cancelled VCN. To view these transactions please run the authorization report for the RCN.'){
        this.spinner.hide()
        this.toastr.error("Token has been cancelled!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='The report date range cannot exceed 31 day(s).'){
        this.spinner.hide()
        this.toastr.error("The report date range cannot exceed 31 day(s).","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='From date should be before to date.'){
        this.spinner.hide()
        this.toastr.error("From date should be before to date.","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else{
        this.spinner.hide()
        this.toastr.error("Semething Went Wrong!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
      if(error.error.message=='File data not found!') {
        this.toastr.error("Failed to get transaction details as token has been blocked!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      } else {
        this.toastr.error("Failed to Get Auth Report!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
     
    })
   // this.route.navigate(['/date-range-report']);

  }

  cardToggleView(index){
    this.filedata[index]['cardView']=!this.filedata[index]['cardView']

}
}
