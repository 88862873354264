import { BrowserModule } from '@angular/platform-browser';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgModule } from '@angular/core';
import { routing } from './app.routing';
import { RouterModule } from '@angular/router';
import { ApiService } from './_api/api.service'
import { AuthService } from './_api/auth.service'
import { UserService } from './_api/user.service'
import { CardService } from './_api/card.service'
import { AccoutsetupService } from './_api/accoutsetup.service'
import { TrueFalseValueDirective } from './directives/truefalsevalue.directive'
import { AuthInterceptorService } from './_api/authinterceptor.service'
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatRadioModule, MatCheckboxModule, MatSelectModule, MatTooltipModule} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { LoginComponent } from './authentication/login/login.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotComponent } from './authentication/forgot/forgot.component';
import { MatNativeDateModule } from '@angular/material';
import { DasboardComponent } from './routingcomponents/dasboard/dasboard.component';

import { HomeComponent } from './supportingcomponents/home/home.component';
import { OrganisationComponent } from './routingcomponents/organisation/organisation.component';
import { AddbulkusersComponent } from './routingcomponents/addbulkusers/addbulkusers.component';
import { PaginationComponent } from './supportingcomponents/pagination/pagination.component';
import { UserviewComponent } from './routingcomponents/userview/userview.component';
import { UnitaryuserComponent } from './routingcomponents/unitaryuser/unitaryuser.component';
import { CardviewComponent } from './routingcomponents/cardview/cardview.component';
import { UnitarycardComponent } from './routingcomponents/unitarycard/unitarycard.component';
import { SuccessComponent } from './supportingcomponents/success/success.component';
import { AlertComponent } from './directives/alert/alert.component';
import { MyprofileComponent } from './supportingcomponents/myprofile/myprofile.component';
import { LoaderService } from './_api/loader.service';
import { LandingComponent } from './landing/landing.component';
import { DataFilterPipeUser } from './routingcomponents/userview/data-filter-user.pipe';
import { DataTableModule } from "angular2-datatable";
import { EncodeurlComponent } from './encodeurl/encodeurl.component';
import { OtpComponent } from './authentication/otp/otp.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { NumberOnlyDirective } from './directives/numbers.directive';
import { EmailValidator } from './directives/emailValidator.directive';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
//import { MyDatePickerModule } from 'angular4-datepicker/src/my-date-picker/my-date-picker.module';
import { MyDatePickerModule } from 'mydatepicker';
import { EditorgComponent } from './routingcomponents/editorg/editorg.component';
import { OwlModule } from 'ngx-owl-carousel';
import { IfscVerificationService } from './_api/ifsc-verification.service';
import { ResetPasswordComponent } from './routingcomponents/resetpassword/resetpassword.component'
import { ResetPasswordService } from './_api/reset-password.service';
import { NotificationmatrixComponent } from './routingcomponents/notificationmatrix/notificationmatrix.component';
import { NotificationMatrixService } from './routingcomponents/notificationmatrix/notificationmatrix.service';
import { SessionService } from './sessionservice/storage.service';
import { ExcelService } from './excelservice/excel.service';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { Http, ResponseContentType , Headers} from '@angular/http';
import { HttpModule } from '@angular/http';
import { ToastrModule } from 'ngx-toastr';
import { UserhomeComponent } from './userhome/userhome.component';
import { InputTrimModule } from 'ng2-trim-directive';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PendingusersComponent } from './routingcomponents/pendingusers/pendingusers.component';
import { PendingcardsComponent } from './routingcomponents/pendingcards/pendingcards.component';
import { OtpuserComponent } from './routingcomponents/otpuser/otpuser.component';
import { OtpcardComponent } from './routingcomponents/otpcard/otpcard.component';
import { AccountsetupComponent } from './routingcomponents/accountsetup/accountsetup.component';
import { AspendingusersFilterPipe } from './routingcomponents/pendingusers/Aspendingusers-filter.pipe';
import { RejectmsgComponent } from './supportingcomponents/rejectmsg/rejectmsg.component';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { RmservicesService } from './_api/rmservices.service';
import { DatePipe } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderModule } from 'ngx-order-pipe';
import { SetnewpasswordComponent } from './authentication/setnewpassword/setnewpassword.component';
import { NewLoginComponent } from './authentication/new-login/new-login.component';
import { NewOtpComponent } from './authentication/new-otp/new-otp.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
import { AutoTabDirective} from './directives/autotab.directive';
import {AmountNumberDirective} from './directives/amountnumber.directive'
import { NewForgotpwdComponent } from './authentication/new-forgotpwd/new-forgotpwd.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FooterComponent } from './supportingcomponents/footer/footer.component';
import { FusionChartsModule } from "angular-fusioncharts";
// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as charts from "fusioncharts/fusioncharts.charts";
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { CarduploadComponent } from './admin_components/cardupload/cardupload.component';
import { CheckercardComponent } from './admin_components/checkercard/checkercard.component';
import { CarduploadViewComponent } from './admin_components/cardupload-view/cardupload-view.component';
import { ApprovecardwidgetComponent } from './admin_components/approvecardwidget/approvecardwidget.component';
import { PendingcardwidgetComponent } from './admin_components/pendingcardwidget/pendingcardwidget.component';
import { BlockedcardwidgetComponent } from './admin_components/blockedcardwidget/blockedcardwidget.component';
import { CardreqhomeComponent } from './admin_components/cardreqhome/cardreqhome.component';
import { CardrelistbyfileComponent } from './admin_components/cardrelistbyfile/cardrelistbyfile.component';
import { CardreqsubsuccessComponent } from './admin_components/cardreqsubsuccess/cardreqsubsuccess.component';
import { CardreqapprsuccessComponent } from './admin_components/cardreqapprsuccess/cardreqapprsuccess.component';
import { MakercardviewComponent } from './admin_components/makercardview/makercardview.component';
import { CardreqheaderComponent } from './admin_components/cardreqheader/cardreqheader.component';
import { CardaliasComponent } from './admin_components/cardalias/cardalias.component';
import { PendingFilesComponent } from './admin_components/pending-files/pending-files.component';
import { ApprovedFilesComponent } from './admin_components/approved-files/approved-files.component';
import { VcnViewComponent } from './corporatecomponents/vcn-view/vcn-view.component';
import { CorporateDashboardComponent } from './corporatecomponents/corporate-dashboard/corporate-dashboard.component';
import { CorporateHeaderComponent } from './corporatecomponents/corporate-header/corporate-header.component';
import { CorporatePendingFilesComponent } from './corporatecomponents/corporate-pending-files/corporate-pending-files.component';
import { CorporateApprovedFilesComponent } from './corporatecomponents/corporate-approved-files/corporate-approved-files.component';
import { PendingVcnViewComponent } from './corporatecomponents/pending-vcn-view/pending-vcn-view.component';
import {ExcelGeneratorService} from './_api/excel-generator.service';
import { ClearanceReportComponent } from './admin_components/clearance-report/clearance-report.component';
import { CheckBalanceComponent } from './admin_components/check-balance/check-balance.component';
import { CorporateOnboardingComponent } from './admin_components/corporate-onboarding/corporate-onboarding.component';
import { CorporateviewComponent } from './admin_components/corporateview/corporateview.component';
import { CorporateUsersComponent } from './admin_components/corporate-users/corporate-users.component';
import { UpdateContactComponent } from './admin_components/update-contact/update-contact.component';
import { MobilesuccessupdateComponent } from './admin_components/mobilesuccessupdate/mobilesuccessupdate.component';
import { MobileSignInComponent } from './user_components/mobile-sign-in/mobile-sign-in.component';
import { MobileOtpComponent } from './user_components/mobile-otp/mobile-otp.component';
import { CardsListingComponent } from './user_components/cards-listing/cards-listing.component';
import { AuthrizationReportComponent } from './user_components/authrization-report/authrization-report.component';
import { DateRangeReportComponent } from './user_components/date-range-report/date-range-report.component'
import {VcnuserService} from './_api/vcnuser.service'
import {VcnService} from './_api/vcn.service';
import { CardBlockComponent } from './admin_components/card-block/card-block.component';
import { CardcancelsuccessComponent } from './admin_components/cardcancelsuccess/cardcancelsuccess.component';
import { UserheaderComponent } from './user_components/userheader/userheader.component';
import { PendingfileswidgetComponent } from './corporatecomponents/pendingfileswidget/pendingfileswidget.component';
import { ApprovedfileswidgetsComponent } from './corporatecomponents/approvedfileswidgets/approvedfileswidgets.component';
import { CorpHomeComponent } from './corporatecomponents/corp-home/corp-home.component';
import { ReqReviewPageComponent } from './corporatecomponents/req-review-page/req-review-page.component';
import { CorporateVcnViewComponent } from './corporatecomponents/corporate-vcn-view/corporate-vcn-view.component'
import {VcncorpService} from './_api/vcncorp.service';
import { ReqSubmitSuccessComponent } from './corporatecomponents/req-submit-success/req-submit-success.component';
import { ReqApproveSuccessComponent } from './corporatecomponents/req-approve-success/req-approve-success.component';
import { ReqRejectSuccessComponent } from './corporatecomponents/req-reject-success/req-reject-success.component';
import { CorporateRejectedFilesComponent } from './corporatecomponents/corporate-rejected-files/corporate-rejected-files.component';
import { CorpdrangereportComponent } from './corporatecomponents/corpdrangereport/corpdrangereport.component';
import { CorpCurrentReportComponent } from './corporatecomponents/corp-current-report/corp-current-report.component';
import { CorpReportsComponent } from './corporatecomponents/corp-reports/corp-reports.component';
import { CorpReportsFilesComponent } from './corporatecomponents/corp-reports-files/corp-reports-files.component';
import { AdminReportsComponent } from './admin_components/admin-reports/admin-reports.component';
import { AdminReportsFilesComponent } from './admin_components/admin-reports-files/admin-reports-files.component';
import { AdminCurrentReportComponent } from './admin_components/admin-current-report/admin-current-report.component';
import { AdminRangeReportComponent } from './admin_components/admin-range-report/admin-range-report.component';
import { UnitarySearchComponent } from './admin_components/unitary-search/unitary-search.component';
import { UpdateCorpContactBulkComponent } from './corporatecomponents/update-corp-contact-bulk/update-corp-contact-bulk.component';
import { UpdateCorpContactUnitaryComponent } from './corporatecomponents/update-corp-contact-unitary/update-corp-contact-unitary.component';
import { UpdateCorpContactSuccessComponent } from './corporatecomponents/update-corp-contact-success/update-corp-contact-success.component';
import { InprocessFilesComponent } from './admin_components/inprocess-files/inprocess-files.component';
import { InprocessVcnViewComponent } from './admin_components/inprocess-vcn-view/inprocess-vcn-view.component';
import { SendBackSuccessComponent } from './admin_components/send-back-success/send-back-success.component';
import { PendingContactComponent } from './corporatecomponents/pending-contact/pending-contact.component';
import { ApproveContactSuccessComponent } from './corporatecomponents/approve-contact-success/approve-contact-success.component';
import { MobileLoginComponent } from './user_components_open/mobile-login/mobile-login.component';
import { MobileOtpOpenComponent } from './user_components_open/mobile-otp-open/mobile-otp-open.component';
import { AuthReportOpenComponent } from './user_components_open/auth-report-open/auth-report-open.component';
import { CardListingOpenComponent } from './user_components_open/card-listing-open/card-listing-open.component';
import { DateRangeReportOpenComponent } from './user_components_open/date-range-report-open/date-range-report-open.component';
import { UserHeaderOpenComponent } from './user_components_open/user-header-open/user-header-open.component'
import { InquireCardsComponent } from './admin_components/inquire-cards/inquire-cards.component';
import { UserIdleModule } from 'angular-user-idle';
import { CorpCancelcardReviewReqComponent } from './corporatecomponents/corp-cancelcard-review-req/corp-cancelcard-review-req.component';
import { CorpCancelcardApprovedFilesComponent } from './corporatecomponents/corp-cancelcard-approved-files/corp-cancelcard-approved-files.component';
import { CorpCancelcardPendingFilesComponent } from './corporatecomponents/corp-cancelcard-pending-files/corp-cancelcard-pending-files.component';
import { CorpCancelcardRejectedFilesComponent } from './corporatecomponents/corp-cancelcard-rejected-files/corp-cancelcard-rejected-files.component';
import { CorpCancelcardVcnViewComponent } from './corporatecomponents/corp-cancelcard-vcn-view/corp-cancelcard-vcn-view.component';
import { PendingCancelcardVcnViewComponent } from './corporatecomponents/pending-cancelcard-vcn-view/pending-cancelcard-vcn-view.component';
import { ApprovedCancelCardFilesComponent } from './admin_components/approved-cancel-card-files/approved-cancel-card-files.component';
import { RejectedCancelCardFilesComponent } from './admin_components/rejected-cancel-card-files/rejected-cancel-card-files.component';
import { MakerCancelCardViewComponent } from './admin_components/maker-cancel-card-view/maker-cancel-card-view.component';
import { UpdateBalanceComponent } from './admin_components/update-balance/update-balance.component';
import { AuthCodeSetupComponent } from './authentication/auth-code-setup/auth-code-setup.component';
import { FirstTimeOTPComponent } from './authentication/first-time-otp/first-time-otp.component';
import { FirstTimeSetPasswordComponent } from './authentication/first-time-set-password/first-time-set-password.component';
import { CorpUnitarySearchComponent } from './corporatecomponents/corp-unitary-search/corp-unitary-search.component';
import { AddUsersComponent } from './corporateonboarding/add-users/add-users.component';
import { UploadDocsComponent } from './supportingcomponents/upload-docs/upload-docs.component';
import { OrgRegComponent } from './supportingcomponents/org-reg/org-reg.component';
import { OrgListComponent } from './supportingcomponents/org-list/org-list.component';
import { OrgDetailsComponent } from './supportingcomponents/org-details/org-details.component';
import { AddCcadminComponent } from './corporateonboarding/add-ccadmin/add-ccadmin.component';
import { AddAliasComponent } from './supportingcomponents/add-alias/add-alias.component';
import { AddTemplateComponent } from './supportingcomponents/add-template/add-template.component';
import { AddSupplierComponent } from './supportingcomponents/add-supplier/add-supplier.component';
import { UpdateUserDetailsComponent } from './supportingcomponents/update-user-details/update-user-details.component';
import { ResetAuthcodeComponent } from './supportingcomponents/reset-authcode/reset-authcode.component';
import { AddUsersAdminComponent } from './supportingcomponents/add-users-admin/add-users-admin.component';
import { TokenUploadComponent } from './corporatecomponents/token-upload/token-upload.component';
import { AddAuthorizeUserComponent } from './corporatecomponents/add-authorize-user/add-authorize-user.component';
import { EmailSignInComponent } from './user_components/email-sign-in/email-sign-in.component';
import { EmailOtpComponent } from './user_components/email-otp/email-otp.component';
import { MapUserAliasComponent } from './supportingcomponents/map-user-alias/map-user-alias.component';
import { VoloSeachClaimnoAdminComponent } from './admin_components/volo-seach-claimno-admin/volo-seach-claimno-admin.component';
import { VoloDashboardAdminComponent } from './admin_components/volo-dashboard-admin/volo-dashboard-admin.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MaintenancePageComponent } from './supportingcomponents/maintenance-page/maintenance-page.component';
import { PendingPccComponent } from './gdscomponents/pending-pcc/pending-pcc.component';
import { ViewPccComponent } from './gdscomponents/view-pcc/view-pcc.component';
import { ViewGdsUsersComponent } from './gdscomponents/view-gds-users/view-gds-users.component';
import { PendingGdsUsersComponent } from './gdscomponents/pending-gds-users/pending-gds-users.component';
import { registerLocaleData } from '@angular/common';

import localeIn from '@angular/common/locales/en-IN';
registerLocaleData(localeIn)
// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, charts, FusionTheme);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotComponent,
    DasboardComponent,

    HomeComponent,
    OrganisationComponent,
    AddbulkusersComponent,
    PaginationComponent,
    UserviewComponent,
    UnitaryuserComponent,
    CardviewComponent,
    UnitarycardComponent,
    SuccessComponent,
    TrueFalseValueDirective,
    AlertComponent,
    MyprofileComponent,
    LandingComponent,
    DataFilterPipeUser,
    EncodeurlComponent,
    OtpComponent,
    NumberOnlyDirective,
    EmailValidator,
    EditorgComponent,
    ResetPasswordComponent,
    NotificationmatrixComponent,
    UserhomeComponent,
    PendingusersComponent,
    PendingcardsComponent,
    OtpuserComponent,
    OtpcardComponent,
    AccountsetupComponent,
    AspendingusersFilterPipe,
    RejectmsgComponent,
    SetnewpasswordComponent,
    NewLoginComponent,
    NewOtpComponent,
    PrivacyPolicyComponent,
    AutoTabDirective,
    NewForgotpwdComponent,
    AmountNumberDirective,
    FooterComponent,
    CarduploadComponent,
    CheckercardComponent,
    CarduploadViewComponent,
    ApprovecardwidgetComponent,
    PendingcardwidgetComponent,
    BlockedcardwidgetComponent,
    CardreqhomeComponent,
    CardrelistbyfileComponent,
    CardreqsubsuccessComponent,
    CardreqapprsuccessComponent,
    MakercardviewComponent,
    CardreqheaderComponent,
    CardaliasComponent,
    PendingFilesComponent,
    ApprovedFilesComponent,
    VcnViewComponent,
    CorporateDashboardComponent,
    CorporateHeaderComponent,
    CorporatePendingFilesComponent,
    CorporateApprovedFilesComponent,
    PendingVcnViewComponent,
    ClearanceReportComponent,
    CheckBalanceComponent,
    CorporateOnboardingComponent,
    CorporateviewComponent,
    CorporateUsersComponent,
    UpdateContactComponent,
    MobilesuccessupdateComponent,
    MobileSignInComponent,
    MobileOtpComponent,
    CardsListingComponent,
    AuthrizationReportComponent,
    DateRangeReportComponent,
    CardBlockComponent,
    CardcancelsuccessComponent,
    UserheaderComponent,
    PendingfileswidgetComponent,
    ApprovedfileswidgetsComponent,
    CorpHomeComponent,
    ReqReviewPageComponent,
    CorporateVcnViewComponent,
    ReqSubmitSuccessComponent,
    ReqApproveSuccessComponent,
    ReqRejectSuccessComponent,
    CorporateRejectedFilesComponent,
    CorpdrangereportComponent,
    CorpCurrentReportComponent,
    CorpReportsComponent,
    CorpReportsFilesComponent,
    AdminReportsComponent,
    AdminReportsFilesComponent,
    AdminCurrentReportComponent,
    AdminRangeReportComponent,
    UnitarySearchComponent,
    UpdateCorpContactBulkComponent,
    UpdateCorpContactUnitaryComponent,
    UpdateCorpContactSuccessComponent,
    InprocessFilesComponent,
    InprocessVcnViewComponent,
    SendBackSuccessComponent,
    PendingContactComponent,
    ApproveContactSuccessComponent,
    MobileLoginComponent,
    MobileOtpOpenComponent,
    AuthReportOpenComponent,
    CardListingOpenComponent,
    DateRangeReportOpenComponent,
    UserHeaderOpenComponent,
    InquireCardsComponent,
    CorpCancelcardReviewReqComponent,
    CorpCancelcardApprovedFilesComponent,
    CorpCancelcardPendingFilesComponent,
    CorpCancelcardRejectedFilesComponent,
    CorpCancelcardVcnViewComponent,
    PendingCancelcardVcnViewComponent,
    ApprovedCancelCardFilesComponent,
    RejectedCancelCardFilesComponent,
    MakerCancelCardViewComponent,
    UpdateBalanceComponent,
    AuthCodeSetupComponent,
    FirstTimeOTPComponent,
    FirstTimeSetPasswordComponent,
    CorpUnitarySearchComponent,
    AddUsersComponent,
    UploadDocsComponent,
    OrgRegComponent,
    OrgListComponent,
    OrgDetailsComponent,
    AddCcadminComponent,
    AddAliasComponent,
    AddTemplateComponent,
    AddSupplierComponent,
    UpdateUserDetailsComponent,
    ResetAuthcodeComponent,
    AddUsersAdminComponent,
    TokenUploadComponent,
    AddAuthorizeUserComponent,
    EmailSignInComponent,
    EmailOtpComponent,
    MapUserAliasComponent,
    VoloSeachClaimnoAdminComponent,
    VoloDashboardAdminComponent,
    MaintenancePageComponent,
    PendingPccComponent,
    ViewPccComponent,
    ViewGdsUsersComponent,
    PendingGdsUsersComponent,
    
  ],
  imports: [ 
    SwiperModule,
    TooltipModule,
    BrowserModule,
    RouterModule,
    routing,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatDialogModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    DataTableModule,
    AngularMultiSelectModule,
    MultiselectDropdownModule,
    MyDatePickerModule,
    OwlModule,
    ChartsModule,
    HttpModule,
    InputTrimModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    AngularDateTimePickerModule,
    Ng2SearchPipeModule,
    OrderModule,
    NgxSpinnerModule,
    NgxUiLoaderModule,
    FusionChartsModule,
    MatTooltipModule,
    UserIdleModule.forRoot({idle: 900, timeout: 900, ping: 150}) ,//Time for user idle session timeout in seconds (15 minutes * 60 seconds = 900 seconds)
    MatPaginatorModule
  ],
  
  providers: [ApiService,AuthService,UserService,CardService,LoaderService,IfscVerificationService,ResetPasswordService,NotificationMatrixService,SessionService,ExcelService,AccoutsetupService,RmservicesService,DatePipe,ExcelGeneratorService,VcnuserService,VcnService,VcncorpService,{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
 },{
  provide: SWIPER_CONFIG,
  useValue: DEFAULT_SWIPER_CONFIG
 }],
  bootstrap: [AppComponent]
})
export class AppModule { }
