import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
declare var jquery:any;
declare var $ :any;
// declare var loadCharts: any;
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import{CardService} from '../../_api/card.service'
import { Router,ActivatedRoute } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-check-balance',
  templateUrl: './check-balance.component.html',
  styleUrls: ['./check-balance.component.css']
})
export class CheckBalanceComponent implements OnInit {
  filename:string="Click to Browse"
  fileUpload:File;
  selectedFiles: FileList;
  step2:boolean=false;
  selectCriteria:any;
  showFileBased:boolean=false;
  showFileUploadBased:boolean=false;
  showPurReqIdbased:boolean=false;
  pur_req_id:any;
  constructor(private userservice: UserService,private cardservice: CardService,private router: Router,private activatedRoute: ActivatedRoute,private vcn:VcnService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  UploadDirectFile(event){
    this.spinner.show()
    this.selectedFiles = event.target.files;
    this.filename = this.selectedFiles[0].name;
    
   // console.log('selectedFiles: ' + event.target.files[0] );
    // this.vcn.uploadreqfile(this.selectedFiles[0]).subscribe(
      
    //   data=>{
    //     this.step2=true;
    //     // this.userData=data["Data"];
    //     // console.log(data);
    //      localStorage.setItem('crdData',JSON.stringify(data))
    //      this.spinner.hide()
    //      this.router.navigate(['/card-upload-view']);
    //   },error=>{
    //     this.step2=false;
    //     this.spinner.hide()
    //     console.log("Failed to Upload")
    //   }
    // )
  
  }

  onChangeCriteria(){
    if(this.selectCriteria=='By File Name'){
      this.showFileBased=true;
    }else if(this.selectCriteria=='By File Upload'){
      this.showFileUploadBased=true;
    }else if(this.selectCriteria=='By Purchase Request ID'){
      this.showPurReqIdbased=true;
    }else{
      this.showPurReqIdbased=false;
      this.showFileUploadBased=false;
      this.showFileBased=false;
    }
  }

  getBalance(){
    if(this.pur_req_id==undefined || this.pur_req_id== null){
      this.step2=true;
    }else{
      $("#checkBalRep").modal("show");
    }
   
  }

  gotoSuccess(){
    this.step2=true;
  }

}
