import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelGeneratorService } from 'src/app/_api/excel-generator.service';
import { VcnService } from 'src/app/_api/vcn.service';
import { VcncorpService } from 'src/app/_api/vcncorp.service';
import * as $ from 'jquery';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-corp-cancelcard-review-req',
  templateUrl: './corp-cancelcard-review-req.component.html',
  styleUrls: ['./corp-cancelcard-review-req.component.css']
})
export class CorpCancelcardReviewReqComponent implements OnInit {
  isSelected:boolean=false
  filedata:any={}
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }
  transdate:any;
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  filesummary={
  }
  uns_filesummary={
  }
  carddata:any=[]
  uns_carddata:any=[]
  utr_no:string="";
  sub_corp_name:string="";
  remarks:string="";
  bank_name:string="";
  transaction_date:any=null;
  trans_date:string=null;
  total_amount:any=null;
  file_data:any[]
  
  myDate:Date = new Date();
  currenTdate:string
  amtinwords:any;
  corporate_id:any;
  alias_id:any;
  corporate_name:any;
  alias_name:any;
  alias_data:any=[]
  successfulTab:boolean=true;
  unsuccessFultab:boolean=false;
  successEntries:number=0;
  unsuccessEntries:number=0;
  unsuccesAmount:number=0;
  successAmount:number=0;
  activeSuccess:string='active'
  activeUnSuccess:string='none'
  totalCardData:number=0;
  uns_downloadArray: any;
  downloadArray: any;
  cardBalancePopup: boolean = false;
  constructor(private excel:ExcelGeneratorService,private router: Router, private route: ActivatedRoute,private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private vcncorp:VcncorpService) { }


  ngOnInit() {
    this.loadcardData()
    this.currenTdate=this.datepipe.transform(this.myDate, 'dd-MM-yyyy')
    console.log(this.currenTdate)
  }

  checkAll(){
    this.isSelected=!this.isSelected

  }
  checkClick(): void {
      $("#cancelcard").modal("show");

  }

  private loadcardData(){
    this.filedata=JSON.parse(localStorage.getItem("CancelCardData") || "[]");
    console.log(this.filedata)
    this.successAmount=0;
    this.unsuccesAmount=0;
    this.alias_id=localStorage.getItem('alias_id')
    var cardEntries=this.filedata['filedata']
    this.totalCardData=cardEntries.length
    this.carddata=cardEntries.filter(crd=>(crd['error']==null))
    this.uns_carddata=cardEntries.filter(crd=>(crd['error']!=null))
    for (var data of this.carddata){
      this.successAmount+=parseFloat(data['currentBalance']) 
    }
    console.log(this.carddata)
    console.log(this.uns_carddata)
    this.successfulTab=true
    
    if(this.successAmount > 0){
      this.cardBalancePopup = true
    }

    for (var data of this.uns_carddata){
      this.unsuccesAmount+=parseFloat(data['currentBalance']) 
      if(parseInt(data['currentBalance']) > 0){
        data.error = "Current balance is greater than zero"
      }
    }

    this.filesummary={
      // "corporate_name":this.filedata['data']['corporate_name'],
      "filename":this.filedata.out.filename,
      "total_amount":this.successAmount,
      "total_request":this.carddata.length,
      "total_unsuccessful_request":this.uns_carddata.length
    }
    console.log(this.filesummary)
  }

  oldCardNumberDisplay(cardNo:any) {
    if(cardNo){
      var returnString = `XXXX XXXX XXXX ${atob(cardNo.substring(16))}`
      return returnString
    }
  }

  showSuccessful(){
    this.successfulTab=true;
    this.unsuccessFultab=false;
    this.activeSuccess='active'
    this.activeUnSuccess='none'
  
  }
  
  showUnSuccessful(){
    this.successfulTab=false;
    this.unsuccessFultab=true;
    this.activeSuccess='none'
    this.activeUnSuccess='active'
    
  }

  submitReq(){
    this.cardBalancePopup = false
    this.submitReplaceCardReq()
  }

  submitReplaceCardReq(){
    if(this.cardBalancePopup){
      $("#cancelcard").modal("show");
    }
    this.spinner.show()
    if(this.carddata.length>0){
    if(this.totalCardData==this.carddata.length){
    var params={
      "transaction_details":{
        "file_name":this.filedata.out.filename,
        // "total_amount":this.total_amount.replace(/,/g, ''),
        "total_amount":this.successAmount.toString(),
        "total_cards":this.carddata.length,
        "utr_no": this.utr_no,
        "transaction_date":!!this.trans_date?this.trans_date:this.currenTdate,
        "bank_name":this.bank_name,
        "sub_corp_name":this.sub_corp_name,
        "remarks":this.remarks
      }, 
      "file_data":this.carddata
    }

    console.log(JSON.stringify(params))

  
    if(parseFloat(params['transaction_details']['total_amount'])===parseFloat(this.filesummary['total_amount'])){
    
      
        this.vcncorp.submitCancelCardRequestFile(params).subscribe(resp=>{
          console.log(resp)
        if(resp['message']=='Card Cancellation Request submit for approval successfully'){
          localStorage.removeItem('crdData')
          localStorage.removeItem('alias_id')
          this.spinner.hide()
          this.router.navigate(['/req-submit-success'],{queryParams:{msg:'cancelcardsuccess'}});
        }else{
          this.spinner.hide()
          this.toastr.error("Something Went wrong!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
       
      },error=>{
        this.spinner.hide()
        this.toastr.error("Failed to Submit Request!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
        console.log(error)
      })
    }else if(parseFloat(params['transaction_details']['total_amount'])>parseFloat(this.filesummary['total_amount'])){
           this.spinner.hide()
        this.toastr.warning("Entered Amount is greater than total amount of file!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
    }else if(parseFloat(params['transaction_details']['total_amount'])<parseFloat(this.filesummary['total_amount'])){
           this.spinner.hide()
        this.toastr.warning("Entered Amount is less than total amount of file!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
    }else{
      console.log('Amount Mismathced')
           this.spinner.hide()
        this.toastr.warning("Amount Mismathced!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
    }
    }else{
      console.log('Please Correct the file')
      this.spinner.hide()
      this.toastr.warning("Please Correct the file. Please check the unsuccessful tab!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
      })
    }
  }else{
    this.spinner.hide()
    this.toastr.warning("Please Correct the file. Please check the unsuccessful tab!",'Alert',{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
  }
    //console.log(JSON.stringify(params))
    
  }

  exportAsXLSX():void {
    this.downloadArray=[]
    this.uns_downloadArray=[]
    if(this.filedata['filedata'].length>=0){
      for(let data of this.carddata){
        var obj={
          Name:data['Name'],
          Mobile_Number:data['MobileNo'],
          Email:data['Email'],
          Current_Balance:data['currentBalance'],
          Token_Number:`XXXX XXXX XXXX ${atob(data['CardNumber'].substring(16))}`,
          Status:data['cardStatus'],
          Reference_No:data['request_purchase_id']
        }
        
        this.downloadArray.push(obj)
      }
  
      for(let data of this.uns_carddata){
        var object={
          Token_Number:data['CardNumber'],
          Current_Balance:data['currentBalance'],
          Status:data['cardStatus'],
          Error: data['error']
        }
        
        this.uns_downloadArray.push(object)
      }
      var fileNameArray = this.filesummary['filename'].split('.');
      var finalFileName= ""
      for(let i = 0; i < fileNameArray.length - 1; i++){
        console.log(fileNameArray[i])
        finalFileName = finalFileName + "." + fileNameArray[i];
      }
      this.excel.exportAsExcelFileMultiple( this.downloadArray, this.uns_downloadArray, finalFileName);
    }else{
      this.toastr.warning("No Data Available","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
   // this.excelservice.exportAsExcelFile( this.users, 'User');
  }

}
