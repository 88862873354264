import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-cardrelistbyfile',
  templateUrl: './cardrelistbyfile.component.html',
  styleUrls: ['./cardrelistbyfile.component.css']
})
export class CardrelistbyfileComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }
  gotolistview(){
    this.router.navigate(['/checker-card']);

  }

}
