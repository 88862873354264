import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/_api/loader.service';
import { OrgserviceService } from 'src/app/_api/orgservice.service';
import { Config } from 'src/app/config';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
const onboardingURL = new Config().getOnboadingURL();
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-yyyy',
  },
  display: {
    dateInput: 'DD-MM-yyyy',
    monthYearLabel: 'DD-MM-yyyy',
    dateA11yLabel: 'DD-MM-yyyy',
    monthYearA11yLabel: 'DD-MM-yyyy',
  },
};
@Component({
  selector: 'app-add-users-admin',
  templateUrl: './add-users-admin.component.html',
  styleUrls: ['./add-users-admin.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class AddUsersAdminComponent implements OnInit {
  formdetailsrow: boolean=true;
  listbillerrow: boolean=false;
  billersaddedlist: any=[];
  selectedIndex: any;
  billdata:any={};
  corpId:any;
  authMatrix:any;
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }
  headChange:any;
  dropdownData:any;
  orgDropdownData:any;
  makerAdded= false;
  checkerAdded= false;
  asAdded= false;
  userRole2 = [
    {
      "name":"Authorised Signatory",
      "value":"AS"
    },
    {
      "name":"Corporate Admin",
      "value":"Corporate Admin"
    },
    {
      "name":"Maker",
      "value":"Maker"
    },
    {
      "name":"Checker",
      "value":"Checker"
    }
  ]
  constructor(private spinner: NgxSpinnerService,private router: Router,public datepipe: DatePipe,private orgservice: OrgserviceService,private loaderService: LoaderService,private http: HttpClient,private toastr: ToastrService) { }

  ngOnInit() {
    this.checkerAdded= false;
    this.asAdded= false;
    this.dropdownData = this.userRole2
    this.loadOrganizations()
  }

  loadOrganizations(){
    this.spinner.show()
    this.orgservice.getOrganisationList().then(data=>{
     this.orgDropdownData = data['data'];
     this.spinner.hide();
    },error=>{
      console.log(error.message);
      this.spinner.hide();
    }).catch(err=>{
      console.log(err.message);
      this.spinner.hide();
    })
  }

  getNames(billdata:any){
    this.billdata=billdata;
    this.billdata.dob = this.datepipe.transform(this.billdata.dob, 'yyyy-MM-dd');
    this.corpId = this.billdata.corporate_id
    this.billersaddedlist.push(this.billdata)
    if(  this.billersaddedlist!=null || this.billersaddedlist!=''){
      this.billdata={}
    }
    this.submitdata() 
  }

      dateChange(event){
   
 
        this.billdata.dob=event._d
        this.billdata.dob=this.datepipe.transform(this.billdata.dob, 'dd-MM-yyyy')
      }

      addanotherbill(){
        this.formdetailsrow=true
        this.listbillerrow=true
        this.headChange=true
      }

      submitdata(){
          this.spinner.show();
          console.log(this.billersaddedlist,this.corpId)
          this.submitBulkUserback(this.billersaddedlist,this.corpId).subscribe(data => {
            this.spinner.hide()
            this.toastr.success(data["message"],"Alert",{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
            this.router.navigate(['/card-request-summary']);
         },error => {
           console.log(error)
           this.spinner.hide()
           this.toastr.error("Something went wrong! Please try again!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        });
      }

      submitBulkUserback(users:any[],orgid:any){
        console.log(users)
        let data = {
          "jsondata":users,
          "organisation_id":orgid
        }
        return this.http.post(onboardingURL+`admin/add-user-admin`, data);
    }

    checkuser() {
      this.spinner.show()
      this.orgservice.checkuser({"email":this.billdata.email}).then(data=>{
        this.spinner.hide()
        if(data.message == "No user found!") {

        } else {
          this.billdata.email = null
          this.toastr.error("Email Id already exist!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
      },err=>{
        this.spinner.hide()
      })
    }

    openDeclarationPopup(){
      let element: HTMLElement = document.getElementById('din-match-button') as HTMLElement
      element.click();
  }
  
  changeCompanyId(){
    this.router.navigate(['/'])
  }
}
