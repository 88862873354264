import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
declare var jquery:any;
declare var $ :any;
// declare var loadCharts: any;
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import{CardService} from '../../_api/card.service'
import { Router,ActivatedRoute } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-card-block',
  templateUrl: './card-block.component.html',
  styleUrls: ['./card-block.component.css']
})
export class CardBlockComponent implements OnInit {
  @ViewChild('myDiv') myDiv: ElementRef;
 // public dashboardData: any;
  public params:string;
  display='none'; //default Variable
  top='50px';
  public username:string;
  public rolename:string;
  currentUser: Users;
  isUnisup:boolean;
  isBulksup:boolean;
  isApprsup:boolean;
  isUnipay:boolean;
  isApprpay:boolean;
  isBulkpay:boolean;
  isPaysupstatus:boolean;
  isCardActivity:boolean;
  cards:any=[];
  pendingcards:any=[];
  approvedcards:any=[];
  rejectedcards:any[];
  fileUpload:File;
  filename:string="Click to Browse"
  selectedFiles: FileList;
  model: any = {};
  userData: any = {};
  _users:any[]=[];
  isSelected = false;
  step2:boolean=false;
  showAliaDD:boolean=false;
  showUploadFileOpt:boolean=false;
  corporateData:any=[]
  aliasdata:any=[]
  corporate_id:any
  alias_id:any;
  constructor(private userservice: UserService,private cardservice: CardService,private router: Router,private activatedRoute: ActivatedRoute,private vcn:VcnService,private spinner: NgxSpinnerService,private toastr: ToastrService) { }

  ngOnInit() {
  }

  UploadDirectFile(event){
    this.spinner.show()
    this.selectedFiles = event.target.files;
    this.filename = this.selectedFiles[0].name;
    this.vcn.uploadCardCancelFile(this.selectedFiles[0]).subscribe(
      
      data=>{
        this.step2=true;
        if(data['message']=='Request to cancel card successfully!'){
          this.spinner.hide()
          this.router.navigate(['/card-cancel-success']);
        }else if(data['message']=='Error!'){
          this.spinner.hide()
          console.log("Error!")
          this.toastr.error("Something Went wrong!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }else if(data['message']=='No file passed!'){
          this.spinner.hide()
          console.log("No file passed!!")
          this.toastr.error("No file passed!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
         
      },error=>{
        this.step2=false;
        this.spinner.hide()
        console.log("Failed to Upload")
        this.toastr.error("failed to Update mibile numbers!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    )
  
  }

}
