import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
// import { Observable, of, throwError } from 'rxjs';
// import { catchError, tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {Config} from '../config'
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

const path = new Config().getVcnUrl();
const path1 = new Config().getVcnUrl();

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  constructor(private http: HttpClient) { }

  getOrganisation(){
    return this.http.get<any>(path+'/ccadmin/organizations')
  }

/*   getState(){
    return this.http.get<any>(path+'/ccadmin/state')
  }
  getCity(id:any){
    return this.http.get<any>(`${path}/ccadmin/city/${id}`)
  } */
  organisationregister(orgdata: any){
    return this.http.post(path+'/organisationreg', orgdata);

  }
  organisationreister(orgdata : any): Promise<any>{
    let promise = new Promise((resolve,reject)=>{
      this.http.post(path+"/organisationreg", orgdata).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }
}
