import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute,NavigationEnd  } from '@angular/router';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
import { ToastrService } from 'ngx-toastr'
import {VcnuserService} from '../../_api/vcnuser.service'
import { NgxSpinnerService } from "ngx-spinner";
import {ExcelGeneratorService} from  '../../_api/excel-generator.service'
import { ExcelService } from '../../excelservice/excel.service'
import { DatePipe } from '@angular/common'
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';

import * as CryptoJS from 'crypto-js';
import { Config } from 'src/app/config';

const decryptKey = new Config().getVCNCipher()
const decryptIV = new Config().getVCNText()

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-yyyy',
  },
  display: {
    dateInput: 'DD-MM-yyyy',
    monthYearLabel: 'DD-MM-yyyy',
    dateA11yLabel: 'DD-MM-yyyy',
    monthYearA11yLabel: 'DD-MM-yyyy',
  },
};

/** @title Datepicker with custom formats */

@Component({
  selector: 'app-cards-listing',
  templateUrl: './cards-listing.component.html',
  styleUrls: ['./cards-listing.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CardsListingComponent implements OnInit {
  fromdate:any;
  isShown = false;
  isShow1 = false;
  isShow2 = false;
  todate:any;
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }
  carddata:any=[]
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  selectedIndex = -1;
  downloadArray:any=[];
  currentFDate:Date=new Date();
  currentTDate:Date=new Date();
  currFdate:string;
  currTdate:string;
  cardId:any
  list_from_date:any;
  list_to_date:any;
  lt_f_date:string;
  lt_t_date:string;
  cardno:any;
  cvv:any;
  cardexpiry:any;
  cardnoToShow:any;
  cvvToShow:any;
  cardexpiryToShow:any;
  balanceSeen: boolean=false;
  cardname: any;
  cardToggle:boolean=false;
  purchase_request_id: any;
  selectedid:any=-1
  decrypted: any;
  transaction_ref_no: any;
  amountToShow: any;
  constructor(private route:Router,private toastr: ToastrService, private vcnuser:VcnuserService,private spinner: NgxSpinnerService,private excelservice : ExcelService, private excel:ExcelGeneratorService,public datepipe: DatePipe) { }

  ngOnInit() {
    $(document).ready(function(){
      $("#vcn").show();
      $("#mycard").click(function(){
        $("#vcn").show();
        $("#vgiftcard").hide();
        $("#pgiftcard").hide();
        $("#mycard").addClass("active");
        $("#pendcard").removeClass("active");
        $("#rejctcard").removeClass("active");
    });
      $("#pendcard").click(function(){
        $("#vgiftcard").show();
        $("#vcn").hide();
        $("#pgiftcard").hide();
        $("#pendcard").addClass("active");
        $("#mycard").removeClass("active");
        $("#rejctcard").removeClass("active");
    });
    $("#rejctcard").click(function(){
      $("#pgiftcard").show();
      $("#vcn").hide();
      $("#vgiftcard").hide();
      $("#rejctcard").addClass("active");
      $("#pendcard").removeClass("active");
      $("#mycard").removeClass("active");
  });
  });
  this.currentFDate.setDate(this.currentFDate.getDate() - 1);
  this.currFdate=this.datepipe.transform(this.currentFDate, 'yyyy-MM-dd');
  this.currTdate=this.datepipe.transform(this.currentTDate, 'yyyy-MM-dd');
  //console.log(this.currFdate+" "+this.currTdate)
  this.loadCardData()
  }

  toggleExpandedView(index){
    this.selectedIndex = index;
    this.balanceSeen=false
   }


  fromdateChange(event){
    this.lt_f_date=event._d
    this.lt_f_date=this.datepipe.transform(this.lt_f_date, 'yyyy-MM-dd')
    console.log(!!this.lt_f_date)
  }

  todateChange(event){
    this.lt_t_date=event._d
    this.lt_t_date=this.datepipe.transform(this.lt_t_date, 'yyyy-MM-dd')
    console.log(!!this.lt_t_date)
  }


  gotoTransactions(id:any){
    this.spinner.show()
    var param={
      "id":id,
      "from_date":this.currFdate,
      "to_date":this.currTdate
    }
    this.vcnuser.getVcnAuthReport(param).then(resp=>{
      console.log(resp)
      if(resp['message']=='There is no transaction between the given dates'){
        this.spinner.hide()
        this.toastr.error("No Transactions found!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='VCN authorization report!'){
        this.spinner.hide()
        localStorage.setItem('authreport',JSON.stringify(resp['data']))
        this.route.navigate(['/card-auth-report']);
      }else if(resp['message']=='Code: ERROR_CREATE_DELETED_VCN_AUTHS_REPORT Description: We are unable to create an authorization report for a cancelled VCN. To view these transactions please run the authorization report for the RCN.'){
        this.spinner.hide()
        this.toastr.error("Card has been cancelled!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else{
        this.spinner.hide()
        this.toastr.error("Semething Went Wrong!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
      this.toastr.error("Failed to Get Auth Report!!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
     
    })
  }



  gotodaterangereport(){
    // console.log(this.cardId)
    // console.log(this.fromdate)
    // console.log(this.todate)
    this.spinner.show()
    if(this.fromdate!=undefined || this.fromdate!=null){
      this.fromdate=this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    }
    else{
      this.fromdate=this.currFdate
    }
    
    if(this.todate!=undefined || this.todate!=null){
      this.todate=this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    }
   else{
    this.todate=this.currTdate
    }


    var param={
      "id":this.cardId,
      "from_date":this.fromdate,
      "to_date":this.todate
    }
    this.vcnuser.getVcnAuthReport(param).then(resp=>{
      console.log(resp)
      if(resp['message']=='There is no transaction between the given dates'){
        this.spinner.hide()
        this.toastr.error("No Transactions found!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='VCN authorization report!'){
        this.spinner.hide()
        localStorage.setItem('authreportdr',JSON.stringify(resp['data']))
       this.route.navigate(['/date-range-report']);
       
      }else if(resp['message']=='Code: ERROR_CREATE_DELETED_VCN_AUTHS_REPORT Description: We are unable to create an authorization report for a cancelled VCN. To view these transactions please run the authorization report for the RCN.'){
        this.spinner.hide()
        this.toastr.error("Card has been cancelled!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='The report date range cannot exceed 31 day(s).'){
        this.spinner.hide()
        this.toastr.error("The report date range cannot exceed 31 day(s).","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='From date should be before to date.'){
        this.spinner.hide()
        this.toastr.error("From date should be before to date.","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else{
        this.spinner.hide()
        this.toastr.error("Semething Went Wrong!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
      this.toastr.error("Failed to Get Auth Report!!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
     
    })
   // this.route.navigate(['/date-range-report']);

  }

  viewPastTxns(id:any){
    this.cardId=id
    $("#pastTxn").modal("show");
  }

  viewcardDetails(crdno,cvv,expiry,name,reqid,transno,amount): void {
    // this.cardno = crdno
    // this.cvv = cvv
    // this.cardexpiry = expiry

    this.cardnoToShow=`${this.decryptUsingAES256(crdno).substring(4,0)}    ${this.decryptUsingAES256(crdno).substring(8,4)}    ${this.decryptUsingAES256(crdno).substring(12,8)}    ${this.decryptUsingAES256(crdno).substring(16,12)}`
    this.cvvToShow=this.decryptUsingAES256(cvv)
    this.cardexpiryToShow=`${this.decryptUsingAES256(expiry).substring(2,0)} / ${this.decryptUsingAES256(expiry).substring(6,2)}`  
    this.cardname=name
    this.purchase_request_id=reqid
    this.transaction_ref_no=transno
    this.amountToShow=amount
    $("#payreport").modal("show");
 
  }

  showCVV(){
    this.cardnoToShow = "XXXX XXXX XXXX XXXX";
    this.cardexpiryToShow = "XX/XX"
    this.cvvToShow = this.cvv
  }

  hideCVV(){
    this.cardnoToShow = `${atob(this.cardno).substring(4,0)}    ${atob(this.cardno).substring(8,4)}    ${atob(this.cardno).substring(12,8)}    ${atob(this.cardno).substring(16,12)}`;
    this.cardexpiryToShow = `${this.cardexpiry.substring(2,0)} / ${this.cardexpiry.substring(6,2)}`
    this.cvvToShow = "XXX"
  }

  toggleShow() {
    this.isShown = ! this.isShown;
  }


  toggleShow1(){
    this.isShow1 = ! this.isShow1;
  }


  toggleShow2(){
    this.isShow2 = ! this.isShow2;
  }

  loadAllCards(){
    this.lt_f_date="";
    this.lt_t_date="";
    this.loadCardData()
  }

  loadCardData(){
    this.spinner.show()
    var params={
      "from":!!this.lt_f_date?this.lt_f_date:"",
      "to":!!this.lt_t_date?this.lt_t_date:"",
    }
    this.vcnuser.getUserCards(params).then(resp=>{
    
      if(resp['message']=='Cards Data!'){
        console.log(resp)
        this.spinner.hide()
        this.carddata=resp['data']
        for(var i=0;i<resp['data'].length;i++){
          this.carddata[i]['new_card_number']=`${this.decryptUsingAES256(this.carddata[i]['card_number']).substring(4,0)}    ${this.decryptUsingAES256(this.carddata[i]['card_number']).substring(8,4)}    ${this.decryptUsingAES256(this.carddata[i]['card_number']).substring(12,8)}    ${this.decryptUsingAES256(this.carddata[i]['card_number']).substring(16,12)}`
          this.carddata[i]['new_expiry']=`${this.decryptUsingAES256(this.carddata[i]['expiry']).substring(2,0)} / ${this.decryptUsingAES256(this.carddata[i]['expiry']).substring(6,2)}` 
          this.carddata[i]['cardView']=false;
          this.carddata[i]['cvv_new']=this.decryptUsingAES256( this.carddata[i]['cvv'])
        }
      }else{
        this.spinner.hide()
        this.toastr.error("Something went wrong!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      this.carddata=[]
      this.spinner.hide()
      this.toastr.error("Card Data Not Found!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
      console.log(error)
    })
  }

  getBalance(id){
    this.spinner.show()
    var params={
      "id":id
    }
    this.vcnuser.getCardBalance(params).then(resp=>{
      if(resp['message']=='Purchase request id not available!'){
        this.spinner.hide()
        this.toastr.error("Card Details are not available","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='The Requested has been Canceled'){
        this.spinner.hide()
        this.toastr.error("This card has been blocked!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='Available Balance!'){
        for(var data of this.carddata){
            if(data['id']==resp['id']){
              this.spinner.hide()
              data['balance']=resp['data']
              this.balanceSeen=true
            }
        }
        console.log(resp)
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }



  exportAsXLSX():void {
    this.downloadArray=[]
    
    if(this.carddata.length>=0){
      for(let data of this.carddata){
        var obj={
          Name:data['name'],
          Token_Number:'XXXX XXXX XXXX '+data["lastDigit"],
          GeneratedDate:data['card_generated_date'],
        }
        this.downloadArray.push(obj)
      }
      this.excel.exportAsExcelFile( this.downloadArray, 'Card_List');
    }else{
      this.toastr.warning("No Data Available","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
   // this.excelservice.exportAsExcelFile( this.users, 'User');
  }

  cardToggleView(index){
      this.carddata[index]['cardView']=!this.carddata[index]['cardView']
  
  }

  decryptUsingAES256(value:any) {
    let _key = CryptoJS.enc.Utf8.parse(decryptKey);
    let _iv = CryptoJS.enc.Utf8.parse(decryptIV);


    this.decrypted = CryptoJS.AES.decrypt(value, _key, {
      keySize: 32,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
    

    return this.decrypted
  }
  
}
