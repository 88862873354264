import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
declare var jquery:any;
declare var $ :any;
// declare var loadCharts: any;
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import{CardService} from '../../_api/card.service'
import { Router,ActivatedRoute } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
@Component({
  selector: 'app-update-contact',
  templateUrl: './update-contact.component.html',
  styleUrls: ['./update-contact.component.css']
})
export class UpdateContactComponent implements OnInit {
  @ViewChild('myDiv') myDiv: ElementRef;
 // public dashboardData: any;
  public params:string;
  display='none'; //default Variable
  top='50px';
  public username:string;
  public rolename:string;
  currentUser: Users;
  isUnisup:boolean;
  isBulksup:boolean;
  isApprsup:boolean;
  isUnipay:boolean;
  isApprpay:boolean;
  isBulkpay:boolean;
  isPaysupstatus:boolean;
  isCardActivity:boolean;
  cards:any=[];
  pendingcards:any=[];
  approvedcards:any=[];
  rejectedcards:any[];
  fileUpload:File;
  filename:string="Click to Browse"
  selectedFiles: FileList;
  model: any = {};
  userData: any = {};
  _users:any[]=[];
  isSelected = false;
  step2:boolean=false;
  showAliaDD:boolean=false;
  showUploadFileOpt:boolean=false;
  corporateData:any=[]
  aliasdata:any=[]
  corporate_id:any
  alias_id:any;
  constructor(private userservice: UserService,private cardservice: CardService,private router: Router,private activatedRoute: ActivatedRoute,private vcn:VcnService,private spinner: NgxSpinnerService,private toastr: ToastrService) { }

  ngOnInit() {
   // this.loadCorporates()
  }

  private loadCorporates(){
    this.vcn.getCorporate().then(resp=>{
     
      if(resp['message']=='Corporates info' && resp['error']==null){
        this.corporateData=resp['data']
      }else{
        console.log("Something Went Wrong")
      }
    },error=>{
      console.log(error)
      console.log("Failed to Load Corporate Data")
    })
  }

  UploadDirectFile(event){
    this.spinner.show()
    this.selectedFiles = event.target.files;
    this.filename = this.selectedFiles[0].name;
    
   // console.log('selectedFiles: ' + event.target.files[0] );
    this.vcn.uploadcontactreqfile(this.selectedFiles[0]).subscribe(
      
      data=>{
        this.step2=true;
        if(data['message']=='Mobile no updated on Wibmo successfully!'){
          this.spinner.hide()
          this.router.navigate(['/mobile-update-success']);
        }else{
          this.spinner.hide()
          console.log("Failed to Update Numbers")
          this.toastr.error("Something Went wrong!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
        // this.userData=data["Data"];
        // console.log(data);
        //  localStorage.setItem('crdData',JSON.stringify(data))
        //  localStorage.setItem('corporate_id',this.corporate_id)
        //  localStorage.setItem('alias_id',this.alias_id)
         this.spinner.hide()
         //this.router.navigate(['/card-upload-view']);
      },error=>{
        this.step2=false;
        this.spinner.hide()
        console.log("Failed to Upload")
        this.toastr.error("failed to Update mibile numbers!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    )
  
  }

}
