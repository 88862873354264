import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-req-submit-success',
  templateUrl: './req-submit-success.component.html',
  styleUrls: ['./req-submit-success.component.css']
})
export class ReqSubmitSuccessComponent implements OnInit {
  corporate_process:any;
  params:any;
  cardRequestSuccess:boolean = false;
  cancelCardSuccess: boolean = false;
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.corporate_process=localStorage.getItem('corporate_process')
    this.params = this.activatedRoute.snapshot.queryParams["msg"];
    console.log(this.params);
    if(this.params=='cardrequestsuccess'){
      this.cardRequestSuccess=true;
    }else if(this.params=='cancelcardsuccess') {
      this.cancelCardSuccess=true;
    }
  }

}
