import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { ExcelService } from '../../excelservice/excel.service'
import {ExcelGeneratorService} from  '../../_api/excel-generator.service'
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-auth-report-open',
  templateUrl: './auth-report-open.component.html',
  styleUrls: ['./auth-report-open.component.css']
})
export class AuthReportOpenComponent implements OnInit {
  transactiondata:any=[]
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  constructor(private _location: Location,private spinner: NgxSpinnerService,private excelservice : ExcelService,private excel:ExcelGeneratorService,private titleService:Title) { 
    this.titleService.setTitle("Virtual Card Auth Report");
  }

  ngOnInit() {
    this.loadTrxData()
  }
  backClicked() {
    localStorage.removeItem('authreport')
    this._location.back();
    
  }

  private loadTrxData(){
    this.spinner.show()
    this.transactiondata=[]
    var transaData=JSON.parse(localStorage.getItem("authreport") || "[]");
    if(Array.isArray(transaData)){
      for(var data of transaData){
        var obj={
          Amount:data['@BillingAmount'],
          TokenNumber:data['@VirtualCardNumber'],
          MerchantName:data['@MerchantName'],
          TransactionDateTime:data['@TxnDateTimeWithTime'],
          IssuerResponse:data['@IssuerResponse'],
          InControlResponse:data['@InControlResponse'],
          Settled:data['@Settled'],
          TransactionDate:data['@TxnDateTime']
        }
        this.transactiondata.push(obj)
  
      }
      if(this.transactiondata.length>0){
        console.log(this.transactiondata)
        this.spinner.hide()
      }else{
        this.spinner.hide()
      }
    }else{
      this.spinner.hide()
      this.transactiondata=[{
        'Amount':transaData['@BillingAmount'],
        'TokenNumber':transaData['@VirtualCardNumber'],
        'MerchantName':transaData['@MerchantName'],
        'TransactionDateTime':transaData['@TxnDateTimeWithTime'],
        'IssuerResponse':transaData['@IssuerResponse'],
        'InControlResponse':transaData['@InControlResponse'],
        'Settled':transaData['@Settled'],
        'TransactionDate':transaData['@TxnDateTime']
      }]
    }
    
    

  }


  exportAsXLSX(){
    this.excel.exportAsExcelFile( this.transactiondata, 'Transaction_Report');
  }

}
