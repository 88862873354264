import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Config} from '../config'

const path = new Config().getOnboadingURL();
const VcnBackend = new Config().getVcnUrl();
@Injectable({
  providedIn: 'root'
})
export class OrgserviceService {

  constructor(private http: HttpClient) { }

  getOrganisation(){
    return this.http.get<any>(path+'/ccadmin/organizations')
  }

  getOrganisationList(): Promise<any>{
    let promise = new Promise((resolve,reject)=>{
      this.http.get(path+"organisations/all").subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  getOrganisationUserList(corpId): Promise<any>{
    let promise = new Promise((resolve,reject)=>{
      this.http.post(VcnBackend+"/user/findUsersForCorp",{"corporate_id":corpId}).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  getOrganisationDataById(data): Promise<any>{
    let promise = new Promise((resolve,reject)=>{
      this.http.post(path+"organisations/viewByOrgId",data).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  setUserAliasMapping(object): Promise<any>{
    let promise = new Promise((resolve,reject)=>{
      this.http.post(VcnBackend+"/user/mapAliasToUsers",object).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  getCompnyNameByCompanyId(data): Promise<any>{
    let promise = new Promise((resolve,reject)=>{
      this.http.post(VcnBackend+"/user/validateCompany",data).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  validateAlias(data): Promise<any>{
    let promise = new Promise((resolve,reject)=>{
      this.http.post(VcnBackend+"/user/validateAlias",data).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  validateTemplate(data): Promise<any>{
    let promise = new Promise((resolve,reject)=>{
      this.http.post(VcnBackend+"/user/validateTemplate",data).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  validateSupplier(data): Promise<any>{
    let promise = new Promise((resolve,reject)=>{
      this.http.post(VcnBackend+"/user/validateSupplier",data).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  getOrganisationData(): Promise<any>{
    let promise = new Promise((resolve,reject)=>{
      this.http.get(path+"organisations/view").subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  setOrganizationType(data):Promise<any>{
    // let token = localStorage.getItem(this.TOKEN_KEY);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
        // .set('authorization', 'Bearer ' + token);
    let options = { headers: headers };
    let promise = new Promise((resolve,reject)=>{
      this.http.post(path+"organisations/setOrgType",data,options).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }
  setCompanyId(data):Promise<any>{
    // let token = localStorage.getItem(this.TOKEN_KEY);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
        // .set('authorization', 'Bearer ' + token);
    let options = { headers: headers };
    let promise = new Promise((resolve,reject)=>{
      this.http.post(path+"organisations/update-company-id",data,options).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  setMakerRestriction(data):Promise<any>{
    // let token = localStorage.getItem(this.TOKEN_KEY);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
        // .set('authorization', 'Bearer ' + token);
    let options = { headers: headers };
    let promise = new Promise((resolve,reject)=>{
      this.http.post(path+"organisations/update-maker-restriction",data,options).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  organisationreister(orgdata : any): Promise<any>{
    console.log("service orgdata")
    console.log(orgdata);
    let promise = new Promise((resolve,reject)=>{
      this.http.post(path+"organisations/add", orgdata).subscribe(
        res=>{
          resolve(res);
          console.log("service resp")
          console.log(orgdata)
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }

  
  checkuser(orgdata : any): Promise<any>{
    let promise = new Promise((resolve,reject)=>{
      this.http.post(path+"auth/check-user", orgdata).subscribe(
        res=>{
          resolve(res);
        },err=>{
          reject(err);
        }
      )
    });
    return promise;
  }
}
