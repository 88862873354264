import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/config';
import { OrgserviceService } from 'src/app/_api/orgservice.service';
const onboardingURL = new Config().getOnboadingURL();
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-yyyy',
  },
  display: {
    dateInput: 'DD-MM-yyyy',
    monthYearLabel: 'DD-MM-yyyy',
    dateA11yLabel: 'DD-MM-yyyy',
    monthYearA11yLabel: 'DD-MM-yyyy',
  },
};
@Component({
  selector: 'app-add-ccadmin',
  templateUrl: './add-ccadmin.component.html',
  styleUrls: ['./add-ccadmin.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class AddCcadminComponent implements OnInit {
  formdetailsrow: boolean=true;
  listbillerrow: boolean=false;
  billersaddedlist: any=[];
  selectedIndex: any;
  billdata:any={};
  corpId:any;
  authMatrix:any;
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }
  headChange:any;
  dropdownData:any;

  userRole = [
    {
      "name":"Corporate Admin",
      "value":"Corporate Admin"
    }
  ]
  constructor(private spinner: NgxSpinnerService,private router: Router,public datepipe: DatePipe,private orgservice: OrgserviceService,private http: HttpClient,private toastr: ToastrService) { }

  ngOnInit() {
    this.dropdownData = this.userRole
  }

      dateChange(event){
   
 
        this.billdata.dob=event._d
        this.billdata.dob=this.datepipe.transform(this.billdata.dob, 'dd-MM-yyyy')
      }

      addanotherbill(){
        this.formdetailsrow=true
        this.listbillerrow=true
        this.headChange=true
      }

      submitdata(){
        this.spinner.show()
        this.billdata.dob = this.datepipe.transform(this.billdata.dob, 'yyyy-MM-dd')
          this.submitBulkUserback(this.billdata).subscribe(data => {
            this.spinner.hide()
            //alert("Submitted SUccessfully")
            if(data["status"] == "Success") {
           this.router.navigate(['/org-listing']);
           this.toastr.success("User uploaded successfully!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
          } else {
            this.toastr.error(data["message"],"Alert",{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
         },error => {
           console.log(error)
           this.spinner.hide()
           this.toastr.error("Something went wrong! Please try again!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        });
      }

      submitBulkUserback(users:any){
        console.log(users)
        return this.http.post(onboardingURL+`organisations/backend-add`, users);
    }

    checkuser() {
      this.spinner.show()
      this.orgservice.checkuser({"email":this.billdata.email}).then(data=>{
        this.spinner.hide()
        if(data.message == "No user found!") {

        } else {
          this.billdata.email = null
          this.toastr.error("Email Id already exist!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
      },err=>{
        this.spinner.hide()
      })
    }
}
