import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-corporateview',
  templateUrl: './corporateview.component.html',
  styleUrls: ['./corporateview.component.css']
})
export class CorporateviewComponent implements OnInit {

  constructor(private router: Router,private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
  }
  gotoUserDetails(){
    this.router.navigate(['/corporate-user-view',]);
  }
}
