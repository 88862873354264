import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AuthService} from './auth.service'
import {Config} from '../config'
const path = new Config().getOnboadingURL();
@Injectable({
  providedIn: 'root'
})
export class AccoutsetupService {

  public getOrgtoken: any;

  constructor(private http: HttpClient,private authservice:AuthService) { }

  setContractDetails(data): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let options = { headers: headers };     
    let promise = new Promise((resolve, reject) => {
        this.http.post(path+"karza/setContractDetails",data,options)
            .subscribe(
                res => {
                    console.log(res);
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }
  getContractDetails(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let options = { headers: headers };     
    let promise = new Promise((resolve, reject) => {
        this.http.get(path+"karza/getContractDetails",options)
            .subscribe(
                res => {
                    console.log(res);
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }
  getOrgDetails(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let options = { headers: headers };     
    let promise = new Promise((resolve, reject) => {
        this.http.get(path+"first-login/organisation-view",options)
            .subscribe(
                res => {
                    console.log(res);
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }
  approveorg(orgid:any): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let options = { headers: headers };     
    var params ={
      "organisation_id":orgid
    } 
    let promise = new Promise((resolve, reject) => {
        this.http.post(path+"first-login/organisation-approve",params,options)
            .subscribe(
                res => {
                    console.log(res);
               
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }
  rejectorg(orgid:any,comment:any): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let options = { headers: headers };     
    var params ={
      "organisation_id":orgid,
      "comment":comment
    } 
    let promise = new Promise((resolve, reject) => {
        this.http.post(path+"first-login/organisation-reject",params,options)
            .subscribe(
                res => {
                    console.log(res);
               
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }
  getUserList(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let options = { headers: headers };     
    let promise = new Promise((resolve, reject) => {
        this.http.get(path+"first-login/user-view",options)
            .subscribe(
                res => {
                    console.log(res);
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }
  approveusers(ids:any[]):Promise<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let options = { headers: headers };     
    var params ={
      "userIds":ids
    } 
    let promise = new Promise((resolve, reject) => {
        this.http.post(path+"first-login/user-approve",params,options)
            .subscribe(
                res => {
                    console.log(res);
               
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }
  rejectusers(ids:any[],comment:any):Promise<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let options = { headers: headers };     
    var params ={
      "userIds":ids,
      "comment":comment
    } 
    let promise = new Promise((resolve, reject) => {
        this.http.post(path+"first-login/user-reject",params,options)
            .subscribe(
                res => {
                    console.log(res);
               
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
  }
  validateauthcode(authcode: any): Promise<any> {
    // let headers = new HttpHeaders().set('Content-Type', 'application/json');
    // let options = { headers: headers };
    let paramsValue = {
        "authcode": authcode
    }
    let promise = new Promise((resolve, reject) => {
        this.http.post(path+"first-login/set-authcode", paramsValue)
            .subscribe(
                res => {
                    
                    this.authservice.saveToken(res['data'])
                    resolve(res);
                },
                err => {
                    console.log(err);
                    reject(err);
                }
            );

    });

    return promise;
}

validatepassword( password: any): Promise<any> {
  let paramsValue = {
      "password": password,
  }
  let promise = new Promise((resolve, reject) => {
      this.http.post(path+"first-login/new-password", paramsValue)
          .subscribe(
              res => {
                  resolve(res);
              },
              err => {
                  console.log( err);
                  reject(err);
              }
          );

  });

  return promise;
}

getAllorganisations(): Promise<any> {
  // let token = this.storage.getData("chlogin_data").token;
  //let newToken = this.authservice.token()
  // let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //     .set('authorization', 'Bearer ' + newToken);
  // let options = { headers: headers };
  let promise = new Promise((resolve, reject) => {
      this.http.get(path+"first-login/authOrgData")
          .subscribe(
              res => {
                  
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );
  });
  return promise;
}

getCurrentUser(): Promise<any>{
    let promise = new Promise((resolve, reject) => {
        this.http.get(`${path}auth/info`)
            .subscribe(
                res => {
                  
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
  
    });
  
    return promise;
}

getUserDetails(): Promise<any> {
  // let token = this.storage.getData("rmlogin_data").token;
  // let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //     .set('authorization', 'Bearer ' + token);
  // let options = { headers: headers };

  let promise = new Promise((resolve, reject) => {
      this.http.get(path+"/adminuser/whois")
          .subscribe(
              res => {
                  
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );

  });
  return promise;
}


}
