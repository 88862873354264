import { Component, OnInit } from '@angular/core';
import {VcnService} from '../../_api/vcn.service'
import { DatePipe } from '@angular/common'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router';
import {VcncorpService} from '../../_api/vcncorp.service'
import { Users } from '../../models/users';
@Component({
  selector: 'app-approvedfileswidgets',
  templateUrl: './approvedfileswidgets.component.html',
  styleUrls: ['./approvedfileswidgets.component.css']
})
export class ApprovedfileswidgetsComponent implements OnInit {

  filedata:any=[]
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  totalamount:number=0;
  totalcards:number=0;
  currentUser: Users;
  constructor(private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private route:Router,private vcncorp:VcncorpService) { }

  ngOnInit() {
    this.loadUserDetails()
   
  }

  private loadapprcrdcount(id){
    this.totalamount=0
    this.totalcards=0
    this.spinner.show()
   
    this.vcncorp.getapprovedcardcount(id).then(resp=>{
      if(resp['message']=='Approved Cards Count!'){
        if(resp['data']['total_cards']!=null){
          this.totalcards=resp['data']['total_cards']
        }else{
          this.totalcards=0
        }
        if(resp['data']['total_amount']!=null){
          this.totalamount=resp['data']['total_amount']
        }else{
          this.totalamount=0
        }
       
        this.spinner.hide()
      }else{
        this.spinner.hide()
        console.log('Not able to fetch count')
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }

  private loadUserDetails(){
    this.vcn.getCurrentUser().then(users => {
     // console.log(users);
      if(users['message']=='User info!'){
        this.loadapprcrdcount(users['data']['corporate_id'])
      }
      },error=>{
        console.log(error)
      });
  }

}
