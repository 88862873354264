import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-back-success',
  templateUrl: './send-back-success.component.html',
  styleUrls: ['./send-back-success.component.css']
})
export class SendBackSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
