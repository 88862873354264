import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute,NavigationEnd  } from '@angular/router';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import{CardService} from '../../_api/card.service'
import{LoaderService} from '../../_api/loader.service';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common'
import { ToastrService } from 'ngx-toastr'
import { e } from '@angular/core/src/render3';
@Component({
  selector: 'app-cardview',
  templateUrl: './cardview.component.html',
  styleUrls: ['./cardview.component.css']
})
export class CardviewComponent implements OnInit {
  @ViewChild('content') content: any;
  isUnicard:boolean;
  isMaker:boolean=false;
  currentUser: Users;
  cards:any=[];
  pendingcards:any=[];
  approvedcards:any=[];
  rejectedcards:any=[];
  public show:boolean = false;
  public buttonName:any = 'View Details';
  balanceResBody:any=[]
  balanceReqBody:any={}
  showBalance:boolean=false;
  currentbalance:string;
  lastCheckedBy:string=""
  lastCheckedbalance:string=""
  lastCheckedDate:string=""
  lastCheckedTime:string=""
  public txt1: any;
  public txt2: any;
  public txt3: any;
  public txt4: any;
  public txt5: any;
  selectedIndex = -1;
  index=-2;
  showModalBox: boolean = false;
  userid:number;
  cardid:number;
  orgid:number;
  checkOtp:string;
  cardBalance:string="";
  selectedDetails=-1;
  showLastCheckedDetails:boolean=false;
  noDetailsMsg:string=""
  lastCheckedTimeStamp:string=""
  constructor(private userservice: UserService,private cardservice: CardService,private loaderService: LoaderService,private route:Router,private activeroute: ActivatedRoute,private datepipe: DatePipe,private toastr: ToastrService) {  
  }
  

  ngOnInit() {
    // this.balanceResBody=JSON.parse(localStorage.getItem("creditResponse"))
    // this.showBalance=JSON.parse(localStorage.getItem('showbal'))
    // console.log(this.balanceResBody)
    // console.log(this.showBalance)
    // localStorage.removeItem("creditResponse")
    // localStorage.removeItem("showbal")
    this.userservice.getUserDetails().subscribe(users => {
      this.currentUser = users['Data'];
      if(this.currentUser.tabpermissionids !=null){
      var str = this.currentUser.tabpermissionids;
      str = str.replace("pid=" ,"");
      str = str.replace(";cid=",",");
      str = str.replace(";","");
      var tab_arr = str.split(",");
      this.isUnicard = this.checkValInArray("24",tab_arr)
    }
    //console.log(this.currentUser['rolename'])
    this.userid=this.currentUser['id']
    if(this.currentUser['rolename']=="Maker"){
      this.isMaker=true;
      //console.log("true"+this.isMaker)
    }else{
      this.isMaker=false;
      //console.log("False"+this.isMaker)
    }
      });

      

      this.loadallcards();
    $(".activeapp").on("click", function () {
      $(".supplink").addClass("active");
      $(".supplink").siblings(".active").removeClass("active");
    });

    // $('.inputstyle').bind('keyup', function (event) {
    //   var val = $(this).val();
    //   if (val != '') {
    //     $(this).parent().next().find('input').focus();
    //   }
    // });


  // $(document).ready(function(){
  //   $(".success").hide();
  
  //   $('.activeapp').click(function () {
  //     $(".success").show();
  //     $(".abc").hide();
  //   });

  // });





  $(document).ready(function(){
    $("#mycard").click(function(){
      $("#London").show();
      $("#Paris").hide();
      $("#Tokyo").hide();
      $("#mycard").addClass("active");
      $("#pendcard").removeClass("active");
      $("#rejctcard").removeClass("active");
  });
    $("#pendcard").click(function(){
      $("#Paris").show();
      $("#London").hide();
      $("#Tokyo").hide();
      $("#pendcard").addClass("active");
      $("#mycard").removeClass("active");
      $("#rejctcard").removeClass("active");
  });
  $("#rejctcard").click(function(){
    $("#Tokyo").show();
    $("#Paris").hide();
    $("#London").hide();
    $("#rejctcard").addClass("active");
    $("#pendcard").removeClass("active");
    $("#mycard").removeClass("active");
});
});
var coll = document.getElementsByClassName("collapsible");
      var i;

      for (i = 0; i < coll.length; i++) {
          coll[i].addEventListener("click", function () {
              this.classList.toggle("cactive");
              var content = this.nextElementSibling;
              if (content.style.maxHeight) {
                  content.style.maxHeight = null;
              } else {
                  content.style.maxHeight = content.scrollHeight + "px";
              }
          });
      }
  }

  checkValInArray(tabid:string , tab_array:string[]){
    return (tab_array.indexOf(tabid)==-1)?false:true;
}

private loadallcards(){
  this.loaderService.display(true);
  this.cardservice.getAll().subscribe(data=>{
    
    this.cards=data["data"];
    // this.pendingcards=data["data"]["pendingcards"];
    // this.rejectedcards=data["data"]["rejectedcards"]
    // this.approvedcards=data["data"]["approvedcards"]
    //console.log(this.approvedcards);
    //console.log(this.cards)
    for(let i = 0; i < this.cards.length; i++){
      if(this.cards[i].status == 1){
          this.approvedcards.push(this.cards[i]);
      }
  }
  for(let i = 0; i < this.cards.length; i++){
      if(this.cards[i].status == 2){
          this.pendingcards.push(this.cards[i]);
      }
  }
  for(let i = 0; i < this.cards.length; i++){
      if(this.cards[i].status == 0){
          this.rejectedcards.push(this.cards[i]);
      }
  }
  this.loaderService.display(false);
  },error=>{
    this.loaderService.display(false);
      console.log("Failed to Fetch")
  }

  )

}

toggle() {
  this.show = !this.show;

  // CHANGE THE NAME OF THE BUTTON.
  if(this.show)  
    this.buttonName = "Hide Details";
  else
    this.buttonName = "View Details";
}

checkBalance(number,id,orgid,index){
 this.loaderService.display(true)
  this.cardid=id;
  this.orgid=orgid
  this.balanceResBody=[]
  //console.log(this.balanceResBody)
  this.cardservice.getbalance(number).then(resp=>{
  
    if(resp['message']=='Somthing went wrong'){
      this.loaderService.display(false)
      this.toastr.error("Something went wrong!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }else{
      if(resp['data']['message']=='valid Card Number'){
        this.currentbalance=resp['data']['CardInformation']['otb']
        this.selectedIndex=index
        this.savebaladetails()
        this.loaderService.display(false)
      }else{
        this.loaderService.display(false)
        this.toastr.error("Card Details are invalid!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    }
    
  },error=>{
    this.loaderService.display(false)
    console.log(error)
    this.toastr.error("Failed to get card details!","Alert",{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
  })
  
  
}




getBalanceEnquiryDetails(id,orgid,index){
  this.showLastCheckedDetails=false;

this.selectedDetails=index
this.cardservice.getBalDetails(orgid,id).then(resp=>{
  
  if(!this.isEmpty(resp)){
  if(!!resp){

    this.lastCheckedBy=resp['Data']['username']+" "+"("+resp['Data']['userrole']+")"
    this.lastCheckedbalance="Rs. "+resp['Data']['balance']
    this.lastCheckedDate=this.datepipe.transform( resp['Data']['checkeddate'], 'dd-MM-yyyy')
    this.lastCheckedTime=this.datepipe.transform( resp['Data']['checkeddate'], 'hh:mm a')
    this.lastCheckedTimeStamp=this.lastCheckedDate+" | "+this.lastCheckedTime
    this.showLastCheckedDetails=true;
  }
}else{
  this.noDetailsMsg="No Details Available!"
  this.lastCheckedBy=this.noDetailsMsg
    this.lastCheckedbalance=this.noDetailsMsg
    this.lastCheckedTimeStamp=this.noDetailsMsg
    
    this.showLastCheckedDetails=true;
}
},error=>{
  console.log(error)
})
}

savebaladetails(){
 
  var params={
    "balance": this.currentbalance,
"cardid": this.cardid,
"adminuserid":this.userid,
"orgid": this.orgid
  }
  //console.log(params)
  this.cardservice.saveBalDetails(params).then(resp=>{
    console.log(resp)
    if(resp['Msg']=='Credit Enquiry Saved'){
      console.log("Details Saved")
    }else{
      console.log("Not able to save latest balance,Check with team!")
    }
  },error=>{
    console.log('Error in Saving Details!')
    console.log(error)
  })
}

isEmpty(obj) {
  for(var prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}



}
