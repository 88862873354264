import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Config } from '../config';
import * as uuid from 'uuid';
import * as S3 from 'aws-sdk/clients/s3';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class AwsuploadService {
bucket:any;
  path = new Config().getOnboadingURL();
  sessionK = new Config().getSessionK();
  sessionS = new Config().getSessionS();
  S3BucketName =""
  constructor(private http: HttpClient,private router: Router,private toastr: ToastrService,private httpClient: HttpClient) {
    this.http.get(this.path+"first-login/get-master-data")
            .subscribe(
                res => {
                    if(res["status"] == "Success") {
                        this.S3BucketName = this.decrypt(res["data"]["bucket_name"])
                        this.bucket = new S3(
                            {
                                accessKeyId: this.decrypt(res["data"]["access_key"]),
                                secretAccessKey: this.decrypt(res["data"]["secret_access"]),
                                endpoint: this.decrypt(res["data"]["region"])
                            }
                        );
                    }
                },
                err => {
                    console.log("Error occured : " + err);
                }
            );
   }

   decrypt(value:any) {
    let _key = CryptoJS.enc.Utf8.parse(this.sessionK);
    let _iv = CryptoJS.enc.Utf8.parse(this.sessionS);


    const decrypted = CryptoJS.AES.decrypt(value, _key, {
      keySize: 32,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
    

    return decrypted
  }
  //AQUAPAY PAYMENT TECHNOLOGIES PRIVATE LIMITED/pancard.jpg
  uploadFile(file,orgName): Promise<any>{ 
    return new Promise((resolve, reject) => {
    const contentType = file.type;
    const params = {
        Bucket: this.S3BucketName,
        Key: orgName + "/"+ file.name +"/"+ uuid.v4(),
        Body: file,
        ACL: 'private',
        ContentType: contentType
    };
      this.bucket.upload(params, function (err, data) {
          if (err) {
              console.log('There was an error uploading your file: ', err);
              resolve({"status":"Failed","data":data});
          }
          console.log('Successfully uploaded file.', data);
          resolve({"status":"Success","data":data});
      });
  //for upload progress   
  /*bucket.upload(params).on('httpUploadProgress', function (evt) {
          console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
      }).send(function (err, data) {
          if (err) {
              console.log('There was an error uploading your file: ', err);
              return false;
          }
          console.log('Successfully uploaded file.', data);
          return true;
      });*/
      
    });
  }
  
  addDocumentRecord(data): Promise<any>{
    return new Promise((resolve, reject) => {
        this.http.post(this.path+"uploadDocumentRecord/upload-record",data)
            .subscribe(
                res => {
                    console.log(res);
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    })
  }

  addDocumentRecordAdmin(data): Promise<any>{
    return new Promise((resolve, reject) => {
        this.http.post(this.path+"uploadDocumentRecord/upload-record-admin",data)
            .subscribe(
                res => {
                    console.log(res);
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    })
  }
  
  getDocument(key): Promise<any>{ 
    return new Promise((resolve, reject) => {
    const params = {
        Bucket: this.S3BucketName,
        Key: key ,
    };
      this.bucket.getObject(params, function (err, data) {
          if (err) {
              console.log('There was an error uploading your file: ', err);
              resolve({"status":"Failed","data":data});
          }
          console.log('Successfully uploaded file.', data);
          resolve({"status":"Success","data":data});
      });
    });
  }
  
  deleteDocument(key): Promise<any>{
    return new Promise((resolve, reject) => {
    const params = {
        Bucket: 'icici-documents',
        Key: key,
    };
        this.bucket.deleteObject(params, function (err, data) {
            if (err) {
                console.log('There was an error uploading your file: ', err);
                resolve({"status":"Failed","data":data});
            }
            console.log('Successfully uploaded file.', data);
            resolve({"status":"Success","data":data});
        });
  })
  }
  
  list(): Promise<any>{
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.path+"uploadDocumentRecord/list")
          .subscribe(
              res => {
                  console.log(res);
                  resolve(res);
              },
              err => {
                  console.log("Error occured : " + err);
                  reject(err);
              }
          );
  
  });
  
  return promise;
  
  }
  
  listByOrgId(orgId): Promise<any>{
      let object = {"orgId":orgId}
      let promise = new Promise((resolve, reject) => {
        this.http.post(this.path+"uploadDocumentRecord/list-by-orgId",object)
            .subscribe(
                res => {
                    console.log(res);
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    
    });
    
    return promise;
    
    }
}
