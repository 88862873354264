import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { VcnService } from 'src/app/_api/vcn.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-update-balance',
  templateUrl: './update-balance.component.html',
  styleUrls: ['./update-balance.component.css']
})
export class UpdateBalanceComponent implements OnInit {
  selectedFiles: FileList;
  filename:string="Click to Browse";
  isValid:boolean = true;
  constructor(private router: Router,private spinner: NgxSpinnerService,private vcn:VcnService, private toastr: ToastrService) { }

  ngOnInit() {
  }

  onFileChange(event: any) {
    this.selectedFiles = event.target.files;
    this.filename = this.selectedFiles[0].name;
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data); // Data will be logged in array format containing objects

      data.forEach(object=>{
        if(object["Request_id"] == null || object["Request_id"] == undefined || object["Amount"] == null || object["Amount"] == undefined) {
          this.isValid = false
        }
      })

      console.log("ISVALID", this.isValid)
      if(this.isValid) {
        console.log("API CALLED")
          this.UploadDirectFile(this.selectedFiles[0])
      } else {
        this.toastr.error("Please check the file data and try again!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    };
    
 }

 UploadDirectFile(file){
  
 // console.log(this.filename.length)

        this.vcn.uploadUpdateBalanceFile(file).subscribe(
          data=>{
            if(data["err"] == null) {
              this.router.navigate(['/card-req-submit-success'],{queryParams:{msg:'updatebalancesuccess'}});
            } else {
              this.toastr.error(data["message"],'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            }
          },error=>{
              this.toastr.error("Failed to upload!",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })

          }
        )
        }
}
