import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
declare var jquery:any;
declare var $ :any;
import { Users } from '../../models/users';
import { Router,ActivatedRoute } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import { NgxSpinnerService } from "ngx-spinner";
import {VcncorpService} from '../../_api/vcncorp.service'
import { ToastrService } from 'ngx-toastr';
import {Urlconfig} from '../../urlconfig';
import {AuthService} from '../../_api/auth.service';
const vgc_path= new Urlconfig().getGiftCardURL()

@Component({
  selector: 'app-corporate-dashboard',
  templateUrl: './corporate-dashboard.component.html',
  styleUrls: ['./corporate-dashboard.component.css']
})
export class CorporateDashboardComponent implements OnInit {

  @ViewChild('myDiv') myDiv: ElementRef;
  // public dashboardData: any;
   public params:string;
   display='none'; //default Variable
   top='50px';
   public username:string;
   public rolename:string;
   currentUser: Users;
   isUnisup:boolean;
   isBulksup:boolean;
   isApprsup:boolean;
   isUnipay:boolean;
   isApprpay:boolean;
   isBulkpay:boolean;
   isPaysupstatus:boolean;
   isCardActivity:boolean;
   cards:any=[];
   pendingcards:any=[];
   approvedcards:any=[];
   rejectedcards:any[];
   fileUpload:File;
   filename:string="Click to Browse";
   cancelcardfilename:string="Click to Browse";
   selectedFiles: FileList;
   model: any = {};
   userData: any = {};
   _users:any[]=[];
   isSelected = false;
   step2:boolean=false;
   showAliaDD:boolean=false;
   showUploadFileOpt:boolean=false;
   corporateData:any=[]
   aliasdata:any=[]
   templatedata:any=[]
   supplierdata:any=[]
   corporate_id:any
   alias_id:any;
   template_id:any;
   supplier_id:any;
   showUploadOption:boolean=false;
   showCancellationModule: boolean = false;
   file_name_params:any;
   template_name_params:any;
   supplier_name_params:any;
   vgc_url:any;
   redirecttoken:string;
   showUploadTokenBox:any = false;
  //  showAttentionBox:any;
   constructor(private auth:AuthService,private route: ActivatedRoute,private router: Router,private vcn:VcnService,private spinner: NgxSpinnerService,private vcncorp:VcncorpService,private toastr: ToastrService) { }
 

  ngOnInit() {
    // this.showAttentionBox = sessionStorage.getItem('showAttentionBox');  //STRING VALUE is stored in the session storage
    this.loadUserDetails()
    this.aliasdata=[]
    this.templatedata=[]
    this.supplierdata=[]
    this.alias_id = null
    this.template_id = null
    this.supplier_id = null
    this.redirecttoken=this.route.snapshot.queryParams["token"];
    if(this.redirecttoken!=undefined){
      this.auth.saveToken(this.redirecttoken)
    }
  }

  checkValInArray(tabid:string , tab_array:string[]){
    return (tab_array.indexOf(tabid)==-1)?false:true;
}

private loadUserDetails(){
  this.vcn.getCurrentUser().then(users => {
    this.vgc_url=vgc_path+'corp-dashboard?token='+localStorage.getItem('token');
     this.currentUser = users['data'];
     sessionStorage.setItem("is_download_file",this.currentUser['is_download_file'])
     sessionStorage.setItem("is_send_file_on_email",this.currentUser['is_send_file_on_email'])
     this.showUploadTokenBox = this.currentUser["corporate_status"] == 'Approved' ? true : false;
     this.showCancellationModule = this.currentUser['is_cancel'];
      if(this.currentUser["corporate_status"] == "Pending"){
       this.router.navigate(["/upload-docs"])
      }

      // CODE TO SHOW POP UP ON LOGIN TO CHECKER USER
      // if(this.showAttentionBox == 'false' && (this.currentUser['role_name']=='Checker' || this.currentUser['self_approval']==true)){
      //   sessionStorage.setItem('showAttentionBox','true');
      //   let element: HTMLElement = document.getElementById('download-file-button') as HTMLElement
      //   element.click();
      // }

      if(this.currentUser['role_name']=='Maker' || this.currentUser['self_approval']==true){
        this.showUploadOption=true
      }else{
        this.showUploadOption=false
      }
      this.loadAlias()
      this.loadTemplates()
      this.loadSuppliers()
    });
}

UploadFile(event){
  this.selectedFiles = event.target.files;
  this.filename = this.selectedFiles[0].name;
 
 
  this.vcncorp.uploadCardReqFile(this.selectedFiles[0]).subscribe(
    
    data=>{
    
     // this.router.navigate(['/card-upload-view']);
      // this.step2=true;
      // this.userData=data["Data"];
    },error=>{
      this.step2=false;
    }
  )

}
UploadDirectFile(event){
  this.spinner.show()
  this.selectedFiles = event.target.files;
  this.filename = this.selectedFiles[0].name;
 if(this.selectedFiles[0].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
  this.spinner.hide()
  this.toastr.warning("Please select a file with .xlsx format!",'Alert',{
    timeOut:3000,
    positionClass:'toast-top-center'
    })
 }else if(this.filename.length<70){

        this.vcncorp.uploadCardReqFile(this.selectedFiles[0]).subscribe(
          data=>{
            this.step2=true;
             localStorage.setItem('crdData',JSON.stringify(data))
             localStorage.setItem('alias_id',this.alias_id)
             localStorage.setItem('template_id',this.template_id)
             localStorage.setItem('supplier_id',this.supplier_id)
             this.spinner.hide()
             this.router.navigate(['/corp-rev-card-req']);
          },error=>{
            if(error.error.message=='File already exist!'){
              this.step2=false;
              this.spinner.hide()
              this.toastr.warning("Please change the filename, file with this filename already used before!",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            }else{
              this.step2=false;
              this.spinner.hide()
              this.toastr.warning("Failed to upload!",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            }
          }
        )
  }else{
    this.step2=false;
    this.spinner.hide()
    this.toastr.warning("Filename length should be of 70 characters only including extension!",'Alert',{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
   }

 


}

UploadCancelCardFile(event){
  this.spinner.show()
  this.selectedFiles = event.target.files;
  this.cancelcardfilename = this.selectedFiles[0].name;

 if(this.selectedFiles[0].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
  this.spinner.hide()
  this.toastr.warning("Please select a file with .xlsx format!",'Alert',{
    timeOut:3000,
    positionClass:'toast-top-center'
    })
 }else if(this.cancelcardfilename.length<70){
    if(this.cancelcardfilename.includes('_')){

        this.vcncorp.uploadCancelCardReqFile(this.selectedFiles[0]).subscribe(
          data=>{
           this.spinner.hide()
           if(data["err"] == true) {
            if(data["message"] == 'Duplicate Card Number found'){
              this.toastr.warning("Please check the file, duplicate card numbers are present in the file",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            } else{
              this.toastr.warning("Failed to upload!",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            }
           } else if(data['out']['total_request'] > 100){
            this.toastr.warning("File cannot contain more than 100 records! Please update the file.",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
           } else {
            let records = data['filedata'];
              this.step2=true;
              localStorage.setItem('CancelCardData',JSON.stringify(data))
              localStorage.setItem('alias_id',this.alias_id)
              this.router.navigate(['/corp-cancel-card-rev-req']);
           }
          },error=>{
            if(error.error.message=='File already exist!'){
              this.step2=false;
              this.spinner.hide()
              this.toastr.warning("Please change the filename, file with this filename already used before!",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            } else{
              this.step2=false;
              this.spinner.hide()
              this.toastr.warning("Failed to upload!",'Alert',{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            }
          }
        )
        
    }else{
      this.step2=false;
      this.spinner.hide()
      this.toastr.warning("Please change the file nameing convention as example shown!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
  }else{
    this.step2=false;
    this.spinner.hide()
    this.toastr.warning("Filename length should be of 70 characters only including extension!",'Alert',{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
   }
}


loadAliasdd(){
  this.showAliaDD=true
}

loadUploadFileOpt(){
  this.file_name_params = null
    for(var data of this.aliasdata){
      if(data['id']==this.alias_id){
        this.file_name_params=data['alias_name']
      }
    }
  if(this.file_name_params != null && this.template_name_params != null && this.supplier_name_params != null) {
    this.showUploadFileOpt=true;
  } else {
    this.showUploadFileOpt=false;
  }
}
loadUploadFileOptTemplate(){
    this.template_name_params = null
    for(var data of this.templatedata){
      if(data['id']==this.template_id){
        this.template_name_params=data['name']
      }
    }
    if(this.file_name_params != null && this.template_name_params != null && this.supplier_name_params != null) {
      this.showUploadFileOpt=true;
    } else {
      this.showUploadFileOpt=false;
    }
}
loadUploadFileOptSupplier(){
    this.supplier_name_params = null
    for(var data of this.supplierdata){
      if(data['id']==this.supplier_id){
        this.supplier_name_params=data['name']
      }
    }
    if(this.file_name_params != null && this.template_name_params != null && this.supplier_name_params != null) {
      this.showUploadFileOpt=true;
    } else {
      this.showUploadFileOpt=false;
    }
}



private loadAlias(){
  this.spinner.show()
  this.vcncorp.getAliasdata().then(resp=>{
   
    if(resp['message']=='Alias data' && resp['error']==null){
      this.spinner.hide()
      if(this.currentUser['corporate']['maker_restriction'] == 'restricted') {
        var data = resp['data'];
        var aliasArray = this.currentUser['alias_id'] == null ? [] : this.currentUser['alias_id'].split(",")
        this.aliasdata = [];
        aliasArray.forEach(obj=>{
          const index = data.findIndex((alias) => alias.id == obj)
          index >= 0 ? this.aliasdata.push(data[index]) : '';
        })
        this.loadAliasdd()
      } else {
        this.aliasdata=resp['data']
        this.loadAliasdd()
      }
    }else{
      this.spinner.hide()
    }
   
  },error=>{
    this.spinner.hide()
    console.log(error)
  })
}

navigateToUnitary(alias_name,template_name,supplier_name){
  sessionStorage.setItem("alias_name",this.file_name_params);
  sessionStorage.setItem("template_name",this.template_name_params);
  sessionStorage.setItem("supplier_name",this.supplier_name_params);
  sessionStorage.setItem("alias_id",this.alias_id);
  sessionStorage.setItem("template_id",this.template_id);
  sessionStorage.setItem("supplier_id",this.supplier_id);
  this.router.navigate(['/token-upload']);
}


private loadTemplates(){
  this.spinner.show()
  this.vcncorp.getTemplatedata({}).then(resp=>{
   
    if(resp['msg']=='Tempaltes Found Successfully' && resp['statusCode']==200){
      this.templatedata=resp['log']
      this.spinner.hide()
    }else{
      this.spinner.hide()
    }
   
  },error=>{
    this.spinner.hide()
    console.log(error)
  })
}
private loadSuppliers(){
  this.spinner.show()
  this.vcncorp.getsupplierdata({}).then(resp=>{
   
    if(resp['msg']=='Suppliers Found Successfully' && resp['statusCode']==200){
      this.supplierdata=[]
      this.supplierdata=resp['log']
      this.spinner.hide()
    }else{
      this.spinner.hide()
    }
   
  },error=>{
    this.spinner.hide()
    console.log(error)
  })
}

private refreshSuppleirs(){
  this.spinner.show()
  this.vcncorp.refreshsupplierdata({}).then(resp=>{
   
    if(resp['msg']=='Added Suppliers Succesfully' && resp['statusCode']==200){
      this.spinner.hide()
      this.loadSuppliers()
    }else{
      this.spinner.hide()
    }
   
  },error=>{
    this.spinner.hide()
    console.log(error)
  })
}

}
