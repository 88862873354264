import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import{LoaderService} from '../../_api/loader.service';
import {UserService} from '../../_api/user.service'
import * as $ from 'jquery';
declare var $: any;
@Component({
  selector: 'app-otpuser',
  templateUrl: './otpuser.component.html',
  styleUrls: ['./otpuser.component.css']
})
export class OtpuserComponent implements OnInit {
  validateOTP: any = [];
  public approvedPayment: any;
  //public id: string;
  public ids: any = [];
  public txt1: any;
  public txt2: any;
  public txt3: any;
  public txt4: any;
  public txt5: any;

  constructor(private route: ActivatedRoute, private router: Router,private loader:LoaderService,private userservice: UserService) { }

  ngOnInit() {
    this.loader.display(true);
    this.ids = JSON.parse(this.route.snapshot.paramMap.get('ids'));
    this.userservice.sendOtp(this.ids).then(resp => {
      this.validateOTP = resp.data;
      if(resp['userotpvalue']==1){
        this.loader.display(false);
        this.router.navigate(['/main/successmsg'],{queryParams:{msg:'usrapprsuccess'}});
      }
      this.loader.display(false);
    });

    $('.inputstyle').bind('keyup', function (event) {
      var val = $(this).val();
      if (val != '') {
        $(this).parent().next().find('input').focus();
      }
    });
  }

  gotoValidateOTP(): void {
    this.loader.display(true);
    if (!!this.txt1 && this.txt2 && this.txt3 && this.txt4 && this.txt5) {
      var checkOtp = this.txt1 + this.txt2 + this.txt3 + this.txt4 + this.txt5;
      console.log("OTP" + checkOtp);
    }
    else {
      this.loader.display(false);
      alert("Enter otp");
    }

    if (checkOtp.length == 5) {
      var checkOtp = this.txt1 + this.txt2 + this.txt3 + this.txt4 + this.txt5;
      this.userservice.validateOTP(checkOtp).then(resp => {
        this.approvedPayment = resp.data;
        if (!!this.approvedPayment && this.approvedPayment == "otp validated") {
          this.loader.display(false);
          this.router.navigate(['/main/successmsg'],{queryParams:{msg:'usrapprsuccess'}});
        } else {
          this.loader.display(false);
          alert("Enter correct OTP");
        }
      });
    }
    else {
      this.loader.display(false);
      alert("Enter proper OTP");
    }

  }

}
