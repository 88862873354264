import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrgserviceService } from 'src/app/_api/orgservice.service';
import { Config } from 'src/app/config';
const path = new Config().getVcnUrl();

@Component({
  selector: 'app-add-alias',
  templateUrl: './add-alias.component.html',
  styleUrls: ['./add-alias.component.css']
})
export class AddAliasComponent implements OnInit {
  formdetailsrow: boolean=true;
  listbillerrow: boolean=false;
  billersaddedlist: any=[];
  selectedIndex: any;
  billdata:any={};
  corpId:any;
  authMatrix:any;
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }
  headChange:any;
  dropdownData:any;
  showSubmitButton = false;
  constructor(private spinner: NgxSpinnerService,private router: Router,public datepipe: DatePipe,private orgservice: OrgserviceService,private http: HttpClient,private toastr: ToastrService) { }

  ngOnInit() {
    this.showSubmitButton = false;
    this.loadOrganizations()
  }

  loadOrganizations(){
    this.spinner.show()
    this.orgservice.getOrganisationList().then(data=>{
     this.dropdownData = data['data'];
     this.spinner.hide();
    },error=>{
      console.log(error.message);
      this.spinner.hide();
    }).catch(err=>{
      console.log(err.message);
      this.spinner.hide();
    })
  }

  setSubCorpName(id){
      if(id) {
        for(var i=0;i<this.dropdownData.length;i++){
          if(this.dropdownData[i].id == id){
            console.log(this.dropdownData[i].name)
            this.billdata.sub_corp_name = this.dropdownData[i].name
          }
        }
      }
  }

      addanotherbill(){
        this.formdetailsrow=true
        this.listbillerrow=true
        this.headChange=true
      }

      validatedata(){
        this.spinner.show()
        this.ValidateBack(this.billdata).subscribe(data => {
          this.spinner.hide()
          //alert("Submitted SUccessfully")
          if(data["msg"] == "success") {
            this.showSubmitButton =true
         this.toastr.success("Template validated successfully!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
        } else {
          this.toastr.error(data["msg"],"Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
       },error => {
         console.log(error)
         this.spinner.hide()
         this.toastr.error("Something went wrong! Please try again!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      });
    }

    ValidateBack(users:any){
      console.log(users)
      return this.http.post(path+`/user/validateAlias`, users);
  }

      submitdata(){
          this.spinner.show()
          this.submitBulkUserback(this.billdata).subscribe(data => {
            this.spinner.hide()
            //alert("Submitted SUccessfully")
            if(data["success"] == true) {
           this.router.navigate(['/card-request-summary']);
           this.toastr.success("Alias added successfully!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
          } else {
            this.toastr.error(data["message"],"Alert",{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
         },error => {
           console.log(error)
           this.spinner.hide()
           this.toastr.error("Something went wrong! Please try again!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        });
      }

      submitBulkUserback(users:any){
        console.log(users)
        return this.http.post(path+`/user/addAlias`, users);
    }

    // checkuser() {
    //   this.spinner.show()
    //   this.orgservice.checkuser({"email":this.billdata.email}).then(data=>{
    //     this.spinner.hide()
    //     if(data.message == "No user found!") {

    //     } else {
    //       this.billdata.email = null
    //       this.toastr.error("Email Id already exist!","Alert",{
    //         timeOut:3000,
    //         positionClass:'toast-top-center'
    //         })
    //     }
    //   },err=>{
    //     this.spinner.hide()
    //   })
    // }
}
