  import { Component, OnInit } from '@angular/core';
  import { Router, ActivatedRoute } from '@angular/router';
  import { NgxSpinnerService } from 'ngx-spinner';
  import { ToastrService } from 'ngx-toastr';
  import { AwsuploadService } from 'src/app/_api/awsupload.service';
  import { KarzaApiService } from 'src/app/_api/karza-api.service';
  import { LoaderService } from 'src/app/_api/loader.service';
  import { OrgserviceService } from 'src/app/_api/orgservice.service';
  declare var $: any;

  @Component({
    selector: 'app-org-details',
    templateUrl: './org-details.component.html',
    styleUrls: ['./org-details.component.css']
  })
  export class OrgDetailsComponent implements OnInit {
    partnershipDocObject = {
      "companyGstCertificate":{
        "type": "companyGstCertificate",
        "status":"Pending"
      },
      "companyPan":{
        "type": "companyPan",
        "status":"Pending"
      },
      "listOfPartners":{
        "type": "listOfPartners",
        "status":"Pending"
      },
      "registrationCertificate":{
        "type": "registrationCertificate",
        "status":"Pending"
      },
      "asDetails":{
        "type": "asDetails",
        "status":"Pending"
      },
      "chDetails":{
        "type": "chDetails",
        "status":"Pending"
      },
      "partnershipDeed":{
        "type": "partnershipDeed",
        "status":"Pending"
      },
      "finalContract":{
        "type": "finalContract",
        "status":"Pending"
      },
      "companyAuthorizationLetter":{
        "type": "companyAuthorizationLetter",
        "status":"Pending"
      }
    };

    privateltdDocObject = {
      "companyGstCertificate":{
        "type": "companyGstCertificate",
        "status":"Pending"
      },
      "companyPan":{
        "type": "companyPan",
        "status":"Pending"
      },
      "moa":{
        "type": "moa",
        "status":"Pending"
      },
      "aoa":{
        "type": "aoa",
        "status":"Pending"
      },
      "certificateOfIncorporation":{
        "type": "certificateOfIncorporation",
        "status":"Pending"
      },
      "allDirectorsPanAndAadhar":{
        "type": "allDirectorsPanAndAadhar",
        "status":"Pending"
      },
      "listOfDirectors":{
        "type": "listOfDirectors",
        "status":"Pending"
      },
      "asDetails":{
        "type": "asDetails",
        "status":"Pending"
      },
      "chDetails":{
        "type": "chDetails",
        "status":"Pending"
      },
      "finalContract":{
        "type": "finalContract",
        "status":"Pending"
      },
      "companyAuthorizationLetter":{
        "type": "companyAuthorizationLetter",
        "status":"Pending"
      }
    };

    proprietorshipDocObject = {
      "companyGstCertificate":{
        "type": "companyGstCertificate",
        "status":"Pending"
      },
      "companyPan":{
        "type": "companyPan",
        "status":"Pending"
      },
      "gumastaOrMsme":{
        "type": "moa",
        "status":"Pending"
      },
      "proprietorDetails":{
        "type": "aoa",
        "status":"Pending"
      },
      "asDetails":{
        "type": "asDetails",
        "status":"Pending"
      },
      "chDetails":{
        "type": "chDetails",
        "status":"Pending"
      },
      "finalContract":{
        "type": "finalContract",
        "status":"Pending"
      },
      "companyAuthorizationLetter":{
        "type": "companyAuthorizationLetter",
        "status":"Pending"
      }
    };

    publicDocObject = {
      "companyGstCertificate":{
        "type": "companyGstCertificate",
        "status":"Pending"
      },
      "companyPan":{
        "type": "companyPan",
        "status":"Pending"
      },
      "moa":{
        "type": "moa",
        "status":"Pending"
      },
      "aoa":{
        "type": "aoa",
        "status":"Pending"
      },
      "certificateOfIncorporation":{
        "type": "certificateOfIncorporation",
        "status":"Pending"
      },
      "allDirectorsPanAndAadhar":{
        "type": "allDirectorsPanAndAadhar",
        "status":"Pending"
      },
      "listOfDirectors":{
        "type": "listOfDirectors",
        "status":"Pending"
      },
      "asDetails":{
        "type": "asDetails",
        "status":"Pending"
      },
      "chDetails":{
        "type": "chDetails",
        "status":"Pending"
      },
      "finalContract":{
        "type": "finalContract",
        "status":"Pending"
      },
      "companyAuthorizationLetter":{
        "type": "companyAuthorizationLetter",
        "status":"Pending"
      }
    };

    LLPDocObject = {
      "companyGstCertificate":{
        "type": "companyGstCertificate",
        "status":"Pending"
      },
      "companyPan":{
        "type": "companyPan",
        "status":"Pending"
      },
      "listOfPartners":{
        "type": "listOfPartners",
        "status":"Pending"
      },
      "registrationCertificate":{
        "type": "registrationCertificate",
        "status":"Pending"
      },
      "asDetails":{
        "type": "asDetails",
        "status":"Pending"
      },
      "chDetails":{
        "type": "chDetails",
        "status":"Pending"
      },
      "partnershipDeed":{
        "type": "partnershipDeed",
        "status":"Pending"
      },
      "finalContract":{
        "type": "finalContract",
        "status":"Pending"
      },
      "companyAuthorizationLetter":{
        "type": "companyAuthorizationLetter",
        "status":"Pending"
      }
    };
    mimes = {
      'jpeg': 'data:image/jpeg;base64,',
      'png': 'data:image/png;base64,',
      'pdf': 'data:application/pdf;base64,'
  };
  uploadedData:{} = {};
    orgdata:any;
    corpgstval:any;
    corppanval:any;
    asUser:any;
    ccadminUser: any;
    esignData:any;
    orgId:any;
    type1 = false;
  type2 = false;
  type3 = false;
  type4 = false;
  type5 = false;
  corporateType: any;
  result:any;
  display:any;
  display1:any;
  GSTFile: any;
  selectedFiles: any;
  filename: any;
  bodyObj:any={};
  contractUploaded = false;
  contractType = "newcontract";
  company_id:any;
  cp_name_user:any = "";
  cp_name_mastercard:any = "";
  maker_restriction:any;
  userData:any;
  selectedIndex: any;
    constructor(private orgservice: OrgserviceService,private spinner: NgxSpinnerService,private karzaService: KarzaApiService,private awsService:AwsuploadService ,private orgService: OrgserviceService,private loaderService: LoaderService,private router: Router,private activatedRoute: ActivatedRoute, private toastr: ToastrService) { }

    ngOnInit() {
      this.orgId = this.activatedRoute.snapshot.queryParams["orgId"];
      this.loadOrganizations();
      this.contractUploaded = false
    }

    UploadFile(event){
      if(event.target.files){
        console.log(event.target.files[0].type)
        // if(event.target.files[0].type == "application/pdf"){
          this.spinner.show();
          this.selectedFiles = event.target.files;
          this.filename = this.selectedFiles[0].name;
          const reader = new FileReader();
          var base64Obj = ""
          this.bodyObj = {}
          reader.onload = (e: any) => {
            base64Obj = e.target.result.split(",")[1];
            console.log(base64Obj)
            this.bodyObj={
              "asname":this.orgdata.asname + " "+this.orgdata.asdesignation,
              "actualASemail":this.orgdata.asemail,
              "base64Doc":base64Obj,
              "org_id":this.orgId
            }
            this.contractUploaded = true
            this.spinner.hide();
          }
          reader.readAsDataURL(event.target.files[0]);
      //   } else {
      //     this.toastr.error("Please upload a pdf file!", 'Alert', {
      //       positionClass:'toast-top-center',
      //       timeOut:3000
      //     })
      //   }
      } else {
        this.toastr.warning("Please select a file!", 'Alert', {
          positionClass:'toast-top-center',
          timeOut:3000
        })
      }
      
      
    }

    showDocuList($event){
      this.contractType = $event.target.value
    }

    goBack(){
      this.router.navigate(['/org-listing']);
    }

    onProcced(){
      this.bodyObj["contractType"] = this.contractType
      this.spinner.show()
      this.karzaService.uploadContractForEsign(this.bodyObj).then(data=>{
        this.spinner.hide()
        if(data.status == "success") {
          this.toastr.success("Contract Uploaded successfully", 'Alert', {
            positionClass:'toast-top-center',
            timeOut:3000
          })
          this.router.navigate(['/org-listing']);
        } else {
          this.toastr.error("Something went wrong. Please try again", 'Alert', {
            positionClass:'toast-top-center',
            timeOut:3000
          })
        }
      })
    }

    loadOrganizations(){
      this.spinner.show()
      this.orgService.getOrganisationDataById({"orgId":this.orgId}).then(data=>{
        this.spinner.hide()
      this.orgdata = data['data'];
      this.company_id = this.orgdata['company_id']
      this.cp_name_user= this.orgdata['name']
      //  this.convenience_fee = this.orgdata.convenience_fee
      this.corpgstval = data['corpgstval'];
      this.corppanval = data['corppanval'];
      this.asUser = data['asUser'];
      this.ccadminUser = data['ccadminUser'];
      this.esignData = data['esignData']
      this.maker_restriction = this.orgdata['maker_restriction']
      this.corporateType = this.orgdata.org_type;
        if(this.orgdata.org_type == "Partnership"){
          this.type1 = true;
          this.type2 = false;
          this.type3 = false;
          this.type4 = false;
          this.type5 = false;
        } else if(this.orgdata.org_type == "Private Ltd"){
          this.type1 = false;
          this.type2 = true;
          this.type3 = false;
          this.type4 = false;
          this.type5 = false;
        } else if(this.orgdata.org_type == "Proprietorship") {
          this.type1 = false;
          this.type2 = false;
          this.type3 = true;
          this.type4 = false;
          this.type5 = false;
        } else if(this.orgdata.org_type == "Public"){
          this.type1 = false;
          this.type2 = false;
          this.type3 = false;
          this.type4 = true;
          this.type5 = false;
        } else if(this.corporateType == "LLP"){
          this.type1 = false;
          this.type2 = false;
          this.type3 = false;
          this.type4 = false;
          this.type5 = true;
        }

        this.awsService.listByOrgId(this.orgdata.id).then(resp=>{
          if(resp.status == "Success") {
            var objects = resp.data;
            objects.map(element => {
              if(element.status == "Success") {
                if(this.type1){
                  this.partnershipDocObject[element.type]["name"] = element.name;
                  this.partnershipDocObject[element.type]["status"] = element.status;
                  this.partnershipDocObject[element.type]["key"] = element.key;
                } else if(this.type2){
                  this.privateltdDocObject[element.type]["name"] = element.name;
                  this.privateltdDocObject[element.type]["status"] = element.status;
                  this.privateltdDocObject[element.type]["key"] = element.key;
                } else if(this.type3){
                  this.proprietorshipDocObject[element.type]["name"] = element.name;
                  this.proprietorshipDocObject[element.type]["status"] = element.status;
                  this.proprietorshipDocObject[element.type]["key"] = element.key;
                } else if(this.type4){
                  this.publicDocObject[element.type]["name"] = element.name;
                  this.publicDocObject[element.type]["status"] = element.status;
                  this.publicDocObject[element.type]["key"] = element.key;
                } else if(this.type5){
                  this.LLPDocObject[element.type]["name"] = element.name;
                  this.LLPDocObject[element.type]["status"] = element.status;
                  this.LLPDocObject[element.type]["key"] = element.key;
                }
                
              }
            });
          } else {
            this.toastr.warning("Something went wrong. Please try again", 'Alert', {
                    positionClass:'toast-top-center',
                    timeOut:3000
                  })
          }
        })
      this.spinner.hide();
      },error=>{
        console.log(error.message);
        this.spinner.hide();
      }).catch(err=>{
        console.log(err.message);
        this.spinner.hide();
      })
      this.getCorpUsers(this.orgId)
    }

    getCorpUsers(id){
      console.log("ID",id)
      this.spinner.show()
       
      this.orgservice.getOrganisationUserList(id).then(data=>{
        this.spinner.hide();
        if(data['success'] == true){
          this.userData = data['log']
        } else {
          this.toastr.error("Something went wrong!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
       },error=>{
         console.log(error.message);
         this.spinner.hide();
       }).catch(err=>{
         console.log(err.message);
         this.spinner.hide();
       })
    }

    openDeclarationPopup(){
      if(this.company_id == this.orgdata.company_id){
        this.toastr.warning("Please update compnay id!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      } else if(this.company_id){
        this.spinner.show()
        this.orgService.getCompnyNameByCompanyId({"company_id":this.company_id.toString()})
        .then(resp=>{
          this.spinner.hide()
          if(resp['msg']=="success"){
            this.cp_name_mastercard = resp["data"]["name"]
            let element: HTMLElement = document.getElementById('din-match-button') as HTMLElement
            element.click();
          } else{
            this.toastr.warning(resp["msg"],"Alert",{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
        },err=>{
          this.spinner.hide()
          console.log(err["msg"])
          this.toastr.warning("Something went wrong!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        })

      } else {
        this.toastr.warning("Please enter compnay id!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    }

    changeCompanyId(){
      var data ={
        "company_id": this.company_id,
        "org_id":this.orgId
      }
      this.spinner.show()
          this.orgService.setCompanyId(data).then(resp=>{
            this.spinner.hide();
            if(resp.status != "Successful") {
              this.toastr.error(resp.message, 'Alert', {
                positionClass:'toast-top-center',
                timeOut:3000
              })
            } else if(resp.status == "Successful"){
              this.toastr.success("Company Id updated successfully", 'Alert', {
                positionClass:'toast-top-center',
                timeOut:3000
              })
            }
          },error=>{
            this.toastr.error(error.message, 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          })
    }

    changeMakerRestriction(){
      var data ={
        "maker_restriction": this.maker_restriction,
        "org_id":this.orgId
      }
      this.spinner.show()
          this.orgService.setMakerRestriction(data).then(resp=>{
            this.spinner.hide();
            if(resp.status != "Successful") {
              this.toastr.error(resp.message, 'Alert', {
                positionClass:'toast-top-center',
                timeOut:3000
              })
            } else if(resp.status == "Successful"){
              this.toastr.success("Maker Restriction updated successfully", 'Alert', {
                positionClass:'toast-top-center',
                timeOut:3000
              })
            }
          },error=>{
            this.toastr.error(error.message, 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          })
    }
    viewDocument(key){
      this.spinner.show();
      this.awsService.getDocument(key).then( async data=>{
        if(data.status == "Success"){
          this.spinner.hide();
          if(data.data.ContentType == "application/pdf"){
            this.getPdfURL(data.data,this.mimes.pdf);
            let element1: HTMLElement = document.getElementById('modal-pdf-hidden-button') as HTMLElement
            element1.click();
            this.display1 = "block";
          } else if(data.data.ContentType == "image/jpeg"){
            this.getUrlByFileName(data.data,this.mimes.jpeg);
            let element: HTMLElement = document.getElementById('modal-hidden-button') as HTMLElement
            element.click();
            this.display = "block";
          } else if(data.data.ContentType == "image/png"){
            this.getUrlByFileName(data.data,this.mimes.png);
            let element: HTMLElement = document.getElementById('modal-hidden-button') as HTMLElement
            element.click();
            this.display = "block";
          }
        }
      })
    }

    downloadDocument(obj){
      this.spinner.show();
      this.awsService.getDocument(obj.key).then( async data=>{
        if(data.status == "Success"){
          this.spinner.hide();
          if(data.data.ContentType == "application/pdf"){
            // PDF DOWNLOAD LOGIC
            var bytes = data.data.Body
            const source = `data:application/pdf;base64,`+this.encode(bytes);
            const link = document.createElement("a");
            link.href = source;
            link.download = obj.name
            link.click();
          } else if(data.data.ContentType == "image/jpeg"){
            var bytes = data.data.Body
            const source = `data:image/jpeg;base64,`+this.encode(bytes);
            const link = document.createElement("a");
            link.href = source;
            link.download = obj.name
            link.click();
          } else if(data.data.ContentType == "image/png") {
            var bytes = data.data.Body
            const source = `data:image/png;base64,`+this.encode(bytes);
            const link = document.createElement("a");
            link.href = source;
            link.download = obj.name
            link.click();
          }
        }
      })
    }

    loadSupLogs(id:number,index){
      this.selectedIndex = index;
    }

    encode(data)
        {
            var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
            return btoa(str).replace(/.{76}(?=.)/g,'$&\n');
        }

      getUrlByFileName(file,mimeType) {
          this.result =  mimeType + this.encode(file.Body);
          $("#PAN").attr('src', this.result);
        }

        getPdfURL(file,mimeType){
          this.result = mimeType + this.encode(file.Body)
          $("#ifrm").attr('src', this.result);

        }

        onCloseHandled() {
          this.display = "none";
        }

        onCloseHandled1() {
          this.display1 = "none";
        }

        uploadStep1(event,type){
          if(event.target.files){
            console.log(event.target.files[0])
            if(event.target.files[0].size / 1000000 < 5){
            if(event.target.files[0].type == "application/pdf" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png"){
          this.GSTFile = event.target.files;
          // this.GSTfilename = this.GSTFile.name;
          console.log("TYPE",type)
          this.spinner.show()
          this.awsService.uploadFile(this.GSTFile.item(0),this.orgdata.name).then(async resp=>{
            this.spinner.hide()
            if(resp.status == "Success") {
              console.log(resp);
              if(this.partnershipDocObject[type]["status"] == "Success") {
                this.awsService.deleteDocument(this.partnershipDocObject[type]["key"]).catch(err=>console.log(err));
              }
              this.partnershipDocObject[type]["status"] = "Success";
              this.partnershipDocObject[type]["key"] = resp["data"]["key"];
              this.partnershipDocObject[type]["name"] = this.GSTFile.item(0).name;
              this.partnershipDocObject[type]["orgId"] = this.orgId
              this.awsService.addDocumentRecordAdmin(this.partnershipDocObject[type]).then(respObject=>{
                if(respObject.status == "Success")
                {
                  this.toastr.success("Document Uploaded Successfully", 'Alert', {
                    positionClass:'toast-top-center',
                    timeOut:3000
                  })
                } else {
                  this.toastr.error("Something went wrong. Please try again", 'Alert', {
                    positionClass:'toast-top-center',
                    timeOut:3000
                  })
                }
              })
            } else {
              this.toastr.warning("Something went wrong. Please try again", 'Alert', {
                      positionClass:'toast-top-center',
                      timeOut:3000
                    })
            }
          },err=>{
            this.spinner.hide()
            this.toastr.warning("Something went wrong. Please try again", 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          })
        } else {
          this.toastr.error("Please upload a jpeg, png or pdf file!", 'Alert', {
            positionClass:'toast-top-center',
            timeOut:3000
          })
        }
      } else {
        this.toastr.error("File size cannot exceed 5MB!", 'Alert', {
          positionClass:'toast-top-center',
          timeOut:3000
        })
      }
      } else {
        this.toastr.warning("Please select a file!", 'Alert', {
          positionClass:'toast-top-center',
          timeOut:3000
        })
      }
        }
      
        uploadStep2(event,type){
          if(event.target.files){
            console.log(event.target.files[0].type)
            if(event.target.files[0].size / 1000000 < 5){
            if(event.target.files[0].type == "application/pdf" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png"){
          this.GSTFile = event.target.files;
          // this.GSTfilename = this.GSTFile.name;
          console.log("TYPE",type)
          this.spinner.show()
          this.awsService.uploadFile(this.GSTFile.item(0),this.orgdata.name).then(async resp=>{
            this.spinner.hide()
            if(resp.status == "Success") {
              console.log(resp);
              if(this.privateltdDocObject[type]["status"] == "Success") {
                this.awsService.deleteDocument(this.privateltdDocObject[type]["key"]).catch(err=>console.log(err));
              }
              this.privateltdDocObject[type]["status"] = "Success";
              this.privateltdDocObject[type]["key"] = resp["data"]["key"];
              this.privateltdDocObject[type]["name"] = this.GSTFile.item(0).name;
              this.privateltdDocObject[type]["orgId"] = this.orgId
              this.awsService.addDocumentRecordAdmin(this.privateltdDocObject[type]).then(respObject=>{
                if(respObject.status == "Success")
                {
                  this.toastr.success("Document Uploaded Successfully", 'Alert', {
                    positionClass:'toast-top-center',
                    timeOut:3000
                  })
                } else {
                  this.toastr.error("Something went wrong. Please try again", 'Alert', {
                    positionClass:'toast-top-center',
                    timeOut:3000
                  })
                }
              })
            } else {
              this.toastr.warning("Something went wrong. Please try again", 'Alert', {
                      positionClass:'toast-top-center',
                      timeOut:3000
                    })
            }
          },err=>{
            this.spinner.hide()
            this.toastr.warning("Something went wrong. Please try again", 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          })
        } else {
          this.toastr.error("Please upload a jpeg, png or pdf file!", 'Alert', {
            positionClass:'toast-top-center',
            timeOut:3000
          })
        }
      } else {
        this.toastr.error("File size cannot exceed 5MB!", 'Alert', {
          positionClass:'toast-top-center',
          timeOut:3000
        })
      }
      } else {
        this.toastr.warning("Please select a file!", 'Alert', {
          positionClass:'toast-top-center',
          timeOut:3000
        })
      }
        }
      
        uploadStep3(event,type){
          if(event.target.files){
            console.log(event.target.files[0].type)
            if(event.target.files[0].size / 1000000 < 5){
            if(event.target.files[0].type == "application/pdf" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png"){
          this.GSTFile = event.target.files;
          // this.GSTfilename = this.GSTFile.name;
          console.log("TYPE",type)
          this.spinner.show()
          this.awsService.uploadFile(this.GSTFile.item(0),this.orgdata.name).then(async resp=>{
            this.spinner.hide()
            if(resp.status == "Success") {
              console.log(resp);
              if(this.proprietorshipDocObject[type]["status"] == "Success") {
                this.awsService.deleteDocument(this.proprietorshipDocObject[type]["key"]).catch(err=>console.log(err));
              }
              this.proprietorshipDocObject[type]["status"] = "Success";
              this.proprietorshipDocObject[type]["key"] = resp["data"]["key"];
              this.proprietorshipDocObject[type]["name"] = this.GSTFile.item(0).name;
              this.proprietorshipDocObject[type]["orgId"] = this.orgId

              this.awsService.addDocumentRecordAdmin(this.proprietorshipDocObject[type]).then(respObject=>{
                if(respObject.status == "Success")
                {
                  this.toastr.success("Document Uploaded Successfully", 'Alert', {
                    positionClass:'toast-top-center',
                    timeOut:3000
                  })
                } else {
                  this.toastr.error("Something went wrong. Please try again", 'Alert', {
                    positionClass:'toast-top-center',
                    timeOut:3000
                  })
                }
              })
            } else {
              this.toastr.warning("Something went wrong. Please try again", 'Alert', {
                      positionClass:'toast-top-center',
                      timeOut:3000
                    })
            }
          },err=>{
            this.spinner.hide()
            this.toastr.warning("Something went wrong. Please try again", 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          })
        } else {
          this.toastr.error("Please upload a jpeg, png or pdf file!", 'Alert', {
            positionClass:'toast-top-center',
            timeOut:3000
          })
        }
      } else {
        this.toastr.error("File size cannot exceed 5MB!", 'Alert', {
          positionClass:'toast-top-center',
          timeOut:3000
        })
      }
      } else {
        this.toastr.warning("Please select a file!", 'Alert', {
          positionClass:'toast-top-center',
          timeOut:3000
        })
      }
        }
      
        uploadStep4(event,type){
          if(event.target.files){
            console.log(event.target.files[0].type)
            if(event.target.files[0].size / 1000000 < 5){
            if(event.target.files[0].type == "application/pdf" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png"){
          this.GSTFile = event.target.files;
          // this.GSTfilename = this.GSTFile.name;
          console.log("TYPE",type)
          this.spinner.show()
          this.awsService.uploadFile(this.GSTFile.item(0),this.orgdata.name).then(async resp=>{
            this.spinner.hide()
            if(resp.status == "Success") {
              console.log(resp);
              if(this.publicDocObject[type]["status"] == "Success") {
                this.awsService.deleteDocument(this.publicDocObject[type]["key"]).catch(err=>console.log(err));
              }
              this.publicDocObject[type]["status"] = "Success";
              this.publicDocObject[type]["key"] = resp["data"]["key"];
              this.publicDocObject[type]["name"] = this.GSTFile.item(0).name;
              this.publicDocObject[type]["orgId"] = this.orgId
              this.awsService.addDocumentRecordAdmin(this.publicDocObject[type]).then(respObject=>{
                if(respObject.status == "Success")
                {
                  this.toastr.success("Document Uploaded Successfully", 'Alert', {
                    positionClass:'toast-top-center',
                    timeOut:3000
                  })
                } else {
                  this.toastr.error("Something went wrong. Please try again", 'Alert', {
                    positionClass:'toast-top-center',
                    timeOut:3000
                  })
                }
              })
            } else {
              this.toastr.warning("Something went wrong. Please try again", 'Alert', {
                      positionClass:'toast-top-center',
                      timeOut:3000
                    })
            }
          },err=>{
            this.spinner.hide()
            this.toastr.warning("Something went wrong. Please try again", 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          })
        } else {
          this.toastr.error("Please upload a jpeg, png or pdf file!", 'Alert', {
            positionClass:'toast-top-center',
            timeOut:3000
          })
        }
      } else {
        this.toastr.error("File size cannot exceed 5MB!", 'Alert', {
          positionClass:'toast-top-center',
          timeOut:3000
        })
      }
      } else {
        this.toastr.warning("Please select a file!", 'Alert', {
          positionClass:'toast-top-center',
          timeOut:3000
        })
      }
        }

        uploadStep5(event,type){
          if(event.target.files){
            console.log(event.target.files[0].type)
            if(event.target.files[0].size / 1000000 < 5){
            if(event.target.files[0].type == "application/pdf" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png"){
          this.GSTFile = event.target.files;
          // this.GSTfilename = this.GSTFile.name;
          console.log("TYPE",type)
          this.spinner.show()
          this.awsService.uploadFile(this.GSTFile.item(0),this.orgdata.name).then(async resp=>{
            this.spinner.hide()
            if(resp.status == "Success") {
              console.log(resp);
              if(this.LLPDocObject[type]["status"] == "Success") {
                this.awsService.deleteDocument(this.LLPDocObject[type]["key"]).catch(err=>console.log(err));
              }
              this.LLPDocObject[type]["status"] = "Success";
              this.LLPDocObject[type]["key"] = resp["data"]["key"];
              this.LLPDocObject[type]["name"] = this.GSTFile.item(0).name;
              this.LLPDocObject[type]["orgId"] = this.orgId
              this.awsService.addDocumentRecordAdmin(this.LLPDocObject[type]).then(respObject=>{
                if(respObject.status == "Success")
                {
                  this.toastr.success("Document Uploaded Successfully", 'Alert', {
                    positionClass:'toast-top-center',
                    timeOut:3000
                  })
                } else {
                  this.toastr.error("Something went wrong. Please try again", 'Alert', {
                    positionClass:'toast-top-center',
                    timeOut:3000
                  })
                }
              })
            } else {
              this.toastr.warning("Something went wrong. Please try again", 'Alert', {
                      positionClass:'toast-top-center',
                      timeOut:3000
                    })
            }
          },err=>{
            this.spinner.hide()
            this.toastr.warning("Something went wrong. Please try again", 'Alert', {
              positionClass:'toast-top-center',
              timeOut:3000
            })
          })
        } else {
          this.toastr.error("Please upload a jpeg, png or pdf file!", 'Alert', {
            positionClass:'toast-top-center',
            timeOut:3000
          })
        }
      } else {
        this.toastr.error("File size cannot exceed 5MB!", 'Alert', {
          positionClass:'toast-top-center',
          timeOut:3000
        })
      }
      } else {
        this.toastr.warning("Please select a file!", 'Alert', {
          positionClass:'toast-top-center',
          timeOut:3000
        })
      }
        }

  }
