import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelGeneratorService } from 'src/app/_api/excel-generator.service';
import { VcnuserService } from 'src/app/_api/vcnuser.service';
import {PageEvent} from '@angular/material/paginator';
import * as $ from 'jquery';
import { HospitalService } from 'src/app/_api/hospital.service';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'app-volo-seach-claimno-admin',
  templateUrl: './volo-seach-claimno-admin.component.html',
  styleUrls: ['./volo-seach-claimno-admin.component.css']
})
export class VoloSeachClaimnoAdminComponent implements OnInit {
  fromdate:any;
  todate:any;
  step2:boolean=false;
  card_no:string=null
  pur_req_id:string=null
  cardData:any={

  }
  currentFDate:Date=new Date();
  currentTDate:Date=new Date();
  currFdate:string;
  currTdate:string;
  cardBalance:any=null;
  showBalanace:boolean=false;
  cardId:any;
  selectedOptp:any;
  showCardNo:boolean=false;
  showPurChasedReqId:boolean=false;
  filedata:any;
  filedatafinal:any = [];
  claimno:any;
  selectedIndex = -1;
  cardnoToShow:any;
  hospital_id:any;
  selectedHosp:any='Select Hospital';
  hospitalData:any;
  hospitalName:any="--";
  hospitalId:any="--";

  cardno:any;
  cvv:any;
  cardexpiry:any;
  cvvToShow:any;
  cardexpiryToShow:any;

  constructor(public datepipe: DatePipe,private hospitalservice:HospitalService,private route:Router,private toastr: ToastrService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.currentFDate.setDate(this.currentFDate.getDate() - 1);
    this.currFdate=this.datepipe.transform(this.currentFDate, 'yyyy-MM-dd');
    this.currTdate=this.datepipe.transform(this.currentTDate, 'yyyy-MM-dd');
    // this.loadHospitalDetails();
  }

  // loadHospitalDetails(){
  //   this.hospitalservice.getHospitalDetails().then(resp=>{
  //     this.spinner.hide()
  //     this.hospitalData=resp['log']
  //     this.hospitalName=resp['log'][0]['hospital_Name']
  //   },error=>{
  //     this.spinner.hide()
  //     console.log(error)
  //   })
  // }


  viewcardDetails(crdno,cvv,expiry): void {
    this.cardno = crdno
    // this.cvv = atob(cvv)
    // this.cardexpiry = atob(expiry)

    this.cardnoToShow=`${atob(crdno).substring(4,0)}    ${atob(crdno).substring(8,4)}    ${atob(crdno).substring(12,8)}    ${atob(crdno).substring(16,12)}`
    // this.cvvToShow="XXX"
    // this.cardexpiryToShow=`${this.cardexpiry.substring(2,0)} / ${this.cardexpiry.substring(6,2)}`  

    $("#payreport").modal("show");
 
  }

  lastdigits(cardno:any){
    return cardno.substring(19,15)
   }

  showCVV(){
    this.cardnoToShow = "XXXX XXXX XXXX XXXX";
    this.cardexpiryToShow = "XX/XX"
    this.cvvToShow = this.cvv
  }

  hideCVV(){
    this.cardnoToShow = `${atob(this.cardno).substring(4,0)}    ${atob(this.cardno).substring(8,4)}    ${atob(this.cardno).substring(12,8)}    ${atob(this.cardno).substring(16,12)}`;
    this.cardexpiryToShow = `${this.cardexpiry.substring(2,0)} / ${this.cardexpiry.substring(6,2)}`
    this.cvvToShow = "XXX"
  }

  getsearchparameter(param){
    if(param=='crd'){
      this.showCardNo=true;
      this.showPurChasedReqId=false;
      this.card_no = null;
      this.claimno = null;
    }else if(param=='prid'){
      this.showCardNo=false;
      this.showPurChasedReqId=true;
      this.card_no = null;
      this.claimno = null;
    }
  }

  finddetails(claimno,hospital_id){
    this.spinner.show()
    console.log(hospital_id);
    
  this.step2=true
  var param ={}
  if(this.showCardNo){
    param={
      "card_no":this.card_no,
      // "hospital_id":this.selectedHosp
    }
  } else {
    param={
      "claim_no":this.claimno,
      // "hospital_id":this.selectedHosp
    }
  }

   console.log(this.filedatafinal);
   this.hospitalservice.getCardByClaimNo(param).then(resp=>{
    console.log(resp)
    this.spinner.hide()
    if(resp["msg"]=="success"){
    this.filedatafinal[0]=resp['log']
    this.filedatafinal[0]['lastdigits']=this.filedatafinal[0]['token_no'].substring(16,12)
    this.hospitalName = this.filedatafinal[0]["hospital_detail"]["hospital_Name"]
    this.hospitalId = this.filedatafinal[0]["hospital_detail"]["hospital_id"];
    console.log(this.cardnoToShow);
    } else {
      this.hospitalName = "--";
      this.hospitalId = "--";
      this.filedatafinal = []
      this.toastr.error(resp["msg"],"Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
  },error=>{
    this.spinner.hide()
    this.hospitalName = "--";
    this.hospitalId = "--";
    this.filedatafinal = []
    console.log(error)
    this.toastr.error("Failed to Get Auth Report!!","Alert",{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
   
  })

  }


  toggleExpandedView(index){
    this.selectedIndex = index;
   }

  

  

  backClicked(){
    this.step2=false
  }
}
