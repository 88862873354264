import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadDocsService {

  constructor(private http: HttpClient){}
    uploadGSTCert(file: File){
      let promise = new Promise<any>((resolve, reject) => {
        const formdata: FormData = new FormData();
        formdata.append('file', file);
        return this.http.post('http://localhost:3000/upload/upload-gst-cert', formdata).subscribe(
            res => {
                resolve(res)
            },
            err => {
                reject(err)
            }
        )
      })
      return promise;
    }

    uploadPAN(file: File){
        let promise = new Promise<any>((resolve, reject) => {
          const formdata: FormData = new FormData();
          formdata.append('file', file);
          return this.http.post('http://localhost:3000/upload/upload-pan', formdata).subscribe(
              res => {
                  resolve(res)
              },
              err => {
                  reject(err)
              }
          )
        })
        return promise;
      }
    
      uploadMOA(file: File){
        let promise = new Promise<any>((resolve, reject) => {
          const formdata: FormData = new FormData();
          formdata.append('file', file);
          return this.http.post('http://localhost:3000/upload/upload-moa', formdata).subscribe(
              res => {
                  resolve(res)
              },
              err => {
                  reject(err)
              }
          )
        })
        return promise;
      }

      uploadCOA(file: File){
        let promise = new Promise<any>((resolve, reject) => {
          const formdata: FormData = new FormData();
          formdata.append('file', file);
          return this.http.post('http://localhost:3000/upload/upload-coa', formdata).subscribe(
              res => {
                  resolve(res)
              },
              err => {
                  reject(err)
              }
          )
        })
        return promise;
      }
     
      uploadAadhar(file: File){
        let promise = new Promise<any>((resolve, reject) => {
            const formdata: FormData = new FormData();
            formdata.append('file', file);
            return this.http.post('http://localhost:3000/upload/upload-aadhar', formdata).subscribe(
                res => {
                    resolve(res)
                },
                err => {
                    reject(err)
                }
            )
          })
          return promise;
      }
}
