import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
declare var jquery:any;
declare var $ :any;
// declare var loadCharts: any;
import{UserService} from '../../_api/user.service'
import { Users } from '../../models/users';
import{CardService} from '../../_api/card.service'
import { Router,ActivatedRoute } from '@angular/router';
import {VcnService} from '../../_api/vcn.service'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
import {VcncorpService} from '../../_api/vcncorp.service'
import {ExcelGeneratorService} from  '../../_api/excel-generator.service'
@Component({
  selector: 'app-update-corp-contact-bulk',
  templateUrl: './update-corp-contact-bulk.component.html',
  styleUrls: ['./update-corp-contact-bulk.component.css']
})
export class UpdateCorpContactBulkComponent implements OnInit {
  @ViewChild('myDiv') myDiv: ElementRef;
 // public dashboardData: any;
  public params:string;
  display='none'; //default Variable
  top='50px';
  public username:string;
  public rolename:string;
  currentUser: Users;
  isUnisup:boolean;
  isBulksup:boolean;
  isApprsup:boolean;
  isUnipay:boolean;
  isApprpay:boolean;
  isBulkpay:boolean;
  isPaysupstatus:boolean;
  isCardActivity:boolean;
  cards:any=[];
  pendingcards:any=[];
  approvedcards:any=[];
  rejectedcards:any[];
  fileUpload:File;
  filename:string="Click to Browse"
  selectedFiles: FileList;
  model: any = {};
  userData: any = {};
  _users:any[]=[];
  isSelected = false;
  step2:boolean=false;
  showAliaDD:boolean=false;
  showUploadFileOpt:boolean=false;
  corporateData:any=[]
  aliasdata:any=[]
  corporate_id:any
  alias_id:any;
  updateData:any=[]
  downloadArray:any=[];
  constructor(private userservice: UserService,private cardservice: CardService,private router: Router,private activatedRoute: ActivatedRoute,private vcn:VcnService,private spinner: NgxSpinnerService,private toastr: ToastrService,private vcncorp:VcncorpService,private excel:ExcelGeneratorService) { }

  ngOnInit() {
  }

  UploadDirectFile(event){
    this.spinner.show()
    this.selectedFiles = event.target.files;
    this.filename = this.selectedFiles[0].name;
    
   // console.log('selectedFiles: ' + event.target.files[0] );
    this.vcncorp.updateContactBulkFile(this.selectedFiles[0]).subscribe(
      
      data=>{
        this.step2=true;
        if(data['message']=='Mobile update!'){
          console.log(data)
          this.updateData=data['data']
          this.downloadArray=[]
          if(this.updateData.length==0){
            console.log('Success')
             this.spinner.hide()
          this.router.navigate(['/mobile-update-success-corp']);
          }else{
            console.log('Un Success')
            for(let data of this.updateData){
              var obj={
                Purchased_Req_id:data['request_purchase_id'],
                Reason:data['reason'],
              }
              this.downloadArray.push(obj)
            }
            this.excel.exportAsExcelFile( this.downloadArray, "Rejected_List_Contact_Update");
            this.toastr.warning("Some Contacts can't be updated because Purchased Req Id not belong to your Corporate, Please Check the downloaded list for your reference!",'Alert',{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
          // this.spinner.hide()
          // this.router.navigate(['/mobile-update-success-corp']);
        }else{
          this.spinner.hide()
          console.log("Failed to Update Numbers")
          this.toastr.error("Something Went wrong!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
        // this.userData=data["Data"];
        // console.log(data);
        //  localStorage.setItem('crdData',JSON.stringify(data))
        //  localStorage.setItem('corporate_id',this.corporate_id)
        //  localStorage.setItem('alias_id',this.alias_id)
         this.spinner.hide()
         //this.router.navigate(['/card-upload-view']);
      },error=>{
        this.step2=false;
        this.spinner.hide()
        console.log("Failed to Upload")
        this.toastr.error("failed to Update mibile numbers!",'Alert',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    )
  
  }

}
