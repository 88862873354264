import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelGeneratorService } from 'src/app/_api/excel-generator.service';
import { VcnuserService } from 'src/app/_api/vcnuser.service';
import {PageEvent} from '@angular/material/paginator';
import * as $ from 'jquery';
import { HospitalService } from 'src/app/_api/hospital.service';
declare var jquery:any;
declare var $ :any;

import * as CryptoJS from 'crypto-js';
import { Config } from 'src/app/config';

const decryptKey = new Config().getVCNCipher()
const decryptIV = new Config().getVCNText()


@Component({
  selector: 'app-volo-dashboard-admin',
  templateUrl: './volo-dashboard-admin.component.html',
  styleUrls: ['./volo-dashboard-admin.component.css']
})
export class VoloDashboardAdminComponent implements OnInit {
 
  filter:any;
  filter1:any;
  fromdate:any;
  isShown = false;
  isShow1 = false;
  isShow2 = false;
  todate:any;
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }
  carddata:any=[]
  filedata:any=[]
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  selectedIndex = -1;
  downloadArray:any=[];
  currentFDate:Date=new Date();
  currentTDate:Date=new Date();
  currFdate:string;
  currTdate:string;
  cardId:any
  list_from_date:any;
  list_to_date:any;
  lt_f_date:string;
  lt_t_date:string;
  cardno:any;
  cvv:any;
  cardexpiry:any;
  cardnoToShow:any;
  cvvToShow:any;
  cardexpiryToShow:any;


  step2:boolean=false;
  card_no:string=null
  pur_req_id:string=null
  cardData:any={

  } 
  cardBalance:any=null;
  showBalanace:boolean=false;
  selectedHosp:any=null;
  showCardNo:boolean=false;
  showPurChasedReqId:boolean=false;
  filedatafinal:any;
  hosp_name:any;
  status:any="pending";
  transactiondata:any=[];
  pageTotal:any=0;

  length = 50;
  pageSize = 1;
  pageIndex = 0;
  pageSizeOptions = [1,2];
  pageEvent: PageEvent;
  hospitalData: any;
  cardholder: any;
  transaction_ref_no: any;
  request_purchase_id: any;
  decrypted: any;

  constructor(private route:Router,private toastr: ToastrService,private hospitalservice:HospitalService, private vcnuser:VcnuserService,private spinner: NgxSpinnerService, private excel:ExcelGeneratorService,public datepipe: DatePipe) { }

  ngOnInit() {

    $(document).ready(function(){
      $("#vcn").show();
      $("#mycard").click(function(){
        $("#vcn").show();
        $("#vgiftcard").hide();
        $("#pgiftcard").hide();
        $("#mycard").addClass("active");
        $("#pendcard").removeClass("active");
        $("#rejctcard").removeClass("active");
    });
      $("#pendcard").click(function(){
        $("#vgiftcard").show();
        $("#vcn").hide();
        $("#pgiftcard").hide();
        $("#pendcard").addClass("active");
        $("#mycard").removeClass("active");
        $("#rejctcard").removeClass("active");
    });
    $("#rejctcard").click(function(){
      $("#pgiftcard").show();
      $("#vcn").hide();
      $("#vgiftcard").hide();
      $("#rejctcard").addClass("active");
      $("#pendcard").removeClass("active");
      $("#mycard").removeClass("active");
  });
  });
  this.currentFDate.setDate(this.currentFDate.getDate() - 1);
  this.currFdate=this.datepipe.transform(this.currentFDate, 'dd-MM-yyyy');
  this.currTdate=this.datepipe.transform(this.currentTDate, 'dd-MM-yyyy');
  //console.log(this.currFdate+" "+this.currTdate)
  // this.loadCardData()
  this.loadHospitalDetails();
  //this.loaddashboardDetails();
  }

  loadHospitalDetails(){
    this.hospitalservice.getHospitalDetails().then(resp=>{
      this.spinner.hide()
      this.hospitalData=resp['log']
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }

  pendingTab(){
    if(this.selectedHosp == null){
      this.toastr.warning("Please Select the Hospital","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    this.carddata=[]
    this.length = 50;
    this.pageSize = 1;
    this.pageIndex = 0;
    this.pageSizeOptions=[1,2]
    this.list_from_date=null
    this.list_to_date=null
    this.status="pending";
   
    this.loaddashboardDetails();
  }

  approvedTab(){
    if(this.selectedHosp == null){
      this.toastr.warning("Please Select the Hospital","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    this.carddata=[]
    this.length = 50;
    this.pageSize = 1;
    this.pageIndex = 0;
    this.pageSizeOptions=[1,2]
    this.list_from_date=null
    this.list_to_date=null
    this.status="settled";
  
    this.loaddashboardDetails();
  }

  redeemTab(){
    if(this.selectedHosp == null){
      this.toastr.warning("Please Select the Hospital","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    this.carddata=[]
    this.length = 50;
    this.pageSize = 1;
    this.pageIndex = 0;
    this.pageSizeOptions=[1,2]
    this.list_from_date=null
    this.list_to_date=null
    this.status="approved";
    
    this.loaddashboardDetails();
  }

  loaddashboardDetails(){
    this.spinner.show()
    var param={
      "fromDate":"",
    "toDate":"",
      "skip":0,
      "limit":100,
      "status":this.status,
      "hospital_id":this.selectedHosp
    }
   
    this.hospitalservice.getdashboardDetails(param).then(resp=>{
      this.spinner.hide()
      if(resp["msg"] == "success") {
        this.length=resp['log']['count']
        this.carddata=resp['log']['rows']
        console.log(resp['data']['rows']);
      } else {
        this.length = 0;
        this.cardData = []
      }
      
    },error=>{
      this.spinner.hide()
      console.log(error)
      // this.toastr.error("Failed to Get Auth Report!!","Alert",{
      //   timeOut:3000,
      //   positionClass:'toast-top-center'
      //   })
     
    })
  }

  loaddashboardFilterDetails(){
    this.spinner.show()
    var fromDate = this.datepipe.transform(this.list_from_date, 'dd-MM-yyyy')
    var toDate = this.datepipe.transform(this.list_to_date, 'dd-MM-yyyy')
    var param={
      "fromDate":fromDate,
      "toDate":toDate,
      "skip":this.pageIndex,
      "limit":this.pageSize,
      "status":this.status,
      "hospital_id":this.selectedHosp
    }
   
    this.hospitalservice.getdashboardDetails(param).then(resp=>{
      this.spinner.hide()
      if(resp["msg"] == "success") {
        this.length=resp['log']['count']
        this.carddata=resp['log']['rows']
        console.log(this.carddata);
      } else {
        this.length = 0;
        this.cardData = []
      }
      
    },error=>{
      this.spinner.hide()
      console.log(error)
      // this.toastr.error("Failed to Get Auth Report!!","Alert",{
      //   timeOut:3000,
      //   positionClass:'toast-top-center'
      //   })
     
    })
  }



  lastdigits(cardno:any){
    return atob(cardno).substring(16,12)
   }

  

  handlePageEvent(e: PageEvent) {
    
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageEvent , this.length,this.pageSize,this.pageIndex);
    this.loaddashboardFilterDetails()
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  fromdateChange(event){
    this.lt_f_date=event._d
    this.lt_f_date=this.datepipe.transform(this.lt_f_date, 'yyyy-MM-dd')
    console.log(!!this.lt_f_date)
  }

  todateChange(event){
    this.lt_t_date=event._d
    this.lt_t_date=this.datepipe.transform(this.lt_t_date, 'yyyy-MM-dd')
    console.log(!!this.lt_t_date)
  }

  toggleExpandedView(index){
    this.selectedIndex = index;
   }


  gotoTransactions(id:any){
    this.spinner.show()
    var param={
      "id":id,
      "from_date":this.currFdate,
      "to_date":this.currTdate
    }
    this.vcnuser.getVcnAuthReport(param).then(resp=>{
      console.log(resp)
      if(resp['message']=='There is no transaction between the given dates'){
        this.spinner.hide()
        this.toastr.error("No Transactions found!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='VCN authorization report!'){
        this.spinner.hide()
        localStorage.setItem('authreport',JSON.stringify(resp['data']))
        this.route.navigate(['/card-auth-report']);
      }else if(resp['message']=='Code: ERROR_CREATE_DELETED_VCN_AUTHS_REPORT Description: We are unable to create an authorization report for a cancelled VCN. To view these transactions please run the authorization report for the RCN.'){
        this.spinner.hide()
        this.toastr.error("Card has been cancelled!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else{
        this.spinner.hide()
        this.toastr.error("Semething Went Wrong!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
      this.toastr.error("Failed to Get Auth Report!!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
     
    })
  }



  gotodaterangereport(){
    // console.log(this.cardId)
    // console.log(this.fromdate)
    // console.log(this.todate)
    this.spinner.show()
    if(this.fromdate!=undefined || this.fromdate!=null){
      this.fromdate=this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    }
    else{
      this.fromdate=this.currFdate
    }
    
    if(this.todate!=undefined || this.todate!=null){
      this.todate=this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    }
   else{
    this.todate=this.currTdate
    }


    var param={
      "id":this.cardId,
      "from_date":this.fromdate,
      "to_date":this.todate
    }
    this.vcnuser.getVcnAuthReport(param).then(resp=>{
      console.log(resp)
      if(resp['message']=='There is no transaction between the given dates'){
        this.spinner.hide()
        this.toastr.error("No Transactions found!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='VCN authorization report!'){
        this.spinner.hide()
        localStorage.setItem('authreportdr',JSON.stringify(resp['data']))
       this.route.navigate(['/date-range-report']);
       
      }else if(resp['message']=='Code: ERROR_CREATE_DELETED_VCN_AUTHS_REPORT Description: We are unable to create an authorization report for a cancelled VCN. To view these transactions please run the authorization report for the RCN.'){
        this.spinner.hide()
        this.toastr.error("Card has been cancelled!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='The report date range cannot exceed 31 day(s).'){
        this.spinner.hide()
        this.toastr.error("The report date range cannot exceed 31 day(s).","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='From date should be before to date.'){
        this.spinner.hide()
        this.toastr.error("From date should be before to date.","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else{
        this.spinner.hide()
        this.toastr.error("Semething Went Wrong!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
      this.toastr.error("Failed to Get Auth Report!!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
     
    })
   // this.route.navigate(['/date-range-report']);

  }

  viewPastTxns(id:any){
    this.cardId=id
    $("#pastTxn").modal("show");
  }

  viewcardDetails(crdno,cvv,expiry): void {
    // this.cardno = crdno
    // this.cvv = cvv
    // this.cardexpiry = expiry
console.log(crdno+" "+cvv+" "+expiry);

    this.cardnoToShow=`${atob(crdno).substring(4,0)}    ${atob(crdno).substring(8,4)}    ${atob(crdno).substring(12,8)}    ${atob(crdno).substring(16,12)}`
    this.cvvToShow="XXX"
    this.cardexpiryToShow=`${expiry.substring(2,0)} / ${expiry.substring(6,2)}`  

    $("#payreport").modal("show");
 
}

    decryptUsingAES256(value:any) {
      let _key = CryptoJS.enc.Utf8.parse(decryptKey);
      let _iv = CryptoJS.enc.Utf8.parse(decryptIV);
 

      this.decrypted = CryptoJS.AES.decrypt(value, _key, {
        keySize: 32,
        iv: _iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(CryptoJS.enc.Utf8);
      

      return this.decrypted
    }

  showCVV(){
    this.cardnoToShow = "XXXX XXXX XXXX XXXX";
    this.cardexpiryToShow = "XX/XX"
    this.cvvToShow = this.cvv
  }

  hideCVV(){
    this.cardnoToShow = `${atob(this.cardno).substring(4,0)}    ${atob(this.cardno).substring(8,4)}    ${atob(this.cardno).substring(12,8)}    ${atob(this.cardno).substring(16,12)}`;
    this.cardexpiryToShow = `${this.cardexpiry.substring(2,0)} / ${this.cardexpiry.substring(6,2)}`
    this.cvvToShow = "XXX"
  }

  toggleShow() {
    this.isShown = ! this.isShown;
  }


  toggleShow1(){
    this.isShow1 = ! this.isShow1;
  }


  toggleShow2(){
    this.isShow2 = ! this.isShow2;
  }

  loadAllCards(){
    this.list_from_date="";
    this.list_to_date="";
    this.carddata=[]
  }

  loadCardData(){
    this.spinner.show()
    var params={
      "from":!!this.lt_f_date?this.lt_f_date:"",
      "to":!!this.lt_t_date?this.lt_t_date:"",
    }
    this.vcnuser.getUserCards(params).then(resp=>{
    
      if(resp['message']=='Cards Data!'){
        console.log(resp)
        this.spinner.hide()
        this.carddata=resp['data']
      }else{
        this.spinner.hide()
        this.toastr.error("Something went wrong!!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      this.carddata=[]
      this.spinner.hide()
      this.toastr.error("Token No Not found!!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
      console.log(error)
    })
  }

  getBalance(id){
    this.spinner.show()
    var params={
      "id":id
    }
    this.vcnuser.getCardBalance(params).then(resp=>{
      if(resp['message']=='Purchase request id not available!'){
        this.spinner.hide()
        this.toastr.error("Card Details are not available","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='The Requested has been Canceled'){
        this.spinner.hide()
        this.toastr.error("This card has been blocked!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else if(resp['message']=='Available Balance!'){
        for(var data of this.carddata){
            if(data['id']==resp['id']){
              this.spinner.hide()
              data['balance']=resp['data']
            }
        }
        console.log(resp)
      }
    },error=>{
      this.spinner.hide()
      console.log(error)
    })
  }



  exportAsXLSX():void {
    this.downloadArray=[]
    
    if(this.carddata.length>=0){
      for(let data of this.carddata){
        var obj={
       //   Name:data['name'],
          //Token_Number:'XXXX XXXX XXXX '+data["lastDigit"],
        //  GeneratedDate:data['card_generated_date'],
          Patient_Name:data['patient_name'],
          Token_Number:'XXXX XXXX XXXX '+this.lastdigits(data['token_no']),
          Approved_Amount:data['approved_amount'],
          Settled_Amount:data['settle_amount'],
          Claim_No:data['claim_no'],
          Initiated_Date:data['card_generated_at'],
          NetOff_TDS_Amount:data['approved_amount']-data['tds_amount'],
          TDS_Amount:data['tds_amount'],
          Transaction_Fee:data['hospital_transaction_fee'],
          GST:data['hospital_gst'],
          Insurarer_Claim_No:data['insurarer_claimno'],
          Insurarer:data['insurarer_memberId'],
          Policy_No:data['policy_No'],
          Claim_Type:data['claim_type'],
          Rohini_Id:data['hospital_detail']['rohini_id'],
          Hospital_Name:data['hospital_detail']['hospital_Name'],
          Hospital_Bank_Name:data['hospital_account']['hospital_bankName'],
          Hospital_IFSC_CODE:data['hospital_account']['hospital_ifsc'],
          Created_Date:data['createdAt'],
          Approver_Name:data['approver_name'],
          Approver_Date:data['approved_date'],
          Approver_Time:data['approved_time'],
          Comment:data['commamt']
        }
        this.downloadArray.push(obj)
      }
      this.excel.exportAsExcelFile( this.downloadArray, 'Card_List');
    }else{
      this.toastr.warning("No Data Available","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
   // this.excelservice.exportAsExcelFile( this.users, 'User');
  }


  getsearchparameter(param){
    if(param=='crd'){
      this.showCardNo=true;
      this.showPurChasedReqId=false;
      this.card_no = null;
      this.pur_req_id = null;
    }else if(param=='prid'){
      this.showCardNo=false;
      this.showPurChasedReqId=true;
      this.card_no = null;
      this.pur_req_id = null;
    }
  }

  finddetails(hosp_name){
    this.step2=true
    this.filedatafinal=this.filedata.filter(function(record){
      return record.purchase_request_id==hosp_name
    })
     console.log(this.filedatafinal);
     
    }

}
