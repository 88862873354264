import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
const path = new Config().getVcnUrl();
@Injectable({
  providedIn: 'root'
})
export class HospitalService {

  constructor(private http: HttpClient) { }

  

  getCardByClaimNo(claimno:any): Promise<any> {
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer ' + localStorage.getItem('token'))
     
    };
    let promise = new Promise((resolve, reject) => {
        // this.http.post(`${path}/Volo/searchByClaimNo`,claimno,options)
        this.http.post(`${path}/Volo/get_filedata_detail_corp`,claimno,options)
            .subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    });
    return promise;
  }

  getdashboardDetails(param:any): Promise<any> {
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer ' + localStorage.getItem('token'))
     
    };
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/Volo/dashboardData`,param,options)
            .subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    });
    return promise;
  }

  getHospitalDetails(): Promise<any> {
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer ' + localStorage.getItem('token'))
     
    };
    let promise = new Promise((resolve, reject) => {
        this.http.post(`${path}/Volo/getAllHospitals`,"",options)
            .subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    console.log("Error occured : " + err);
                    reject(err);
                }
            );
    });
    return promise;
  }
}
