import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/excelservice/excel.service';
import { Users } from 'src/app/models/users';
import { LoaderService } from 'src/app/_api/loader.service';
import { VcnService } from 'src/app/_api/vcn.service';
import { VcncorpService } from 'src/app/_api/vcncorp.service';
import * as $ from 'jquery';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'app-pending-cancelcard-vcn-view',
  templateUrl: './pending-cancelcard-vcn-view.component.html',
  styleUrls: ['./pending-cancelcard-vcn-view.component.css']
})
export class PendingCancelcardVcnViewComponent implements OnInit {
  currentUser: Users;
  corporate_id:any=null;
  filterQuery = "";
  sortBy = "amount";
  sortOrder = "asc";
  public asGroupsPendingDetails: any=[];
  public temp: any;
  public checkedValueArray: any = [];
  public showHide: any;
  public test: any;
  public flag: any;
  public comment: any;
  public rejectedgroupdata: any;
  public rejectedgroupmsg: any;
  public cntChk:any;
  public pendingdetails:any=[];
  selectall:boolean=false;
  isSelected:boolean=false
  requestSelected:number=0;
  filedata:any=[]
  filesummary:any={}
  rowsOnPage = 1000;
  // sortBy = "amount";
  // sortOrder = "asc";
  public id: string;
  cmt:any;
  userrole:any;
  selfApproval:any;
  useCheckerProcess:boolean=true;
  corpIds:any=[]
  constructor(private loader:LoaderService,private route: Router, private excel:ExcelService,private activeroute: ActivatedRoute,private vcn:VcnService,public datepipe: DatePipe,private spinner: NgxSpinnerService,private toastr: ToastrService,private vcncorp:VcncorpService, private http:HttpClient) { }

  ngOnInit() {
    this.id = this.activeroute.snapshot.paramMap.get('id');
    this.userrole=localStorage.getItem('rolename')
    this.selfApproval=localStorage.getItem('self_approval')
   this.corporate_id=localStorage.getItem('corporate_id')
    this.http.get("assets/json/makerCheckerAdminCorpId.json").subscribe(data =>{
      console.log(data);
      this.corpIds = data['corpoId'];
    })
    this.loadFileData()
  
  }

  gotoOTP(){
    this.spinner.show()
    // if(this.corpIds.includes(parseInt(this.corporate_id))){
      this.vcncorp.apprPendingCancelCardFileOther(this.id).then(resp=>{
      
        if(resp['message']=='Request approved successfully!'){
          this.spinner.hide()
          this.route.navigate(['/req-approve-success'],{queryParams:{msg:'cancelcardsuccess'}});
        }else{
          this.spinner.hide()
          this.toastr.error("Something went wrong!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
      },(error: HttpErrorResponse)=>{
        console.log(error.status)
        console.log(error.statusText)
        if(error.statusText=='Unknown Error'){
          this.spinner.hide()
          this.route.navigate(['/req-approve-success'],{queryParams:{msg:'cancelcardsuccess'}});
        }else{
          this.spinner.hide()
          this.toastr.error("Failed to Approve file!",'Alert',{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
      })
    // }
    // else{
    //   this.vcncorp.apprPendingFile(this.id).then(resp=>{
      
    //     if(resp['message']=='Request approved successfully!'){
    //       this.spinner.hide()
    //       this.route.navigate(['/req-approve-success']);
    //     }else{
    //       this.spinner.hide()
    //       this.toastr.error("Something went wrong!",'Alert',{
    //         timeOut:3000,
    //         positionClass:'toast-top-center'
    //         })
    //     }
    //   },(error: HttpErrorResponse)=>{
    //     console.log(error.status)
    //     console.log(error.statusText)
    //     if(error.statusText=='Unknown Error'){
    //       this.spinner.hide()
    //       this.route.navigate(['/req-approve-success']);
    //     }else{
    //       this.spinner.hide()
    //       this.toastr.error("Failed to Approve file!",'Alert',{
    //         timeOut:3000,
    //         positionClass:'toast-top-center'
    //         })
    //     }
    //   })
    // }
    
    
  }



checkClick(): void {
  $("#payreport").modal("show");
}

oldCardNumberDisplay(cardNo:any) {
  if(cardNo){
    var returnString = `XXXX XXXX XXXX ${atob(cardNo.substring(16))}`
    return returnString
  }
}

rejectFile(){
  this.spinner.show()
  this.vcncorp.rejCancelCardPendingFile(this.id,this.cmt).then(resp=>{

    if(resp['message']=='Request rejected successfully!'){
      this.spinner.hide()
      this.route.navigate(['/req-reject-success'],{queryParams:{msg:'cancelcardsuccess'}});
    }else{
      this.spinner.hide()
      this.toastr.error("Something went wrong!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
  },(error: HttpErrorResponse)=>{
   
    console.log(error.status)
    console.log(error.statusText)
    if(error.statusText=='Unknown Error'){
      this.spinner.hide()
      this.route.navigate(['/req-reject-success'],{queryParams:{msg:'cancelcardsuccess'}});
    }else{
      this.spinner.hide()
      this.toastr.error("Failed to Approve file!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
 
  })
}

private loadFileData(){
  this.spinner.show()
  this.vcncorp.getCancelCardFileData(this.id).then(resp=>{
    console.log("RESP",resp)
    var filedatalist=resp['data']['file_data']
    // this.filedata=filedatalist.filter(file=>(file['status']=='Inactive'))
    this.filedata=filedatalist
    this.filesummary={
      "corporate_name": resp['data']['corporate_name'],
      "corporate_id": resp['data']['corporate_id'],
      "file_name": resp['data']['file_name'],
      "total_cards": resp['data']['total_cards'],
      "total_amount": resp['data']['total_amount'],
      "utr_no":resp['data']['utr_no'],
      "status":resp['data']['status'],
      "bank_name":resp['data']['bank_name'],
      "transaction_date": resp['data']['transaction_date'],
      "sub_corp_name":resp['data']['sub_corp_name']
    }
    this.spinner.hide()

  },error=>{
    this.spinner.hide()
    console.log(error)
    this.toastr.error("Failed to load data!",'Alert',{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
  })
}


approvesingle(){
  for(var data of this.filedata){
    var params={
      
        "amount":data['amount'],
        "mobile":data['mobile_no'],
        "trnrefno":data[''],
        "id":data['id']
      
        }
      this.vcn.approveSingleCard(params,this.filesummary['rcn_id'],this.filesummary['alias_name'],this.filesummary['file_name'],this.filesummary['total_cards'],this.id)

  }
}

private loadUserDetails(){
  this.vcn.getCurrentUser().then(users => {
    console.log(users);
     this.currentUser = users['data']; 
     this.corporate_id=users['data']['corporate_id']
     
    
     if(this.id!=undefined || this.id!=null){
      this.loadFileData()
    }
    else{
      this.toastr.error("Something Went wrong!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    },error=>{
      console.log(error)
    });
}
}
