import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Config } from '../config';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const path = new Config().getVcnUrl();
const onboardingURL = new Config().getOnboadingURL();
@Injectable({
  providedIn: 'root'
})
export class ExcelGeneratorService {

  constructor(private http: HttpClient,) { }
  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFileWithPassword(json: any[], excelFileName: string,fname:any,dob:any): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      this.http.post(`${onboardingURL}organisations/xlsxDownload`,{"json":json,"fname":fname,"dob":dob},{
        responseType: 'blob' // <-- changed to blob
      })
          .subscribe(
              res => {
                this.downloadFile(res, 'application/xlsx', excelFileName)
                  resolve(res);
              },
              err => {
                  console.log(err.message);
                  reject(err);
              }
          );
  });
  return promise;
  }

  public exportAsExcelFileWithPasswordonEmail(json: any[],password:any): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      this.http.post(`${onboardingURL}organisations/xlsxDownloadCustomPassword`,{"json":json,"password":password},{
        responseType: 'blob' // <-- changed to blob
      })
          .subscribe(
              res => {
                  resolve(res);
              },
              err => {
                  console.log(err.message);
                  reject(err);
              }
          );
  });
  return promise;
  }

  public sendEmailwithXLSX(dob:any,filename:any,toEmail:any,toName: any,initiatorId:any,corpId:any,password:any,corpName:any): Promise<any> {
  let promise = new Promise((resolve, reject) => {
    const formdata: FormData = new FormData();
        formdata.append('file', dob);
        formdata.append('fileName', filename);
        formdata.append('toEmail', toEmail);
        formdata.append('toName', toName);
        formdata.append('initiatorId', initiatorId);
        formdata.append('corpId', corpId);
        formdata.append('password', password);
        formdata.append('corpName', corpName);
    this.http.post(`${onboardingURL}organisations/sendReportOnEmail`,formdata)
        .subscribe(
            res => {
                resolve(res);
            },
            err => {
                console.log(err);
                reject(err);
            }
        );
});
return promise;
  }

  public sendLoginLinkonMail(corpName:any,toEmail:any): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      var obj ={
      'corpName': corpName,
      'toEmail': toEmail
      }

      this.http.post(`${onboardingURL}organisations/sendThirdPartyLoginEmail`,obj)
          .subscribe(
              res => {
                  resolve(res);
              },
              err => {
                  console.log(err);
                  reject(err);
              }
          );
    });
    return promise;
  }

    public sendCardonMail(corpName:any,cardData:any,corpId:any): Promise<any> {
      let promise = new Promise((resolve, reject) => {
        var obj ={
        'corpName': corpName,
        'data': cardData,
        'corpId':corpId
        }
  
        this.http.post(`${onboardingURL}organisations/sendCardDetailsOnEmail`,obj)
            .subscribe(
                res => {
                    resolve(res);
                },
                err => {
                    console.log(err);
                    reject(err);
                }
            );
    });
    return promise;
      }

  public exportAsExcelFileMultiple(json: any[],jsonObject: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonObject);
    const workbook: XLSX.WorkBook = { Sheets: { 'Successful': worksheet, 'Unsuccessful': worksheet1 }, SheetNames: ['Successful','Unsuccessful'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  
  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  private downloadFile(blob: any, type: string, filename: string): string {
    const url = window.URL.createObjectURL(blob); // <-- work with blob directly
  
    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
  
    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
    return url;
  }
}
