import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrgserviceService } from 'src/app/_api/orgservice.service';
import { Config } from 'src/app/config';
const path = new Config().getVcnUrl();

@Component({
  selector: 'app-update-user-details',
  templateUrl: './update-user-details.component.html',
  styleUrls: ['./update-user-details.component.css']
})
export class UpdateUserDetailsComponent implements OnInit {
  formdetailsrow: boolean=true;
  listbillerrow: boolean=false;
  billersaddedlist: any=[];
  selectedIndex: any;
  billdata:any={};
  corpId:any;
  authMatrix:any;
  settings = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false
  }
  headChange:any;
  dropdownData:any;
  constructor(private spinner: NgxSpinnerService,private router: Router,public datepipe: DatePipe,private orgservice: OrgserviceService,private http: HttpClient,private toastr: ToastrService) { }

  ngOnInit() {
  }

      submitdata(){
          this.spinner.show()
          this.billdata.mobile_no = this.billdata.mobile_no.toString()
          this.submitBulkUserback(this.billdata).subscribe(data => {
            this.spinner.hide()
            //alert("Submitted SUccessfully")
            if(data["log"][0] == 1) {
           this.router.navigate(['/card-request-summary']);
           this.toastr.success("Supplier added successfully!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
          } else {
            this.toastr.error("Email Id not found! Please check email id!","Alert",{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          }
         },error => {
           console.log(error)
           this.spinner.hide()
           this.toastr.error("Something went wrong! Please try again!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        });
      }

      submitBulkUserback(users:any){
        console.log(users)
        return this.http.post(path+`/user/updateUsers`, users);
    }

    // checkuser() {
    //   this.spinner.show()
    //   this.orgservice.checkuser({"email":this.billdata.email}).then(data=>{
    //     this.spinner.hide()
    //     if(data.message == "No user found!") {

    //     } else {
    //       this.billdata.email = null
    //       this.toastr.error("Email Id already exist!","Alert",{
    //         timeOut:3000,
    //         positionClass:'toast-top-center'
    //         })
    //     }
    //   },err=>{
    //     this.spinner.hide()
    //   })
    // }
}
