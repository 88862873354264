import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/_api/loader.service';
import { OrgserviceService } from 'src/app/_api/orgservice.service';

@Component({
  selector: 'app-org-list',
  templateUrl: './org-list.component.html',
  styleUrls: ['./org-list.component.css']
})
export class OrgListComponent implements OnInit {

  orglist:any;
  selectedIndex: any;
  constructor(private spinner: NgxSpinnerService,private orgService: OrgserviceService,private loaderService: LoaderService,private router: Router, private toastr: ToastrService) { }

  ngOnInit() {
    this.loadOrganizations();
  }

  loadOrganizations(){
    this.spinner.show()
    this.orgService.getOrganisationList().then(data=>{
     this.orglist = data['data'];
     console.log(this.orglist)
     this.spinner.hide();
    },error=>{
      console.log(error.message);
      this.spinner.hide();
    }).catch(err=>{
      console.log(err.message);
      this.spinner.hide();
    })
  }

  loadSupLogs(id:number,index){
    this.selectedIndex = index;
  }

  redirectToDetails(id,orgObj){
    if(orgObj.auth_matrix != null){
      this.router.navigate(['/org-details'],{queryParams:{orgId:id}});
    }
    else {
      this.toastr.warning("Corporate Details are not yet registered by CCADMIN", 'Alert', {
        positionClass:'toast-top-center',
        timeOut:3000
      })
    }
  }

}
