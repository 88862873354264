import { Component, OnInit } from '@angular/core';
import {VcncorpService} from '../../_api/vcncorp.service'
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router';
import * as $ from 'jquery';
declare var $: any;
@Component({
  selector: 'app-pending-contact',
  templateUrl: './pending-contact.component.html',
  styleUrls: ['./pending-contact.component.css']
})
export class PendingContactComponent implements OnInit {
  filedata:any =[]
  public flag: any;
  public checkedValueArray: any = [];
  public cntChk: any;
  public temp: any;
  public ids: any = [];
  selectall:boolean=false;
  rowsOnPage = 1000;
  sortBy = "amount";
  sortOrder = "asc";
  rolename:string;
  selectedAll: any;
  constructor(private spinner: NgxSpinnerService,private toastr: ToastrService,private route:Router,private vcncorp:VcncorpService) { }

  ngOnInit() {
   
   
    // $("input[name='mainmenu']").click(function () {
    //   var is_checked = $(this).is(":checked");
    //   $("input[name='submenu']").prop("checked", is_checked);
    // });
    this.rolename=localStorage.getItem('rolename')
    this.loadData()
  }


  private loadData(){
    this.spinner.show()
    this.vcncorp.getUpdateContactList().then(resp=>{
      console.log(resp)
      if(resp['message']=='Request list with file data'){
        this.filedata=resp['data']
        this.spinner.hide()
      }else{
        this.spinner.hide()
        this.toastr.error("Something Went Wrong!!","Error",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      console.log(error)
      this.spinner.hide()
      this.toastr.error("Failed to Load!!","Error",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    })
  }

  change(id): void {
    this.flag = 0;
  for (var i = 0; i < this.checkedValueArray.length; i++) {
    if (this.checkedValueArray[i] == id) {
      this.checkedValueArray.splice(i, 1);
      this.flag = 1;
    }
  }
  if (this.flag == 0) {
    this.checkedValueArray.push(id);
   
  }
  
  if (this.checkedValueArray.length > 0) {
    this.temp = true;
    if(this.checkedValueArray.length<this.filedata.length){
      this.selectall=false
    }else{
      this.selectall=true;
      this.cntChk = 1;
    }
    console.log(this.selectall)
   
  }
  else {
    this.temp = false;
    if(this.checkedValueArray.length<this.filedata.length){
      this.selectall=false
    }else{
      this.selectall=true;
    }
    console.log(this.selectall)
   
  }
  console.log(this.checkedValueArray)
  }

  changeAll(pendingContacts): void {
    if(this.checkedValueArray.length==this.filedata.length){
      this.cntChk=1
      }else{
      this.checkedValueArray = [];
      this.cntChk=0
      }
      console.log(this.selectall)
      if (this.cntChk == 0) {
        this.cntChk = 1;
        this.temp = true;
        this.selectall=true;
        for (var i = 0; i < pendingContacts.length; i++) {
          this.checkedValueArray[i] = pendingContacts[i].id;
        }
        this.cntChk = 0;
      }
     
      else {
        this.cntChk = 0;
        this.temp = false;
        this.checkedValueArray = [];
      }
      console.log(this.checkedValueArray)
  }

  selectAll() {
    for (var i = 0; i < this.filedata.length; i++) {
      this.filedata[i].selected = this.filedata;
    }
  }

  checkIfAllSelected() {
    this.selectedAll = this.filedata.every(function(item:any) {
        return item.selected == true;
      })
  }

  updateContacts(){
    this.spinner.show()
    if (this.temp == true) {
      var params={
        "requestIds":this.checkedValueArray
      }
      this.vcncorp.approveForContacts(params).then(resp=>{
        console.log(resp)
        if(resp['message']=='Mobile Update!'){
          this.spinner.hide()
              this.route.navigate(['/mobile-approve-success']);
        }else{
          this.spinner.hide()
          this.toastr.error("Something Went Wrong!!","Error",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
      },error=>{
        this.spinner.hide()
        this.toastr.error("Failed to Update!!","Error",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
        console.log(error)
      })
    } else {
      this.spinner.hide()
      this.toastr.warning("Atleast Select one entry to Update!!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
  }

}
