import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccoutsetupService } from 'src/app/_api/accoutsetup.service';
import { FirstLoginService } from 'src/app/_api/first-login.service';
import { VcnService } from 'src/app/_api/vcn.service';
declare var Leegality: any;
@Component({
  selector: 'app-accountsetup',
  templateUrl: './accountsetup.component.html',
  styleUrls: ['./accountsetup.component.css']
})
export class AccountsetupComponent implements OnInit {

  authcode:any;
  userdata:any;
  authmatrix:any;
  useremail:any;
  password:any;
  cnfpassword:any;
  validPassword:boolean=false;
  orgdata:any={};
  contractDetails:any;
  userDetails:any=[];
  groupDetails:any=[];
  ruledetails:any=[];
  checkvalarrayuser:any=[];
  checkvalarraygroup:any=[];
  checkvalarrarule:any=[];
  checkidsuser:any=[];
  checkidsgroup:any=[];
  checkidsrule:any=[];
  isSelectedUser = false;
  isSelectedGroup = false;
  isSelectedRule = false;
  isAllSelectedUser = false;
  isAllSelectedRule = false;
  isAllSelectedGroup = false;
  enableLogin:boolean=true;
  checkValue:boolean=false;
  public testuser: any;
  public flaguser: any;
  public cntChkuser: any;
  public testgrp: any;
  public flaggrp: any;
  public cntChkgrp: any;
  public testrule: any;
  public flagrule: any;
  public cntChkrule: any;
  orgid:any;
  box1 : boolean = true;
  box2 : boolean = false;
  box3 : boolean = false;
  box4 : boolean = false;
  box5 : boolean = false;
  box6 : boolean = false;
  box7 : boolean = false;
  expandedViewOrg:boolean=false
  selectedIndex = -1;
  commentuser:any;
  commentorg:any;
  commentgroup:any;
  commentrule:any;
  isViewuser:boolean;
  isUniuser:boolean;
  isBulkuser:boolean;
  isAppruser:boolean;
  isViewcard:boolean;
  isUnicard:boolean;
  isApprcard:boolean;
  isViewgroup:boolean;
  isUnigroup:boolean;
  isApprgroup:boolean;
  isRuleValidate:boolean;
  isUnirule:boolean;
  isRuleappr:boolean;
  isViewsup:boolean;
  isUnisup:boolean;
  isBulksup:boolean;
  isApprsup:boolean;
  isViewpay:boolean;
  isUnipay:boolean;
  isApprpay:boolean;
  isBulkpay:boolean;
  leegalityResponse: any;
  esignStatus:any;
  currentUser:any;
  obj = {
    logoUrl: "",
    callback: this.eSigncallback
  };
  
  constructor(private vcn:VcnService,private spinner: NgxSpinnerService,private firstloginService: FirstLoginService,private accountservice: AccoutsetupService,private toastr: ToastrService,private router: Router,private titleService:Title) { 
    this.titleService.setTitle("First Login");
  }

  ngOnInit() {
  }

  gotoSetpass(){
    console.log("Page Set Password")
    this.spinner.show()
    this.accountservice.validateauthcode(this.authcode).then(resp=>{
      console.log(resp)
      this.spinner.hide()
      if(resp['status']=='Success'){
        this.box1 = false;
        this.box2 = true;
        this.box3 = false;
        this.box4 = false;
        this.box5 = false;
        this.getUserDetails();
      } else if(resp["message"] == "No user found."){
        this.toastr.error("Please enter correct authcode!",'Error',{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
   
    },error=>{
      console.log(error)
      this.spinner.hide()
      this.toastr.error("Failed to validate authcode, please contact aquapay support team!",'Error',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    })
  }
  getUserDetails(){
    this.accountservice.getCurrentUser().then(resp => {
      console.log(resp)
      this.userdata=resp['data']
      this.authmatrix=resp['data']['auth_matrix']
      this.useremail=resp['data']['email']
      switch(this.userdata.onboarding_step){
        case "step1":
          this.box1 = false;
          this.box2 = true;
          this.box3 = false;
          this.box4 = false;
          this.box7 = false;
          break;
        case "step2":
          this.getContractDetails()
          this.box1 = false;
          this.box2 = false;
          this.box3 = true;
          this.box4 = false;
          this.box7 = false;
        break;
        case "step3":
          this.proceedNext()
          this.box1 = false;
          this.box2 = false;
          this.box3 = false;
          this.box4 = true;
          this.box7 = false;
        break;
        case "step4":
          this.getUserList()
          this.box1 = false;
          this.box2 = false;
          this.box3 = false;
          this.box4 = false;
          this.box7 = true;
        break;
        default:
          this.box1 = false;
          this.box2 = true;
          this.box3 = false;
          this.box4 = false;
          this.box7 = false;
          break
      }
    },error=>{
      console.log(error)
    })
  }

  CheckPassword(inputtxt) { 
    console.log(inputtxt)
    var passw=  /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;

    if (passw.test(inputtxt)) {
      console.log("Valid Password")
      this.validPassword=true;
  } else {
    this.toastr.error("Invalid Password, Passwords must contain at least eight characters, including atleast one uppercase, one lowercase letter , one number and one special character.!",'Alert',{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
      this.validPassword=false;
  }
}

eSigncallback(response) {

  this.leegalityResponse = response;
  console.log(this.leegalityResponse.message)
  if(this.leegalityResponse.message == "The document(s) has been signed successfully."){
    document.getElementById('message').style.color = "green";
    document.getElementById('message').innerHTML = response.message;
    document.getElementById('esignBtn').style.display = "none";
    document.getElementById('proceedBtn').style.display = "inline";
  }else{
    document.getElementById('message').style.color = "red";
    document.getElementById('message').innerHTML = response.error;
  }
  document.getElementById('proceedBtn').click()
}


openContract(){
    if(this.contractDetails != null){
      var leegality = new Leegality(this.obj);
      leegality.init();
      //You will get a signing url by uploading document from backend.
      var signingUrl = this.contractDetails.as_esign_link;
      leegality.esign(signingUrl);
    } else {
      this.toastr.error("No Contract Found! Please contact Aquapay Support Team.","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
}



setEsignStatus(){
  this.spinner.show()
  this.accountservice.setContractDetails({"contractId":this.contractDetails.id,"documentId":this.contractDetails.document_id}).then(resp=>{
    this.spinner.hide()
    console.log(resp)
    if(resp['status']=='Success'){
      this.proceedNext()
    } else {
      this.toastr.error("Something went wrong! Please contact Aquapay Support Team.","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
  },err=>{
    this.spinner.hide()
    this.toastr.error("Something went wrong! Please contact Aquapay Support Team.","Alert",{
      timeOut:3000,
      positionClass:'toast-top-center'
      })
  })
}

proceedNext(){
    this.accountservice.getOrgDetails().then(resp=>{
      console.log(resp)
      if(resp['status']=='Success'){
        this.orgdata=resp['data']
        this.box1 = false;
        this.box2 = false;
        this.box3 = false;
        this.box4 = true;
        this.box5 = false;
      }else{
        this.toastr.error("No Organisation found!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      console.log(error)
    })
}


  gotoAprOrg(){
    if(this.validPassword == true){
      if((this.password!=null || this.password!=undefined) && (this.cnfpassword!=null || this.cnfpassword!= undefined)){
        if(this.password==this.cnfpassword){
          this.spinner.show()
          this.accountservice.validatepassword(this.password).then(resp=>{
            this.spinner.hide()
            console.log(resp)
            if(resp['status']=='Success'){
            this.getContractDetails()
            this.box1 = false;
            this.box2 = false;
            this.box3 = true;
            this.box4 = false;
            this.box5 = false;            
            }else{
              this.toastr.error("Failed to update!","Alert",{
                timeOut:3000,
                positionClass:'toast-top-center'
                })
            }
          },error=>{
            this.spinner.hide()
            console.log(error)
            this.toastr.error("Failed to update!","Alert",{
              timeOut:3000,
              positionClass:'toast-top-center'
              })
          })
        }else{
          this.toastr.warning("Password not match!","Alert",{
            timeOut:3000,
            positionClass:'toast-top-center'
            })
        }
      }else{
        this.toastr.warning("Please fill the details first!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    } else {
      this.toastr.warning("Please enter the valid password as mentioned on the screen!",'Alert',{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
    
    console.log("Page Approve Organisation")
 

  }

  getContractDetails(){
    this.spinner.show()
    this.accountservice.getContractDetails().then(resp=>{
      this.spinner.hide()
      if(resp['status']=='Success'){
        this.contractDetails = resp["contractDetails"]
        this.esignStatus = this.contractDetails.as_esign_status
        if(this.esignStatus == "Success"){
          document.getElementById('esignBtn').style.display = "none";
          document.getElementById('proceedBtn').style.display = "inline";
        }
      } else {
        this.toastr.error("Something went wrong! Please contact Aquapay Support Team.","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },err=>{
      this.spinner.hide()
      console.log(err)
      this.toastr.error("Failed to get contract details!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    })
  }

  toggleOrgExpView(){
    this.expandedViewOrg=!this.expandedViewOrg
  }

  approrg(){
    this.spinner.show()
    this.accountservice.approveorg(this.orgdata['id']).then(resp=>{
      this.spinner.hide()
      if(resp['status']=='Success'){
        this.getUserList()
      }else{
        this.toastr.error("Failed to Approve Organisation, please Contact Aquapay Support team!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      this.spinner.hide()
      this.toastr.error("Failed to Approve Organisation, please Contact Aquapay Support team!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    })
  }

  rejorg(){
    this.accountservice.rejectorg(this.orgdata['id'],this.commentorg).then(resp=>{
      console.log(resp)
      if(resp['status']=='Success'){
      localStorage.removeItem('token')
      this.router.navigate(['/']);
      this.toastr.warning("Please request another Authentication Code with Updated details!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
      }else{
        this.toastr.error("Failed to Reject Organisation, please Contact Aquapay Support team!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      console.log(error)
      this.toastr.error("Failed to Reject Organisation, please Contact Aquapay Support team!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    })
  }

  getUserList(){
    this.spinner.show()
    this.accountservice.getUserList().then(resp=>{
      this.spinner.hide()
      this.userDetails=resp['data']
      this.changeAll(this.isAllSelectedUser)
      this.box1=false;
      this.box2=false;
      this.box3=false;
      this.box4=false;
      this.box5=false;
      this.box6=false;
      this.box7=true;
    },error=>{
      this.spinner.hide()
      this.toastr.error("Failed to load Users!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    })
  }
 
  selectexpanded(index){
    this.selectedIndex = index;
  }



  /**
   * check & uncheck functionality
   */

  change(user:any , index:number){
    var temp_index=-1;
   for(var i=0;i<this.checkvalarrayuser.length;i++){
     if(this.checkvalarrayuser[i]["_id"]==index){
       temp_index=i;
     }
   }

   if(temp_index>-1){
    this.checkvalarrayuser.splice(temp_index , 1);
   }else{
    user["_id"] = index;
    this.checkvalarrayuser.push(user);
   }
   console.log(this.checkvalarrayuser);
   if(this.checkvalarrayuser.length==0){
    this.isAllSelectedUser=false;
   }
   if(this.checkvalarrayuser.length==this.userDetails.length){
    this.isAllSelectedUser=true;
   }else{
    this.isAllSelectedUser=false;
   }
  }

  changegroup(group:any , index:number){
    var temp_index=-1;
   for(var i=0;i<this.checkvalarraygroup.length;i++){
     if(this.checkvalarraygroup[i]["_id"]==index){
       temp_index=i;
     }
   }

   if(temp_index>-1){
    this.checkvalarraygroup.splice(temp_index , 1);
   }else{
    group["_id"] = index;
    this.checkvalarraygroup.push(group);
   }
   console.log(this.checkvalarraygroup);
   if(this.checkvalarraygroup.length==0){
    this.isAllSelectedGroup=false;
   }
   if(this.checkvalarraygroup.length==this.groupDetails.length){
    this.isAllSelectedGroup=true;
   }else{
    this.isAllSelectedGroup=false;
   }
  }

  changerule(rule:any , index:number){
    var temp_index=-1;
   for(var i=0;i<this.checkvalarrarule.length;i++){
     if(this.checkvalarrarule[i]["_id"]==index){
       temp_index=i;
     }
   }

   if(temp_index>-1){
    this.checkvalarrarule.splice(temp_index , 1);
   }else{
    rule["_id"] = index;
    this.checkvalarrarule.push(rule);
   }
   console.log(this.checkvalarrarule);
   if(this.checkvalarrarule.length==0){
    this.isAllSelectedRule=false;
   }
   if(this.checkvalarrarule.length==this.ruledetails.length){
    this.isAllSelectedRule=true;
   }else{
    this.isAllSelectedRule=false;
   }
  }


  changeAll(checkedState:boolean){
   console.log(this.userDetails)
    if(!checkedState){
      this.checkvalarrayuser=[];
      for(var i=0;i<this.userDetails.length;i++){
        this.checkvalarrayuser.push(this.userDetails[i]);
        this.checkvalarrayuser[i]["_id"]=i;
      }
    }else{
      this.checkvalarrayuser=[];
    }

    //console.log(this.checkedValueArray)

    //console.log(this.checkedValueArray);
    this.isSelectedUser = !checkedState
    this.isAllSelectedUser=!checkedState
  }

  changeAllgroup(checkedState:boolean){
    console.log(checkedState);

    if(!checkedState){
      this.checkvalarraygroup=[];
      for(var i=0;i<this.groupDetails.length;i++){
        this.checkvalarraygroup.push(this.groupDetails[i]);
        this.checkvalarraygroup[i]["_id"]=i;
      }
    }else{
      this.checkvalarraygroup=[];
    }

    console.log(this.checkvalarraygroup);
    this.isSelectedGroup = !checkedState
    this.isAllSelectedGroup=!checkedState
  }

  changeAllrule(checkedState:boolean){
    console.log(checkedState);

    if(!checkedState){
      this.checkvalarrarule=[];
      for(var i=0;i<this.ruledetails.length;i++){
        this.checkvalarrarule.push(this.ruledetails[i]);
        this.checkvalarrarule[i]["_id"]=i;
      }
    }else{
      this.checkvalarrarule=[];
    }

    console.log(this.checkvalarrarule);
    this.isSelectedRule = !checkedState
    this.isAllSelectedRule=!checkedState
  }

  checkAll(checkedState:boolean){
    console.log(checkedState);

   
   
    this.isSelectedUser = !checkedState
    this.isSelectedGroup = !checkedState
    this.isSelectedRule = !checkedState
  }

  apprusr(){
    if(this.checkvalarrayuser.length>0){
      for(var i=0;i<this.checkvalarrayuser.length;i++){
        this.checkidsuser.push(this.checkvalarrayuser[i]['id']);
   
      }
    }
    console.log(this.checkidsuser)
    if(this.checkidsuser.length>0){
    this.accountservice.approveusers(this.checkidsuser).then(resp=>{
      console.log(resp)
      if(resp['status']=='Success'){

      }else{
        this.toastr.error("failed to approve users, please contact Aquapay Support team!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      console.log(error)
      this.toastr.error("failed to approve users, please contact Aquapay Support team!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    })
    }else{
      this.toastr.error("failed to load users to approve, please contact Aquapay Support team!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }

  }

  rejusr(){
    if(this.checkvalarrayuser.length>0){
      for(var i=0;i<this.checkvalarrayuser.length;i++){
        this.checkidsuser.push(this.checkvalarrayuser[i]['id']);
   
      }
    }
    console.log(this.checkidsuser)
    if(this.checkidsuser.length>0){
    this.accountservice.rejectusers(this.checkidsuser,this.commentuser).then(resp=>{
      console.log(resp)
      if(resp['status']=='Success'){
        localStorage.removeItem('token')
        this.router.navigate(['/']);
        this.toastr.warning("Please request another Authentication Code with Updated details!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }else{
        this.toastr.error("Failed to Reject Users, please contact Aquapay Support team!","Alert",{
          timeOut:3000,
          positionClass:'toast-top-center'
          })
      }
    },error=>{
      this.toastr.error("Failed to Reject Users, please contact Aquapay Support team!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
      console.log(error)
    })
    }else{
      this.toastr.error("failed to load users to reject, please contact Aquapay Support team!","Alert",{
        timeOut:3000,
        positionClass:'toast-top-center'
        })
    }
  }
  gotodashboard(){
    // this.storage.setData("chlogin_data", this.getToken);
    // this.storage.setData("rmlogin_data", this.getToken);
  
   // localStorage.setItem('rolename', 'aschecker');
   this.router.navigate(['/']);
    //  this.router.navigate(['/main/dashboard'],{queryParams:{msg:'firstloginas'}});
   }

   getprivileges(dual_role:any){
    if(dual_role=='maker'){
      this.isViewuser=false;
     this.isUniuser=false;
     this.isUnicard=false;
     this.isApprcard=false;
     this.isAppruser=false;
     this.isBulkuser=false;
     this.isApprpay=false;
     this.isApprsup=false;
     this.isViewcard=true;
     this.isUnipay=true;
     this.isUnisup=true;
     this.isBulksup=true;
     this.isBulkpay=true;
     this.isViewpay=true;
     this.isViewsup=true;
    }else if(dual_role=='ccmaker'){
      this.isViewuser=true;
      this.isUniuser=true;
      this.isUnicard=true;
      this.isApprcard=false;
      this.isAppruser=false;
      this.isBulkuser=true;
      this.isApprpay=false;
      this.isApprsup=false;
      this.isViewcard=true;
      this.isUnipay=true;
      this.isUnisup=true;
      this.isBulksup=true;
      this.isBulkpay=true;
      this.isViewpay=true;
      this.isViewsup=true;
    }else if(dual_role=='checker'){
      this.isViewuser=false;
      this.isUniuser=false;
      this.isUnicard=false;
      this.isApprcard=false;
      this.isAppruser=false;
      this.isBulkuser=false;
      this.isApprpay=true;
      this.isApprsup=true;
      this.isViewcard=true;
      this.isUnipay=false;
      this.isUnisup=false;
      this.isBulksup=false;
      this.isBulkpay=false;
      this.isViewpay=true;
      this.isViewsup=true;
    }else if(dual_role=='ccchecker'){
      this.isViewuser=true;
      this.isUniuser=true;
      this.isUnicard=true;
      this.isApprcard=false;
      this.isAppruser=false;
      this.isBulkuser=true;
      this.isApprpay=true;
      this.isApprsup=true;
      this.isViewcard=true;
      this.isUnipay=false;
      this.isUnisup=false;
      this.isBulksup=false;
      this.isBulkpay=false;
      this.isViewpay=true;
      this.isViewsup=true;
    }else if(dual_role=='aschecker'){
      this.isViewuser=true;
      this.isUniuser=false;
      this.isUnicard=false;
      this.isApprcard=true;
      this.isAppruser=true;
      this.isBulkuser=false;
      this.isApprpay=true;
      this.isApprsup=true;
      this.isViewcard=true;
      this.isUnipay=false;
      this.isUnisup=false;
      this.isBulksup=false;
      this.isBulkpay=false;
      this.isViewpay=true;
      this.isViewsup=true;
    }else if(dual_role=='as'){
      this.isViewuser=true;
      this.isUniuser=false;
      this.isUnicard=false;
      this.isApprcard=true;
      this.isAppruser=true;
      this.isBulkuser=false;
      this.isApprpay=false;
      this.isApprsup=false;
      this.isViewcard=true;
      this.isUnipay=false;
      this.isUnisup=false;
      this.isBulksup=false;
      this.isBulkpay=false;
      this.isViewpay=true;
      this.isViewsup=true;
    }else if(dual_role=='ccadmin'){
      this.isViewuser=true;
      this.isUniuser=true;
      this.isUnicard=true;
      this.isApprcard=true;
      this.isAppruser=true;
      this.isBulkuser=true;
      this.isApprpay=false;
      this.isApprsup=false;
      this.isViewcard=true;
      this.isUnipay=false;
      this.isUnisup=false;
      this.isBulksup=false;
      this.isBulkpay=false;
      this.isViewpay=true;
      this.isViewsup=true;
    }
   }
}
